/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

.note-image-css {
    width: 200px !important;
    height: inherit;
    display: initial !important;
    margin-top: 20px !important;
}

.dropdown .cdk-overlay-pane {
    // overflow:auto !important;
    min-width: 568px !important;
    font-size: 14px !important;
    top: 439px !important;
    left: 200px !important;
    transform: translateX(-16px) translateY(-10px);
}
.card-btn {
    float: right !important;
}
.mat-select-panel {
    min-width: 100% !important;
}
.user_name {
    font-size: 14px;
    font-weight: bold;
}
.comments-list .media {
    border-bottom: 1px dotted #ccc;
}
#upload_file {
    display: none;
}
.custom-modalbox {
    max-width: 100% !important;
    width: 100% !important;
    // height: inherit !important;
    mat-dialog-container {
        padding: 0 !important;
    }
}
.scrumboard-custom-modalbox{
    max-width: 100% !important;
    width: 100% !important;
    mat-dialog-container {
        padding: 0 !important;
    }
}
.board-icon-css{
    font-size: 20px !important;
}

.mat-slide-toggle {
    z-index: -1;
}

.align-btn {
    position: fixed;
    bottom: 0px;
    right: 15px;
    width: 100%;
    background-color: #eef2ff !important;
    padding: 15px 9px;
    z-index: 9;
}

.title-postion{
    position: fixed;
    width: 100% !important;
    z-index: 1;
    height: 101px;
    top: 64px;
    z-index: 9;
}

.date-btn {
    vertical-align: middle;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
}

.mat-error {
    font-size: 12px;
}

.singleField {
    padding-top: 10px;
}

.doubleField {
    margin-top: -5px;
}

.edit-btn {
    border-color: var(--fuse-primary) !important;
    cursor: pointer;
    width: fit-content;
    background-color: white;
    color: #a1a3a5;
    border-radius: 50%;
    padding: 3px 4px 4px 4px;
    border: 1px solid var(--fuse-primary) !important;
}

.edit-btn:hover {
    background: var(--fuse-primary) !important;
    border-color:  var(--fuse-primary) !important;
    color: var(--fuse-on-primary) !important;
}

.print-excel {
    cursor: pointer;
    width: fit-content;
    background-color: rgb(255, 255, 255);
    color: #a1a3a5;
    border-radius: 15%;
    padding: 6px 4px 6px;
    border: 1px solid var(--fuse-primary) !important;
}

.print-excel:hover {
    background: var(--fuse-primary) !important;
    border-color:  var(--fuse-primary) !important;
    color: var(--fuse-on-primary) !important;
}

.delete-btn {
    border-color: #d11a2a !important;
    cursor: pointer;
    width: fit-content;
    background-color: white;
    color: #a1a3a5;
    border-radius: 50%;
    padding: 3px 4px 4px 4px;
    border: 1px solid #d11a2a !important;
}

.delete-btn:hover {
    background: #d11a2a !important;
    border-color: #d11a2a !important;
    color: #fff;
}

.manageButton:hover{
    background-color: var(--fuse-primary) !important;
    color: white!important;
}
.background-clr {
    color: var(--fuse-primary) !important;
}

.mat-button-toggle-group .mat-button-toggle.mat-button-toggle-checked .mat-button-toggle-label-content {
    color: var(--fuse-on-primary) !important;
    background: var(--fuse-primary) !important;
}
.mat-button-toggle-group .mat-button-toggle {
    border-radius: unset !important;
}

.mat-card {
    padding: 0 !important;
}

// .mat-button-toggle-group .mat-button-toggle .mat-button-toggle-label-content {
//     color: #1E293B;
// }

.grid-color {
    color: var(--fuse-primary) !important;
}

.save-btns {
    background-color: var(--fuse-primary) !important;
    color:var(--fuse-on-primary) !important;
}

.btn-radius{
    border-radius: 2px !important;
}

.sticky-row {
    position: sticky;
    bottom: -25px;
    z-index: 10;
}

.primaryText{
    color:var(--fuse-primary) !important;
  }

//   .Custom-Container{
//     mat-dialog-container{
//     padding-bottom:0 !important;
//   }
//   }

.cdk-overlay-container .Custom-container + * > .cdk-overlay-pane {
    max-width: 100vw !important;
}

  .Custom-container1 {
    mat-dialog-container{
    padding: 24px 0 0 !important;
    overflow:hidden !important;
  }
}

.Custom-container2 {
    mat-dialog-container{
    padding: 0 !important;
  }
}

  
.mat-tab-group .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label.mat-tab-label-active{
    color: var(--fuse-on-primary) !important;
    background: var(--fuse-primary) !important;
    border-radius: unset !important;
}
.dark .mat-tab-group .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label.mat-tab-label-active {
    color: var(--fuse-on-primary) !important;
    background: var(--fuse-primary) !important;
}

// .mat-tab-group .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label+.mat-tab-label {
//     color: #000;
// }

.mat-tab-list .mat-tab-labels {
    padding: 0 !important;
    width: 28% !important;
}

.ngx-pagination .current {
    color: var(--fuse-on-primary) !important;
    background: var(--fuse-primary) !important;
}
.lblue {
    background: lightblue;
}

.disabled{
    pointer-events: none;
    opacity: 0.5;
}



  /* loading.component.css */
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6.8px);
    -webkit-backdrop-filter: blur(6.8px);
    border: 1px solid rgba(255, 255, 255, 0.16);
    // z-index: 10;
  }
  
.loader {
    width: 75px;
    aspect-ratio: 1;
    display: grid;
    border: 4px solid #0000;
    border-radius: 50%;
    border-right-color: var(--fuse-primary);
    animation: l15 1s infinite linear;
  }
  .loader::before,
  .loader::after {    
    content: "";
    grid-area: 1/1;
    margin: 2px;
    border: inherit;
    border-radius: 50%;
    animation: l15 2s infinite;
  }
  .loader::after {
    margin: 8px;
    animation-duration: 3s;
  }
  @keyframes l15{ 
    100%{transform: rotate(1turn)}
  }

  
  @media (min-width:1280px) {
    .account-settings{
        padding: 3rem 0!important;
    } 
}

//responsive
@media (min-width:960px) {
    .account-settings{
        padding: 1rem 0!important;
    } 
}
  .account-settings-1{
    overflow-y: scroll !important;
    overflow-x: hidden;
    height: 26rem;
    width: auto;
   
  }

  .account-settings-2{
    overflow-y: scroll !important;
    overflow-x: hidden;
    height: 23rem;
    width: auto;
   
  }

  @media (max-width:1240px) {
    .account-settings-1{
        overflow-y: scroll !important;
        overflow-x: hidden;
        height: 21rem !important;
        width: auto;
       
      }
    
      .account-settings-2{
        overflow-y: scroll !important;
        overflow-x: hidden;
        height: 21rem !important;
        width: auto;
       
      }

    }

// ================ SNACKBAR STYLES ================ //
.success-snackbar {
    border-radius: 4px;
    background-color: #019301 !important;

    .mdc-snackbar__surface {
        background-color: #019301 !important;
        border-radius: 4px;
        margin: 0 !important;
        position: absolute;
        right: 10px;
        top: 60px;
    }

    .mdc-snackbar__label {
        font-weight: 500 !important;
        color: #fff !important;
    }
}

.error-snackbar {
    border-radius: 4px;
    background-color: #de3a3a !important;

    .mdc-snackbar__surface {
        background-color: #de3a3a !important;
        border-radius: 4px;
    }

    .mdc-snackbar__label {
        font-weight: 500 !important;
        color: #fff !important;
    }

}

.warning-snackbar{
    border-radius: 4px;
    background-color: #ebeb00 !important;

    .mdc-snackbar__surface {
        background-color: #ebeb00 !important;
        border-radius: 4px;
    }

    .mdc-snackbar__label {
        font-weight: 500 !important;
        color: #000 !important;
    }

    .mat-mdc-snack-bar-actions {
        .mat-mdc-button.mat-mdc-snack-bar-action {
            color: #000 !important;
        }
    }
}

.mat-mdc-snack-bar-container .mdc-snackbar__label {
    max-width: 300px;
}

.edit-icon:hover {
    color: green;
}

.cancel-icon:hover {
    color: var(--fuse-primary);
}

.del-icon:hover {
    color: red;
}
.settings-res{
    margin-top: 0;
    }
    @media (max-width:600px) {
        .settings-res{
            margin-top: -16px;   
    }
    }
    .settings-responce{
        flex-direction: column !important;
    }
    @media (max-width:600px) {
        .settings-responce{
            flex-direction: row !important;
    }
    }
    .setting-padding{
        margin-top: 0;
        padding-left: 0;
    }
    @media (max-width:600px) {
        .setting-padding {
            margin-top: 6px !important;
            padding-left: 11px !important ;
    }
}
.scrollable-content {
    max-height: calc(100vh - 225px);
    overflow-x: hidden;
    overflow-y: auto;
    padding-right:10px;
}
@media (max-width: 450px) {
    .hrsMargin{
        margin-left: -10px !important;
        margin-right: -10px !important;
    }
    
}
.taskView-wrap{
    overflow-wrap: anywhere !important;
}

.ml2{
    margin-left: 0.5rem !important;
}

@media (max-width: 353px) {

    .wfull{
        width:100% !important
    }
    .ml0{
        margin-left: 0 !important;
        margin-top: 0.5rem;
    }

    .mb1{
        margin-bottom: 1rem !important;
    }
    
}

.btn-primary:hover {
    color: var(--fuse-accent) !important;
    background-color: var(--fuse-primary) !important;
    border-color: var(--fuse-primary) !important;
}

.btn-primary {
    color: var(--fuse-accent) !important;
    background-color: var(--fuse-primary-300) !important;
    border-color: var(--fuse-primary-300) !important;
}

a {
    color: var(--fuse-primary);
}

a:hover {
    color: var(--fuse-accent);
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    background: var(--fuse-primary) !important;
    color: var(--fuse-accent-100) !important;
    border-color: var(--fuse-primary) !important;
}


.btn-success.disabled, .btn-success:disabled {
    color: var(--fuse-primary) !important;
    background-color: var(--fuse-accent-300) !important;
    border-color: var(--fuse-accent-300) !important;
}


.mat-mdc-row {
  height: 2.5rem !important;
}

.small-panel{
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.fuse-vertical-navigation-content{
    background: var(--fuse-primary-900);
    color: var(--fuse-secondary-100) !important;
}

.fuse-vertical-navigation .fuse-vertical-navigation-wrapper .fuse-vertical-navigation-content {
    background: var(--fuse-primary-900);
    color: var(--fuse-secondary-100) !important;
}

.primary-bubble{
    background: var(--fuse-primary) !important;
    color: var(--fuse-accent-100) !important;
}
.primary-text-bubble{
    --tw-text-opacity: 1 !important;
    color: var(--fuse-primary) !important;
}

.secondary-text-bubble{
    --tw-text-opacity: 1 !important;
    color: var(--fuse-secondary) !important;
}
.secondary-bubble{
    background: var(--fuse-secondary-500) !important;
    color: var(--fuse-primary) !important;
}

.accent-text-bubble{
    --tw-text-opacity: 1 !important;
    color: var(--fuse-accent-200) !important;
}

.accent-bubble{
    background: var(--fuse-accent-200) !important;
    color: var(--fuse-primary) !important;
}

.grey-bubble {
    background-color: #6B7280 !important;
    color: #F9FAFB !important;
}

.grey-text-bubble{
    --tw-text-opacity: 1 !important;
    color: #6B7280 !important;
}

.nav-link.active{
    background: var(--fuse-primary) !important;
    color: var(--fuse-accent-100) !important;
}

.nav-link.active .mat-icon{
    background: var(--fuse-primary) !important;
    color: var(--fuse-accent-100) !important;
}

.back-icon{
    background: var(--fuse-warn) !important;
    color: var(--fuse-accent-100) !important;
}

/* Specific styles for each status */
.Active {
    background-color: #4caf50; /* Green */
}
  
.Inactive, .In-Active, .Hold {
    background-color: #9e9e9e; /* Gray */
}

.Saved, .NA, .New-Request, .In-Progress {
    background-color: #2196f3; /* Blue */
}

.Submitted, .Created, .Under-Review, .AIP, .Pending {
    background-color: #ffc107; /* Amber */
}

.Approved, .Posted, .Completed {
    background-color: #4caf50; /* Green */
}

.Rejected {
    background-color: #f44336; /* Red */
}

.Pushedback {
    background-color: #ff9800; /* Orange */
}

.Active,
.Inactive,
.In-Active,
.Saved,
.Submitted,
.AIP,
.Approved,
.Rejected,
.Pushedback,
.NA,
.Created,
.Posted,
.New-Request,
.Under-Review,
.Hold,
.In-Progress,
.Pending,
.Completed {
    min-width: 5rem;
    max-width: 7rem;
}

.has-error .form-control {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.has-error .control-label {
    color: #dc3545;
 }
 @media (max-width: 590px) {
    .mobile-search{
        transform: none !important;
        height: 4rem !important;
    }
 }

.bg-fuse-primary {
    background-color: var(--fuse-primary) !important;
}