/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import third party library styles here.
/* ----------------------------------------------------------------------------------------------------- */

/* Perfect scrollbar */
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

/* Quill */
// @import '~quill/dist/quill.snow.css';

@import './scss/bootstrap.scss';
@import './variables.scss';
//@import './bootswatch.scss';
@import '@ctrl/ngx-emoji-mart/picker';

// ionic styles
@import '@ionic/angular/css/core.css';
@import '@ionic/angular/css/normalize.css';
// @import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';
@import 'theme/variables.css';

// $fa-font-path: './fonts/fonts/';
@import './fonts/scss//font-awesome.scss';
