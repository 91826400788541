/* Set the base colors for light themes */
/* Set the base colors for dark themes */
/* Include the core Angular Material styles */
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, transparent);
  color: var(--mat-app-text-color, inherit);
}

/* Create a base theme without any color to set the density and typography */
html {
  --mat-option-label-text-font: theme("fontFamily.sans");
  --mat-option-label-text-line-height: 1.5rem;
  --mat-option-label-text-size: 0.875rem;
  --mat-option-label-text-tracking: normal;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: theme("fontFamily.sans");
  --mat-optgroup-label-text-line-height: 1.5rem;
  --mat-optgroup-label-text-size: 0.875rem;
  --mat-optgroup-label-text-tracking: normal;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}

html {
  --mat-card-title-text-font: theme("fontFamily.sans");
  --mat-card-title-text-line-height: 1.5rem;
  --mat-card-title-text-size: 0.875rem;
  --mat-card-title-text-tracking: normal;
  --mat-card-title-text-weight: 400;
  --mat-card-subtitle-text-font: theme("fontFamily.sans");
  --mat-card-subtitle-text-line-height: 1.25rem;
  --mat-card-subtitle-text-size: 0.875rem;
  --mat-card-subtitle-text-tracking: normal;
  --mat-card-subtitle-text-weight: 600;
}

html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-supporting-text-font: theme("fontFamily.sans");
  --mdc-plain-tooltip-supporting-text-size: 0.75rem;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: normal;
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}

html {
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
}

html {
  --mdc-filled-text-field-label-text-font: theme("fontFamily.sans");
  --mdc-filled-text-field-label-text-size: 0.875rem;
  --mdc-filled-text-field-label-text-tracking: normal;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: theme("fontFamily.sans");
  --mdc-outlined-text-field-label-text-size: 0.875rem;
  --mdc-outlined-text-field-label-text-tracking: normal;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: theme("fontFamily.sans");
  --mat-form-field-container-text-line-height: 1.5rem;
  --mat-form-field-container-text-size: 0.875rem;
  --mat-form-field-container-text-tracking: normal;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 0.875rem;
  --mat-form-field-subscript-text-font: theme("fontFamily.sans");
  --mat-form-field-subscript-text-line-height: 1rem;
  --mat-form-field-subscript-text-size: 0.75rem;
  --mat-form-field-subscript-text-tracking: normal;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-select-arrow-transform: translateY(-8px);
}

html {
  --mat-select-trigger-text-font: theme("fontFamily.sans");
  --mat-select-trigger-text-line-height: 1.5rem;
  --mat-select-trigger-text-size: 0.875rem;
  --mat-select-trigger-text-tracking: normal;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
}

html {
  --mdc-dialog-subhead-font: theme("fontFamily.sans");
  --mdc-dialog-subhead-line-height: 1.5rem;
  --mdc-dialog-subhead-size: 0.875rem;
  --mdc-dialog-subhead-weight: 400;
  --mdc-dialog-subhead-tracking: normal;
  --mdc-dialog-supporting-text-font: theme("fontFamily.sans");
  --mdc-dialog-supporting-text-line-height: 1.5rem;
  --mdc-dialog-supporting-text-size: 0.875rem;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: normal;
}

.mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: theme("fontFamily.sans");
  --mdc-chip-label-text-line-height: 1.5rem;
  --mdc-chip-label-text-size: 0.875rem;
  --mdc-chip-label-text-tracking: normal;
  --mdc-chip-label-text-weight: 400;
}

html {
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}

html {
  --mdc-switch-state-layer-size: 40px;
}

html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-font: theme("fontFamily.sans");
  --mdc-form-field-label-text-line-height: 1.5rem;
  --mdc-form-field-label-text-size: 0.875rem;
  --mdc-form-field-label-text-tracking: normal;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}

html {
  --mdc-radio-state-layer-size: 40px;
  --mat-radio-touch-target-display: block;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-font: theme("fontFamily.sans");
  --mdc-form-field-label-text-line-height: 1.5rem;
  --mdc-form-field-label-text-size: 0.875rem;
  --mdc-form-field-label-text-tracking: normal;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
}

html {
  --mdc-slider-label-label-text-font: theme("fontFamily.sans");
  --mdc-slider-label-label-text-size: 0.875rem;
  --mdc-slider-label-label-text-line-height: 1.25rem;
  --mdc-slider-label-label-text-tracking: normal;
  --mdc-slider-label-label-text-weight: 600;
}

html {
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
}

html {
  --mat-menu-item-label-text-font: theme("fontFamily.sans");
  --mat-menu-item-label-text-size: 0.875rem;
  --mat-menu-item-label-text-tracking: normal;
  --mat-menu-item-label-text-line-height: 1.5rem;
  --mat-menu-item-label-text-weight: 400;
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 0;
}

html {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 32px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mdc-list-list-item-label-text-font: theme("fontFamily.sans");
  --mdc-list-list-item-label-text-line-height: 1.5rem;
  --mdc-list-list-item-label-text-size: 0.875rem;
  --mdc-list-list-item-label-text-tracking: normal;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: theme("fontFamily.sans");
  --mdc-list-list-item-supporting-text-line-height: 1.5rem;
  --mdc-list-list-item-supporting-text-size: 0.875rem;
  --mdc-list-list-item-supporting-text-tracking: normal;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: theme("fontFamily.sans");
  --mdc-list-list-item-trailing-supporting-text-line-height: 1rem;
  --mdc-list-list-item-trailing-supporting-text-size: 0.75rem;
  --mdc-list-list-item-trailing-supporting-text-tracking: normal;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font: 400 1rem / 1.75rem theme("fontFamily.sans");
  letter-spacing: normal;
}

html {
  --mat-paginator-container-size: 56px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
}

html {
  --mat-paginator-container-text-font: theme("fontFamily.sans");
  --mat-paginator-container-text-line-height: 1rem;
  --mat-paginator-container-text-size: 0.75rem;
  --mat-paginator-container-text-tracking: normal;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 0.75rem;
}

html {
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: theme("fontFamily.sans");
  --mat-tab-header-label-text-size: 0.875rem;
  --mat-tab-header-label-text-tracking: normal;
  --mat-tab-header-label-text-line-height: 0.875rem;
  --mat-tab-header-label-text-weight: 500;
}

html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}

html {
  --mdc-checkbox-state-layer-size: 40px;
  --mat-checkbox-touch-target-display: block;
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-font: theme("fontFamily.sans");
  --mdc-form-field-label-text-line-height: 1.5rem;
  --mdc-form-field-label-text-size: 0.875rem;
  --mdc-form-field-label-text-tracking: normal;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
}

html {
  --mdc-text-button-container-height: 36px;
  --mdc-filled-button-container-height: 36px;
  --mdc-outlined-button-container-height: 36px;
  --mdc-protected-button-container-height: 36px;
  --mat-text-button-touch-target-display: block;
  --mat-filled-button-touch-target-display: block;
  --mat-protected-button-touch-target-display: block;
  --mat-outlined-button-touch-target-display: block;
}

html {
  --mdc-text-button-label-text-font: theme("fontFamily.sans");
  --mdc-text-button-label-text-size: 0.875rem;
  --mdc-text-button-label-text-tracking: normal;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-label-text-transform: none;
  --mdc-filled-button-label-text-font: theme("fontFamily.sans");
  --mdc-filled-button-label-text-size: 0.875rem;
  --mdc-filled-button-label-text-tracking: normal;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-label-text-transform: none;
  --mdc-outlined-button-label-text-font: theme("fontFamily.sans");
  --mdc-outlined-button-label-text-size: 0.875rem;
  --mdc-outlined-button-label-text-tracking: normal;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-label-text-transform: none;
  --mdc-protected-button-label-text-font: theme("fontFamily.sans");
  --mdc-protected-button-label-text-size: 0.875rem;
  --mdc-protected-button-label-text-tracking: normal;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-label-text-transform: none;
}

html {
  --mdc-icon-button-icon-size: 24px;
}

html {
  --mat-icon-button-touch-target-display: block;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

html {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
}

html {
  --mat-fab-touch-target-display: block;
  --mat-fab-small-touch-target-display: block;
}

html {
  --mdc-extended-fab-label-text-font: theme("fontFamily.sans");
  --mdc-extended-fab-label-text-size: 0.875rem;
  --mdc-extended-fab-label-text-tracking: normal;
  --mdc-extended-fab-label-text-weight: 500;
}

html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-supporting-text-font: theme("fontFamily.sans");
  --mdc-snackbar-supporting-text-line-height: 1.5rem;
  --mdc-snackbar-supporting-text-size: 0.875rem;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

html {
  --mat-table-header-headline-font: theme("fontFamily.sans");
  --mat-table-header-headline-line-height: 1.25rem;
  --mat-table-header-headline-size: 0.875rem;
  --mat-table-header-headline-weight: 600;
  --mat-table-header-headline-tracking: normal;
  --mat-table-row-item-label-text-font: theme("fontFamily.sans");
  --mat-table-row-item-label-text-line-height: 1.5rem;
  --mat-table-row-item-label-text-size: 0.875rem;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: normal;
  --mat-table-footer-supporting-text-font: theme("fontFamily.sans");
  --mat-table-footer-supporting-text-line-height: 1.5rem;
  --mat-table-footer-supporting-text-size: 0.875rem;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: normal;
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
}

html {
  --mat-badge-text-font: theme("fontFamily.sans");
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

html {
  --mat-bottom-sheet-container-shape: 4px;
}

html {
  --mat-bottom-sheet-container-text-font: theme("fontFamily.sans");
  --mat-bottom-sheet-container-text-line-height: 1.5rem;
  --mat-bottom-sheet-container-text-size: 0.875rem;
  --mat-bottom-sheet-container-text-tracking: normal;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-legacy-button-toggle-label-text-font: theme("fontFamily.sans");
  --mat-legacy-button-toggle-label-text-line-height: 1.5rem;
  --mat-legacy-button-toggle-label-text-size: 0.875rem;
  --mat-legacy-button-toggle-label-text-tracking: normal;
  --mat-legacy-button-toggle-label-text-weight: 400;
  --mat-standard-button-toggle-label-text-font: theme("fontFamily.sans");
  --mat-standard-button-toggle-label-text-line-height: 1.5rem;
  --mat-standard-button-toggle-label-text-size: 0.875rem;
  --mat-standard-button-toggle-label-text-tracking: normal;
  --mat-standard-button-toggle-label-text-weight: 400;
}

html {
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-calendar-controls {
  --mat-icon-button-touch-target-display: none;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

html {
  --mat-datepicker-calendar-text-font: theme("fontFamily.sans");
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 0.875rem;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 0.875rem;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-divider-width: 1px;
}

html {
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-expansion-header-text-font: theme("fontFamily.sans");
  --mat-expansion-header-text-size: 0.875rem;
  --mat-expansion-header-text-weight: 600;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: theme("fontFamily.sans");
  --mat-expansion-container-text-line-height: 1.5rem;
  --mat-expansion-container-text-size: 0.875rem;
  --mat-expansion-container-text-tracking: normal;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 0.875rem;
  --mat-grid-list-tile-header-secondary-text-size: 0.75rem;
  --mat-grid-list-tile-footer-primary-text-size: 0.875rem;
  --mat-grid-list-tile-footer-secondary-text-size: 0.75rem;
}

html {
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-stepper-container-text-font: theme("fontFamily.sans");
  --mat-stepper-header-label-text-font: theme("fontFamily.sans");
  --mat-stepper-header-label-text-size: 0.875rem;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 0.875rem;
  --mat-stepper-header-selected-state-label-text-size: 0.875rem;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-toolbar-title-text-font: theme("fontFamily.sans");
  --mat-toolbar-title-text-line-height: 1.5rem;
  --mat-toolbar-title-text-size: 0.875rem;
  --mat-toolbar-title-text-tracking: normal;
  --mat-toolbar-title-text-weight: 400;
}

html {
  --mat-tree-node-min-height: 48px;
}

html {
  --mat-tree-node-text-font: theme("fontFamily.sans");
  --mat-tree-node-text-size: 0.875rem;
  --mat-tree-node-text-weight: 400;
}

/* Loop through user themes and generate Angular Material themes */
/* Generate the palettes */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define a light & dark Angular Material theme with the generated palettes */
/* Merge the custom base colors with the generated themes */
/* Generate and encapsulate Angular Material themes */
.theme-default .light,
.theme-default.light {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-option-selected-state-label-text-color: #4f46e5;
  --mat-option-label-text-color: #1e293b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-optgroup-label-text-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
  --mat-app-background-color: #f1f5f9;
  --mat-app-text-color: #1e293b;
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: #64748b;
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #1e293b;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #4f46e5;
  --mdc-filled-text-field-focus-active-indicator-color: #4f46e5;
  --mdc-filled-text-field-focus-label-text-color: rgba(79, 70, 229, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(30, 41, 59, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(148, 163, 184, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(30, 41, 59, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #dc2626;
  --mdc-filled-text-field-error-focus-label-text-color: #dc2626;
  --mdc-filled-text-field-error-label-text-color: #dc2626;
  --mdc-filled-text-field-error-caret-color: #dc2626;
  --mdc-filled-text-field-active-indicator-color: rgba(226, 232, 240, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(226, 232, 240, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(226, 232, 240, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-hover-active-indicator-color: #dc2626;
  --mdc-outlined-text-field-caret-color: #4f46e5;
  --mdc-outlined-text-field-focus-outline-color: #4f46e5;
  --mdc-outlined-text-field-focus-label-text-color: rgba(79, 70, 229, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(30, 41, 59, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(148, 163, 184, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(30, 41, 59, 0.6);
  --mdc-outlined-text-field-error-caret-color: #dc2626;
  --mdc-outlined-text-field-error-focus-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-hover-label-text-color: #dc2626;
  --mdc-outlined-text-field-outline-color: rgba(226, 232, 240, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(226, 232, 240, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(226, 232, 240, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-error-hover-outline-color: #dc2626;
  --mdc-outlined-text-field-error-outline-color: #dc2626;
  --mat-form-field-focus-select-arrow-color: rgba(79, 70, 229, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(100, 116, 139, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #dc2626;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(100, 116, 139, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(100, 116, 139, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(30, 41, 59, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(148, 163, 184, 0.38);
  --mat-select-placeholder-text-color: rgba(30, 41, 59, 0.6);
  --mat-select-enabled-arrow-color: rgba(226, 232, 240, 0.54);
  --mat-select-disabled-arrow-color: rgba(30, 41, 59, 0.38);
  --mat-select-focused-arrow-color: rgba(79, 70, 229, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: white;
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(30, 41, 59, 0.87);
  --mdc-dialog-supporting-text-color: rgba(30, 41, 59, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
  --mdc-switch-selected-focus-state-layer-color: #4f46e5;
  --mdc-switch-selected-handle-color: #4f46e5;
  --mdc-switch-selected-hover-state-layer-color: #4f46e5;
  --mdc-switch-selected-pressed-state-layer-color: #4f46e5;
  --mdc-switch-selected-focus-handle-color: #312e81;
  --mdc-switch-selected-hover-handle-color: #312e81;
  --mdc-switch-selected-pressed-handle-color: #312e81;
  --mdc-switch-selected-focus-track-color: #a5b4fc;
  --mdc-switch-selected-hover-track-color: #a5b4fc;
  --mdc-switch-selected-pressed-track-color: #a5b4fc;
  --mdc-switch-selected-track-color: #a5b4fc;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-disabled-label-text-color: #94a3b8;
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-color: #4f46e5;
  --mdc-slider-focus-handle-color: #4f46e5;
  --mdc-slider-hover-handle-color: #4f46e5;
  --mdc-slider-active-track-color: #4f46e5;
  --mdc-slider-inactive-track-color: #4f46e5;
  --mdc-slider-with-tick-marks-inactive-container-color: #4f46e5;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: #4f46e5;
  --mat-slider-hover-state-layer-color: rgba(79, 70, 229, 0.05);
  --mat-slider-focus-state-layer-color: rgba(79, 70, 229, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-item-label-text-color: #1e293b;
  --mat-menu-item-icon-color: #1e293b;
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: #e2e8f0;
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 0;
  --mdc-list-list-item-label-text-color: #1e293b;
  --mdc-list-list-item-supporting-text-color: #64748b;
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: #94a3b8;
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: #1e293b;
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: #1e293b;
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: #94a3b8;
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: #1e293b;
  --mdc-text-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: #1e293b;
  --mdc-filled-button-disabled-container-color: rgba(148, 163, 184, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: #1e293b;
  --mdc-protected-button-disabled-container-color: rgba(148, 163, 184, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: #e2e8f0;
  --mdc-outlined-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-outlined-button-label-text-color: #1e293b;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-fab-container-color: white;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(148, 163, 184, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(148, 163, 184, 0.38);
  --mdc-fab-small-container-color: white;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(148, 163, 184, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(148, 163, 184, 0.38);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #1e293b;
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: white;
  --mat-table-header-headline-color: #1e293b;
  --mat-table-row-item-label-text-color: #1e293b;
  --mat-table-row-item-outline-color: #e2e8f0;
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #4f46e5;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #4f46e5;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #94a3b8;
  --mat-badge-disabled-state-text-color: #94a3b8;
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: #1e293b;
  --mat-bottom-sheet-container-background-color: white;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: #94a3b8;
  --mat-legacy-button-toggle-state-layer-color: #64748b;
  --mat-legacy-button-toggle-selected-state-text-color: #64748b;
  --mat-legacy-button-toggle-selected-state-background-color: #e2e8f0;
  --mat-legacy-button-toggle-disabled-state-text-color: #94a3b8;
  --mat-legacy-button-toggle-disabled-state-background-color: #cbd5e1;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #e2e8f0;
  --mat-standard-button-toggle-text-color: #1e293b;
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: #64748b;
  --mat-standard-button-toggle-selected-state-background-color: #e2e8f0;
  --mat-standard-button-toggle-selected-state-text-color: #1e293b;
  --mat-standard-button-toggle-disabled-state-text-color: #94a3b8;
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: #1e293b;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #e2e8f0;
  --mat-standard-button-toggle-divider-color: #e2e8f0;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #4f46e5;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(79, 70, 229, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(79, 70, 229, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(79, 70, 229, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #4f46e5;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(79, 70, 229, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: #64748b;
  --mat-datepicker-calendar-body-label-text-color: #64748b;
  --mat-datepicker-calendar-period-button-text-color: #1e293b;
  --mat-datepicker-calendar-period-button-icon-color: #64748b;
  --mat-datepicker-calendar-navigation-button-icon-color: #64748b;
  --mat-datepicker-calendar-header-divider-color: #e2e8f0;
  --mat-datepicker-calendar-header-text-color: #64748b;
  --mat-datepicker-calendar-date-today-outline-color: #94a3b8;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(148, 163, 184, 0.8);
  --mat-datepicker-calendar-date-text-color: #1e293b;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: #94a3b8;
  --mat-datepicker-calendar-date-preview-state-outline-color: #e2e8f0;
  --mat-datepicker-range-input-separator-color: #1e293b;
  --mat-datepicker-range-input-disabled-state-separator-color: #94a3b8;
  --mat-datepicker-range-input-disabled-state-text-color: #94a3b8;
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: #1e293b;
  --mat-divider-width: 1px;
  --mat-divider-color: #e2e8f0;
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: #1e293b;
  --mat-expansion-actions-divider-color: #e2e8f0;
  --mat-expansion-header-hover-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-expansion-header-focus-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-expansion-header-disabled-state-text-color: #94a3b8;
  --mat-expansion-header-text-color: #1e293b;
  --mat-expansion-header-description-color: #64748b;
  --mat-expansion-header-indicator-color: #64748b;
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: #e2e8f0;
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: #1e293b;
  --mat-sidenav-content-background-color: #f1f5f9;
  --mat-sidenav-content-text-color: #1e293b;
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #4f46e5;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #4f46e5;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #4f46e5;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: #e2e8f0;
  --mat-stepper-header-hover-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-stepper-header-focus-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-stepper-header-label-text-color: #64748b;
  --mat-stepper-header-optional-label-text-color: #64748b;
  --mat-stepper-header-selected-state-label-text-color: #1e293b;
  --mat-stepper-header-error-state-label-text-color: #dc2626;
  --mat-stepper-header-icon-background-color: #64748b;
  --mat-stepper-header-error-state-icon-foreground-color: #dc2626;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #64748b;
  --mat-toolbar-container-background-color: white;
  --mat-toolbar-container-text-color: #1e293b;
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: #1e293b;
}
.theme-default .light .mat-accent,
.theme-default.light .mat-accent {
  --mat-option-selected-state-label-text-color: #1e293b;
  --mat-option-label-text-color: #1e293b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.theme-default .light .mat-warn,
.theme-default.light .mat-warn {
  --mat-option-selected-state-label-text-color: #dc2626;
  --mat-option-label-text-color: #1e293b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.theme-default .light .mat-primary,
.theme-default.light .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #4f46e5;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #4f46e5;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-default .light .mat-accent,
.theme-default.light .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-default .light .mat-warn,
.theme-default.light .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #dc2626;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #dc2626;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-default .light .mat-elevation-z0, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z0,
.theme-default.light .mat-elevation-z0,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z1, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z1,
.theme-default.light .mat-elevation-z1,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z2, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z2,
.theme-default.light .mat-elevation-z2,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z3, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z3,
.theme-default.light .mat-elevation-z3,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z4, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z4,
.theme-default.light .mat-elevation-z4,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z5, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z5,
.theme-default.light .mat-elevation-z5,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z6, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z6,
.theme-default.light .mat-elevation-z6,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z7, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z7,
.theme-default.light .mat-elevation-z7,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z8, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z8,
.theme-default.light .mat-elevation-z8,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z9, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z9,
.theme-default.light .mat-elevation-z9,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z10, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z10,
.theme-default.light .mat-elevation-z10,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z11, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z11,
.theme-default.light .mat-elevation-z11,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z12, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z12,
.theme-default.light .mat-elevation-z12,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z13, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z13,
.theme-default.light .mat-elevation-z13,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z14, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z14,
.theme-default.light .mat-elevation-z14,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z15, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z15,
.theme-default.light .mat-elevation-z15,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z16, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z16,
.theme-default.light .mat-elevation-z16,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z17, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z17,
.theme-default.light .mat-elevation-z17,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z18, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z18,
.theme-default.light .mat-elevation-z18,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z19, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z19,
.theme-default.light .mat-elevation-z19,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z20, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z20,
.theme-default.light .mat-elevation-z20,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z21, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z21,
.theme-default.light .mat-elevation-z21,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z22, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z22,
.theme-default.light .mat-elevation-z22,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z23, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z23,
.theme-default.light .mat-elevation-z23,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-default .light .mat-elevation-z24, .theme-default .light .mat-mdc-elevation-specific.mat-elevation-z24,
.theme-default.light .mat-elevation-z24,
.theme-default.light .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-default .light .mat-mdc-progress-bar,
.theme-default.light .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #4f46e5;
  --mdc-linear-progress-track-color: rgba(79, 70, 229, 0.25);
}
.theme-default .light .mat-mdc-progress-bar.mat-accent,
.theme-default.light .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1e293b;
  --mdc-linear-progress-track-color: rgba(30, 41, 59, 0.25);
}
.theme-default .light .mat-mdc-progress-bar.mat-warn,
.theme-default.light .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc2626;
  --mdc-linear-progress-track-color: rgba(220, 38, 38, 0.25);
}
.theme-default .light .mat-mdc-form-field.mat-accent,
.theme-default.light .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #1e293b;
  --mdc-filled-text-field-focus-active-indicator-color: #1e293b;
  --mdc-filled-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mdc-outlined-text-field-caret-color: #1e293b;
  --mdc-outlined-text-field-focus-outline-color: #1e293b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(30, 41, 59, 0.87);
}
.theme-default .light .mat-mdc-form-field.mat-warn,
.theme-default.light .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #dc2626;
  --mdc-filled-text-field-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mdc-outlined-text-field-caret-color: #dc2626;
  --mdc-outlined-text-field-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-default .light .mat-mdc-form-field.mat-accent,
.theme-default.light .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(30, 41, 59, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(148, 163, 184, 0.38);
  --mat-select-placeholder-text-color: rgba(30, 41, 59, 0.6);
  --mat-select-enabled-arrow-color: rgba(226, 232, 240, 0.54);
  --mat-select-disabled-arrow-color: rgba(30, 41, 59, 0.38);
  --mat-select-focused-arrow-color: rgba(30, 41, 59, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-default .light .mat-mdc-form-field.mat-warn,
.theme-default.light .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(30, 41, 59, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(148, 163, 184, 0.38);
  --mat-select-placeholder-text-color: rgba(30, 41, 59, 0.6);
  --mat-select-enabled-arrow-color: rgba(226, 232, 240, 0.54);
  --mat-select-disabled-arrow-color: rgba(30, 41, 59, 0.38);
  --mat-select-focused-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-default .light .mat-mdc-standard-chip,
.theme-default.light .mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.theme-default .light .mat-mdc-standard-chip,
.theme-default.light .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.theme-default .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .theme-default .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.theme-default.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.theme-default.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #4f46e5;
  --mdc-chip-elevated-selected-container-color: #4f46e5;
  --mdc-chip-elevated-disabled-container-color: #4f46e5;
  --mdc-chip-flat-disabled-selected-container-color: #4f46e5;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-default .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .theme-default .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.theme-default.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.theme-default.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #1e293b;
  --mdc-chip-elevated-selected-container-color: #1e293b;
  --mdc-chip-elevated-disabled-container-color: #1e293b;
  --mdc-chip-flat-disabled-selected-container-color: #1e293b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-default .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .theme-default .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.theme-default.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.theme-default.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-selected-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-flat-disabled-selected-container-color: #dc2626;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-default .light .mat-mdc-slide-toggle,
.theme-default.light .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: #1e293b;
}
.theme-default .light .mat-mdc-slide-toggle.mat-accent,
.theme-default.light .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #475569;
  --mdc-switch-selected-handle-color: #475569;
  --mdc-switch-selected-hover-state-layer-color: #475569;
  --mdc-switch-selected-pressed-state-layer-color: #475569;
  --mdc-switch-selected-focus-handle-color: #0f172a;
  --mdc-switch-selected-hover-handle-color: #0f172a;
  --mdc-switch-selected-pressed-handle-color: #0f172a;
  --mdc-switch-selected-focus-track-color: #cbd5e1;
  --mdc-switch-selected-hover-track-color: #cbd5e1;
  --mdc-switch-selected-pressed-track-color: #cbd5e1;
  --mdc-switch-selected-track-color: #cbd5e1;
}
.theme-default .light .mat-mdc-slide-toggle.mat-warn,
.theme-default.light .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.theme-default .light .mat-mdc-radio-button,
.theme-default.light .mat-mdc-radio-button {
  --mdc-form-field-label-text-color: #1e293b;
}
.theme-default .light .mat-mdc-radio-button.mat-primary,
.theme-default.light .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #4f46e5;
  --mdc-radio-selected-hover-icon-color: #4f46e5;
  --mdc-radio-selected-icon-color: #4f46e5;
  --mdc-radio-selected-pressed-icon-color: #4f46e5;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #4f46e5;
  --mat-radio-disabled-label-color: #94a3b8;
}
.theme-default .light .mat-mdc-radio-button.mat-accent,
.theme-default.light .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #1e293b;
  --mat-radio-disabled-label-color: #94a3b8;
}
.theme-default .light .mat-mdc-radio-button.mat-warn,
.theme-default.light .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #dc2626;
  --mat-radio-disabled-label-color: #94a3b8;
}
.theme-default .light .mat-accent,
.theme-default.light .mat-accent {
  --mat-slider-ripple-color: #1e293b;
  --mat-slider-hover-state-layer-color: rgba(30, 41, 59, 0.05);
  --mat-slider-focus-state-layer-color: rgba(30, 41, 59, 0.2);
  --mdc-slider-handle-color: #1e293b;
  --mdc-slider-focus-handle-color: #1e293b;
  --mdc-slider-hover-handle-color: #1e293b;
  --mdc-slider-active-track-color: #1e293b;
  --mdc-slider-inactive-track-color: #1e293b;
  --mdc-slider-with-tick-marks-inactive-container-color: #1e293b;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-default .light .mat-warn,
.theme-default.light .mat-warn {
  --mat-slider-ripple-color: #dc2626;
  --mat-slider-hover-state-layer-color: rgba(220, 38, 38, 0.05);
  --mat-slider-focus-state-layer-color: rgba(220, 38, 38, 0.2);
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-default .light .mdc-list-item__start,
.theme-default .light .mdc-list-item__end,
.theme-default.light .mdc-list-item__start,
.theme-default.light .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #4f46e5;
  --mdc-radio-selected-hover-icon-color: #4f46e5;
  --mdc-radio-selected-icon-color: #4f46e5;
  --mdc-radio-selected-pressed-icon-color: #4f46e5;
}
.theme-default .light .mat-accent .mdc-list-item__start,
.theme-default .light .mat-accent .mdc-list-item__end,
.theme-default.light .mat-accent .mdc-list-item__start,
.theme-default.light .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
}
.theme-default .light .mat-warn .mdc-list-item__start,
.theme-default .light .mat-warn .mdc-list-item__end,
.theme-default.light .mat-warn .mdc-list-item__start,
.theme-default.light .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
}
.theme-default .light .mat-mdc-list-option,
.theme-default.light .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #4f46e5;
  --mdc-checkbox-selected-hover-icon-color: #4f46e5;
  --mdc-checkbox-selected-icon-color: #4f46e5;
  --mdc-checkbox-selected-pressed-icon-color: #4f46e5;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #4f46e5;
  --mdc-checkbox-selected-hover-state-layer-color: #4f46e5;
  --mdc-checkbox-selected-pressed-state-layer-color: #4f46e5;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-default .light .mat-mdc-list-option.mat-accent,
.theme-default.light .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-default .light .mat-mdc-list-option.mat-warn,
.theme-default.light .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-default .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-default .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-default.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-default.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #4f46e5;
}
.theme-default .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #4f46e5;
}
.theme-default .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-default .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-default .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.theme-default.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-default.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-default.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.theme-default .light .mat-mdc-tab-group, .theme-default .light .mat-mdc-tab-nav-bar,
.theme-default.light .mat-mdc-tab-group,
.theme-default.light .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #4f46e5;
  --mat-tab-header-disabled-ripple-color: #94a3b8;
  --mat-tab-header-pagination-icon-color: #64748b;
  --mat-tab-header-inactive-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-label-text-color: #4f46e5;
  --mat-tab-header-active-ripple-color: #4f46e5;
  --mat-tab-header-inactive-ripple-color: #4f46e5;
  --mat-tab-header-inactive-focus-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-focus-label-text-color: #4f46e5;
  --mat-tab-header-active-hover-label-text-color: #4f46e5;
  --mat-tab-header-active-focus-indicator-color: #4f46e5;
  --mat-tab-header-active-hover-indicator-color: #4f46e5;
}
.theme-default .light .mat-mdc-tab-group.mat-accent, .theme-default .light .mat-mdc-tab-nav-bar.mat-accent,
.theme-default.light .mat-mdc-tab-group.mat-accent,
.theme-default.light .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #1e293b;
  --mat-tab-header-disabled-ripple-color: #94a3b8;
  --mat-tab-header-pagination-icon-color: #64748b;
  --mat-tab-header-inactive-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-label-text-color: #1e293b;
  --mat-tab-header-active-ripple-color: #1e293b;
  --mat-tab-header-inactive-ripple-color: #1e293b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-focus-label-text-color: #1e293b;
  --mat-tab-header-active-hover-label-text-color: #1e293b;
  --mat-tab-header-active-focus-indicator-color: #1e293b;
  --mat-tab-header-active-hover-indicator-color: #1e293b;
}
.theme-default .light .mat-mdc-tab-group.mat-warn, .theme-default .light .mat-mdc-tab-nav-bar.mat-warn,
.theme-default.light .mat-mdc-tab-group.mat-warn,
.theme-default.light .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #dc2626;
  --mat-tab-header-disabled-ripple-color: #94a3b8;
  --mat-tab-header-pagination-icon-color: #64748b;
  --mat-tab-header-inactive-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-label-text-color: #dc2626;
  --mat-tab-header-active-ripple-color: #dc2626;
  --mat-tab-header-inactive-ripple-color: #dc2626;
  --mat-tab-header-inactive-focus-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-focus-label-text-color: #dc2626;
  --mat-tab-header-active-hover-label-text-color: #dc2626;
  --mat-tab-header-active-focus-indicator-color: #dc2626;
  --mat-tab-header-active-hover-indicator-color: #dc2626;
}
.theme-default .light .mat-mdc-tab-group.mat-background-primary, .theme-default .light .mat-mdc-tab-nav-bar.mat-background-primary,
.theme-default.light .mat-mdc-tab-group.mat-background-primary,
.theme-default.light .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #4f46e5;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-default .light .mat-mdc-tab-group.mat-background-accent, .theme-default .light .mat-mdc-tab-nav-bar.mat-background-accent,
.theme-default.light .mat-mdc-tab-group.mat-background-accent,
.theme-default.light .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #1e293b;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-default .light .mat-mdc-tab-group.mat-background-warn, .theme-default .light .mat-mdc-tab-nav-bar.mat-background-warn,
.theme-default.light .mat-mdc-tab-group.mat-background-warn,
.theme-default.light .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #dc2626;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-default .light .mat-mdc-checkbox,
.theme-default.light .mat-mdc-checkbox {
  --mdc-form-field-label-text-color: #1e293b;
}
.theme-default .light .mat-mdc-checkbox.mat-primary,
.theme-default.light .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #4f46e5;
  --mdc-checkbox-selected-hover-icon-color: #4f46e5;
  --mdc-checkbox-selected-icon-color: #4f46e5;
  --mdc-checkbox-selected-pressed-icon-color: #4f46e5;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #4f46e5;
  --mdc-checkbox-selected-hover-state-layer-color: #4f46e5;
  --mdc-checkbox-selected-pressed-state-layer-color: #4f46e5;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-default .light .mat-mdc-checkbox.mat-warn,
.theme-default.light .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-default .light .mat-mdc-button.mat-primary,
.theme-default.light .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #4f46e5;
  --mat-text-button-state-layer-color: #4f46e5;
  --mat-text-button-ripple-color: rgba(79, 70, 229, 0.1);
}
.theme-default .light .mat-mdc-button.mat-accent,
.theme-default.light .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1e293b;
  --mat-text-button-state-layer-color: #1e293b;
  --mat-text-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-default .light .mat-mdc-button.mat-warn,
.theme-default.light .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc2626;
  --mat-text-button-state-layer-color: #dc2626;
  --mat-text-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-default .light .mat-mdc-unelevated-button.mat-primary,
.theme-default.light .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #4f46e5;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .light .mat-mdc-unelevated-button.mat-accent,
.theme-default.light .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .light .mat-mdc-unelevated-button.mat-warn,
.theme-default.light .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .light .mat-mdc-raised-button.mat-primary,
.theme-default.light .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #4f46e5;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .light .mat-mdc-raised-button.mat-accent,
.theme-default.light .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .light .mat-mdc-raised-button.mat-warn,
.theme-default.light .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .light .mat-mdc-outlined-button.mat-primary,
.theme-default.light .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #4f46e5;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: #4f46e5;
  --mat-outlined-button-ripple-color: rgba(79, 70, 229, 0.1);
}
.theme-default .light .mat-mdc-outlined-button.mat-accent,
.theme-default.light .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1e293b;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: #1e293b;
  --mat-outlined-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-default .light .mat-mdc-outlined-button.mat-warn,
.theme-default.light .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc2626;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: #dc2626;
  --mat-outlined-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-default .light .mat-mdc-icon-button.mat-primary,
.theme-default.light .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #4f46e5;
  --mat-icon-button-state-layer-color: #4f46e5;
  --mat-icon-button-ripple-color: rgba(79, 70, 229, 0.1);
}
.theme-default .light .mat-mdc-icon-button.mat-accent,
.theme-default.light .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1e293b;
  --mat-icon-button-state-layer-color: #1e293b;
  --mat-icon-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-default .light .mat-mdc-icon-button.mat-warn,
.theme-default.light .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc2626;
  --mat-icon-button-state-layer-color: #dc2626;
  --mat-icon-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-default .light .mat-mdc-fab.mat-primary,
.theme-default.light .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #4f46e5;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .light .mat-mdc-fab.mat-accent,
.theme-default.light .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #1e293b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .light .mat-mdc-fab.mat-warn,
.theme-default.light .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #dc2626;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .light .mat-mdc-mini-fab.mat-primary,
.theme-default.light .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #4f46e5;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .light .mat-mdc-mini-fab.mat-accent,
.theme-default.light .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #1e293b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .light .mat-mdc-mini-fab.mat-warn,
.theme-default.light .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #dc2626;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .light .mat-accent,
.theme-default.light .mat-accent {
  --mdc-circular-progress-active-indicator-color: #1e293b;
}
.theme-default .light .mat-warn,
.theme-default.light .mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}
.theme-default .light .mat-badge-accent,
.theme-default.light .mat-badge-accent {
  --mat-badge-background-color: #1e293b;
  --mat-badge-text-color: white;
}
.theme-default .light .mat-badge-warn,
.theme-default.light .mat-badge-warn {
  --mat-badge-background-color: #dc2626;
  --mat-badge-text-color: white;
}
.theme-default .light .mat-datepicker-content.mat-accent,
.theme-default.light .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #1e293b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(30, 41, 59, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(30, 41, 59, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-default .light .mat-datepicker-content.mat-warn,
.theme-default.light .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #dc2626;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(220, 38, 38, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(220, 38, 38, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-default .light .mat-datepicker-toggle-active.mat-accent,
.theme-default.light .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #1e293b;
}
.theme-default .light .mat-datepicker-toggle-active.mat-warn,
.theme-default.light .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #dc2626;
}
.theme-default .light .mat-icon.mat-primary,
.theme-default.light .mat-icon.mat-primary {
  --mat-icon-color: #4f46e5;
}
.theme-default .light .mat-icon.mat-accent,
.theme-default.light .mat-icon.mat-accent {
  --mat-icon-color: #1e293b;
}
.theme-default .light .mat-icon.mat-warn,
.theme-default.light .mat-icon.mat-warn {
  --mat-icon-color: #dc2626;
}
.theme-default .light .mat-step-header.mat-accent,
.theme-default.light .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #1e293b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #1e293b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #1e293b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-default .light .mat-step-header.mat-warn,
.theme-default.light .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #dc2626;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #dc2626;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #dc2626;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-default .light .mat-toolbar.mat-primary,
.theme-default.light .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #4f46e5;
  --mat-toolbar-container-text-color: white;
}
.theme-default .light .mat-toolbar.mat-accent,
.theme-default.light .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #1e293b;
  --mat-toolbar-container-text-color: white;
}
.theme-default .light .mat-toolbar.mat-warn,
.theme-default.light .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #dc2626;
  --mat-toolbar-container-text-color: white;
}

.theme-default .dark,
.theme-default.dark {
  --mat-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-option-selected-state-label-text-color: #4f46e5;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-optgroup-label-text-color: white;
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
  --mat-app-background-color: #0f172a;
  --mat-app-text-color: white;
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: #1e293b;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: #1e293b;
  --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: #94a3b8;
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #64748b;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #4f46e5;
  --mdc-filled-text-field-focus-active-indicator-color: #4f46e5;
  --mdc-filled-text-field-focus-label-text-color: rgba(79, 70, 229, 0.87);
  --mdc-filled-text-field-container-color: #273243;
  --mdc-filled-text-field-disabled-container-color: #232d3f;
  --mdc-filled-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(71, 85, 105, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(71, 85, 105, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #dc2626;
  --mdc-filled-text-field-error-focus-label-text-color: #dc2626;
  --mdc-filled-text-field-error-label-text-color: #dc2626;
  --mdc-filled-text-field-error-caret-color: #dc2626;
  --mdc-filled-text-field-active-indicator-color: rgba(241, 245, 249, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(241, 245, 249, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(241, 245, 249, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-hover-active-indicator-color: #dc2626;
  --mdc-outlined-text-field-caret-color: #4f46e5;
  --mdc-outlined-text-field-focus-outline-color: #4f46e5;
  --mdc-outlined-text-field-focus-label-text-color: rgba(79, 70, 229, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(71, 85, 105, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(71, 85, 105, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-error-caret-color: #dc2626;
  --mdc-outlined-text-field-error-focus-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-hover-label-text-color: #dc2626;
  --mdc-outlined-text-field-outline-color: rgba(241, 245, 249, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(241, 245, 249, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(241, 245, 249, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-error-hover-outline-color: #dc2626;
  --mdc-outlined-text-field-error-outline-color: #dc2626;
  --mat-form-field-focus-select-arrow-color: rgba(79, 70, 229, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(241, 245, 249, 0.38);
  --mat-form-field-state-layer-color: rgba(255, 255, 255, 0.87);
  --mat-form-field-error-text-color: #dc2626;
  --mat-form-field-select-option-text-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-select-disabled-option-text-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(241, 245, 249, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.08;
  --mat-form-field-focus-state-layer-opacity: 0.24;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: #1e293b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(71, 85, 105, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(79, 70, 229, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: #1e293b;
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: #1e293b;
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
  --mdc-switch-selected-focus-state-layer-color: #a5b4fc;
  --mdc-switch-selected-handle-color: #a5b4fc;
  --mdc-switch-selected-hover-state-layer-color: #a5b4fc;
  --mdc-switch-selected-pressed-state-layer-color: #a5b4fc;
  --mdc-switch-selected-focus-handle-color: #c7d2fe;
  --mdc-switch-selected-hover-handle-color: #c7d2fe;
  --mdc-switch-selected-pressed-handle-color: #c7d2fe;
  --mdc-switch-selected-focus-track-color: #4f46e5;
  --mdc-switch-selected-hover-track-color: #4f46e5;
  --mdc-switch-selected-pressed-track-color: #4f46e5;
  --mdc-switch-selected-track-color: #4f46e5;
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-icon-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-disabled-label-text-color: #475569;
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-color: #4f46e5;
  --mdc-slider-focus-handle-color: #4f46e5;
  --mdc-slider-hover-handle-color: #4f46e5;
  --mdc-slider-active-track-color: #4f46e5;
  --mdc-slider-inactive-track-color: #4f46e5;
  --mdc-slider-with-tick-marks-inactive-container-color: #4f46e5;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-label-container-color: #fff;
  --mdc-slider-label-label-text-color: #000;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: #4f46e5;
  --mat-slider-hover-state-layer-color: rgba(79, 70, 229, 0.05);
  --mat-slider-focus-state-layer-color: rgba(79, 70, 229, 0.2);
  --mat-slider-value-indicator-opacity: 0.9;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-item-label-text-color: white;
  --mat-menu-item-icon-color: white;
  --mat-menu-item-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-container-color: #1e293b;
  --mat-menu-divider-color: rgba(241, 245, 249, 0.12);
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 0;
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: #94a3b8;
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: #64748b;
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: white;
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
  --mat-paginator-container-text-color: rgba(255, 255, 255, 0.87);
  --mat-paginator-container-background-color: #1e293b;
  --mat-paginator-enabled-icon-color: rgba(255, 255, 255, 0.54);
  --mat-paginator-disabled-icon-color: rgba(255, 255, 255, 0.12);
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
  --mat-checkbox-disabled-label-color: #475569;
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: white;
  --mdc-text-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mat-text-button-state-layer-color: white;
  --mat-text-button-disabled-state-layer-color: white;
  --mat-text-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.08;
  --mat-text-button-focus-state-layer-opacity: 0.24;
  --mat-text-button-pressed-state-layer-opacity: 0.24;
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: white;
  --mdc-filled-button-disabled-container-color: rgba(30, 41, 59, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-disabled-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.08;
  --mat-filled-button-focus-state-layer-opacity: 0.24;
  --mat-filled-button-pressed-state-layer-opacity: 0.24;
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: white;
  --mdc-protected-button-disabled-container-color: rgba(30, 41, 59, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-disabled-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.08;
  --mat-protected-button-focus-state-layer-opacity: 0.24;
  --mat-protected-button-pressed-state-layer-opacity: 0.24;
  --mdc-outlined-button-disabled-outline-color: rgba(241, 245, 249, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mdc-outlined-button-label-text-color: white;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: white;
  --mat-outlined-button-disabled-state-layer-color: white;
  --mat-outlined-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.08;
  --mat-outlined-button-focus-state-layer-opacity: 0.24;
  --mat-outlined-button-pressed-state-layer-opacity: 0.24;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
  --mat-icon-button-state-layer-color: white;
  --mat-icon-button-disabled-state-layer-color: white;
  --mat-icon-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.08;
  --mat-icon-button-focus-state-layer-opacity: 0.24;
  --mat-icon-button-pressed-state-layer-opacity: 0.24;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-fab-container-color: #1e293b;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-disabled-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.08;
  --mat-fab-focus-state-layer-opacity: 0.24;
  --mat-fab-pressed-state-layer-opacity: 0.24;
  --mat-fab-disabled-state-container-color: rgba(15, 23, 42, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(30, 41, 59, 0.5);
  --mdc-fab-small-container-color: #1e293b;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-disabled-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.08;
  --mat-fab-small-focus-state-layer-opacity: 0.24;
  --mat-fab-small-pressed-state-layer-opacity: 0.24;
  --mat-fab-small-disabled-state-container-color: rgba(15, 23, 42, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(30, 41, 59, 0.5);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #d2d4d8;
  --mdc-snackbar-supporting-text-color: rgba(30, 41, 59, 0.87);
  --mat-snack-bar-button-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: #1e293b;
  --mat-table-header-headline-color: white;
  --mat-table-row-item-label-text-color: white;
  --mat-table-row-item-outline-color: rgba(241, 245, 249, 0.12);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #4f46e5;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #4f46e5;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #1e293b;
  --mat-badge-disabled-state-text-color: #475569;
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: white;
  --mat-bottom-sheet-container-background-color: #1e293b;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: #64748b;
  --mat-legacy-button-toggle-state-layer-color: #e2e8f0;
  --mat-legacy-button-toggle-selected-state-text-color: #94a3b8;
  --mat-legacy-button-toggle-selected-state-background-color: rgba(255, 255, 255, 0.05);
  --mat-legacy-button-toggle-disabled-state-text-color: #1e293b;
  --mat-legacy-button-toggle-disabled-state-background-color: #0f172a;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #1e293b;
  --mat-standard-button-toggle-text-color: white;
  --mat-standard-button-toggle-background-color: #1e293b;
  --mat-standard-button-toggle-state-layer-color: #e2e8f0;
  --mat-standard-button-toggle-selected-state-background-color: rgba(255, 255, 255, 0.05);
  --mat-standard-button-toggle-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-state-text-color: #1e293b;
  --mat-standard-button-toggle-disabled-state-background-color: #1e293b;
  --mat-standard-button-toggle-disabled-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #1e293b;
  --mat-standard-button-toggle-divider-color: #374152;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #4f46e5;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(79, 70, 229, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(79, 70, 229, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(79, 70, 229, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #4f46e5;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(79, 70, 229, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: #f1f5f9;
  --mat-datepicker-calendar-body-label-text-color: #94a3b8;
  --mat-datepicker-calendar-period-button-text-color: white;
  --mat-datepicker-calendar-period-button-icon-color: #f1f5f9;
  --mat-datepicker-calendar-navigation-button-icon-color: #f1f5f9;
  --mat-datepicker-calendar-header-divider-color: rgba(241, 245, 249, 0.12);
  --mat-datepicker-calendar-header-text-color: #94a3b8;
  --mat-datepicker-calendar-date-today-outline-color: #64748b;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(100, 116, 139, 0.8);
  --mat-datepicker-calendar-date-text-color: white;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: #475569;
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(241, 245, 249, 0.24);
  --mat-datepicker-range-input-separator-color: white;
  --mat-datepicker-range-input-disabled-state-separator-color: #475569;
  --mat-datepicker-range-input-disabled-state-text-color: #475569;
  --mat-datepicker-calendar-container-background-color: #1e293b;
  --mat-datepicker-calendar-container-text-color: white;
  --mat-divider-width: 1px;
  --mat-divider-color: rgba(241, 245, 249, 0.12);
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: #1e293b;
  --mat-expansion-container-text-color: white;
  --mat-expansion-actions-divider-color: rgba(241, 245, 249, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-expansion-header-focus-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-expansion-header-disabled-state-text-color: #1e293b;
  --mat-expansion-header-text-color: white;
  --mat-expansion-header-description-color: #94a3b8;
  --mat-expansion-header-indicator-color: #94a3b8;
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: rgba(241, 245, 249, 0.12);
  --mat-sidenav-container-background-color: #1e293b;
  --mat-sidenav-container-text-color: white;
  --mat-sidenav-content-background-color: #0f172a;
  --mat-sidenav-content-text-color: white;
  --mat-sidenav-scrim-color: rgba(225, 214, 196, 0.6);
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #4f46e5;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #4f46e5;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #4f46e5;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: #1e293b;
  --mat-stepper-line-color: rgba(241, 245, 249, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-stepper-header-focus-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-stepper-header-label-text-color: #94a3b8;
  --mat-stepper-header-optional-label-text-color: #94a3b8;
  --mat-stepper-header-selected-state-label-text-color: white;
  --mat-stepper-header-error-state-label-text-color: #dc2626;
  --mat-stepper-header-icon-background-color: #94a3b8;
  --mat-stepper-header-error-state-icon-foreground-color: #dc2626;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #94a3b8;
  --mat-toolbar-container-background-color: #0f172a;
  --mat-toolbar-container-text-color: white;
  --mat-tree-container-background-color: #1e293b;
  --mat-tree-node-text-color: white;
}
.theme-default .dark .mat-accent,
.theme-default.dark .mat-accent {
  --mat-option-selected-state-label-text-color: #1e293b;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.theme-default .dark .mat-warn,
.theme-default.dark .mat-warn {
  --mat-option-selected-state-label-text-color: #dc2626;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.theme-default .dark .mat-primary,
.theme-default.dark .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #4f46e5;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #4f46e5;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-default .dark .mat-accent,
.theme-default.dark .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-default .dark .mat-warn,
.theme-default.dark .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #dc2626;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #dc2626;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-default .dark .mat-elevation-z0, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z0,
.theme-default.dark .mat-elevation-z0,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z1, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z1,
.theme-default.dark .mat-elevation-z1,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z2, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z2,
.theme-default.dark .mat-elevation-z2,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z3, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z3,
.theme-default.dark .mat-elevation-z3,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z4, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z4,
.theme-default.dark .mat-elevation-z4,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z5, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z5,
.theme-default.dark .mat-elevation-z5,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z6, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z6,
.theme-default.dark .mat-elevation-z6,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z7, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z7,
.theme-default.dark .mat-elevation-z7,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z8, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z8,
.theme-default.dark .mat-elevation-z8,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z9, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z9,
.theme-default.dark .mat-elevation-z9,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z10, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z10,
.theme-default.dark .mat-elevation-z10,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z11, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z11,
.theme-default.dark .mat-elevation-z11,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z12, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z12,
.theme-default.dark .mat-elevation-z12,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z13, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z13,
.theme-default.dark .mat-elevation-z13,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z14, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z14,
.theme-default.dark .mat-elevation-z14,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z15, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z15,
.theme-default.dark .mat-elevation-z15,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z16, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z16,
.theme-default.dark .mat-elevation-z16,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z17, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z17,
.theme-default.dark .mat-elevation-z17,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z18, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z18,
.theme-default.dark .mat-elevation-z18,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z19, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z19,
.theme-default.dark .mat-elevation-z19,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z20, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z20,
.theme-default.dark .mat-elevation-z20,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z21, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z21,
.theme-default.dark .mat-elevation-z21,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z22, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z22,
.theme-default.dark .mat-elevation-z22,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z23, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z23,
.theme-default.dark .mat-elevation-z23,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-default .dark .mat-elevation-z24, .theme-default .dark .mat-mdc-elevation-specific.mat-elevation-z24,
.theme-default.dark .mat-elevation-z24,
.theme-default.dark .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-default .dark .mat-mdc-progress-bar,
.theme-default.dark .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #4f46e5;
  --mdc-linear-progress-track-color: rgba(79, 70, 229, 0.25);
}
.theme-default .dark .mat-mdc-progress-bar.mat-accent,
.theme-default.dark .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1e293b;
  --mdc-linear-progress-track-color: rgba(30, 41, 59, 0.25);
}
.theme-default .dark .mat-mdc-progress-bar.mat-warn,
.theme-default.dark .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc2626;
  --mdc-linear-progress-track-color: rgba(220, 38, 38, 0.25);
}
.theme-default .dark .mat-mdc-form-field.mat-accent,
.theme-default.dark .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #1e293b;
  --mdc-filled-text-field-focus-active-indicator-color: #1e293b;
  --mdc-filled-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mdc-outlined-text-field-caret-color: #1e293b;
  --mdc-outlined-text-field-focus-outline-color: #1e293b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(30, 41, 59, 0.87);
}
.theme-default .dark .mat-mdc-form-field.mat-warn,
.theme-default.dark .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #dc2626;
  --mdc-filled-text-field-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mdc-outlined-text-field-caret-color: #dc2626;
  --mdc-outlined-text-field-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-default .dark .mat-mdc-form-field.mat-accent,
.theme-default.dark .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: #1e293b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(71, 85, 105, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(30, 41, 59, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-default .dark .mat-mdc-form-field.mat-warn,
.theme-default.dark .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: #1e293b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(71, 85, 105, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-default .dark .mat-mdc-standard-chip,
.theme-default.dark .mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.theme-default .dark .mat-mdc-standard-chip,
.theme-default.dark .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-elevated-container-color: #394353;
  --mdc-chip-elevated-selected-container-color: #394353;
  --mdc-chip-elevated-disabled-container-color: #394353;
  --mdc-chip-flat-disabled-selected-container-color: #394353;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-selected-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
  --mat-chip-selected-disabled-trailing-icon-color: #fafafa;
  --mat-chip-selected-trailing-icon-color: #fafafa;
}
.theme-default .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .theme-default .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.theme-default.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.theme-default.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #4f46e5;
  --mdc-chip-elevated-selected-container-color: #4f46e5;
  --mdc-chip-elevated-disabled-container-color: #4f46e5;
  --mdc-chip-flat-disabled-selected-container-color: #4f46e5;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-default .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .theme-default .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.theme-default.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.theme-default.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #1e293b;
  --mdc-chip-elevated-selected-container-color: #1e293b;
  --mdc-chip-elevated-disabled-container-color: #1e293b;
  --mdc-chip-flat-disabled-selected-container-color: #1e293b;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-default .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .theme-default .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.theme-default.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.theme-default.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-selected-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-flat-disabled-selected-container-color: #dc2626;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-default .dark .mat-mdc-slide-toggle,
.theme-default.dark .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: white;
}
.theme-default .dark .mat-mdc-slide-toggle.mat-accent,
.theme-default.dark .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #cbd5e1;
  --mdc-switch-selected-handle-color: #cbd5e1;
  --mdc-switch-selected-hover-state-layer-color: #cbd5e1;
  --mdc-switch-selected-pressed-state-layer-color: #cbd5e1;
  --mdc-switch-selected-focus-handle-color: #e2e8f0;
  --mdc-switch-selected-hover-handle-color: #e2e8f0;
  --mdc-switch-selected-pressed-handle-color: #e2e8f0;
  --mdc-switch-selected-focus-track-color: #475569;
  --mdc-switch-selected-hover-track-color: #475569;
  --mdc-switch-selected-pressed-track-color: #475569;
  --mdc-switch-selected-track-color: #475569;
}
.theme-default .dark .mat-mdc-slide-toggle.mat-warn,
.theme-default.dark .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #fca5a5;
  --mdc-switch-selected-handle-color: #fca5a5;
  --mdc-switch-selected-hover-state-layer-color: #fca5a5;
  --mdc-switch-selected-pressed-state-layer-color: #fca5a5;
  --mdc-switch-selected-focus-handle-color: #fecaca;
  --mdc-switch-selected-hover-handle-color: #fecaca;
  --mdc-switch-selected-pressed-handle-color: #fecaca;
  --mdc-switch-selected-focus-track-color: #dc2626;
  --mdc-switch-selected-hover-track-color: #dc2626;
  --mdc-switch-selected-pressed-track-color: #dc2626;
  --mdc-switch-selected-track-color: #dc2626;
}
.theme-default .dark .mat-mdc-radio-button,
.theme-default.dark .mat-mdc-radio-button {
  --mdc-form-field-label-text-color: white;
}
.theme-default .dark .mat-mdc-radio-button.mat-primary,
.theme-default.dark .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #4f46e5;
  --mdc-radio-selected-hover-icon-color: #4f46e5;
  --mdc-radio-selected-icon-color: #4f46e5;
  --mdc-radio-selected-pressed-icon-color: #4f46e5;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #4f46e5;
  --mat-radio-disabled-label-color: #475569;
}
.theme-default .dark .mat-mdc-radio-button.mat-accent,
.theme-default.dark .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #1e293b;
  --mat-radio-disabled-label-color: #475569;
}
.theme-default .dark .mat-mdc-radio-button.mat-warn,
.theme-default.dark .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #dc2626;
  --mat-radio-disabled-label-color: #475569;
}
.theme-default .dark .mat-accent,
.theme-default.dark .mat-accent {
  --mat-slider-ripple-color: #1e293b;
  --mat-slider-hover-state-layer-color: rgba(30, 41, 59, 0.05);
  --mat-slider-focus-state-layer-color: rgba(30, 41, 59, 0.2);
  --mdc-slider-handle-color: #1e293b;
  --mdc-slider-focus-handle-color: #1e293b;
  --mdc-slider-hover-handle-color: #1e293b;
  --mdc-slider-active-track-color: #1e293b;
  --mdc-slider-inactive-track-color: #1e293b;
  --mdc-slider-with-tick-marks-inactive-container-color: #1e293b;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-default .dark .mat-warn,
.theme-default.dark .mat-warn {
  --mat-slider-ripple-color: #dc2626;
  --mat-slider-hover-state-layer-color: rgba(220, 38, 38, 0.05);
  --mat-slider-focus-state-layer-color: rgba(220, 38, 38, 0.2);
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-default .dark .mdc-list-item__start,
.theme-default .dark .mdc-list-item__end,
.theme-default.dark .mdc-list-item__start,
.theme-default.dark .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #4f46e5;
  --mdc-radio-selected-hover-icon-color: #4f46e5;
  --mdc-radio-selected-icon-color: #4f46e5;
  --mdc-radio-selected-pressed-icon-color: #4f46e5;
}
.theme-default .dark .mat-accent .mdc-list-item__start,
.theme-default .dark .mat-accent .mdc-list-item__end,
.theme-default.dark .mat-accent .mdc-list-item__start,
.theme-default.dark .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
}
.theme-default .dark .mat-warn .mdc-list-item__start,
.theme-default .dark .mat-warn .mdc-list-item__end,
.theme-default.dark .mat-warn .mdc-list-item__start,
.theme-default.dark .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
}
.theme-default .dark .mat-mdc-list-option,
.theme-default.dark .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #4f46e5;
  --mdc-checkbox-selected-hover-icon-color: #4f46e5;
  --mdc-checkbox-selected-icon-color: #4f46e5;
  --mdc-checkbox-selected-pressed-icon-color: #4f46e5;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #4f46e5;
  --mdc-checkbox-selected-hover-state-layer-color: #4f46e5;
  --mdc-checkbox-selected-pressed-state-layer-color: #4f46e5;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-default .dark .mat-mdc-list-option.mat-accent,
.theme-default.dark .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-default .dark .mat-mdc-list-option.mat-warn,
.theme-default.dark .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-default .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-default .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-default.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-default.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #4f46e5;
}
.theme-default .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #4f46e5;
}
.theme-default .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-default .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-default .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.theme-default.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-default.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-default.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.theme-default .dark .mat-mdc-tab-group, .theme-default .dark .mat-mdc-tab-nav-bar,
.theme-default.dark .mat-mdc-tab-group,
.theme-default.dark .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #4f46e5;
  --mat-tab-header-disabled-ripple-color: #475569;
  --mat-tab-header-pagination-icon-color: #f1f5f9;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #4f46e5;
  --mat-tab-header-active-ripple-color: #4f46e5;
  --mat-tab-header-inactive-ripple-color: #4f46e5;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #4f46e5;
  --mat-tab-header-active-hover-label-text-color: #4f46e5;
  --mat-tab-header-active-focus-indicator-color: #4f46e5;
  --mat-tab-header-active-hover-indicator-color: #4f46e5;
}
.theme-default .dark .mat-mdc-tab-group.mat-accent, .theme-default .dark .mat-mdc-tab-nav-bar.mat-accent,
.theme-default.dark .mat-mdc-tab-group.mat-accent,
.theme-default.dark .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #1e293b;
  --mat-tab-header-disabled-ripple-color: #475569;
  --mat-tab-header-pagination-icon-color: #f1f5f9;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #1e293b;
  --mat-tab-header-active-ripple-color: #1e293b;
  --mat-tab-header-inactive-ripple-color: #1e293b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #1e293b;
  --mat-tab-header-active-hover-label-text-color: #1e293b;
  --mat-tab-header-active-focus-indicator-color: #1e293b;
  --mat-tab-header-active-hover-indicator-color: #1e293b;
}
.theme-default .dark .mat-mdc-tab-group.mat-warn, .theme-default .dark .mat-mdc-tab-nav-bar.mat-warn,
.theme-default.dark .mat-mdc-tab-group.mat-warn,
.theme-default.dark .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #dc2626;
  --mat-tab-header-disabled-ripple-color: #475569;
  --mat-tab-header-pagination-icon-color: #f1f5f9;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #dc2626;
  --mat-tab-header-active-ripple-color: #dc2626;
  --mat-tab-header-inactive-ripple-color: #dc2626;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #dc2626;
  --mat-tab-header-active-hover-label-text-color: #dc2626;
  --mat-tab-header-active-focus-indicator-color: #dc2626;
  --mat-tab-header-active-hover-indicator-color: #dc2626;
}
.theme-default .dark .mat-mdc-tab-group.mat-background-primary, .theme-default .dark .mat-mdc-tab-nav-bar.mat-background-primary,
.theme-default.dark .mat-mdc-tab-group.mat-background-primary,
.theme-default.dark .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #4f46e5;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-default .dark .mat-mdc-tab-group.mat-background-accent, .theme-default .dark .mat-mdc-tab-nav-bar.mat-background-accent,
.theme-default.dark .mat-mdc-tab-group.mat-background-accent,
.theme-default.dark .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #1e293b;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-default .dark .mat-mdc-tab-group.mat-background-warn, .theme-default .dark .mat-mdc-tab-nav-bar.mat-background-warn,
.theme-default.dark .mat-mdc-tab-group.mat-background-warn,
.theme-default.dark .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #dc2626;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-default .dark .mat-mdc-checkbox,
.theme-default.dark .mat-mdc-checkbox {
  --mdc-form-field-label-text-color: white;
}
.theme-default .dark .mat-mdc-checkbox.mat-primary,
.theme-default.dark .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #4f46e5;
  --mdc-checkbox-selected-hover-icon-color: #4f46e5;
  --mdc-checkbox-selected-icon-color: #4f46e5;
  --mdc-checkbox-selected-pressed-icon-color: #4f46e5;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #4f46e5;
  --mdc-checkbox-selected-hover-state-layer-color: #4f46e5;
  --mdc-checkbox-selected-pressed-state-layer-color: #4f46e5;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-default .dark .mat-mdc-checkbox.mat-warn,
.theme-default.dark .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-default .dark .mat-mdc-button.mat-primary,
.theme-default.dark .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #4f46e5;
  --mat-text-button-state-layer-color: #4f46e5;
  --mat-text-button-ripple-color: rgba(79, 70, 229, 0.1);
}
.theme-default .dark .mat-mdc-button.mat-accent,
.theme-default.dark .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1e293b;
  --mat-text-button-state-layer-color: #1e293b;
  --mat-text-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-default .dark .mat-mdc-button.mat-warn,
.theme-default.dark .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc2626;
  --mat-text-button-state-layer-color: #dc2626;
  --mat-text-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-default .dark .mat-mdc-unelevated-button.mat-primary,
.theme-default.dark .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #4f46e5;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .dark .mat-mdc-unelevated-button.mat-accent,
.theme-default.dark .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .dark .mat-mdc-unelevated-button.mat-warn,
.theme-default.dark .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .dark .mat-mdc-raised-button.mat-primary,
.theme-default.dark .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #4f46e5;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .dark .mat-mdc-raised-button.mat-accent,
.theme-default.dark .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .dark .mat-mdc-raised-button.mat-warn,
.theme-default.dark .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .dark .mat-mdc-outlined-button.mat-primary,
.theme-default.dark .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #4f46e5;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: #4f46e5;
  --mat-outlined-button-ripple-color: rgba(79, 70, 229, 0.1);
}
.theme-default .dark .mat-mdc-outlined-button.mat-accent,
.theme-default.dark .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1e293b;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: #1e293b;
  --mat-outlined-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-default .dark .mat-mdc-outlined-button.mat-warn,
.theme-default.dark .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc2626;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: #dc2626;
  --mat-outlined-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-default .dark .mat-mdc-icon-button.mat-primary,
.theme-default.dark .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #4f46e5;
  --mat-icon-button-state-layer-color: #4f46e5;
  --mat-icon-button-ripple-color: rgba(79, 70, 229, 0.1);
}
.theme-default .dark .mat-mdc-icon-button.mat-accent,
.theme-default.dark .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1e293b;
  --mat-icon-button-state-layer-color: #1e293b;
  --mat-icon-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-default .dark .mat-mdc-icon-button.mat-warn,
.theme-default.dark .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc2626;
  --mat-icon-button-state-layer-color: #dc2626;
  --mat-icon-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-default .dark .mat-mdc-fab.mat-primary,
.theme-default.dark .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #4f46e5;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .dark .mat-mdc-fab.mat-accent,
.theme-default.dark .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #1e293b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .dark .mat-mdc-fab.mat-warn,
.theme-default.dark .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #dc2626;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .dark .mat-mdc-mini-fab.mat-primary,
.theme-default.dark .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #4f46e5;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .dark .mat-mdc-mini-fab.mat-accent,
.theme-default.dark .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #1e293b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .dark .mat-mdc-mini-fab.mat-warn,
.theme-default.dark .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #dc2626;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .dark .mat-accent,
.theme-default.dark .mat-accent {
  --mdc-circular-progress-active-indicator-color: #1e293b;
}
.theme-default .dark .mat-warn,
.theme-default.dark .mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}
.theme-default .dark .mat-badge-accent,
.theme-default.dark .mat-badge-accent {
  --mat-badge-background-color: #1e293b;
  --mat-badge-text-color: white;
}
.theme-default .dark .mat-badge-warn,
.theme-default.dark .mat-badge-warn {
  --mat-badge-background-color: #dc2626;
  --mat-badge-text-color: white;
}
.theme-default .dark .mat-datepicker-content.mat-accent,
.theme-default.dark .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #1e293b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(30, 41, 59, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(30, 41, 59, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-default .dark .mat-datepicker-content.mat-warn,
.theme-default.dark .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #dc2626;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(220, 38, 38, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(220, 38, 38, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-default .dark .mat-datepicker-toggle-active.mat-accent,
.theme-default.dark .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #1e293b;
}
.theme-default .dark .mat-datepicker-toggle-active.mat-warn,
.theme-default.dark .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #dc2626;
}
.theme-default .dark .mat-icon.mat-primary,
.theme-default.dark .mat-icon.mat-primary {
  --mat-icon-color: #4f46e5;
}
.theme-default .dark .mat-icon.mat-accent,
.theme-default.dark .mat-icon.mat-accent {
  --mat-icon-color: #1e293b;
}
.theme-default .dark .mat-icon.mat-warn,
.theme-default.dark .mat-icon.mat-warn {
  --mat-icon-color: #dc2626;
}
.theme-default .dark .mat-step-header.mat-accent,
.theme-default.dark .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #1e293b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #1e293b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #1e293b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-default .dark .mat-step-header.mat-warn,
.theme-default.dark .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #dc2626;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #dc2626;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #dc2626;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-default .dark .mat-toolbar.mat-primary,
.theme-default.dark .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #4f46e5;
  --mat-toolbar-container-text-color: white;
}
.theme-default .dark .mat-toolbar.mat-accent,
.theme-default.dark .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #1e293b;
  --mat-toolbar-container-text-color: white;
}
.theme-default .dark .mat-toolbar.mat-warn,
.theme-default.dark .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #dc2626;
  --mat-toolbar-container-text-color: white;
}

/* Generate the palettes */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define a light & dark Angular Material theme with the generated palettes */
/* Merge the custom base colors with the generated themes */
/* Generate and encapsulate Angular Material themes */
.theme-brand .light,
.theme-brand.light {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-option-selected-state-label-text-color: #2196f3;
  --mat-option-label-text-color: #1e293b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-optgroup-label-text-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
  --mat-app-background-color: #f1f5f9;
  --mat-app-text-color: #1e293b;
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: #64748b;
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #1e293b;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #2196f3;
  --mdc-filled-text-field-focus-active-indicator-color: #2196f3;
  --mdc-filled-text-field-focus-label-text-color: rgba(33, 150, 243, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(30, 41, 59, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(148, 163, 184, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(30, 41, 59, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #dc2626;
  --mdc-filled-text-field-error-focus-label-text-color: #dc2626;
  --mdc-filled-text-field-error-label-text-color: #dc2626;
  --mdc-filled-text-field-error-caret-color: #dc2626;
  --mdc-filled-text-field-active-indicator-color: rgba(226, 232, 240, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(226, 232, 240, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(226, 232, 240, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-hover-active-indicator-color: #dc2626;
  --mdc-outlined-text-field-caret-color: #2196f3;
  --mdc-outlined-text-field-focus-outline-color: #2196f3;
  --mdc-outlined-text-field-focus-label-text-color: rgba(33, 150, 243, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(30, 41, 59, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(148, 163, 184, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(30, 41, 59, 0.6);
  --mdc-outlined-text-field-error-caret-color: #dc2626;
  --mdc-outlined-text-field-error-focus-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-hover-label-text-color: #dc2626;
  --mdc-outlined-text-field-outline-color: rgba(226, 232, 240, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(226, 232, 240, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(226, 232, 240, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-error-hover-outline-color: #dc2626;
  --mdc-outlined-text-field-error-outline-color: #dc2626;
  --mat-form-field-focus-select-arrow-color: rgba(33, 150, 243, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(100, 116, 139, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #dc2626;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(100, 116, 139, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(100, 116, 139, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(30, 41, 59, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(148, 163, 184, 0.38);
  --mat-select-placeholder-text-color: rgba(30, 41, 59, 0.6);
  --mat-select-enabled-arrow-color: rgba(226, 232, 240, 0.54);
  --mat-select-disabled-arrow-color: rgba(30, 41, 59, 0.38);
  --mat-select-focused-arrow-color: rgba(33, 150, 243, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: white;
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(30, 41, 59, 0.87);
  --mdc-dialog-supporting-text-color: rgba(30, 41, 59, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
  --mdc-switch-selected-focus-state-layer-color: #1e88dc;
  --mdc-switch-selected-handle-color: #1e88dc;
  --mdc-switch-selected-hover-state-layer-color: #1e88dc;
  --mdc-switch-selected-pressed-state-layer-color: #1e88dc;
  --mdc-switch-selected-focus-handle-color: #125183;
  --mdc-switch-selected-hover-handle-color: #125183;
  --mdc-switch-selected-pressed-handle-color: #125183;
  --mdc-switch-selected-focus-track-color: #a1c6f8;
  --mdc-switch-selected-hover-track-color: #a1c6f8;
  --mdc-switch-selected-pressed-track-color: #a1c6f8;
  --mdc-switch-selected-track-color: #a1c6f8;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-disabled-label-text-color: #94a3b8;
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-color: #2196f3;
  --mdc-slider-focus-handle-color: #2196f3;
  --mdc-slider-hover-handle-color: #2196f3;
  --mdc-slider-active-track-color: #2196f3;
  --mdc-slider-inactive-track-color: #2196f3;
  --mdc-slider-with-tick-marks-inactive-container-color: #2196f3;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: #2196f3;
  --mat-slider-hover-state-layer-color: rgba(33, 150, 243, 0.05);
  --mat-slider-focus-state-layer-color: rgba(33, 150, 243, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-item-label-text-color: #1e293b;
  --mat-menu-item-icon-color: #1e293b;
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: #e2e8f0;
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 0;
  --mdc-list-list-item-label-text-color: #1e293b;
  --mdc-list-list-item-supporting-text-color: #64748b;
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: #94a3b8;
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: #1e293b;
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: #1e293b;
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: #94a3b8;
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: #1e293b;
  --mdc-text-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: #1e293b;
  --mdc-filled-button-disabled-container-color: rgba(148, 163, 184, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: #1e293b;
  --mdc-protected-button-disabled-container-color: rgba(148, 163, 184, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: #e2e8f0;
  --mdc-outlined-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-outlined-button-label-text-color: #1e293b;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-fab-container-color: white;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(148, 163, 184, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(148, 163, 184, 0.38);
  --mdc-fab-small-container-color: white;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(148, 163, 184, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(148, 163, 184, 0.38);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #1e293b;
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: white;
  --mat-table-header-headline-color: #1e293b;
  --mat-table-row-item-label-text-color: #1e293b;
  --mat-table-row-item-outline-color: #e2e8f0;
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #2196f3;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #2196f3;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #94a3b8;
  --mat-badge-disabled-state-text-color: #94a3b8;
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: #1e293b;
  --mat-bottom-sheet-container-background-color: white;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: #94a3b8;
  --mat-legacy-button-toggle-state-layer-color: #64748b;
  --mat-legacy-button-toggle-selected-state-text-color: #64748b;
  --mat-legacy-button-toggle-selected-state-background-color: #e2e8f0;
  --mat-legacy-button-toggle-disabled-state-text-color: #94a3b8;
  --mat-legacy-button-toggle-disabled-state-background-color: #cbd5e1;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #e2e8f0;
  --mat-standard-button-toggle-text-color: #1e293b;
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: #64748b;
  --mat-standard-button-toggle-selected-state-background-color: #e2e8f0;
  --mat-standard-button-toggle-selected-state-text-color: #1e293b;
  --mat-standard-button-toggle-disabled-state-text-color: #94a3b8;
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: #1e293b;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #e2e8f0;
  --mat-standard-button-toggle-divider-color: #e2e8f0;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #2196f3;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(33, 150, 243, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(33, 150, 243, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(33, 150, 243, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #2196f3;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(33, 150, 243, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: #64748b;
  --mat-datepicker-calendar-body-label-text-color: #64748b;
  --mat-datepicker-calendar-period-button-text-color: #1e293b;
  --mat-datepicker-calendar-period-button-icon-color: #64748b;
  --mat-datepicker-calendar-navigation-button-icon-color: #64748b;
  --mat-datepicker-calendar-header-divider-color: #e2e8f0;
  --mat-datepicker-calendar-header-text-color: #64748b;
  --mat-datepicker-calendar-date-today-outline-color: #94a3b8;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(148, 163, 184, 0.8);
  --mat-datepicker-calendar-date-text-color: #1e293b;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: #94a3b8;
  --mat-datepicker-calendar-date-preview-state-outline-color: #e2e8f0;
  --mat-datepicker-range-input-separator-color: #1e293b;
  --mat-datepicker-range-input-disabled-state-separator-color: #94a3b8;
  --mat-datepicker-range-input-disabled-state-text-color: #94a3b8;
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: #1e293b;
  --mat-divider-width: 1px;
  --mat-divider-color: #e2e8f0;
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: #1e293b;
  --mat-expansion-actions-divider-color: #e2e8f0;
  --mat-expansion-header-hover-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-expansion-header-focus-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-expansion-header-disabled-state-text-color: #94a3b8;
  --mat-expansion-header-text-color: #1e293b;
  --mat-expansion-header-description-color: #64748b;
  --mat-expansion-header-indicator-color: #64748b;
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: #e2e8f0;
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: #1e293b;
  --mat-sidenav-content-background-color: #f1f5f9;
  --mat-sidenav-content-text-color: #1e293b;
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #2196f3;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #2196f3;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #2196f3;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: #e2e8f0;
  --mat-stepper-header-hover-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-stepper-header-focus-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-stepper-header-label-text-color: #64748b;
  --mat-stepper-header-optional-label-text-color: #64748b;
  --mat-stepper-header-selected-state-label-text-color: #1e293b;
  --mat-stepper-header-error-state-label-text-color: #dc2626;
  --mat-stepper-header-icon-background-color: #64748b;
  --mat-stepper-header-error-state-icon-foreground-color: #dc2626;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #64748b;
  --mat-toolbar-container-background-color: white;
  --mat-toolbar-container-text-color: #1e293b;
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: #1e293b;
}
.theme-brand .light .mat-accent,
.theme-brand.light .mat-accent {
  --mat-option-selected-state-label-text-color: #1e293b;
  --mat-option-label-text-color: #1e293b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.theme-brand .light .mat-warn,
.theme-brand.light .mat-warn {
  --mat-option-selected-state-label-text-color: #dc2626;
  --mat-option-label-text-color: #1e293b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.theme-brand .light .mat-primary,
.theme-brand.light .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #2196f3;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #2196f3;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-brand .light .mat-accent,
.theme-brand.light .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-brand .light .mat-warn,
.theme-brand.light .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #dc2626;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #dc2626;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-brand .light .mat-elevation-z0, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z0,
.theme-brand.light .mat-elevation-z0,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z1, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z1,
.theme-brand.light .mat-elevation-z1,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z2, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z2,
.theme-brand.light .mat-elevation-z2,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z3, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z3,
.theme-brand.light .mat-elevation-z3,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z4, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z4,
.theme-brand.light .mat-elevation-z4,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z5, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z5,
.theme-brand.light .mat-elevation-z5,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z6, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z6,
.theme-brand.light .mat-elevation-z6,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z7, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z7,
.theme-brand.light .mat-elevation-z7,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z8, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z8,
.theme-brand.light .mat-elevation-z8,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z9, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z9,
.theme-brand.light .mat-elevation-z9,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z10, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z10,
.theme-brand.light .mat-elevation-z10,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z11, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z11,
.theme-brand.light .mat-elevation-z11,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z12, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z12,
.theme-brand.light .mat-elevation-z12,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z13, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z13,
.theme-brand.light .mat-elevation-z13,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z14, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z14,
.theme-brand.light .mat-elevation-z14,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z15, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z15,
.theme-brand.light .mat-elevation-z15,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z16, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z16,
.theme-brand.light .mat-elevation-z16,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z17, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z17,
.theme-brand.light .mat-elevation-z17,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z18, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z18,
.theme-brand.light .mat-elevation-z18,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z19, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z19,
.theme-brand.light .mat-elevation-z19,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z20, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z20,
.theme-brand.light .mat-elevation-z20,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z21, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z21,
.theme-brand.light .mat-elevation-z21,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z22, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z22,
.theme-brand.light .mat-elevation-z22,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z23, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z23,
.theme-brand.light .mat-elevation-z23,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-brand .light .mat-elevation-z24, .theme-brand .light .mat-mdc-elevation-specific.mat-elevation-z24,
.theme-brand.light .mat-elevation-z24,
.theme-brand.light .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-brand .light .mat-mdc-progress-bar,
.theme-brand.light .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #2196f3;
  --mdc-linear-progress-track-color: rgba(33, 150, 243, 0.25);
}
.theme-brand .light .mat-mdc-progress-bar.mat-accent,
.theme-brand.light .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1e293b;
  --mdc-linear-progress-track-color: rgba(30, 41, 59, 0.25);
}
.theme-brand .light .mat-mdc-progress-bar.mat-warn,
.theme-brand.light .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc2626;
  --mdc-linear-progress-track-color: rgba(220, 38, 38, 0.25);
}
.theme-brand .light .mat-mdc-form-field.mat-accent,
.theme-brand.light .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #1e293b;
  --mdc-filled-text-field-focus-active-indicator-color: #1e293b;
  --mdc-filled-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mdc-outlined-text-field-caret-color: #1e293b;
  --mdc-outlined-text-field-focus-outline-color: #1e293b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(30, 41, 59, 0.87);
}
.theme-brand .light .mat-mdc-form-field.mat-warn,
.theme-brand.light .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #dc2626;
  --mdc-filled-text-field-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mdc-outlined-text-field-caret-color: #dc2626;
  --mdc-outlined-text-field-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-brand .light .mat-mdc-form-field.mat-accent,
.theme-brand.light .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(30, 41, 59, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(148, 163, 184, 0.38);
  --mat-select-placeholder-text-color: rgba(30, 41, 59, 0.6);
  --mat-select-enabled-arrow-color: rgba(226, 232, 240, 0.54);
  --mat-select-disabled-arrow-color: rgba(30, 41, 59, 0.38);
  --mat-select-focused-arrow-color: rgba(30, 41, 59, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-brand .light .mat-mdc-form-field.mat-warn,
.theme-brand.light .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(30, 41, 59, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(148, 163, 184, 0.38);
  --mat-select-placeholder-text-color: rgba(30, 41, 59, 0.6);
  --mat-select-enabled-arrow-color: rgba(226, 232, 240, 0.54);
  --mat-select-disabled-arrow-color: rgba(30, 41, 59, 0.38);
  --mat-select-focused-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-brand .light .mat-mdc-standard-chip,
.theme-brand.light .mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.theme-brand .light .mat-mdc-standard-chip,
.theme-brand.light .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.theme-brand .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .theme-brand .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.theme-brand.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.theme-brand.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #2196f3;
  --mdc-chip-elevated-selected-container-color: #2196f3;
  --mdc-chip-elevated-disabled-container-color: #2196f3;
  --mdc-chip-flat-disabled-selected-container-color: #2196f3;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-brand .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .theme-brand .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.theme-brand.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.theme-brand.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #1e293b;
  --mdc-chip-elevated-selected-container-color: #1e293b;
  --mdc-chip-elevated-disabled-container-color: #1e293b;
  --mdc-chip-flat-disabled-selected-container-color: #1e293b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-brand .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .theme-brand .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.theme-brand.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.theme-brand.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-selected-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-flat-disabled-selected-container-color: #dc2626;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-brand .light .mat-mdc-slide-toggle,
.theme-brand.light .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: #1e293b;
}
.theme-brand .light .mat-mdc-slide-toggle.mat-accent,
.theme-brand.light .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #475569;
  --mdc-switch-selected-handle-color: #475569;
  --mdc-switch-selected-hover-state-layer-color: #475569;
  --mdc-switch-selected-pressed-state-layer-color: #475569;
  --mdc-switch-selected-focus-handle-color: #0f172a;
  --mdc-switch-selected-hover-handle-color: #0f172a;
  --mdc-switch-selected-pressed-handle-color: #0f172a;
  --mdc-switch-selected-focus-track-color: #cbd5e1;
  --mdc-switch-selected-hover-track-color: #cbd5e1;
  --mdc-switch-selected-pressed-track-color: #cbd5e1;
  --mdc-switch-selected-track-color: #cbd5e1;
}
.theme-brand .light .mat-mdc-slide-toggle.mat-warn,
.theme-brand.light .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.theme-brand .light .mat-mdc-radio-button,
.theme-brand.light .mat-mdc-radio-button {
  --mdc-form-field-label-text-color: #1e293b;
}
.theme-brand .light .mat-mdc-radio-button.mat-primary,
.theme-brand.light .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #2196f3;
  --mdc-radio-selected-hover-icon-color: #2196f3;
  --mdc-radio-selected-icon-color: #2196f3;
  --mdc-radio-selected-pressed-icon-color: #2196f3;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #2196f3;
  --mat-radio-disabled-label-color: #94a3b8;
}
.theme-brand .light .mat-mdc-radio-button.mat-accent,
.theme-brand.light .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #1e293b;
  --mat-radio-disabled-label-color: #94a3b8;
}
.theme-brand .light .mat-mdc-radio-button.mat-warn,
.theme-brand.light .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #dc2626;
  --mat-radio-disabled-label-color: #94a3b8;
}
.theme-brand .light .mat-accent,
.theme-brand.light .mat-accent {
  --mat-slider-ripple-color: #1e293b;
  --mat-slider-hover-state-layer-color: rgba(30, 41, 59, 0.05);
  --mat-slider-focus-state-layer-color: rgba(30, 41, 59, 0.2);
  --mdc-slider-handle-color: #1e293b;
  --mdc-slider-focus-handle-color: #1e293b;
  --mdc-slider-hover-handle-color: #1e293b;
  --mdc-slider-active-track-color: #1e293b;
  --mdc-slider-inactive-track-color: #1e293b;
  --mdc-slider-with-tick-marks-inactive-container-color: #1e293b;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-brand .light .mat-warn,
.theme-brand.light .mat-warn {
  --mat-slider-ripple-color: #dc2626;
  --mat-slider-hover-state-layer-color: rgba(220, 38, 38, 0.05);
  --mat-slider-focus-state-layer-color: rgba(220, 38, 38, 0.2);
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-brand .light .mdc-list-item__start,
.theme-brand .light .mdc-list-item__end,
.theme-brand.light .mdc-list-item__start,
.theme-brand.light .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #2196f3;
  --mdc-radio-selected-hover-icon-color: #2196f3;
  --mdc-radio-selected-icon-color: #2196f3;
  --mdc-radio-selected-pressed-icon-color: #2196f3;
}
.theme-brand .light .mat-accent .mdc-list-item__start,
.theme-brand .light .mat-accent .mdc-list-item__end,
.theme-brand.light .mat-accent .mdc-list-item__start,
.theme-brand.light .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
}
.theme-brand .light .mat-warn .mdc-list-item__start,
.theme-brand .light .mat-warn .mdc-list-item__end,
.theme-brand.light .mat-warn .mdc-list-item__start,
.theme-brand.light .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
}
.theme-brand .light .mat-mdc-list-option,
.theme-brand.light .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #2196f3;
  --mdc-checkbox-selected-hover-icon-color: #2196f3;
  --mdc-checkbox-selected-icon-color: #2196f3;
  --mdc-checkbox-selected-pressed-icon-color: #2196f3;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #2196f3;
  --mdc-checkbox-selected-hover-state-layer-color: #2196f3;
  --mdc-checkbox-selected-pressed-state-layer-color: #2196f3;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-brand .light .mat-mdc-list-option.mat-accent,
.theme-brand.light .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-brand .light .mat-mdc-list-option.mat-warn,
.theme-brand.light .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-brand .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-brand .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-brand.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-brand.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #2196f3;
}
.theme-brand .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #2196f3;
}
.theme-brand .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-brand .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-brand .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.theme-brand.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-brand.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-brand.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.theme-brand .light .mat-mdc-tab-group, .theme-brand .light .mat-mdc-tab-nav-bar,
.theme-brand.light .mat-mdc-tab-group,
.theme-brand.light .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #2196f3;
  --mat-tab-header-disabled-ripple-color: #94a3b8;
  --mat-tab-header-pagination-icon-color: #64748b;
  --mat-tab-header-inactive-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-label-text-color: #2196f3;
  --mat-tab-header-active-ripple-color: #2196f3;
  --mat-tab-header-inactive-ripple-color: #2196f3;
  --mat-tab-header-inactive-focus-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-focus-label-text-color: #2196f3;
  --mat-tab-header-active-hover-label-text-color: #2196f3;
  --mat-tab-header-active-focus-indicator-color: #2196f3;
  --mat-tab-header-active-hover-indicator-color: #2196f3;
}
.theme-brand .light .mat-mdc-tab-group.mat-accent, .theme-brand .light .mat-mdc-tab-nav-bar.mat-accent,
.theme-brand.light .mat-mdc-tab-group.mat-accent,
.theme-brand.light .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #1e293b;
  --mat-tab-header-disabled-ripple-color: #94a3b8;
  --mat-tab-header-pagination-icon-color: #64748b;
  --mat-tab-header-inactive-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-label-text-color: #1e293b;
  --mat-tab-header-active-ripple-color: #1e293b;
  --mat-tab-header-inactive-ripple-color: #1e293b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-focus-label-text-color: #1e293b;
  --mat-tab-header-active-hover-label-text-color: #1e293b;
  --mat-tab-header-active-focus-indicator-color: #1e293b;
  --mat-tab-header-active-hover-indicator-color: #1e293b;
}
.theme-brand .light .mat-mdc-tab-group.mat-warn, .theme-brand .light .mat-mdc-tab-nav-bar.mat-warn,
.theme-brand.light .mat-mdc-tab-group.mat-warn,
.theme-brand.light .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #dc2626;
  --mat-tab-header-disabled-ripple-color: #94a3b8;
  --mat-tab-header-pagination-icon-color: #64748b;
  --mat-tab-header-inactive-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-label-text-color: #dc2626;
  --mat-tab-header-active-ripple-color: #dc2626;
  --mat-tab-header-inactive-ripple-color: #dc2626;
  --mat-tab-header-inactive-focus-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-focus-label-text-color: #dc2626;
  --mat-tab-header-active-hover-label-text-color: #dc2626;
  --mat-tab-header-active-focus-indicator-color: #dc2626;
  --mat-tab-header-active-hover-indicator-color: #dc2626;
}
.theme-brand .light .mat-mdc-tab-group.mat-background-primary, .theme-brand .light .mat-mdc-tab-nav-bar.mat-background-primary,
.theme-brand.light .mat-mdc-tab-group.mat-background-primary,
.theme-brand.light .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #2196f3;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-brand .light .mat-mdc-tab-group.mat-background-accent, .theme-brand .light .mat-mdc-tab-nav-bar.mat-background-accent,
.theme-brand.light .mat-mdc-tab-group.mat-background-accent,
.theme-brand.light .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #1e293b;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-brand .light .mat-mdc-tab-group.mat-background-warn, .theme-brand .light .mat-mdc-tab-nav-bar.mat-background-warn,
.theme-brand.light .mat-mdc-tab-group.mat-background-warn,
.theme-brand.light .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #dc2626;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-brand .light .mat-mdc-checkbox,
.theme-brand.light .mat-mdc-checkbox {
  --mdc-form-field-label-text-color: #1e293b;
}
.theme-brand .light .mat-mdc-checkbox.mat-primary,
.theme-brand.light .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #2196f3;
  --mdc-checkbox-selected-hover-icon-color: #2196f3;
  --mdc-checkbox-selected-icon-color: #2196f3;
  --mdc-checkbox-selected-pressed-icon-color: #2196f3;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #2196f3;
  --mdc-checkbox-selected-hover-state-layer-color: #2196f3;
  --mdc-checkbox-selected-pressed-state-layer-color: #2196f3;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-brand .light .mat-mdc-checkbox.mat-warn,
.theme-brand.light .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-brand .light .mat-mdc-button.mat-primary,
.theme-brand.light .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #2196f3;
  --mat-text-button-state-layer-color: #2196f3;
  --mat-text-button-ripple-color: rgba(33, 150, 243, 0.1);
}
.theme-brand .light .mat-mdc-button.mat-accent,
.theme-brand.light .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1e293b;
  --mat-text-button-state-layer-color: #1e293b;
  --mat-text-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-brand .light .mat-mdc-button.mat-warn,
.theme-brand.light .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc2626;
  --mat-text-button-state-layer-color: #dc2626;
  --mat-text-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-brand .light .mat-mdc-unelevated-button.mat-primary,
.theme-brand.light .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #2196f3;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .light .mat-mdc-unelevated-button.mat-accent,
.theme-brand.light .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .light .mat-mdc-unelevated-button.mat-warn,
.theme-brand.light .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .light .mat-mdc-raised-button.mat-primary,
.theme-brand.light .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #2196f3;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .light .mat-mdc-raised-button.mat-accent,
.theme-brand.light .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .light .mat-mdc-raised-button.mat-warn,
.theme-brand.light .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .light .mat-mdc-outlined-button.mat-primary,
.theme-brand.light .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #2196f3;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: #2196f3;
  --mat-outlined-button-ripple-color: rgba(33, 150, 243, 0.1);
}
.theme-brand .light .mat-mdc-outlined-button.mat-accent,
.theme-brand.light .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1e293b;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: #1e293b;
  --mat-outlined-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-brand .light .mat-mdc-outlined-button.mat-warn,
.theme-brand.light .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc2626;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: #dc2626;
  --mat-outlined-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-brand .light .mat-mdc-icon-button.mat-primary,
.theme-brand.light .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #2196f3;
  --mat-icon-button-state-layer-color: #2196f3;
  --mat-icon-button-ripple-color: rgba(33, 150, 243, 0.1);
}
.theme-brand .light .mat-mdc-icon-button.mat-accent,
.theme-brand.light .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1e293b;
  --mat-icon-button-state-layer-color: #1e293b;
  --mat-icon-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-brand .light .mat-mdc-icon-button.mat-warn,
.theme-brand.light .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc2626;
  --mat-icon-button-state-layer-color: #dc2626;
  --mat-icon-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-brand .light .mat-mdc-fab.mat-primary,
.theme-brand.light .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #2196f3;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .light .mat-mdc-fab.mat-accent,
.theme-brand.light .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #1e293b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .light .mat-mdc-fab.mat-warn,
.theme-brand.light .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #dc2626;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .light .mat-mdc-mini-fab.mat-primary,
.theme-brand.light .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #2196f3;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .light .mat-mdc-mini-fab.mat-accent,
.theme-brand.light .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #1e293b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .light .mat-mdc-mini-fab.mat-warn,
.theme-brand.light .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #dc2626;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .light .mat-accent,
.theme-brand.light .mat-accent {
  --mdc-circular-progress-active-indicator-color: #1e293b;
}
.theme-brand .light .mat-warn,
.theme-brand.light .mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}
.theme-brand .light .mat-badge-accent,
.theme-brand.light .mat-badge-accent {
  --mat-badge-background-color: #1e293b;
  --mat-badge-text-color: white;
}
.theme-brand .light .mat-badge-warn,
.theme-brand.light .mat-badge-warn {
  --mat-badge-background-color: #dc2626;
  --mat-badge-text-color: white;
}
.theme-brand .light .mat-datepicker-content.mat-accent,
.theme-brand.light .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #1e293b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(30, 41, 59, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(30, 41, 59, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-brand .light .mat-datepicker-content.mat-warn,
.theme-brand.light .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #dc2626;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(220, 38, 38, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(220, 38, 38, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-brand .light .mat-datepicker-toggle-active.mat-accent,
.theme-brand.light .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #1e293b;
}
.theme-brand .light .mat-datepicker-toggle-active.mat-warn,
.theme-brand.light .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #dc2626;
}
.theme-brand .light .mat-icon.mat-primary,
.theme-brand.light .mat-icon.mat-primary {
  --mat-icon-color: #2196f3;
}
.theme-brand .light .mat-icon.mat-accent,
.theme-brand.light .mat-icon.mat-accent {
  --mat-icon-color: #1e293b;
}
.theme-brand .light .mat-icon.mat-warn,
.theme-brand.light .mat-icon.mat-warn {
  --mat-icon-color: #dc2626;
}
.theme-brand .light .mat-step-header.mat-accent,
.theme-brand.light .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #1e293b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #1e293b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #1e293b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-brand .light .mat-step-header.mat-warn,
.theme-brand.light .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #dc2626;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #dc2626;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #dc2626;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-brand .light .mat-toolbar.mat-primary,
.theme-brand.light .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #2196f3;
  --mat-toolbar-container-text-color: white;
}
.theme-brand .light .mat-toolbar.mat-accent,
.theme-brand.light .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #1e293b;
  --mat-toolbar-container-text-color: white;
}
.theme-brand .light .mat-toolbar.mat-warn,
.theme-brand.light .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #dc2626;
  --mat-toolbar-container-text-color: white;
}

.theme-brand .dark,
.theme-brand.dark {
  --mat-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-option-selected-state-label-text-color: #2196f3;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-optgroup-label-text-color: white;
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
  --mat-app-background-color: #0f172a;
  --mat-app-text-color: white;
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: #1e293b;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: #1e293b;
  --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: #94a3b8;
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #64748b;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #2196f3;
  --mdc-filled-text-field-focus-active-indicator-color: #2196f3;
  --mdc-filled-text-field-focus-label-text-color: rgba(33, 150, 243, 0.87);
  --mdc-filled-text-field-container-color: #273243;
  --mdc-filled-text-field-disabled-container-color: #232d3f;
  --mdc-filled-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(71, 85, 105, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(71, 85, 105, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #dc2626;
  --mdc-filled-text-field-error-focus-label-text-color: #dc2626;
  --mdc-filled-text-field-error-label-text-color: #dc2626;
  --mdc-filled-text-field-error-caret-color: #dc2626;
  --mdc-filled-text-field-active-indicator-color: rgba(241, 245, 249, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(241, 245, 249, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(241, 245, 249, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-hover-active-indicator-color: #dc2626;
  --mdc-outlined-text-field-caret-color: #2196f3;
  --mdc-outlined-text-field-focus-outline-color: #2196f3;
  --mdc-outlined-text-field-focus-label-text-color: rgba(33, 150, 243, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(71, 85, 105, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(71, 85, 105, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-error-caret-color: #dc2626;
  --mdc-outlined-text-field-error-focus-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-hover-label-text-color: #dc2626;
  --mdc-outlined-text-field-outline-color: rgba(241, 245, 249, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(241, 245, 249, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(241, 245, 249, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-error-hover-outline-color: #dc2626;
  --mdc-outlined-text-field-error-outline-color: #dc2626;
  --mat-form-field-focus-select-arrow-color: rgba(33, 150, 243, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(241, 245, 249, 0.38);
  --mat-form-field-state-layer-color: rgba(255, 255, 255, 0.87);
  --mat-form-field-error-text-color: #dc2626;
  --mat-form-field-select-option-text-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-select-disabled-option-text-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(241, 245, 249, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.08;
  --mat-form-field-focus-state-layer-opacity: 0.24;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: #1e293b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(71, 85, 105, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(33, 150, 243, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: #1e293b;
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: #1e293b;
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
  --mdc-switch-selected-focus-state-layer-color: #a1c6f8;
  --mdc-switch-selected-handle-color: #a1c6f8;
  --mdc-switch-selected-hover-state-layer-color: #a1c6f8;
  --mdc-switch-selected-pressed-state-layer-color: #a1c6f8;
  --mdc-switch-selected-focus-handle-color: #c4dafa;
  --mdc-switch-selected-hover-handle-color: #c4dafa;
  --mdc-switch-selected-pressed-handle-color: #c4dafa;
  --mdc-switch-selected-focus-track-color: #1e88dc;
  --mdc-switch-selected-hover-track-color: #1e88dc;
  --mdc-switch-selected-pressed-track-color: #1e88dc;
  --mdc-switch-selected-track-color: #1e88dc;
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-icon-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-disabled-label-text-color: #475569;
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-color: #2196f3;
  --mdc-slider-focus-handle-color: #2196f3;
  --mdc-slider-hover-handle-color: #2196f3;
  --mdc-slider-active-track-color: #2196f3;
  --mdc-slider-inactive-track-color: #2196f3;
  --mdc-slider-with-tick-marks-inactive-container-color: #2196f3;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-label-container-color: #fff;
  --mdc-slider-label-label-text-color: #000;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: #2196f3;
  --mat-slider-hover-state-layer-color: rgba(33, 150, 243, 0.05);
  --mat-slider-focus-state-layer-color: rgba(33, 150, 243, 0.2);
  --mat-slider-value-indicator-opacity: 0.9;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-item-label-text-color: white;
  --mat-menu-item-icon-color: white;
  --mat-menu-item-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-container-color: #1e293b;
  --mat-menu-divider-color: rgba(241, 245, 249, 0.12);
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 0;
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: #94a3b8;
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: #64748b;
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: white;
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
  --mat-paginator-container-text-color: rgba(255, 255, 255, 0.87);
  --mat-paginator-container-background-color: #1e293b;
  --mat-paginator-enabled-icon-color: rgba(255, 255, 255, 0.54);
  --mat-paginator-disabled-icon-color: rgba(255, 255, 255, 0.12);
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
  --mat-checkbox-disabled-label-color: #475569;
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: white;
  --mdc-text-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mat-text-button-state-layer-color: white;
  --mat-text-button-disabled-state-layer-color: white;
  --mat-text-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.08;
  --mat-text-button-focus-state-layer-opacity: 0.24;
  --mat-text-button-pressed-state-layer-opacity: 0.24;
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: white;
  --mdc-filled-button-disabled-container-color: rgba(30, 41, 59, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-disabled-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.08;
  --mat-filled-button-focus-state-layer-opacity: 0.24;
  --mat-filled-button-pressed-state-layer-opacity: 0.24;
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: white;
  --mdc-protected-button-disabled-container-color: rgba(30, 41, 59, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-disabled-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.08;
  --mat-protected-button-focus-state-layer-opacity: 0.24;
  --mat-protected-button-pressed-state-layer-opacity: 0.24;
  --mdc-outlined-button-disabled-outline-color: rgba(241, 245, 249, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mdc-outlined-button-label-text-color: white;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: white;
  --mat-outlined-button-disabled-state-layer-color: white;
  --mat-outlined-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.08;
  --mat-outlined-button-focus-state-layer-opacity: 0.24;
  --mat-outlined-button-pressed-state-layer-opacity: 0.24;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
  --mat-icon-button-state-layer-color: white;
  --mat-icon-button-disabled-state-layer-color: white;
  --mat-icon-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.08;
  --mat-icon-button-focus-state-layer-opacity: 0.24;
  --mat-icon-button-pressed-state-layer-opacity: 0.24;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-fab-container-color: #1e293b;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-disabled-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.08;
  --mat-fab-focus-state-layer-opacity: 0.24;
  --mat-fab-pressed-state-layer-opacity: 0.24;
  --mat-fab-disabled-state-container-color: rgba(15, 23, 42, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(30, 41, 59, 0.5);
  --mdc-fab-small-container-color: #1e293b;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-disabled-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.08;
  --mat-fab-small-focus-state-layer-opacity: 0.24;
  --mat-fab-small-pressed-state-layer-opacity: 0.24;
  --mat-fab-small-disabled-state-container-color: rgba(15, 23, 42, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(30, 41, 59, 0.5);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #d2d4d8;
  --mdc-snackbar-supporting-text-color: rgba(30, 41, 59, 0.87);
  --mat-snack-bar-button-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: #1e293b;
  --mat-table-header-headline-color: white;
  --mat-table-row-item-label-text-color: white;
  --mat-table-row-item-outline-color: rgba(241, 245, 249, 0.12);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #2196f3;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #2196f3;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #1e293b;
  --mat-badge-disabled-state-text-color: #475569;
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: white;
  --mat-bottom-sheet-container-background-color: #1e293b;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: #64748b;
  --mat-legacy-button-toggle-state-layer-color: #e2e8f0;
  --mat-legacy-button-toggle-selected-state-text-color: #94a3b8;
  --mat-legacy-button-toggle-selected-state-background-color: rgba(255, 255, 255, 0.05);
  --mat-legacy-button-toggle-disabled-state-text-color: #1e293b;
  --mat-legacy-button-toggle-disabled-state-background-color: #0f172a;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #1e293b;
  --mat-standard-button-toggle-text-color: white;
  --mat-standard-button-toggle-background-color: #1e293b;
  --mat-standard-button-toggle-state-layer-color: #e2e8f0;
  --mat-standard-button-toggle-selected-state-background-color: rgba(255, 255, 255, 0.05);
  --mat-standard-button-toggle-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-state-text-color: #1e293b;
  --mat-standard-button-toggle-disabled-state-background-color: #1e293b;
  --mat-standard-button-toggle-disabled-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #1e293b;
  --mat-standard-button-toggle-divider-color: #374152;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #2196f3;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(33, 150, 243, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(33, 150, 243, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(33, 150, 243, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #2196f3;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(33, 150, 243, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: #f1f5f9;
  --mat-datepicker-calendar-body-label-text-color: #94a3b8;
  --mat-datepicker-calendar-period-button-text-color: white;
  --mat-datepicker-calendar-period-button-icon-color: #f1f5f9;
  --mat-datepicker-calendar-navigation-button-icon-color: #f1f5f9;
  --mat-datepicker-calendar-header-divider-color: rgba(241, 245, 249, 0.12);
  --mat-datepicker-calendar-header-text-color: #94a3b8;
  --mat-datepicker-calendar-date-today-outline-color: #64748b;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(100, 116, 139, 0.8);
  --mat-datepicker-calendar-date-text-color: white;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: #475569;
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(241, 245, 249, 0.24);
  --mat-datepicker-range-input-separator-color: white;
  --mat-datepicker-range-input-disabled-state-separator-color: #475569;
  --mat-datepicker-range-input-disabled-state-text-color: #475569;
  --mat-datepicker-calendar-container-background-color: #1e293b;
  --mat-datepicker-calendar-container-text-color: white;
  --mat-divider-width: 1px;
  --mat-divider-color: rgba(241, 245, 249, 0.12);
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: #1e293b;
  --mat-expansion-container-text-color: white;
  --mat-expansion-actions-divider-color: rgba(241, 245, 249, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-expansion-header-focus-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-expansion-header-disabled-state-text-color: #1e293b;
  --mat-expansion-header-text-color: white;
  --mat-expansion-header-description-color: #94a3b8;
  --mat-expansion-header-indicator-color: #94a3b8;
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: rgba(241, 245, 249, 0.12);
  --mat-sidenav-container-background-color: #1e293b;
  --mat-sidenav-container-text-color: white;
  --mat-sidenav-content-background-color: #0f172a;
  --mat-sidenav-content-text-color: white;
  --mat-sidenav-scrim-color: rgba(225, 214, 196, 0.6);
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #2196f3;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #2196f3;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #2196f3;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: #1e293b;
  --mat-stepper-line-color: rgba(241, 245, 249, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-stepper-header-focus-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-stepper-header-label-text-color: #94a3b8;
  --mat-stepper-header-optional-label-text-color: #94a3b8;
  --mat-stepper-header-selected-state-label-text-color: white;
  --mat-stepper-header-error-state-label-text-color: #dc2626;
  --mat-stepper-header-icon-background-color: #94a3b8;
  --mat-stepper-header-error-state-icon-foreground-color: #dc2626;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #94a3b8;
  --mat-toolbar-container-background-color: #0f172a;
  --mat-toolbar-container-text-color: white;
  --mat-tree-container-background-color: #1e293b;
  --mat-tree-node-text-color: white;
}
.theme-brand .dark .mat-accent,
.theme-brand.dark .mat-accent {
  --mat-option-selected-state-label-text-color: #1e293b;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.theme-brand .dark .mat-warn,
.theme-brand.dark .mat-warn {
  --mat-option-selected-state-label-text-color: #dc2626;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.theme-brand .dark .mat-primary,
.theme-brand.dark .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #2196f3;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #2196f3;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-brand .dark .mat-accent,
.theme-brand.dark .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-brand .dark .mat-warn,
.theme-brand.dark .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #dc2626;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #dc2626;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-brand .dark .mat-elevation-z0, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z0,
.theme-brand.dark .mat-elevation-z0,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z1, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z1,
.theme-brand.dark .mat-elevation-z1,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z2, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z2,
.theme-brand.dark .mat-elevation-z2,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z3, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z3,
.theme-brand.dark .mat-elevation-z3,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z4, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z4,
.theme-brand.dark .mat-elevation-z4,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z5, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z5,
.theme-brand.dark .mat-elevation-z5,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z6, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z6,
.theme-brand.dark .mat-elevation-z6,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z7, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z7,
.theme-brand.dark .mat-elevation-z7,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z8, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z8,
.theme-brand.dark .mat-elevation-z8,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z9, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z9,
.theme-brand.dark .mat-elevation-z9,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z10, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z10,
.theme-brand.dark .mat-elevation-z10,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z11, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z11,
.theme-brand.dark .mat-elevation-z11,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z12, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z12,
.theme-brand.dark .mat-elevation-z12,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z13, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z13,
.theme-brand.dark .mat-elevation-z13,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z14, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z14,
.theme-brand.dark .mat-elevation-z14,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z15, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z15,
.theme-brand.dark .mat-elevation-z15,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z16, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z16,
.theme-brand.dark .mat-elevation-z16,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z17, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z17,
.theme-brand.dark .mat-elevation-z17,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z18, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z18,
.theme-brand.dark .mat-elevation-z18,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z19, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z19,
.theme-brand.dark .mat-elevation-z19,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z20, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z20,
.theme-brand.dark .mat-elevation-z20,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z21, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z21,
.theme-brand.dark .mat-elevation-z21,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z22, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z22,
.theme-brand.dark .mat-elevation-z22,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z23, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z23,
.theme-brand.dark .mat-elevation-z23,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-brand .dark .mat-elevation-z24, .theme-brand .dark .mat-mdc-elevation-specific.mat-elevation-z24,
.theme-brand.dark .mat-elevation-z24,
.theme-brand.dark .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-brand .dark .mat-mdc-progress-bar,
.theme-brand.dark .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #2196f3;
  --mdc-linear-progress-track-color: rgba(33, 150, 243, 0.25);
}
.theme-brand .dark .mat-mdc-progress-bar.mat-accent,
.theme-brand.dark .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1e293b;
  --mdc-linear-progress-track-color: rgba(30, 41, 59, 0.25);
}
.theme-brand .dark .mat-mdc-progress-bar.mat-warn,
.theme-brand.dark .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc2626;
  --mdc-linear-progress-track-color: rgba(220, 38, 38, 0.25);
}
.theme-brand .dark .mat-mdc-form-field.mat-accent,
.theme-brand.dark .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #1e293b;
  --mdc-filled-text-field-focus-active-indicator-color: #1e293b;
  --mdc-filled-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mdc-outlined-text-field-caret-color: #1e293b;
  --mdc-outlined-text-field-focus-outline-color: #1e293b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(30, 41, 59, 0.87);
}
.theme-brand .dark .mat-mdc-form-field.mat-warn,
.theme-brand.dark .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #dc2626;
  --mdc-filled-text-field-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mdc-outlined-text-field-caret-color: #dc2626;
  --mdc-outlined-text-field-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-brand .dark .mat-mdc-form-field.mat-accent,
.theme-brand.dark .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: #1e293b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(71, 85, 105, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(30, 41, 59, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-brand .dark .mat-mdc-form-field.mat-warn,
.theme-brand.dark .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: #1e293b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(71, 85, 105, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-brand .dark .mat-mdc-standard-chip,
.theme-brand.dark .mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.theme-brand .dark .mat-mdc-standard-chip,
.theme-brand.dark .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-elevated-container-color: #394353;
  --mdc-chip-elevated-selected-container-color: #394353;
  --mdc-chip-elevated-disabled-container-color: #394353;
  --mdc-chip-flat-disabled-selected-container-color: #394353;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-selected-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
  --mat-chip-selected-disabled-trailing-icon-color: #fafafa;
  --mat-chip-selected-trailing-icon-color: #fafafa;
}
.theme-brand .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .theme-brand .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.theme-brand.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.theme-brand.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #2196f3;
  --mdc-chip-elevated-selected-container-color: #2196f3;
  --mdc-chip-elevated-disabled-container-color: #2196f3;
  --mdc-chip-flat-disabled-selected-container-color: #2196f3;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-brand .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .theme-brand .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.theme-brand.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.theme-brand.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #1e293b;
  --mdc-chip-elevated-selected-container-color: #1e293b;
  --mdc-chip-elevated-disabled-container-color: #1e293b;
  --mdc-chip-flat-disabled-selected-container-color: #1e293b;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-brand .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .theme-brand .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.theme-brand.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.theme-brand.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-selected-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-flat-disabled-selected-container-color: #dc2626;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-brand .dark .mat-mdc-slide-toggle,
.theme-brand.dark .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: white;
}
.theme-brand .dark .mat-mdc-slide-toggle.mat-accent,
.theme-brand.dark .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #cbd5e1;
  --mdc-switch-selected-handle-color: #cbd5e1;
  --mdc-switch-selected-hover-state-layer-color: #cbd5e1;
  --mdc-switch-selected-pressed-state-layer-color: #cbd5e1;
  --mdc-switch-selected-focus-handle-color: #e2e8f0;
  --mdc-switch-selected-hover-handle-color: #e2e8f0;
  --mdc-switch-selected-pressed-handle-color: #e2e8f0;
  --mdc-switch-selected-focus-track-color: #475569;
  --mdc-switch-selected-hover-track-color: #475569;
  --mdc-switch-selected-pressed-track-color: #475569;
  --mdc-switch-selected-track-color: #475569;
}
.theme-brand .dark .mat-mdc-slide-toggle.mat-warn,
.theme-brand.dark .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #fca5a5;
  --mdc-switch-selected-handle-color: #fca5a5;
  --mdc-switch-selected-hover-state-layer-color: #fca5a5;
  --mdc-switch-selected-pressed-state-layer-color: #fca5a5;
  --mdc-switch-selected-focus-handle-color: #fecaca;
  --mdc-switch-selected-hover-handle-color: #fecaca;
  --mdc-switch-selected-pressed-handle-color: #fecaca;
  --mdc-switch-selected-focus-track-color: #dc2626;
  --mdc-switch-selected-hover-track-color: #dc2626;
  --mdc-switch-selected-pressed-track-color: #dc2626;
  --mdc-switch-selected-track-color: #dc2626;
}
.theme-brand .dark .mat-mdc-radio-button,
.theme-brand.dark .mat-mdc-radio-button {
  --mdc-form-field-label-text-color: white;
}
.theme-brand .dark .mat-mdc-radio-button.mat-primary,
.theme-brand.dark .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #2196f3;
  --mdc-radio-selected-hover-icon-color: #2196f3;
  --mdc-radio-selected-icon-color: #2196f3;
  --mdc-radio-selected-pressed-icon-color: #2196f3;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #2196f3;
  --mat-radio-disabled-label-color: #475569;
}
.theme-brand .dark .mat-mdc-radio-button.mat-accent,
.theme-brand.dark .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #1e293b;
  --mat-radio-disabled-label-color: #475569;
}
.theme-brand .dark .mat-mdc-radio-button.mat-warn,
.theme-brand.dark .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #dc2626;
  --mat-radio-disabled-label-color: #475569;
}
.theme-brand .dark .mat-accent,
.theme-brand.dark .mat-accent {
  --mat-slider-ripple-color: #1e293b;
  --mat-slider-hover-state-layer-color: rgba(30, 41, 59, 0.05);
  --mat-slider-focus-state-layer-color: rgba(30, 41, 59, 0.2);
  --mdc-slider-handle-color: #1e293b;
  --mdc-slider-focus-handle-color: #1e293b;
  --mdc-slider-hover-handle-color: #1e293b;
  --mdc-slider-active-track-color: #1e293b;
  --mdc-slider-inactive-track-color: #1e293b;
  --mdc-slider-with-tick-marks-inactive-container-color: #1e293b;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-brand .dark .mat-warn,
.theme-brand.dark .mat-warn {
  --mat-slider-ripple-color: #dc2626;
  --mat-slider-hover-state-layer-color: rgba(220, 38, 38, 0.05);
  --mat-slider-focus-state-layer-color: rgba(220, 38, 38, 0.2);
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-brand .dark .mdc-list-item__start,
.theme-brand .dark .mdc-list-item__end,
.theme-brand.dark .mdc-list-item__start,
.theme-brand.dark .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #2196f3;
  --mdc-radio-selected-hover-icon-color: #2196f3;
  --mdc-radio-selected-icon-color: #2196f3;
  --mdc-radio-selected-pressed-icon-color: #2196f3;
}
.theme-brand .dark .mat-accent .mdc-list-item__start,
.theme-brand .dark .mat-accent .mdc-list-item__end,
.theme-brand.dark .mat-accent .mdc-list-item__start,
.theme-brand.dark .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
}
.theme-brand .dark .mat-warn .mdc-list-item__start,
.theme-brand .dark .mat-warn .mdc-list-item__end,
.theme-brand.dark .mat-warn .mdc-list-item__start,
.theme-brand.dark .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
}
.theme-brand .dark .mat-mdc-list-option,
.theme-brand.dark .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #2196f3;
  --mdc-checkbox-selected-hover-icon-color: #2196f3;
  --mdc-checkbox-selected-icon-color: #2196f3;
  --mdc-checkbox-selected-pressed-icon-color: #2196f3;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #2196f3;
  --mdc-checkbox-selected-hover-state-layer-color: #2196f3;
  --mdc-checkbox-selected-pressed-state-layer-color: #2196f3;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-brand .dark .mat-mdc-list-option.mat-accent,
.theme-brand.dark .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-brand .dark .mat-mdc-list-option.mat-warn,
.theme-brand.dark .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-brand .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-brand .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-brand.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-brand.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #2196f3;
}
.theme-brand .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brand.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #2196f3;
}
.theme-brand .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-brand .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-brand .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.theme-brand.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-brand.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-brand.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.theme-brand .dark .mat-mdc-tab-group, .theme-brand .dark .mat-mdc-tab-nav-bar,
.theme-brand.dark .mat-mdc-tab-group,
.theme-brand.dark .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #2196f3;
  --mat-tab-header-disabled-ripple-color: #475569;
  --mat-tab-header-pagination-icon-color: #f1f5f9;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #2196f3;
  --mat-tab-header-active-ripple-color: #2196f3;
  --mat-tab-header-inactive-ripple-color: #2196f3;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #2196f3;
  --mat-tab-header-active-hover-label-text-color: #2196f3;
  --mat-tab-header-active-focus-indicator-color: #2196f3;
  --mat-tab-header-active-hover-indicator-color: #2196f3;
}
.theme-brand .dark .mat-mdc-tab-group.mat-accent, .theme-brand .dark .mat-mdc-tab-nav-bar.mat-accent,
.theme-brand.dark .mat-mdc-tab-group.mat-accent,
.theme-brand.dark .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #1e293b;
  --mat-tab-header-disabled-ripple-color: #475569;
  --mat-tab-header-pagination-icon-color: #f1f5f9;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #1e293b;
  --mat-tab-header-active-ripple-color: #1e293b;
  --mat-tab-header-inactive-ripple-color: #1e293b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #1e293b;
  --mat-tab-header-active-hover-label-text-color: #1e293b;
  --mat-tab-header-active-focus-indicator-color: #1e293b;
  --mat-tab-header-active-hover-indicator-color: #1e293b;
}
.theme-brand .dark .mat-mdc-tab-group.mat-warn, .theme-brand .dark .mat-mdc-tab-nav-bar.mat-warn,
.theme-brand.dark .mat-mdc-tab-group.mat-warn,
.theme-brand.dark .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #dc2626;
  --mat-tab-header-disabled-ripple-color: #475569;
  --mat-tab-header-pagination-icon-color: #f1f5f9;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #dc2626;
  --mat-tab-header-active-ripple-color: #dc2626;
  --mat-tab-header-inactive-ripple-color: #dc2626;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #dc2626;
  --mat-tab-header-active-hover-label-text-color: #dc2626;
  --mat-tab-header-active-focus-indicator-color: #dc2626;
  --mat-tab-header-active-hover-indicator-color: #dc2626;
}
.theme-brand .dark .mat-mdc-tab-group.mat-background-primary, .theme-brand .dark .mat-mdc-tab-nav-bar.mat-background-primary,
.theme-brand.dark .mat-mdc-tab-group.mat-background-primary,
.theme-brand.dark .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #2196f3;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-brand .dark .mat-mdc-tab-group.mat-background-accent, .theme-brand .dark .mat-mdc-tab-nav-bar.mat-background-accent,
.theme-brand.dark .mat-mdc-tab-group.mat-background-accent,
.theme-brand.dark .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #1e293b;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-brand .dark .mat-mdc-tab-group.mat-background-warn, .theme-brand .dark .mat-mdc-tab-nav-bar.mat-background-warn,
.theme-brand.dark .mat-mdc-tab-group.mat-background-warn,
.theme-brand.dark .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #dc2626;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-brand .dark .mat-mdc-checkbox,
.theme-brand.dark .mat-mdc-checkbox {
  --mdc-form-field-label-text-color: white;
}
.theme-brand .dark .mat-mdc-checkbox.mat-primary,
.theme-brand.dark .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #2196f3;
  --mdc-checkbox-selected-hover-icon-color: #2196f3;
  --mdc-checkbox-selected-icon-color: #2196f3;
  --mdc-checkbox-selected-pressed-icon-color: #2196f3;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #2196f3;
  --mdc-checkbox-selected-hover-state-layer-color: #2196f3;
  --mdc-checkbox-selected-pressed-state-layer-color: #2196f3;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-brand .dark .mat-mdc-checkbox.mat-warn,
.theme-brand.dark .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-brand .dark .mat-mdc-button.mat-primary,
.theme-brand.dark .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #2196f3;
  --mat-text-button-state-layer-color: #2196f3;
  --mat-text-button-ripple-color: rgba(33, 150, 243, 0.1);
}
.theme-brand .dark .mat-mdc-button.mat-accent,
.theme-brand.dark .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1e293b;
  --mat-text-button-state-layer-color: #1e293b;
  --mat-text-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-brand .dark .mat-mdc-button.mat-warn,
.theme-brand.dark .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc2626;
  --mat-text-button-state-layer-color: #dc2626;
  --mat-text-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-brand .dark .mat-mdc-unelevated-button.mat-primary,
.theme-brand.dark .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #2196f3;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .dark .mat-mdc-unelevated-button.mat-accent,
.theme-brand.dark .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .dark .mat-mdc-unelevated-button.mat-warn,
.theme-brand.dark .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .dark .mat-mdc-raised-button.mat-primary,
.theme-brand.dark .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #2196f3;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .dark .mat-mdc-raised-button.mat-accent,
.theme-brand.dark .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .dark .mat-mdc-raised-button.mat-warn,
.theme-brand.dark .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .dark .mat-mdc-outlined-button.mat-primary,
.theme-brand.dark .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #2196f3;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: #2196f3;
  --mat-outlined-button-ripple-color: rgba(33, 150, 243, 0.1);
}
.theme-brand .dark .mat-mdc-outlined-button.mat-accent,
.theme-brand.dark .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1e293b;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: #1e293b;
  --mat-outlined-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-brand .dark .mat-mdc-outlined-button.mat-warn,
.theme-brand.dark .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc2626;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: #dc2626;
  --mat-outlined-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-brand .dark .mat-mdc-icon-button.mat-primary,
.theme-brand.dark .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #2196f3;
  --mat-icon-button-state-layer-color: #2196f3;
  --mat-icon-button-ripple-color: rgba(33, 150, 243, 0.1);
}
.theme-brand .dark .mat-mdc-icon-button.mat-accent,
.theme-brand.dark .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1e293b;
  --mat-icon-button-state-layer-color: #1e293b;
  --mat-icon-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-brand .dark .mat-mdc-icon-button.mat-warn,
.theme-brand.dark .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc2626;
  --mat-icon-button-state-layer-color: #dc2626;
  --mat-icon-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-brand .dark .mat-mdc-fab.mat-primary,
.theme-brand.dark .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #2196f3;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .dark .mat-mdc-fab.mat-accent,
.theme-brand.dark .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #1e293b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .dark .mat-mdc-fab.mat-warn,
.theme-brand.dark .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #dc2626;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .dark .mat-mdc-mini-fab.mat-primary,
.theme-brand.dark .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #2196f3;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .dark .mat-mdc-mini-fab.mat-accent,
.theme-brand.dark .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #1e293b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .dark .mat-mdc-mini-fab.mat-warn,
.theme-brand.dark .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #dc2626;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brand .dark .mat-accent,
.theme-brand.dark .mat-accent {
  --mdc-circular-progress-active-indicator-color: #1e293b;
}
.theme-brand .dark .mat-warn,
.theme-brand.dark .mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}
.theme-brand .dark .mat-badge-accent,
.theme-brand.dark .mat-badge-accent {
  --mat-badge-background-color: #1e293b;
  --mat-badge-text-color: white;
}
.theme-brand .dark .mat-badge-warn,
.theme-brand.dark .mat-badge-warn {
  --mat-badge-background-color: #dc2626;
  --mat-badge-text-color: white;
}
.theme-brand .dark .mat-datepicker-content.mat-accent,
.theme-brand.dark .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #1e293b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(30, 41, 59, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(30, 41, 59, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-brand .dark .mat-datepicker-content.mat-warn,
.theme-brand.dark .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #dc2626;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(220, 38, 38, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(220, 38, 38, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-brand .dark .mat-datepicker-toggle-active.mat-accent,
.theme-brand.dark .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #1e293b;
}
.theme-brand .dark .mat-datepicker-toggle-active.mat-warn,
.theme-brand.dark .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #dc2626;
}
.theme-brand .dark .mat-icon.mat-primary,
.theme-brand.dark .mat-icon.mat-primary {
  --mat-icon-color: #2196f3;
}
.theme-brand .dark .mat-icon.mat-accent,
.theme-brand.dark .mat-icon.mat-accent {
  --mat-icon-color: #1e293b;
}
.theme-brand .dark .mat-icon.mat-warn,
.theme-brand.dark .mat-icon.mat-warn {
  --mat-icon-color: #dc2626;
}
.theme-brand .dark .mat-step-header.mat-accent,
.theme-brand.dark .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #1e293b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #1e293b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #1e293b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-brand .dark .mat-step-header.mat-warn,
.theme-brand.dark .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #dc2626;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #dc2626;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #dc2626;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-brand .dark .mat-toolbar.mat-primary,
.theme-brand.dark .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #2196f3;
  --mat-toolbar-container-text-color: white;
}
.theme-brand .dark .mat-toolbar.mat-accent,
.theme-brand.dark .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #1e293b;
  --mat-toolbar-container-text-color: white;
}
.theme-brand .dark .mat-toolbar.mat-warn,
.theme-brand.dark .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #dc2626;
  --mat-toolbar-container-text-color: white;
}

/* Generate the palettes */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define a light & dark Angular Material theme with the generated palettes */
/* Merge the custom base colors with the generated themes */
/* Generate and encapsulate Angular Material themes */
.theme-teal .light,
.theme-teal.light {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-option-selected-state-label-text-color: #0d9488;
  --mat-option-label-text-color: #1e293b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-optgroup-label-text-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
  --mat-app-background-color: #f1f5f9;
  --mat-app-text-color: #1e293b;
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: #64748b;
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #1e293b;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #0d9488;
  --mdc-filled-text-field-focus-active-indicator-color: #0d9488;
  --mdc-filled-text-field-focus-label-text-color: rgba(13, 148, 136, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(30, 41, 59, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(148, 163, 184, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(30, 41, 59, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #dc2626;
  --mdc-filled-text-field-error-focus-label-text-color: #dc2626;
  --mdc-filled-text-field-error-label-text-color: #dc2626;
  --mdc-filled-text-field-error-caret-color: #dc2626;
  --mdc-filled-text-field-active-indicator-color: rgba(226, 232, 240, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(226, 232, 240, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(226, 232, 240, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-hover-active-indicator-color: #dc2626;
  --mdc-outlined-text-field-caret-color: #0d9488;
  --mdc-outlined-text-field-focus-outline-color: #0d9488;
  --mdc-outlined-text-field-focus-label-text-color: rgba(13, 148, 136, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(30, 41, 59, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(148, 163, 184, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(30, 41, 59, 0.6);
  --mdc-outlined-text-field-error-caret-color: #dc2626;
  --mdc-outlined-text-field-error-focus-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-hover-label-text-color: #dc2626;
  --mdc-outlined-text-field-outline-color: rgba(226, 232, 240, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(226, 232, 240, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(226, 232, 240, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-error-hover-outline-color: #dc2626;
  --mdc-outlined-text-field-error-outline-color: #dc2626;
  --mat-form-field-focus-select-arrow-color: rgba(13, 148, 136, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(100, 116, 139, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #dc2626;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(100, 116, 139, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(100, 116, 139, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(30, 41, 59, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(148, 163, 184, 0.38);
  --mat-select-placeholder-text-color: rgba(30, 41, 59, 0.6);
  --mat-select-enabled-arrow-color: rgba(226, 232, 240, 0.54);
  --mat-select-disabled-arrow-color: rgba(30, 41, 59, 0.38);
  --mat-select-focused-arrow-color: rgba(13, 148, 136, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: white;
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(30, 41, 59, 0.87);
  --mdc-dialog-supporting-text-color: rgba(30, 41, 59, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
  --mdc-switch-selected-focus-state-layer-color: #0d9488;
  --mdc-switch-selected-handle-color: #0d9488;
  --mdc-switch-selected-hover-state-layer-color: #0d9488;
  --mdc-switch-selected-pressed-state-layer-color: #0d9488;
  --mdc-switch-selected-focus-handle-color: #134e4a;
  --mdc-switch-selected-hover-handle-color: #134e4a;
  --mdc-switch-selected-pressed-handle-color: #134e4a;
  --mdc-switch-selected-focus-track-color: #5eead4;
  --mdc-switch-selected-hover-track-color: #5eead4;
  --mdc-switch-selected-pressed-track-color: #5eead4;
  --mdc-switch-selected-track-color: #5eead4;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-disabled-label-text-color: #94a3b8;
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-color: #0d9488;
  --mdc-slider-focus-handle-color: #0d9488;
  --mdc-slider-hover-handle-color: #0d9488;
  --mdc-slider-active-track-color: #0d9488;
  --mdc-slider-inactive-track-color: #0d9488;
  --mdc-slider-with-tick-marks-inactive-container-color: #0d9488;
  --mdc-slider-with-tick-marks-active-container-color: #042f2e;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: #0d9488;
  --mat-slider-hover-state-layer-color: rgba(13, 148, 136, 0.05);
  --mat-slider-focus-state-layer-color: rgba(13, 148, 136, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-item-label-text-color: #1e293b;
  --mat-menu-item-icon-color: #1e293b;
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: #e2e8f0;
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 0;
  --mdc-list-list-item-label-text-color: #1e293b;
  --mdc-list-list-item-supporting-text-color: #64748b;
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: #94a3b8;
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: #1e293b;
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: #1e293b;
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: #94a3b8;
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: #1e293b;
  --mdc-text-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: #1e293b;
  --mdc-filled-button-disabled-container-color: rgba(148, 163, 184, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: #1e293b;
  --mdc-protected-button-disabled-container-color: rgba(148, 163, 184, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: #e2e8f0;
  --mdc-outlined-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-outlined-button-label-text-color: #1e293b;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-fab-container-color: white;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(148, 163, 184, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(148, 163, 184, 0.38);
  --mdc-fab-small-container-color: white;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(148, 163, 184, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(148, 163, 184, 0.38);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #1e293b;
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: white;
  --mat-table-header-headline-color: #1e293b;
  --mat-table-row-item-label-text-color: #1e293b;
  --mat-table-row-item-outline-color: #e2e8f0;
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #0d9488;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #0d9488;
  --mat-badge-text-color: #042f2e;
  --mat-badge-disabled-state-background-color: #94a3b8;
  --mat-badge-disabled-state-text-color: #94a3b8;
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: #1e293b;
  --mat-bottom-sheet-container-background-color: white;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: #94a3b8;
  --mat-legacy-button-toggle-state-layer-color: #64748b;
  --mat-legacy-button-toggle-selected-state-text-color: #64748b;
  --mat-legacy-button-toggle-selected-state-background-color: #e2e8f0;
  --mat-legacy-button-toggle-disabled-state-text-color: #94a3b8;
  --mat-legacy-button-toggle-disabled-state-background-color: #cbd5e1;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #e2e8f0;
  --mat-standard-button-toggle-text-color: #1e293b;
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: #64748b;
  --mat-standard-button-toggle-selected-state-background-color: #e2e8f0;
  --mat-standard-button-toggle-selected-state-text-color: #1e293b;
  --mat-standard-button-toggle-disabled-state-text-color: #94a3b8;
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: #1e293b;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #e2e8f0;
  --mat-standard-button-toggle-divider-color: #e2e8f0;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: #042f2e;
  --mat-datepicker-calendar-date-selected-state-background-color: #0d9488;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(13, 148, 136, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #042f2e;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(13, 148, 136, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(13, 148, 136, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #0d9488;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(13, 148, 136, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: #64748b;
  --mat-datepicker-calendar-body-label-text-color: #64748b;
  --mat-datepicker-calendar-period-button-text-color: #1e293b;
  --mat-datepicker-calendar-period-button-icon-color: #64748b;
  --mat-datepicker-calendar-navigation-button-icon-color: #64748b;
  --mat-datepicker-calendar-header-divider-color: #e2e8f0;
  --mat-datepicker-calendar-header-text-color: #64748b;
  --mat-datepicker-calendar-date-today-outline-color: #94a3b8;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(148, 163, 184, 0.8);
  --mat-datepicker-calendar-date-text-color: #1e293b;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: #94a3b8;
  --mat-datepicker-calendar-date-preview-state-outline-color: #e2e8f0;
  --mat-datepicker-range-input-separator-color: #1e293b;
  --mat-datepicker-range-input-disabled-state-separator-color: #94a3b8;
  --mat-datepicker-range-input-disabled-state-text-color: #94a3b8;
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: #1e293b;
  --mat-divider-width: 1px;
  --mat-divider-color: #e2e8f0;
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: #1e293b;
  --mat-expansion-actions-divider-color: #e2e8f0;
  --mat-expansion-header-hover-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-expansion-header-focus-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-expansion-header-disabled-state-text-color: #94a3b8;
  --mat-expansion-header-text-color: #1e293b;
  --mat-expansion-header-description-color: #64748b;
  --mat-expansion-header-indicator-color: #64748b;
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: #e2e8f0;
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: #1e293b;
  --mat-sidenav-content-background-color: #f1f5f9;
  --mat-sidenav-content-text-color: #1e293b;
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
  --mat-stepper-header-icon-foreground-color: #042f2e;
  --mat-stepper-header-selected-state-icon-background-color: #0d9488;
  --mat-stepper-header-selected-state-icon-foreground-color: #042f2e;
  --mat-stepper-header-done-state-icon-background-color: #0d9488;
  --mat-stepper-header-done-state-icon-foreground-color: #042f2e;
  --mat-stepper-header-edit-state-icon-background-color: #0d9488;
  --mat-stepper-header-edit-state-icon-foreground-color: #042f2e;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: #e2e8f0;
  --mat-stepper-header-hover-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-stepper-header-focus-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-stepper-header-label-text-color: #64748b;
  --mat-stepper-header-optional-label-text-color: #64748b;
  --mat-stepper-header-selected-state-label-text-color: #1e293b;
  --mat-stepper-header-error-state-label-text-color: #dc2626;
  --mat-stepper-header-icon-background-color: #64748b;
  --mat-stepper-header-error-state-icon-foreground-color: #dc2626;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #64748b;
  --mat-toolbar-container-background-color: white;
  --mat-toolbar-container-text-color: #1e293b;
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: #1e293b;
}
.theme-teal .light .mat-accent,
.theme-teal.light .mat-accent {
  --mat-option-selected-state-label-text-color: #1e293b;
  --mat-option-label-text-color: #1e293b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.theme-teal .light .mat-warn,
.theme-teal.light .mat-warn {
  --mat-option-selected-state-label-text-color: #dc2626;
  --mat-option-label-text-color: #1e293b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.theme-teal .light .mat-primary,
.theme-teal.light .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #0d9488;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #0d9488;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-teal .light .mat-accent,
.theme-teal.light .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-teal .light .mat-warn,
.theme-teal.light .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #dc2626;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #dc2626;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-teal .light .mat-elevation-z0, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z0,
.theme-teal.light .mat-elevation-z0,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z1, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z1,
.theme-teal.light .mat-elevation-z1,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z2, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z2,
.theme-teal.light .mat-elevation-z2,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z3, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z3,
.theme-teal.light .mat-elevation-z3,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z4, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z4,
.theme-teal.light .mat-elevation-z4,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z5, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z5,
.theme-teal.light .mat-elevation-z5,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z6, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z6,
.theme-teal.light .mat-elevation-z6,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z7, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z7,
.theme-teal.light .mat-elevation-z7,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z8, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z8,
.theme-teal.light .mat-elevation-z8,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z9, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z9,
.theme-teal.light .mat-elevation-z9,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z10, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z10,
.theme-teal.light .mat-elevation-z10,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z11, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z11,
.theme-teal.light .mat-elevation-z11,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z12, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z12,
.theme-teal.light .mat-elevation-z12,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z13, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z13,
.theme-teal.light .mat-elevation-z13,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z14, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z14,
.theme-teal.light .mat-elevation-z14,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z15, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z15,
.theme-teal.light .mat-elevation-z15,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z16, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z16,
.theme-teal.light .mat-elevation-z16,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z17, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z17,
.theme-teal.light .mat-elevation-z17,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z18, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z18,
.theme-teal.light .mat-elevation-z18,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z19, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z19,
.theme-teal.light .mat-elevation-z19,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z20, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z20,
.theme-teal.light .mat-elevation-z20,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z21, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z21,
.theme-teal.light .mat-elevation-z21,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z22, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z22,
.theme-teal.light .mat-elevation-z22,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z23, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z23,
.theme-teal.light .mat-elevation-z23,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-teal .light .mat-elevation-z24, .theme-teal .light .mat-mdc-elevation-specific.mat-elevation-z24,
.theme-teal.light .mat-elevation-z24,
.theme-teal.light .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-teal .light .mat-mdc-progress-bar,
.theme-teal.light .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #0d9488;
  --mdc-linear-progress-track-color: rgba(13, 148, 136, 0.25);
}
.theme-teal .light .mat-mdc-progress-bar.mat-accent,
.theme-teal.light .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1e293b;
  --mdc-linear-progress-track-color: rgba(30, 41, 59, 0.25);
}
.theme-teal .light .mat-mdc-progress-bar.mat-warn,
.theme-teal.light .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc2626;
  --mdc-linear-progress-track-color: rgba(220, 38, 38, 0.25);
}
.theme-teal .light .mat-mdc-form-field.mat-accent,
.theme-teal.light .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #1e293b;
  --mdc-filled-text-field-focus-active-indicator-color: #1e293b;
  --mdc-filled-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mdc-outlined-text-field-caret-color: #1e293b;
  --mdc-outlined-text-field-focus-outline-color: #1e293b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(30, 41, 59, 0.87);
}
.theme-teal .light .mat-mdc-form-field.mat-warn,
.theme-teal.light .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #dc2626;
  --mdc-filled-text-field-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mdc-outlined-text-field-caret-color: #dc2626;
  --mdc-outlined-text-field-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-teal .light .mat-mdc-form-field.mat-accent,
.theme-teal.light .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(30, 41, 59, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(148, 163, 184, 0.38);
  --mat-select-placeholder-text-color: rgba(30, 41, 59, 0.6);
  --mat-select-enabled-arrow-color: rgba(226, 232, 240, 0.54);
  --mat-select-disabled-arrow-color: rgba(30, 41, 59, 0.38);
  --mat-select-focused-arrow-color: rgba(30, 41, 59, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-teal .light .mat-mdc-form-field.mat-warn,
.theme-teal.light .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(30, 41, 59, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(148, 163, 184, 0.38);
  --mat-select-placeholder-text-color: rgba(30, 41, 59, 0.6);
  --mat-select-enabled-arrow-color: rgba(226, 232, 240, 0.54);
  --mat-select-disabled-arrow-color: rgba(30, 41, 59, 0.38);
  --mat-select-focused-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-teal .light .mat-mdc-standard-chip,
.theme-teal.light .mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.theme-teal .light .mat-mdc-standard-chip,
.theme-teal.light .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.theme-teal .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .theme-teal .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.theme-teal.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.theme-teal.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: #042f2e;
  --mdc-chip-elevated-container-color: #0d9488;
  --mdc-chip-elevated-selected-container-color: #0d9488;
  --mdc-chip-elevated-disabled-container-color: #0d9488;
  --mdc-chip-flat-disabled-selected-container-color: #0d9488;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #042f2e;
  --mdc-chip-selected-label-text-color: #042f2e;
  --mdc-chip-with-icon-icon-color: #042f2e;
  --mdc-chip-with-icon-disabled-icon-color: #042f2e;
  --mdc-chip-with-icon-selected-icon-color: #042f2e;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #042f2e;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #042f2e;
  --mat-chip-selected-disabled-trailing-icon-color: #042f2e;
  --mat-chip-selected-trailing-icon-color: #042f2e;
}
.theme-teal .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .theme-teal .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.theme-teal.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.theme-teal.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #1e293b;
  --mdc-chip-elevated-selected-container-color: #1e293b;
  --mdc-chip-elevated-disabled-container-color: #1e293b;
  --mdc-chip-flat-disabled-selected-container-color: #1e293b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-teal .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .theme-teal .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.theme-teal.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.theme-teal.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-selected-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-flat-disabled-selected-container-color: #dc2626;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-teal .light .mat-mdc-slide-toggle,
.theme-teal.light .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: #1e293b;
}
.theme-teal .light .mat-mdc-slide-toggle.mat-accent,
.theme-teal.light .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #475569;
  --mdc-switch-selected-handle-color: #475569;
  --mdc-switch-selected-hover-state-layer-color: #475569;
  --mdc-switch-selected-pressed-state-layer-color: #475569;
  --mdc-switch-selected-focus-handle-color: #0f172a;
  --mdc-switch-selected-hover-handle-color: #0f172a;
  --mdc-switch-selected-pressed-handle-color: #0f172a;
  --mdc-switch-selected-focus-track-color: #cbd5e1;
  --mdc-switch-selected-hover-track-color: #cbd5e1;
  --mdc-switch-selected-pressed-track-color: #cbd5e1;
  --mdc-switch-selected-track-color: #cbd5e1;
}
.theme-teal .light .mat-mdc-slide-toggle.mat-warn,
.theme-teal.light .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.theme-teal .light .mat-mdc-radio-button,
.theme-teal.light .mat-mdc-radio-button {
  --mdc-form-field-label-text-color: #1e293b;
}
.theme-teal .light .mat-mdc-radio-button.mat-primary,
.theme-teal.light .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #0d9488;
  --mdc-radio-selected-hover-icon-color: #0d9488;
  --mdc-radio-selected-icon-color: #0d9488;
  --mdc-radio-selected-pressed-icon-color: #0d9488;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #0d9488;
  --mat-radio-disabled-label-color: #94a3b8;
}
.theme-teal .light .mat-mdc-radio-button.mat-accent,
.theme-teal.light .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #1e293b;
  --mat-radio-disabled-label-color: #94a3b8;
}
.theme-teal .light .mat-mdc-radio-button.mat-warn,
.theme-teal.light .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #dc2626;
  --mat-radio-disabled-label-color: #94a3b8;
}
.theme-teal .light .mat-accent,
.theme-teal.light .mat-accent {
  --mat-slider-ripple-color: #1e293b;
  --mat-slider-hover-state-layer-color: rgba(30, 41, 59, 0.05);
  --mat-slider-focus-state-layer-color: rgba(30, 41, 59, 0.2);
  --mdc-slider-handle-color: #1e293b;
  --mdc-slider-focus-handle-color: #1e293b;
  --mdc-slider-hover-handle-color: #1e293b;
  --mdc-slider-active-track-color: #1e293b;
  --mdc-slider-inactive-track-color: #1e293b;
  --mdc-slider-with-tick-marks-inactive-container-color: #1e293b;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-teal .light .mat-warn,
.theme-teal.light .mat-warn {
  --mat-slider-ripple-color: #dc2626;
  --mat-slider-hover-state-layer-color: rgba(220, 38, 38, 0.05);
  --mat-slider-focus-state-layer-color: rgba(220, 38, 38, 0.2);
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-teal .light .mdc-list-item__start,
.theme-teal .light .mdc-list-item__end,
.theme-teal.light .mdc-list-item__start,
.theme-teal.light .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #0d9488;
  --mdc-radio-selected-hover-icon-color: #0d9488;
  --mdc-radio-selected-icon-color: #0d9488;
  --mdc-radio-selected-pressed-icon-color: #0d9488;
}
.theme-teal .light .mat-accent .mdc-list-item__start,
.theme-teal .light .mat-accent .mdc-list-item__end,
.theme-teal.light .mat-accent .mdc-list-item__start,
.theme-teal.light .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
}
.theme-teal .light .mat-warn .mdc-list-item__start,
.theme-teal .light .mat-warn .mdc-list-item__end,
.theme-teal.light .mat-warn .mdc-list-item__start,
.theme-teal.light .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
}
.theme-teal .light .mat-mdc-list-option,
.theme-teal.light .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #042f2e;
  --mdc-checkbox-selected-focus-icon-color: #0d9488;
  --mdc-checkbox-selected-hover-icon-color: #0d9488;
  --mdc-checkbox-selected-icon-color: #0d9488;
  --mdc-checkbox-selected-pressed-icon-color: #0d9488;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #0d9488;
  --mdc-checkbox-selected-hover-state-layer-color: #0d9488;
  --mdc-checkbox-selected-pressed-state-layer-color: #0d9488;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-teal .light .mat-mdc-list-option.mat-accent,
.theme-teal.light .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-teal .light .mat-mdc-list-option.mat-warn,
.theme-teal.light .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-teal .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-teal .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-teal.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-teal.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #0d9488;
}
.theme-teal .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #0d9488;
}
.theme-teal .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-teal .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-teal .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.theme-teal.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-teal.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-teal.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.theme-teal .light .mat-mdc-tab-group, .theme-teal .light .mat-mdc-tab-nav-bar,
.theme-teal.light .mat-mdc-tab-group,
.theme-teal.light .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #0d9488;
  --mat-tab-header-disabled-ripple-color: #94a3b8;
  --mat-tab-header-pagination-icon-color: #64748b;
  --mat-tab-header-inactive-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-label-text-color: #0d9488;
  --mat-tab-header-active-ripple-color: #0d9488;
  --mat-tab-header-inactive-ripple-color: #0d9488;
  --mat-tab-header-inactive-focus-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-focus-label-text-color: #0d9488;
  --mat-tab-header-active-hover-label-text-color: #0d9488;
  --mat-tab-header-active-focus-indicator-color: #0d9488;
  --mat-tab-header-active-hover-indicator-color: #0d9488;
}
.theme-teal .light .mat-mdc-tab-group.mat-accent, .theme-teal .light .mat-mdc-tab-nav-bar.mat-accent,
.theme-teal.light .mat-mdc-tab-group.mat-accent,
.theme-teal.light .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #1e293b;
  --mat-tab-header-disabled-ripple-color: #94a3b8;
  --mat-tab-header-pagination-icon-color: #64748b;
  --mat-tab-header-inactive-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-label-text-color: #1e293b;
  --mat-tab-header-active-ripple-color: #1e293b;
  --mat-tab-header-inactive-ripple-color: #1e293b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-focus-label-text-color: #1e293b;
  --mat-tab-header-active-hover-label-text-color: #1e293b;
  --mat-tab-header-active-focus-indicator-color: #1e293b;
  --mat-tab-header-active-hover-indicator-color: #1e293b;
}
.theme-teal .light .mat-mdc-tab-group.mat-warn, .theme-teal .light .mat-mdc-tab-nav-bar.mat-warn,
.theme-teal.light .mat-mdc-tab-group.mat-warn,
.theme-teal.light .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #dc2626;
  --mat-tab-header-disabled-ripple-color: #94a3b8;
  --mat-tab-header-pagination-icon-color: #64748b;
  --mat-tab-header-inactive-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-label-text-color: #dc2626;
  --mat-tab-header-active-ripple-color: #dc2626;
  --mat-tab-header-inactive-ripple-color: #dc2626;
  --mat-tab-header-inactive-focus-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-focus-label-text-color: #dc2626;
  --mat-tab-header-active-hover-label-text-color: #dc2626;
  --mat-tab-header-active-focus-indicator-color: #dc2626;
  --mat-tab-header-active-hover-indicator-color: #dc2626;
}
.theme-teal .light .mat-mdc-tab-group.mat-background-primary, .theme-teal .light .mat-mdc-tab-nav-bar.mat-background-primary,
.theme-teal.light .mat-mdc-tab-group.mat-background-primary,
.theme-teal.light .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #0d9488;
  --mat-tab-header-with-background-foreground-color: #042f2e;
}
.theme-teal .light .mat-mdc-tab-group.mat-background-accent, .theme-teal .light .mat-mdc-tab-nav-bar.mat-background-accent,
.theme-teal.light .mat-mdc-tab-group.mat-background-accent,
.theme-teal.light .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #1e293b;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-teal .light .mat-mdc-tab-group.mat-background-warn, .theme-teal .light .mat-mdc-tab-nav-bar.mat-background-warn,
.theme-teal.light .mat-mdc-tab-group.mat-background-warn,
.theme-teal.light .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #dc2626;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-teal .light .mat-mdc-checkbox,
.theme-teal.light .mat-mdc-checkbox {
  --mdc-form-field-label-text-color: #1e293b;
}
.theme-teal .light .mat-mdc-checkbox.mat-primary,
.theme-teal.light .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #042f2e;
  --mdc-checkbox-selected-focus-icon-color: #0d9488;
  --mdc-checkbox-selected-hover-icon-color: #0d9488;
  --mdc-checkbox-selected-icon-color: #0d9488;
  --mdc-checkbox-selected-pressed-icon-color: #0d9488;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #0d9488;
  --mdc-checkbox-selected-hover-state-layer-color: #0d9488;
  --mdc-checkbox-selected-pressed-state-layer-color: #0d9488;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-teal .light .mat-mdc-checkbox.mat-warn,
.theme-teal.light .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-teal .light .mat-mdc-button.mat-primary,
.theme-teal.light .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #0d9488;
  --mat-text-button-state-layer-color: #0d9488;
  --mat-text-button-ripple-color: rgba(13, 148, 136, 0.1);
}
.theme-teal .light .mat-mdc-button.mat-accent,
.theme-teal.light .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1e293b;
  --mat-text-button-state-layer-color: #1e293b;
  --mat-text-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-teal .light .mat-mdc-button.mat-warn,
.theme-teal.light .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc2626;
  --mat-text-button-state-layer-color: #dc2626;
  --mat-text-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-teal .light .mat-mdc-unelevated-button.mat-primary,
.theme-teal.light .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #0d9488;
  --mdc-filled-button-label-text-color: #042f2e;
  --mat-filled-button-state-layer-color: #042f2e;
  --mat-filled-button-ripple-color: rgba(4, 47, 46, 0.1);
}
.theme-teal .light .mat-mdc-unelevated-button.mat-accent,
.theme-teal.light .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-teal .light .mat-mdc-unelevated-button.mat-warn,
.theme-teal.light .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-teal .light .mat-mdc-raised-button.mat-primary,
.theme-teal.light .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #0d9488;
  --mdc-protected-button-label-text-color: #042f2e;
  --mat-protected-button-state-layer-color: #042f2e;
  --mat-protected-button-ripple-color: rgba(4, 47, 46, 0.1);
}
.theme-teal .light .mat-mdc-raised-button.mat-accent,
.theme-teal.light .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-teal .light .mat-mdc-raised-button.mat-warn,
.theme-teal.light .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-teal .light .mat-mdc-outlined-button.mat-primary,
.theme-teal.light .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #0d9488;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: #0d9488;
  --mat-outlined-button-ripple-color: rgba(13, 148, 136, 0.1);
}
.theme-teal .light .mat-mdc-outlined-button.mat-accent,
.theme-teal.light .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1e293b;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: #1e293b;
  --mat-outlined-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-teal .light .mat-mdc-outlined-button.mat-warn,
.theme-teal.light .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc2626;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: #dc2626;
  --mat-outlined-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-teal .light .mat-mdc-icon-button.mat-primary,
.theme-teal.light .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #0d9488;
  --mat-icon-button-state-layer-color: #0d9488;
  --mat-icon-button-ripple-color: rgba(13, 148, 136, 0.1);
}
.theme-teal .light .mat-mdc-icon-button.mat-accent,
.theme-teal.light .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1e293b;
  --mat-icon-button-state-layer-color: #1e293b;
  --mat-icon-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-teal .light .mat-mdc-icon-button.mat-warn,
.theme-teal.light .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc2626;
  --mat-icon-button-state-layer-color: #dc2626;
  --mat-icon-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-teal .light .mat-mdc-fab.mat-primary,
.theme-teal.light .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #0d9488;
  --mat-fab-foreground-color: #042f2e;
  --mat-fab-state-layer-color: #042f2e;
  --mat-fab-ripple-color: rgba(4, 47, 46, 0.1);
}
.theme-teal .light .mat-mdc-fab.mat-accent,
.theme-teal.light .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #1e293b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-teal .light .mat-mdc-fab.mat-warn,
.theme-teal.light .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #dc2626;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-teal .light .mat-mdc-mini-fab.mat-primary,
.theme-teal.light .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #0d9488;
  --mat-fab-small-foreground-color: #042f2e;
  --mat-fab-small-state-layer-color: #042f2e;
  --mat-fab-small-ripple-color: rgba(4, 47, 46, 0.1);
}
.theme-teal .light .mat-mdc-mini-fab.mat-accent,
.theme-teal.light .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #1e293b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-teal .light .mat-mdc-mini-fab.mat-warn,
.theme-teal.light .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #dc2626;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-teal .light .mat-accent,
.theme-teal.light .mat-accent {
  --mdc-circular-progress-active-indicator-color: #1e293b;
}
.theme-teal .light .mat-warn,
.theme-teal.light .mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}
.theme-teal .light .mat-badge-accent,
.theme-teal.light .mat-badge-accent {
  --mat-badge-background-color: #1e293b;
  --mat-badge-text-color: white;
}
.theme-teal .light .mat-badge-warn,
.theme-teal.light .mat-badge-warn {
  --mat-badge-background-color: #dc2626;
  --mat-badge-text-color: white;
}
.theme-teal .light .mat-datepicker-content.mat-accent,
.theme-teal.light .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #1e293b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(30, 41, 59, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(30, 41, 59, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-teal .light .mat-datepicker-content.mat-warn,
.theme-teal.light .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #dc2626;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(220, 38, 38, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(220, 38, 38, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-teal .light .mat-datepicker-toggle-active.mat-accent,
.theme-teal.light .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #1e293b;
}
.theme-teal .light .mat-datepicker-toggle-active.mat-warn,
.theme-teal.light .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #dc2626;
}
.theme-teal .light .mat-icon.mat-primary,
.theme-teal.light .mat-icon.mat-primary {
  --mat-icon-color: #0d9488;
}
.theme-teal .light .mat-icon.mat-accent,
.theme-teal.light .mat-icon.mat-accent {
  --mat-icon-color: #1e293b;
}
.theme-teal .light .mat-icon.mat-warn,
.theme-teal.light .mat-icon.mat-warn {
  --mat-icon-color: #dc2626;
}
.theme-teal .light .mat-step-header.mat-accent,
.theme-teal.light .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #1e293b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #1e293b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #1e293b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-teal .light .mat-step-header.mat-warn,
.theme-teal.light .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #dc2626;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #dc2626;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #dc2626;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-teal .light .mat-toolbar.mat-primary,
.theme-teal.light .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #0d9488;
  --mat-toolbar-container-text-color: #042f2e;
}
.theme-teal .light .mat-toolbar.mat-accent,
.theme-teal.light .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #1e293b;
  --mat-toolbar-container-text-color: white;
}
.theme-teal .light .mat-toolbar.mat-warn,
.theme-teal.light .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #dc2626;
  --mat-toolbar-container-text-color: white;
}

.theme-teal .dark,
.theme-teal.dark {
  --mat-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-option-selected-state-label-text-color: #0d9488;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-optgroup-label-text-color: white;
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
  --mat-app-background-color: #0f172a;
  --mat-app-text-color: white;
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: #1e293b;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: #1e293b;
  --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: #94a3b8;
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #64748b;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #0d9488;
  --mdc-filled-text-field-focus-active-indicator-color: #0d9488;
  --mdc-filled-text-field-focus-label-text-color: rgba(13, 148, 136, 0.87);
  --mdc-filled-text-field-container-color: #273243;
  --mdc-filled-text-field-disabled-container-color: #232d3f;
  --mdc-filled-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(71, 85, 105, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(71, 85, 105, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #dc2626;
  --mdc-filled-text-field-error-focus-label-text-color: #dc2626;
  --mdc-filled-text-field-error-label-text-color: #dc2626;
  --mdc-filled-text-field-error-caret-color: #dc2626;
  --mdc-filled-text-field-active-indicator-color: rgba(241, 245, 249, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(241, 245, 249, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(241, 245, 249, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-hover-active-indicator-color: #dc2626;
  --mdc-outlined-text-field-caret-color: #0d9488;
  --mdc-outlined-text-field-focus-outline-color: #0d9488;
  --mdc-outlined-text-field-focus-label-text-color: rgba(13, 148, 136, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(71, 85, 105, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(71, 85, 105, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-error-caret-color: #dc2626;
  --mdc-outlined-text-field-error-focus-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-hover-label-text-color: #dc2626;
  --mdc-outlined-text-field-outline-color: rgba(241, 245, 249, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(241, 245, 249, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(241, 245, 249, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-error-hover-outline-color: #dc2626;
  --mdc-outlined-text-field-error-outline-color: #dc2626;
  --mat-form-field-focus-select-arrow-color: rgba(13, 148, 136, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(241, 245, 249, 0.38);
  --mat-form-field-state-layer-color: rgba(255, 255, 255, 0.87);
  --mat-form-field-error-text-color: #dc2626;
  --mat-form-field-select-option-text-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-select-disabled-option-text-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(241, 245, 249, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.08;
  --mat-form-field-focus-state-layer-opacity: 0.24;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: #1e293b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(71, 85, 105, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(13, 148, 136, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: #1e293b;
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: #1e293b;
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
  --mdc-switch-selected-focus-state-layer-color: #5eead4;
  --mdc-switch-selected-handle-color: #5eead4;
  --mdc-switch-selected-hover-state-layer-color: #5eead4;
  --mdc-switch-selected-pressed-state-layer-color: #5eead4;
  --mdc-switch-selected-focus-handle-color: #99f6e4;
  --mdc-switch-selected-hover-handle-color: #99f6e4;
  --mdc-switch-selected-pressed-handle-color: #99f6e4;
  --mdc-switch-selected-focus-track-color: #0d9488;
  --mdc-switch-selected-hover-track-color: #0d9488;
  --mdc-switch-selected-pressed-track-color: #0d9488;
  --mdc-switch-selected-track-color: #0d9488;
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-icon-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-disabled-label-text-color: #475569;
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-color: #0d9488;
  --mdc-slider-focus-handle-color: #0d9488;
  --mdc-slider-hover-handle-color: #0d9488;
  --mdc-slider-active-track-color: #0d9488;
  --mdc-slider-inactive-track-color: #0d9488;
  --mdc-slider-with-tick-marks-inactive-container-color: #0d9488;
  --mdc-slider-with-tick-marks-active-container-color: #042f2e;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-label-container-color: #fff;
  --mdc-slider-label-label-text-color: #000;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: #0d9488;
  --mat-slider-hover-state-layer-color: rgba(13, 148, 136, 0.05);
  --mat-slider-focus-state-layer-color: rgba(13, 148, 136, 0.2);
  --mat-slider-value-indicator-opacity: 0.9;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-item-label-text-color: white;
  --mat-menu-item-icon-color: white;
  --mat-menu-item-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-container-color: #1e293b;
  --mat-menu-divider-color: rgba(241, 245, 249, 0.12);
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 0;
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: #94a3b8;
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: #64748b;
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: white;
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
  --mat-paginator-container-text-color: rgba(255, 255, 255, 0.87);
  --mat-paginator-container-background-color: #1e293b;
  --mat-paginator-enabled-icon-color: rgba(255, 255, 255, 0.54);
  --mat-paginator-disabled-icon-color: rgba(255, 255, 255, 0.12);
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
  --mat-checkbox-disabled-label-color: #475569;
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: white;
  --mdc-text-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mat-text-button-state-layer-color: white;
  --mat-text-button-disabled-state-layer-color: white;
  --mat-text-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.08;
  --mat-text-button-focus-state-layer-opacity: 0.24;
  --mat-text-button-pressed-state-layer-opacity: 0.24;
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: white;
  --mdc-filled-button-disabled-container-color: rgba(30, 41, 59, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-disabled-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.08;
  --mat-filled-button-focus-state-layer-opacity: 0.24;
  --mat-filled-button-pressed-state-layer-opacity: 0.24;
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: white;
  --mdc-protected-button-disabled-container-color: rgba(30, 41, 59, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-disabled-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.08;
  --mat-protected-button-focus-state-layer-opacity: 0.24;
  --mat-protected-button-pressed-state-layer-opacity: 0.24;
  --mdc-outlined-button-disabled-outline-color: rgba(241, 245, 249, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mdc-outlined-button-label-text-color: white;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: white;
  --mat-outlined-button-disabled-state-layer-color: white;
  --mat-outlined-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.08;
  --mat-outlined-button-focus-state-layer-opacity: 0.24;
  --mat-outlined-button-pressed-state-layer-opacity: 0.24;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
  --mat-icon-button-state-layer-color: white;
  --mat-icon-button-disabled-state-layer-color: white;
  --mat-icon-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.08;
  --mat-icon-button-focus-state-layer-opacity: 0.24;
  --mat-icon-button-pressed-state-layer-opacity: 0.24;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-fab-container-color: #1e293b;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-disabled-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.08;
  --mat-fab-focus-state-layer-opacity: 0.24;
  --mat-fab-pressed-state-layer-opacity: 0.24;
  --mat-fab-disabled-state-container-color: rgba(15, 23, 42, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(30, 41, 59, 0.5);
  --mdc-fab-small-container-color: #1e293b;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-disabled-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.08;
  --mat-fab-small-focus-state-layer-opacity: 0.24;
  --mat-fab-small-pressed-state-layer-opacity: 0.24;
  --mat-fab-small-disabled-state-container-color: rgba(15, 23, 42, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(30, 41, 59, 0.5);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #d2d4d8;
  --mdc-snackbar-supporting-text-color: rgba(30, 41, 59, 0.87);
  --mat-snack-bar-button-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: #1e293b;
  --mat-table-header-headline-color: white;
  --mat-table-row-item-label-text-color: white;
  --mat-table-row-item-outline-color: rgba(241, 245, 249, 0.12);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #0d9488;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #0d9488;
  --mat-badge-text-color: #042f2e;
  --mat-badge-disabled-state-background-color: #1e293b;
  --mat-badge-disabled-state-text-color: #475569;
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: white;
  --mat-bottom-sheet-container-background-color: #1e293b;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: #64748b;
  --mat-legacy-button-toggle-state-layer-color: #e2e8f0;
  --mat-legacy-button-toggle-selected-state-text-color: #94a3b8;
  --mat-legacy-button-toggle-selected-state-background-color: rgba(255, 255, 255, 0.05);
  --mat-legacy-button-toggle-disabled-state-text-color: #1e293b;
  --mat-legacy-button-toggle-disabled-state-background-color: #0f172a;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #1e293b;
  --mat-standard-button-toggle-text-color: white;
  --mat-standard-button-toggle-background-color: #1e293b;
  --mat-standard-button-toggle-state-layer-color: #e2e8f0;
  --mat-standard-button-toggle-selected-state-background-color: rgba(255, 255, 255, 0.05);
  --mat-standard-button-toggle-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-state-text-color: #1e293b;
  --mat-standard-button-toggle-disabled-state-background-color: #1e293b;
  --mat-standard-button-toggle-disabled-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #1e293b;
  --mat-standard-button-toggle-divider-color: #374152;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: #042f2e;
  --mat-datepicker-calendar-date-selected-state-background-color: #0d9488;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(13, 148, 136, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #042f2e;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(13, 148, 136, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(13, 148, 136, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #0d9488;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(13, 148, 136, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: #f1f5f9;
  --mat-datepicker-calendar-body-label-text-color: #94a3b8;
  --mat-datepicker-calendar-period-button-text-color: white;
  --mat-datepicker-calendar-period-button-icon-color: #f1f5f9;
  --mat-datepicker-calendar-navigation-button-icon-color: #f1f5f9;
  --mat-datepicker-calendar-header-divider-color: rgba(241, 245, 249, 0.12);
  --mat-datepicker-calendar-header-text-color: #94a3b8;
  --mat-datepicker-calendar-date-today-outline-color: #64748b;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(100, 116, 139, 0.8);
  --mat-datepicker-calendar-date-text-color: white;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: #475569;
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(241, 245, 249, 0.24);
  --mat-datepicker-range-input-separator-color: white;
  --mat-datepicker-range-input-disabled-state-separator-color: #475569;
  --mat-datepicker-range-input-disabled-state-text-color: #475569;
  --mat-datepicker-calendar-container-background-color: #1e293b;
  --mat-datepicker-calendar-container-text-color: white;
  --mat-divider-width: 1px;
  --mat-divider-color: rgba(241, 245, 249, 0.12);
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: #1e293b;
  --mat-expansion-container-text-color: white;
  --mat-expansion-actions-divider-color: rgba(241, 245, 249, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-expansion-header-focus-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-expansion-header-disabled-state-text-color: #1e293b;
  --mat-expansion-header-text-color: white;
  --mat-expansion-header-description-color: #94a3b8;
  --mat-expansion-header-indicator-color: #94a3b8;
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: rgba(241, 245, 249, 0.12);
  --mat-sidenav-container-background-color: #1e293b;
  --mat-sidenav-container-text-color: white;
  --mat-sidenav-content-background-color: #0f172a;
  --mat-sidenav-content-text-color: white;
  --mat-sidenav-scrim-color: rgba(225, 214, 196, 0.6);
  --mat-stepper-header-icon-foreground-color: #042f2e;
  --mat-stepper-header-selected-state-icon-background-color: #0d9488;
  --mat-stepper-header-selected-state-icon-foreground-color: #042f2e;
  --mat-stepper-header-done-state-icon-background-color: #0d9488;
  --mat-stepper-header-done-state-icon-foreground-color: #042f2e;
  --mat-stepper-header-edit-state-icon-background-color: #0d9488;
  --mat-stepper-header-edit-state-icon-foreground-color: #042f2e;
  --mat-stepper-container-color: #1e293b;
  --mat-stepper-line-color: rgba(241, 245, 249, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-stepper-header-focus-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-stepper-header-label-text-color: #94a3b8;
  --mat-stepper-header-optional-label-text-color: #94a3b8;
  --mat-stepper-header-selected-state-label-text-color: white;
  --mat-stepper-header-error-state-label-text-color: #dc2626;
  --mat-stepper-header-icon-background-color: #94a3b8;
  --mat-stepper-header-error-state-icon-foreground-color: #dc2626;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #94a3b8;
  --mat-toolbar-container-background-color: #0f172a;
  --mat-toolbar-container-text-color: white;
  --mat-tree-container-background-color: #1e293b;
  --mat-tree-node-text-color: white;
}
.theme-teal .dark .mat-accent,
.theme-teal.dark .mat-accent {
  --mat-option-selected-state-label-text-color: #1e293b;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.theme-teal .dark .mat-warn,
.theme-teal.dark .mat-warn {
  --mat-option-selected-state-label-text-color: #dc2626;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.theme-teal .dark .mat-primary,
.theme-teal.dark .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #0d9488;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #0d9488;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-teal .dark .mat-accent,
.theme-teal.dark .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-teal .dark .mat-warn,
.theme-teal.dark .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #dc2626;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #dc2626;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-teal .dark .mat-elevation-z0, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z0,
.theme-teal.dark .mat-elevation-z0,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z1, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z1,
.theme-teal.dark .mat-elevation-z1,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z2, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z2,
.theme-teal.dark .mat-elevation-z2,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z3, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z3,
.theme-teal.dark .mat-elevation-z3,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z4, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z4,
.theme-teal.dark .mat-elevation-z4,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z5, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z5,
.theme-teal.dark .mat-elevation-z5,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z6, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z6,
.theme-teal.dark .mat-elevation-z6,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z7, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z7,
.theme-teal.dark .mat-elevation-z7,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z8, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z8,
.theme-teal.dark .mat-elevation-z8,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z9, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z9,
.theme-teal.dark .mat-elevation-z9,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z10, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z10,
.theme-teal.dark .mat-elevation-z10,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z11, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z11,
.theme-teal.dark .mat-elevation-z11,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z12, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z12,
.theme-teal.dark .mat-elevation-z12,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z13, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z13,
.theme-teal.dark .mat-elevation-z13,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z14, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z14,
.theme-teal.dark .mat-elevation-z14,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z15, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z15,
.theme-teal.dark .mat-elevation-z15,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z16, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z16,
.theme-teal.dark .mat-elevation-z16,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z17, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z17,
.theme-teal.dark .mat-elevation-z17,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z18, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z18,
.theme-teal.dark .mat-elevation-z18,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z19, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z19,
.theme-teal.dark .mat-elevation-z19,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z20, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z20,
.theme-teal.dark .mat-elevation-z20,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z21, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z21,
.theme-teal.dark .mat-elevation-z21,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z22, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z22,
.theme-teal.dark .mat-elevation-z22,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z23, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z23,
.theme-teal.dark .mat-elevation-z23,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-teal .dark .mat-elevation-z24, .theme-teal .dark .mat-mdc-elevation-specific.mat-elevation-z24,
.theme-teal.dark .mat-elevation-z24,
.theme-teal.dark .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-teal .dark .mat-mdc-progress-bar,
.theme-teal.dark .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #0d9488;
  --mdc-linear-progress-track-color: rgba(13, 148, 136, 0.25);
}
.theme-teal .dark .mat-mdc-progress-bar.mat-accent,
.theme-teal.dark .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1e293b;
  --mdc-linear-progress-track-color: rgba(30, 41, 59, 0.25);
}
.theme-teal .dark .mat-mdc-progress-bar.mat-warn,
.theme-teal.dark .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc2626;
  --mdc-linear-progress-track-color: rgba(220, 38, 38, 0.25);
}
.theme-teal .dark .mat-mdc-form-field.mat-accent,
.theme-teal.dark .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #1e293b;
  --mdc-filled-text-field-focus-active-indicator-color: #1e293b;
  --mdc-filled-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mdc-outlined-text-field-caret-color: #1e293b;
  --mdc-outlined-text-field-focus-outline-color: #1e293b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(30, 41, 59, 0.87);
}
.theme-teal .dark .mat-mdc-form-field.mat-warn,
.theme-teal.dark .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #dc2626;
  --mdc-filled-text-field-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mdc-outlined-text-field-caret-color: #dc2626;
  --mdc-outlined-text-field-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-teal .dark .mat-mdc-form-field.mat-accent,
.theme-teal.dark .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: #1e293b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(71, 85, 105, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(30, 41, 59, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-teal .dark .mat-mdc-form-field.mat-warn,
.theme-teal.dark .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: #1e293b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(71, 85, 105, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-teal .dark .mat-mdc-standard-chip,
.theme-teal.dark .mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.theme-teal .dark .mat-mdc-standard-chip,
.theme-teal.dark .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-elevated-container-color: #394353;
  --mdc-chip-elevated-selected-container-color: #394353;
  --mdc-chip-elevated-disabled-container-color: #394353;
  --mdc-chip-flat-disabled-selected-container-color: #394353;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-selected-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
  --mat-chip-selected-disabled-trailing-icon-color: #fafafa;
  --mat-chip-selected-trailing-icon-color: #fafafa;
}
.theme-teal .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .theme-teal .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.theme-teal.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.theme-teal.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: #042f2e;
  --mdc-chip-elevated-container-color: #0d9488;
  --mdc-chip-elevated-selected-container-color: #0d9488;
  --mdc-chip-elevated-disabled-container-color: #0d9488;
  --mdc-chip-flat-disabled-selected-container-color: #0d9488;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #042f2e;
  --mdc-chip-selected-label-text-color: #042f2e;
  --mdc-chip-with-icon-icon-color: #042f2e;
  --mdc-chip-with-icon-disabled-icon-color: #042f2e;
  --mdc-chip-with-icon-selected-icon-color: #042f2e;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #042f2e;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #042f2e;
  --mat-chip-selected-disabled-trailing-icon-color: #042f2e;
  --mat-chip-selected-trailing-icon-color: #042f2e;
}
.theme-teal .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .theme-teal .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.theme-teal.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.theme-teal.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #1e293b;
  --mdc-chip-elevated-selected-container-color: #1e293b;
  --mdc-chip-elevated-disabled-container-color: #1e293b;
  --mdc-chip-flat-disabled-selected-container-color: #1e293b;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-teal .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .theme-teal .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.theme-teal.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.theme-teal.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-selected-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-flat-disabled-selected-container-color: #dc2626;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-teal .dark .mat-mdc-slide-toggle,
.theme-teal.dark .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: white;
}
.theme-teal .dark .mat-mdc-slide-toggle.mat-accent,
.theme-teal.dark .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #cbd5e1;
  --mdc-switch-selected-handle-color: #cbd5e1;
  --mdc-switch-selected-hover-state-layer-color: #cbd5e1;
  --mdc-switch-selected-pressed-state-layer-color: #cbd5e1;
  --mdc-switch-selected-focus-handle-color: #e2e8f0;
  --mdc-switch-selected-hover-handle-color: #e2e8f0;
  --mdc-switch-selected-pressed-handle-color: #e2e8f0;
  --mdc-switch-selected-focus-track-color: #475569;
  --mdc-switch-selected-hover-track-color: #475569;
  --mdc-switch-selected-pressed-track-color: #475569;
  --mdc-switch-selected-track-color: #475569;
}
.theme-teal .dark .mat-mdc-slide-toggle.mat-warn,
.theme-teal.dark .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #fca5a5;
  --mdc-switch-selected-handle-color: #fca5a5;
  --mdc-switch-selected-hover-state-layer-color: #fca5a5;
  --mdc-switch-selected-pressed-state-layer-color: #fca5a5;
  --mdc-switch-selected-focus-handle-color: #fecaca;
  --mdc-switch-selected-hover-handle-color: #fecaca;
  --mdc-switch-selected-pressed-handle-color: #fecaca;
  --mdc-switch-selected-focus-track-color: #dc2626;
  --mdc-switch-selected-hover-track-color: #dc2626;
  --mdc-switch-selected-pressed-track-color: #dc2626;
  --mdc-switch-selected-track-color: #dc2626;
}
.theme-teal .dark .mat-mdc-radio-button,
.theme-teal.dark .mat-mdc-radio-button {
  --mdc-form-field-label-text-color: white;
}
.theme-teal .dark .mat-mdc-radio-button.mat-primary,
.theme-teal.dark .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #0d9488;
  --mdc-radio-selected-hover-icon-color: #0d9488;
  --mdc-radio-selected-icon-color: #0d9488;
  --mdc-radio-selected-pressed-icon-color: #0d9488;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #0d9488;
  --mat-radio-disabled-label-color: #475569;
}
.theme-teal .dark .mat-mdc-radio-button.mat-accent,
.theme-teal.dark .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #1e293b;
  --mat-radio-disabled-label-color: #475569;
}
.theme-teal .dark .mat-mdc-radio-button.mat-warn,
.theme-teal.dark .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #dc2626;
  --mat-radio-disabled-label-color: #475569;
}
.theme-teal .dark .mat-accent,
.theme-teal.dark .mat-accent {
  --mat-slider-ripple-color: #1e293b;
  --mat-slider-hover-state-layer-color: rgba(30, 41, 59, 0.05);
  --mat-slider-focus-state-layer-color: rgba(30, 41, 59, 0.2);
  --mdc-slider-handle-color: #1e293b;
  --mdc-slider-focus-handle-color: #1e293b;
  --mdc-slider-hover-handle-color: #1e293b;
  --mdc-slider-active-track-color: #1e293b;
  --mdc-slider-inactive-track-color: #1e293b;
  --mdc-slider-with-tick-marks-inactive-container-color: #1e293b;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-teal .dark .mat-warn,
.theme-teal.dark .mat-warn {
  --mat-slider-ripple-color: #dc2626;
  --mat-slider-hover-state-layer-color: rgba(220, 38, 38, 0.05);
  --mat-slider-focus-state-layer-color: rgba(220, 38, 38, 0.2);
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-teal .dark .mdc-list-item__start,
.theme-teal .dark .mdc-list-item__end,
.theme-teal.dark .mdc-list-item__start,
.theme-teal.dark .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #0d9488;
  --mdc-radio-selected-hover-icon-color: #0d9488;
  --mdc-radio-selected-icon-color: #0d9488;
  --mdc-radio-selected-pressed-icon-color: #0d9488;
}
.theme-teal .dark .mat-accent .mdc-list-item__start,
.theme-teal .dark .mat-accent .mdc-list-item__end,
.theme-teal.dark .mat-accent .mdc-list-item__start,
.theme-teal.dark .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
}
.theme-teal .dark .mat-warn .mdc-list-item__start,
.theme-teal .dark .mat-warn .mdc-list-item__end,
.theme-teal.dark .mat-warn .mdc-list-item__start,
.theme-teal.dark .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
}
.theme-teal .dark .mat-mdc-list-option,
.theme-teal.dark .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #042f2e;
  --mdc-checkbox-selected-focus-icon-color: #0d9488;
  --mdc-checkbox-selected-hover-icon-color: #0d9488;
  --mdc-checkbox-selected-icon-color: #0d9488;
  --mdc-checkbox-selected-pressed-icon-color: #0d9488;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #0d9488;
  --mdc-checkbox-selected-hover-state-layer-color: #0d9488;
  --mdc-checkbox-selected-pressed-state-layer-color: #0d9488;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-teal .dark .mat-mdc-list-option.mat-accent,
.theme-teal.dark .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-teal .dark .mat-mdc-list-option.mat-warn,
.theme-teal.dark .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-teal .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-teal .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-teal.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-teal.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #0d9488;
}
.theme-teal .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-teal.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #0d9488;
}
.theme-teal .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-teal .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-teal .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.theme-teal.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-teal.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-teal.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.theme-teal .dark .mat-mdc-tab-group, .theme-teal .dark .mat-mdc-tab-nav-bar,
.theme-teal.dark .mat-mdc-tab-group,
.theme-teal.dark .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #0d9488;
  --mat-tab-header-disabled-ripple-color: #475569;
  --mat-tab-header-pagination-icon-color: #f1f5f9;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #0d9488;
  --mat-tab-header-active-ripple-color: #0d9488;
  --mat-tab-header-inactive-ripple-color: #0d9488;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #0d9488;
  --mat-tab-header-active-hover-label-text-color: #0d9488;
  --mat-tab-header-active-focus-indicator-color: #0d9488;
  --mat-tab-header-active-hover-indicator-color: #0d9488;
}
.theme-teal .dark .mat-mdc-tab-group.mat-accent, .theme-teal .dark .mat-mdc-tab-nav-bar.mat-accent,
.theme-teal.dark .mat-mdc-tab-group.mat-accent,
.theme-teal.dark .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #1e293b;
  --mat-tab-header-disabled-ripple-color: #475569;
  --mat-tab-header-pagination-icon-color: #f1f5f9;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #1e293b;
  --mat-tab-header-active-ripple-color: #1e293b;
  --mat-tab-header-inactive-ripple-color: #1e293b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #1e293b;
  --mat-tab-header-active-hover-label-text-color: #1e293b;
  --mat-tab-header-active-focus-indicator-color: #1e293b;
  --mat-tab-header-active-hover-indicator-color: #1e293b;
}
.theme-teal .dark .mat-mdc-tab-group.mat-warn, .theme-teal .dark .mat-mdc-tab-nav-bar.mat-warn,
.theme-teal.dark .mat-mdc-tab-group.mat-warn,
.theme-teal.dark .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #dc2626;
  --mat-tab-header-disabled-ripple-color: #475569;
  --mat-tab-header-pagination-icon-color: #f1f5f9;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #dc2626;
  --mat-tab-header-active-ripple-color: #dc2626;
  --mat-tab-header-inactive-ripple-color: #dc2626;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #dc2626;
  --mat-tab-header-active-hover-label-text-color: #dc2626;
  --mat-tab-header-active-focus-indicator-color: #dc2626;
  --mat-tab-header-active-hover-indicator-color: #dc2626;
}
.theme-teal .dark .mat-mdc-tab-group.mat-background-primary, .theme-teal .dark .mat-mdc-tab-nav-bar.mat-background-primary,
.theme-teal.dark .mat-mdc-tab-group.mat-background-primary,
.theme-teal.dark .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #0d9488;
  --mat-tab-header-with-background-foreground-color: #042f2e;
}
.theme-teal .dark .mat-mdc-tab-group.mat-background-accent, .theme-teal .dark .mat-mdc-tab-nav-bar.mat-background-accent,
.theme-teal.dark .mat-mdc-tab-group.mat-background-accent,
.theme-teal.dark .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #1e293b;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-teal .dark .mat-mdc-tab-group.mat-background-warn, .theme-teal .dark .mat-mdc-tab-nav-bar.mat-background-warn,
.theme-teal.dark .mat-mdc-tab-group.mat-background-warn,
.theme-teal.dark .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #dc2626;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-teal .dark .mat-mdc-checkbox,
.theme-teal.dark .mat-mdc-checkbox {
  --mdc-form-field-label-text-color: white;
}
.theme-teal .dark .mat-mdc-checkbox.mat-primary,
.theme-teal.dark .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #042f2e;
  --mdc-checkbox-selected-focus-icon-color: #0d9488;
  --mdc-checkbox-selected-hover-icon-color: #0d9488;
  --mdc-checkbox-selected-icon-color: #0d9488;
  --mdc-checkbox-selected-pressed-icon-color: #0d9488;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #0d9488;
  --mdc-checkbox-selected-hover-state-layer-color: #0d9488;
  --mdc-checkbox-selected-pressed-state-layer-color: #0d9488;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-teal .dark .mat-mdc-checkbox.mat-warn,
.theme-teal.dark .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-teal .dark .mat-mdc-button.mat-primary,
.theme-teal.dark .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #0d9488;
  --mat-text-button-state-layer-color: #0d9488;
  --mat-text-button-ripple-color: rgba(13, 148, 136, 0.1);
}
.theme-teal .dark .mat-mdc-button.mat-accent,
.theme-teal.dark .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1e293b;
  --mat-text-button-state-layer-color: #1e293b;
  --mat-text-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-teal .dark .mat-mdc-button.mat-warn,
.theme-teal.dark .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc2626;
  --mat-text-button-state-layer-color: #dc2626;
  --mat-text-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-teal .dark .mat-mdc-unelevated-button.mat-primary,
.theme-teal.dark .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #0d9488;
  --mdc-filled-button-label-text-color: #042f2e;
  --mat-filled-button-state-layer-color: #042f2e;
  --mat-filled-button-ripple-color: rgba(4, 47, 46, 0.1);
}
.theme-teal .dark .mat-mdc-unelevated-button.mat-accent,
.theme-teal.dark .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-teal .dark .mat-mdc-unelevated-button.mat-warn,
.theme-teal.dark .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-teal .dark .mat-mdc-raised-button.mat-primary,
.theme-teal.dark .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #0d9488;
  --mdc-protected-button-label-text-color: #042f2e;
  --mat-protected-button-state-layer-color: #042f2e;
  --mat-protected-button-ripple-color: rgba(4, 47, 46, 0.1);
}
.theme-teal .dark .mat-mdc-raised-button.mat-accent,
.theme-teal.dark .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-teal .dark .mat-mdc-raised-button.mat-warn,
.theme-teal.dark .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-teal .dark .mat-mdc-outlined-button.mat-primary,
.theme-teal.dark .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #0d9488;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: #0d9488;
  --mat-outlined-button-ripple-color: rgba(13, 148, 136, 0.1);
}
.theme-teal .dark .mat-mdc-outlined-button.mat-accent,
.theme-teal.dark .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1e293b;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: #1e293b;
  --mat-outlined-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-teal .dark .mat-mdc-outlined-button.mat-warn,
.theme-teal.dark .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc2626;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: #dc2626;
  --mat-outlined-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-teal .dark .mat-mdc-icon-button.mat-primary,
.theme-teal.dark .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #0d9488;
  --mat-icon-button-state-layer-color: #0d9488;
  --mat-icon-button-ripple-color: rgba(13, 148, 136, 0.1);
}
.theme-teal .dark .mat-mdc-icon-button.mat-accent,
.theme-teal.dark .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1e293b;
  --mat-icon-button-state-layer-color: #1e293b;
  --mat-icon-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-teal .dark .mat-mdc-icon-button.mat-warn,
.theme-teal.dark .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc2626;
  --mat-icon-button-state-layer-color: #dc2626;
  --mat-icon-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-teal .dark .mat-mdc-fab.mat-primary,
.theme-teal.dark .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #0d9488;
  --mat-fab-foreground-color: #042f2e;
  --mat-fab-state-layer-color: #042f2e;
  --mat-fab-ripple-color: rgba(4, 47, 46, 0.1);
}
.theme-teal .dark .mat-mdc-fab.mat-accent,
.theme-teal.dark .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #1e293b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-teal .dark .mat-mdc-fab.mat-warn,
.theme-teal.dark .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #dc2626;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-teal .dark .mat-mdc-mini-fab.mat-primary,
.theme-teal.dark .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #0d9488;
  --mat-fab-small-foreground-color: #042f2e;
  --mat-fab-small-state-layer-color: #042f2e;
  --mat-fab-small-ripple-color: rgba(4, 47, 46, 0.1);
}
.theme-teal .dark .mat-mdc-mini-fab.mat-accent,
.theme-teal.dark .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #1e293b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-teal .dark .mat-mdc-mini-fab.mat-warn,
.theme-teal.dark .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #dc2626;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-teal .dark .mat-accent,
.theme-teal.dark .mat-accent {
  --mdc-circular-progress-active-indicator-color: #1e293b;
}
.theme-teal .dark .mat-warn,
.theme-teal.dark .mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}
.theme-teal .dark .mat-badge-accent,
.theme-teal.dark .mat-badge-accent {
  --mat-badge-background-color: #1e293b;
  --mat-badge-text-color: white;
}
.theme-teal .dark .mat-badge-warn,
.theme-teal.dark .mat-badge-warn {
  --mat-badge-background-color: #dc2626;
  --mat-badge-text-color: white;
}
.theme-teal .dark .mat-datepicker-content.mat-accent,
.theme-teal.dark .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #1e293b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(30, 41, 59, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(30, 41, 59, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-teal .dark .mat-datepicker-content.mat-warn,
.theme-teal.dark .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #dc2626;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(220, 38, 38, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(220, 38, 38, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-teal .dark .mat-datepicker-toggle-active.mat-accent,
.theme-teal.dark .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #1e293b;
}
.theme-teal .dark .mat-datepicker-toggle-active.mat-warn,
.theme-teal.dark .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #dc2626;
}
.theme-teal .dark .mat-icon.mat-primary,
.theme-teal.dark .mat-icon.mat-primary {
  --mat-icon-color: #0d9488;
}
.theme-teal .dark .mat-icon.mat-accent,
.theme-teal.dark .mat-icon.mat-accent {
  --mat-icon-color: #1e293b;
}
.theme-teal .dark .mat-icon.mat-warn,
.theme-teal.dark .mat-icon.mat-warn {
  --mat-icon-color: #dc2626;
}
.theme-teal .dark .mat-step-header.mat-accent,
.theme-teal.dark .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #1e293b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #1e293b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #1e293b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-teal .dark .mat-step-header.mat-warn,
.theme-teal.dark .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #dc2626;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #dc2626;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #dc2626;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-teal .dark .mat-toolbar.mat-primary,
.theme-teal.dark .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #0d9488;
  --mat-toolbar-container-text-color: #042f2e;
}
.theme-teal .dark .mat-toolbar.mat-accent,
.theme-teal.dark .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #1e293b;
  --mat-toolbar-container-text-color: white;
}
.theme-teal .dark .mat-toolbar.mat-warn,
.theme-teal.dark .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #dc2626;
  --mat-toolbar-container-text-color: white;
}

/* Generate the palettes */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define a light & dark Angular Material theme with the generated palettes */
/* Merge the custom base colors with the generated themes */
/* Generate and encapsulate Angular Material themes */
.theme-rose .light,
.theme-rose.light {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-option-selected-state-label-text-color: #f43f5e;
  --mat-option-label-text-color: #1e293b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-optgroup-label-text-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
  --mat-app-background-color: #f1f5f9;
  --mat-app-text-color: #1e293b;
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: #64748b;
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #1e293b;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #f43f5e;
  --mdc-filled-text-field-focus-active-indicator-color: #f43f5e;
  --mdc-filled-text-field-focus-label-text-color: rgba(244, 63, 94, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(30, 41, 59, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(148, 163, 184, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(30, 41, 59, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #dc2626;
  --mdc-filled-text-field-error-focus-label-text-color: #dc2626;
  --mdc-filled-text-field-error-label-text-color: #dc2626;
  --mdc-filled-text-field-error-caret-color: #dc2626;
  --mdc-filled-text-field-active-indicator-color: rgba(226, 232, 240, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(226, 232, 240, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(226, 232, 240, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-hover-active-indicator-color: #dc2626;
  --mdc-outlined-text-field-caret-color: #f43f5e;
  --mdc-outlined-text-field-focus-outline-color: #f43f5e;
  --mdc-outlined-text-field-focus-label-text-color: rgba(244, 63, 94, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(30, 41, 59, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(148, 163, 184, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(30, 41, 59, 0.6);
  --mdc-outlined-text-field-error-caret-color: #dc2626;
  --mdc-outlined-text-field-error-focus-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-hover-label-text-color: #dc2626;
  --mdc-outlined-text-field-outline-color: rgba(226, 232, 240, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(226, 232, 240, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(226, 232, 240, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-error-hover-outline-color: #dc2626;
  --mdc-outlined-text-field-error-outline-color: #dc2626;
  --mat-form-field-focus-select-arrow-color: rgba(244, 63, 94, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(100, 116, 139, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #dc2626;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(100, 116, 139, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(100, 116, 139, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(30, 41, 59, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(148, 163, 184, 0.38);
  --mat-select-placeholder-text-color: rgba(30, 41, 59, 0.6);
  --mat-select-enabled-arrow-color: rgba(226, 232, 240, 0.54);
  --mat-select-disabled-arrow-color: rgba(30, 41, 59, 0.38);
  --mat-select-focused-arrow-color: rgba(244, 63, 94, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: white;
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(30, 41, 59, 0.87);
  --mdc-dialog-supporting-text-color: rgba(30, 41, 59, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
  --mdc-switch-selected-focus-state-layer-color: #e11d48;
  --mdc-switch-selected-handle-color: #e11d48;
  --mdc-switch-selected-hover-state-layer-color: #e11d48;
  --mdc-switch-selected-pressed-state-layer-color: #e11d48;
  --mdc-switch-selected-focus-handle-color: #881337;
  --mdc-switch-selected-hover-handle-color: #881337;
  --mdc-switch-selected-pressed-handle-color: #881337;
  --mdc-switch-selected-focus-track-color: #fda4af;
  --mdc-switch-selected-hover-track-color: #fda4af;
  --mdc-switch-selected-pressed-track-color: #fda4af;
  --mdc-switch-selected-track-color: #fda4af;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-disabled-label-text-color: #94a3b8;
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-color: #f43f5e;
  --mdc-slider-focus-handle-color: #f43f5e;
  --mdc-slider-hover-handle-color: #f43f5e;
  --mdc-slider-active-track-color: #f43f5e;
  --mdc-slider-inactive-track-color: #f43f5e;
  --mdc-slider-with-tick-marks-inactive-container-color: #f43f5e;
  --mdc-slider-with-tick-marks-active-container-color: #4c0519;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: #f43f5e;
  --mat-slider-hover-state-layer-color: rgba(244, 63, 94, 0.05);
  --mat-slider-focus-state-layer-color: rgba(244, 63, 94, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-item-label-text-color: #1e293b;
  --mat-menu-item-icon-color: #1e293b;
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: #e2e8f0;
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 0;
  --mdc-list-list-item-label-text-color: #1e293b;
  --mdc-list-list-item-supporting-text-color: #64748b;
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: #94a3b8;
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: #1e293b;
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: #1e293b;
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: #94a3b8;
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: #1e293b;
  --mdc-text-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: #1e293b;
  --mdc-filled-button-disabled-container-color: rgba(148, 163, 184, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: #1e293b;
  --mdc-protected-button-disabled-container-color: rgba(148, 163, 184, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: #e2e8f0;
  --mdc-outlined-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-outlined-button-label-text-color: #1e293b;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-fab-container-color: white;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(148, 163, 184, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(148, 163, 184, 0.38);
  --mdc-fab-small-container-color: white;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(148, 163, 184, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(148, 163, 184, 0.38);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #1e293b;
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: white;
  --mat-table-header-headline-color: #1e293b;
  --mat-table-row-item-label-text-color: #1e293b;
  --mat-table-row-item-outline-color: #e2e8f0;
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #f43f5e;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #f43f5e;
  --mat-badge-text-color: #4c0519;
  --mat-badge-disabled-state-background-color: #94a3b8;
  --mat-badge-disabled-state-text-color: #94a3b8;
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: #1e293b;
  --mat-bottom-sheet-container-background-color: white;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: #94a3b8;
  --mat-legacy-button-toggle-state-layer-color: #64748b;
  --mat-legacy-button-toggle-selected-state-text-color: #64748b;
  --mat-legacy-button-toggle-selected-state-background-color: #e2e8f0;
  --mat-legacy-button-toggle-disabled-state-text-color: #94a3b8;
  --mat-legacy-button-toggle-disabled-state-background-color: #cbd5e1;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #e2e8f0;
  --mat-standard-button-toggle-text-color: #1e293b;
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: #64748b;
  --mat-standard-button-toggle-selected-state-background-color: #e2e8f0;
  --mat-standard-button-toggle-selected-state-text-color: #1e293b;
  --mat-standard-button-toggle-disabled-state-text-color: #94a3b8;
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: #1e293b;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #e2e8f0;
  --mat-standard-button-toggle-divider-color: #e2e8f0;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: #4c0519;
  --mat-datepicker-calendar-date-selected-state-background-color: #f43f5e;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 63, 94, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #4c0519;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 63, 94, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 63, 94, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #f43f5e;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 63, 94, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: #64748b;
  --mat-datepicker-calendar-body-label-text-color: #64748b;
  --mat-datepicker-calendar-period-button-text-color: #1e293b;
  --mat-datepicker-calendar-period-button-icon-color: #64748b;
  --mat-datepicker-calendar-navigation-button-icon-color: #64748b;
  --mat-datepicker-calendar-header-divider-color: #e2e8f0;
  --mat-datepicker-calendar-header-text-color: #64748b;
  --mat-datepicker-calendar-date-today-outline-color: #94a3b8;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(148, 163, 184, 0.8);
  --mat-datepicker-calendar-date-text-color: #1e293b;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: #94a3b8;
  --mat-datepicker-calendar-date-preview-state-outline-color: #e2e8f0;
  --mat-datepicker-range-input-separator-color: #1e293b;
  --mat-datepicker-range-input-disabled-state-separator-color: #94a3b8;
  --mat-datepicker-range-input-disabled-state-text-color: #94a3b8;
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: #1e293b;
  --mat-divider-width: 1px;
  --mat-divider-color: #e2e8f0;
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: #1e293b;
  --mat-expansion-actions-divider-color: #e2e8f0;
  --mat-expansion-header-hover-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-expansion-header-focus-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-expansion-header-disabled-state-text-color: #94a3b8;
  --mat-expansion-header-text-color: #1e293b;
  --mat-expansion-header-description-color: #64748b;
  --mat-expansion-header-indicator-color: #64748b;
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: #e2e8f0;
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: #1e293b;
  --mat-sidenav-content-background-color: #f1f5f9;
  --mat-sidenav-content-text-color: #1e293b;
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
  --mat-stepper-header-icon-foreground-color: #4c0519;
  --mat-stepper-header-selected-state-icon-background-color: #f43f5e;
  --mat-stepper-header-selected-state-icon-foreground-color: #4c0519;
  --mat-stepper-header-done-state-icon-background-color: #f43f5e;
  --mat-stepper-header-done-state-icon-foreground-color: #4c0519;
  --mat-stepper-header-edit-state-icon-background-color: #f43f5e;
  --mat-stepper-header-edit-state-icon-foreground-color: #4c0519;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: #e2e8f0;
  --mat-stepper-header-hover-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-stepper-header-focus-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-stepper-header-label-text-color: #64748b;
  --mat-stepper-header-optional-label-text-color: #64748b;
  --mat-stepper-header-selected-state-label-text-color: #1e293b;
  --mat-stepper-header-error-state-label-text-color: #dc2626;
  --mat-stepper-header-icon-background-color: #64748b;
  --mat-stepper-header-error-state-icon-foreground-color: #dc2626;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #64748b;
  --mat-toolbar-container-background-color: white;
  --mat-toolbar-container-text-color: #1e293b;
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: #1e293b;
}
.theme-rose .light .mat-accent,
.theme-rose.light .mat-accent {
  --mat-option-selected-state-label-text-color: #1e293b;
  --mat-option-label-text-color: #1e293b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.theme-rose .light .mat-warn,
.theme-rose.light .mat-warn {
  --mat-option-selected-state-label-text-color: #dc2626;
  --mat-option-label-text-color: #1e293b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.theme-rose .light .mat-primary,
.theme-rose.light .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #f43f5e;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f43f5e;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-rose .light .mat-accent,
.theme-rose.light .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-rose .light .mat-warn,
.theme-rose.light .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #dc2626;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #dc2626;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-rose .light .mat-elevation-z0, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z0,
.theme-rose.light .mat-elevation-z0,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z1, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z1,
.theme-rose.light .mat-elevation-z1,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z2, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z2,
.theme-rose.light .mat-elevation-z2,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z3, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z3,
.theme-rose.light .mat-elevation-z3,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z4, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z4,
.theme-rose.light .mat-elevation-z4,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z5, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z5,
.theme-rose.light .mat-elevation-z5,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z6, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z6,
.theme-rose.light .mat-elevation-z6,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z7, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z7,
.theme-rose.light .mat-elevation-z7,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z8, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z8,
.theme-rose.light .mat-elevation-z8,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z9, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z9,
.theme-rose.light .mat-elevation-z9,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z10, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z10,
.theme-rose.light .mat-elevation-z10,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z11, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z11,
.theme-rose.light .mat-elevation-z11,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z12, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z12,
.theme-rose.light .mat-elevation-z12,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z13, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z13,
.theme-rose.light .mat-elevation-z13,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z14, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z14,
.theme-rose.light .mat-elevation-z14,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z15, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z15,
.theme-rose.light .mat-elevation-z15,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z16, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z16,
.theme-rose.light .mat-elevation-z16,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z17, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z17,
.theme-rose.light .mat-elevation-z17,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z18, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z18,
.theme-rose.light .mat-elevation-z18,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z19, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z19,
.theme-rose.light .mat-elevation-z19,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z20, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z20,
.theme-rose.light .mat-elevation-z20,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z21, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z21,
.theme-rose.light .mat-elevation-z21,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z22, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z22,
.theme-rose.light .mat-elevation-z22,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z23, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z23,
.theme-rose.light .mat-elevation-z23,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-rose .light .mat-elevation-z24, .theme-rose .light .mat-mdc-elevation-specific.mat-elevation-z24,
.theme-rose.light .mat-elevation-z24,
.theme-rose.light .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-rose .light .mat-mdc-progress-bar,
.theme-rose.light .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #f43f5e;
  --mdc-linear-progress-track-color: rgba(244, 63, 94, 0.25);
}
.theme-rose .light .mat-mdc-progress-bar.mat-accent,
.theme-rose.light .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1e293b;
  --mdc-linear-progress-track-color: rgba(30, 41, 59, 0.25);
}
.theme-rose .light .mat-mdc-progress-bar.mat-warn,
.theme-rose.light .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc2626;
  --mdc-linear-progress-track-color: rgba(220, 38, 38, 0.25);
}
.theme-rose .light .mat-mdc-form-field.mat-accent,
.theme-rose.light .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #1e293b;
  --mdc-filled-text-field-focus-active-indicator-color: #1e293b;
  --mdc-filled-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mdc-outlined-text-field-caret-color: #1e293b;
  --mdc-outlined-text-field-focus-outline-color: #1e293b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(30, 41, 59, 0.87);
}
.theme-rose .light .mat-mdc-form-field.mat-warn,
.theme-rose.light .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #dc2626;
  --mdc-filled-text-field-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mdc-outlined-text-field-caret-color: #dc2626;
  --mdc-outlined-text-field-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-rose .light .mat-mdc-form-field.mat-accent,
.theme-rose.light .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(30, 41, 59, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(148, 163, 184, 0.38);
  --mat-select-placeholder-text-color: rgba(30, 41, 59, 0.6);
  --mat-select-enabled-arrow-color: rgba(226, 232, 240, 0.54);
  --mat-select-disabled-arrow-color: rgba(30, 41, 59, 0.38);
  --mat-select-focused-arrow-color: rgba(30, 41, 59, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-rose .light .mat-mdc-form-field.mat-warn,
.theme-rose.light .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(30, 41, 59, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(148, 163, 184, 0.38);
  --mat-select-placeholder-text-color: rgba(30, 41, 59, 0.6);
  --mat-select-enabled-arrow-color: rgba(226, 232, 240, 0.54);
  --mat-select-disabled-arrow-color: rgba(30, 41, 59, 0.38);
  --mat-select-focused-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-rose .light .mat-mdc-standard-chip,
.theme-rose.light .mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.theme-rose .light .mat-mdc-standard-chip,
.theme-rose.light .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.theme-rose .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .theme-rose .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.theme-rose.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.theme-rose.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: #4c0519;
  --mdc-chip-elevated-container-color: #f43f5e;
  --mdc-chip-elevated-selected-container-color: #f43f5e;
  --mdc-chip-elevated-disabled-container-color: #f43f5e;
  --mdc-chip-flat-disabled-selected-container-color: #f43f5e;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #4c0519;
  --mdc-chip-selected-label-text-color: #4c0519;
  --mdc-chip-with-icon-icon-color: #4c0519;
  --mdc-chip-with-icon-disabled-icon-color: #4c0519;
  --mdc-chip-with-icon-selected-icon-color: #4c0519;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #4c0519;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #4c0519;
  --mat-chip-selected-disabled-trailing-icon-color: #4c0519;
  --mat-chip-selected-trailing-icon-color: #4c0519;
}
.theme-rose .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .theme-rose .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.theme-rose.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.theme-rose.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #1e293b;
  --mdc-chip-elevated-selected-container-color: #1e293b;
  --mdc-chip-elevated-disabled-container-color: #1e293b;
  --mdc-chip-flat-disabled-selected-container-color: #1e293b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-rose .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .theme-rose .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.theme-rose.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.theme-rose.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-selected-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-flat-disabled-selected-container-color: #dc2626;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-rose .light .mat-mdc-slide-toggle,
.theme-rose.light .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: #1e293b;
}
.theme-rose .light .mat-mdc-slide-toggle.mat-accent,
.theme-rose.light .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #475569;
  --mdc-switch-selected-handle-color: #475569;
  --mdc-switch-selected-hover-state-layer-color: #475569;
  --mdc-switch-selected-pressed-state-layer-color: #475569;
  --mdc-switch-selected-focus-handle-color: #0f172a;
  --mdc-switch-selected-hover-handle-color: #0f172a;
  --mdc-switch-selected-pressed-handle-color: #0f172a;
  --mdc-switch-selected-focus-track-color: #cbd5e1;
  --mdc-switch-selected-hover-track-color: #cbd5e1;
  --mdc-switch-selected-pressed-track-color: #cbd5e1;
  --mdc-switch-selected-track-color: #cbd5e1;
}
.theme-rose .light .mat-mdc-slide-toggle.mat-warn,
.theme-rose.light .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.theme-rose .light .mat-mdc-radio-button,
.theme-rose.light .mat-mdc-radio-button {
  --mdc-form-field-label-text-color: #1e293b;
}
.theme-rose .light .mat-mdc-radio-button.mat-primary,
.theme-rose.light .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #f43f5e;
  --mdc-radio-selected-hover-icon-color: #f43f5e;
  --mdc-radio-selected-icon-color: #f43f5e;
  --mdc-radio-selected-pressed-icon-color: #f43f5e;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #f43f5e;
  --mat-radio-disabled-label-color: #94a3b8;
}
.theme-rose .light .mat-mdc-radio-button.mat-accent,
.theme-rose.light .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #1e293b;
  --mat-radio-disabled-label-color: #94a3b8;
}
.theme-rose .light .mat-mdc-radio-button.mat-warn,
.theme-rose.light .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #dc2626;
  --mat-radio-disabled-label-color: #94a3b8;
}
.theme-rose .light .mat-accent,
.theme-rose.light .mat-accent {
  --mat-slider-ripple-color: #1e293b;
  --mat-slider-hover-state-layer-color: rgba(30, 41, 59, 0.05);
  --mat-slider-focus-state-layer-color: rgba(30, 41, 59, 0.2);
  --mdc-slider-handle-color: #1e293b;
  --mdc-slider-focus-handle-color: #1e293b;
  --mdc-slider-hover-handle-color: #1e293b;
  --mdc-slider-active-track-color: #1e293b;
  --mdc-slider-inactive-track-color: #1e293b;
  --mdc-slider-with-tick-marks-inactive-container-color: #1e293b;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-rose .light .mat-warn,
.theme-rose.light .mat-warn {
  --mat-slider-ripple-color: #dc2626;
  --mat-slider-hover-state-layer-color: rgba(220, 38, 38, 0.05);
  --mat-slider-focus-state-layer-color: rgba(220, 38, 38, 0.2);
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-rose .light .mdc-list-item__start,
.theme-rose .light .mdc-list-item__end,
.theme-rose.light .mdc-list-item__start,
.theme-rose.light .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #f43f5e;
  --mdc-radio-selected-hover-icon-color: #f43f5e;
  --mdc-radio-selected-icon-color: #f43f5e;
  --mdc-radio-selected-pressed-icon-color: #f43f5e;
}
.theme-rose .light .mat-accent .mdc-list-item__start,
.theme-rose .light .mat-accent .mdc-list-item__end,
.theme-rose.light .mat-accent .mdc-list-item__start,
.theme-rose.light .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
}
.theme-rose .light .mat-warn .mdc-list-item__start,
.theme-rose .light .mat-warn .mdc-list-item__end,
.theme-rose.light .mat-warn .mdc-list-item__start,
.theme-rose.light .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
}
.theme-rose .light .mat-mdc-list-option,
.theme-rose.light .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #4c0519;
  --mdc-checkbox-selected-focus-icon-color: #f43f5e;
  --mdc-checkbox-selected-hover-icon-color: #f43f5e;
  --mdc-checkbox-selected-icon-color: #f43f5e;
  --mdc-checkbox-selected-pressed-icon-color: #f43f5e;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f43f5e;
  --mdc-checkbox-selected-hover-state-layer-color: #f43f5e;
  --mdc-checkbox-selected-pressed-state-layer-color: #f43f5e;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-rose .light .mat-mdc-list-option.mat-accent,
.theme-rose.light .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-rose .light .mat-mdc-list-option.mat-warn,
.theme-rose.light .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-rose .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-rose .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-rose.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-rose.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #f43f5e;
}
.theme-rose .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #f43f5e;
}
.theme-rose .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-rose .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-rose .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.theme-rose.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-rose.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-rose.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.theme-rose .light .mat-mdc-tab-group, .theme-rose .light .mat-mdc-tab-nav-bar,
.theme-rose.light .mat-mdc-tab-group,
.theme-rose.light .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #f43f5e;
  --mat-tab-header-disabled-ripple-color: #94a3b8;
  --mat-tab-header-pagination-icon-color: #64748b;
  --mat-tab-header-inactive-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-label-text-color: #f43f5e;
  --mat-tab-header-active-ripple-color: #f43f5e;
  --mat-tab-header-inactive-ripple-color: #f43f5e;
  --mat-tab-header-inactive-focus-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f43f5e;
  --mat-tab-header-active-hover-label-text-color: #f43f5e;
  --mat-tab-header-active-focus-indicator-color: #f43f5e;
  --mat-tab-header-active-hover-indicator-color: #f43f5e;
}
.theme-rose .light .mat-mdc-tab-group.mat-accent, .theme-rose .light .mat-mdc-tab-nav-bar.mat-accent,
.theme-rose.light .mat-mdc-tab-group.mat-accent,
.theme-rose.light .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #1e293b;
  --mat-tab-header-disabled-ripple-color: #94a3b8;
  --mat-tab-header-pagination-icon-color: #64748b;
  --mat-tab-header-inactive-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-label-text-color: #1e293b;
  --mat-tab-header-active-ripple-color: #1e293b;
  --mat-tab-header-inactive-ripple-color: #1e293b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-focus-label-text-color: #1e293b;
  --mat-tab-header-active-hover-label-text-color: #1e293b;
  --mat-tab-header-active-focus-indicator-color: #1e293b;
  --mat-tab-header-active-hover-indicator-color: #1e293b;
}
.theme-rose .light .mat-mdc-tab-group.mat-warn, .theme-rose .light .mat-mdc-tab-nav-bar.mat-warn,
.theme-rose.light .mat-mdc-tab-group.mat-warn,
.theme-rose.light .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #dc2626;
  --mat-tab-header-disabled-ripple-color: #94a3b8;
  --mat-tab-header-pagination-icon-color: #64748b;
  --mat-tab-header-inactive-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-label-text-color: #dc2626;
  --mat-tab-header-active-ripple-color: #dc2626;
  --mat-tab-header-inactive-ripple-color: #dc2626;
  --mat-tab-header-inactive-focus-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-focus-label-text-color: #dc2626;
  --mat-tab-header-active-hover-label-text-color: #dc2626;
  --mat-tab-header-active-focus-indicator-color: #dc2626;
  --mat-tab-header-active-hover-indicator-color: #dc2626;
}
.theme-rose .light .mat-mdc-tab-group.mat-background-primary, .theme-rose .light .mat-mdc-tab-nav-bar.mat-background-primary,
.theme-rose.light .mat-mdc-tab-group.mat-background-primary,
.theme-rose.light .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #f43f5e;
  --mat-tab-header-with-background-foreground-color: #4c0519;
}
.theme-rose .light .mat-mdc-tab-group.mat-background-accent, .theme-rose .light .mat-mdc-tab-nav-bar.mat-background-accent,
.theme-rose.light .mat-mdc-tab-group.mat-background-accent,
.theme-rose.light .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #1e293b;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-rose .light .mat-mdc-tab-group.mat-background-warn, .theme-rose .light .mat-mdc-tab-nav-bar.mat-background-warn,
.theme-rose.light .mat-mdc-tab-group.mat-background-warn,
.theme-rose.light .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #dc2626;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-rose .light .mat-mdc-checkbox,
.theme-rose.light .mat-mdc-checkbox {
  --mdc-form-field-label-text-color: #1e293b;
}
.theme-rose .light .mat-mdc-checkbox.mat-primary,
.theme-rose.light .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #4c0519;
  --mdc-checkbox-selected-focus-icon-color: #f43f5e;
  --mdc-checkbox-selected-hover-icon-color: #f43f5e;
  --mdc-checkbox-selected-icon-color: #f43f5e;
  --mdc-checkbox-selected-pressed-icon-color: #f43f5e;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f43f5e;
  --mdc-checkbox-selected-hover-state-layer-color: #f43f5e;
  --mdc-checkbox-selected-pressed-state-layer-color: #f43f5e;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-rose .light .mat-mdc-checkbox.mat-warn,
.theme-rose.light .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-rose .light .mat-mdc-button.mat-primary,
.theme-rose.light .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #f43f5e;
  --mat-text-button-state-layer-color: #f43f5e;
  --mat-text-button-ripple-color: rgba(244, 63, 94, 0.1);
}
.theme-rose .light .mat-mdc-button.mat-accent,
.theme-rose.light .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1e293b;
  --mat-text-button-state-layer-color: #1e293b;
  --mat-text-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-rose .light .mat-mdc-button.mat-warn,
.theme-rose.light .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc2626;
  --mat-text-button-state-layer-color: #dc2626;
  --mat-text-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-rose .light .mat-mdc-unelevated-button.mat-primary,
.theme-rose.light .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #f43f5e;
  --mdc-filled-button-label-text-color: #4c0519;
  --mat-filled-button-state-layer-color: #4c0519;
  --mat-filled-button-ripple-color: rgba(76, 5, 25, 0.1);
}
.theme-rose .light .mat-mdc-unelevated-button.mat-accent,
.theme-rose.light .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-rose .light .mat-mdc-unelevated-button.mat-warn,
.theme-rose.light .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-rose .light .mat-mdc-raised-button.mat-primary,
.theme-rose.light .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #f43f5e;
  --mdc-protected-button-label-text-color: #4c0519;
  --mat-protected-button-state-layer-color: #4c0519;
  --mat-protected-button-ripple-color: rgba(76, 5, 25, 0.1);
}
.theme-rose .light .mat-mdc-raised-button.mat-accent,
.theme-rose.light .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-rose .light .mat-mdc-raised-button.mat-warn,
.theme-rose.light .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-rose .light .mat-mdc-outlined-button.mat-primary,
.theme-rose.light .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #f43f5e;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: #f43f5e;
  --mat-outlined-button-ripple-color: rgba(244, 63, 94, 0.1);
}
.theme-rose .light .mat-mdc-outlined-button.mat-accent,
.theme-rose.light .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1e293b;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: #1e293b;
  --mat-outlined-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-rose .light .mat-mdc-outlined-button.mat-warn,
.theme-rose.light .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc2626;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: #dc2626;
  --mat-outlined-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-rose .light .mat-mdc-icon-button.mat-primary,
.theme-rose.light .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #f43f5e;
  --mat-icon-button-state-layer-color: #f43f5e;
  --mat-icon-button-ripple-color: rgba(244, 63, 94, 0.1);
}
.theme-rose .light .mat-mdc-icon-button.mat-accent,
.theme-rose.light .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1e293b;
  --mat-icon-button-state-layer-color: #1e293b;
  --mat-icon-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-rose .light .mat-mdc-icon-button.mat-warn,
.theme-rose.light .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc2626;
  --mat-icon-button-state-layer-color: #dc2626;
  --mat-icon-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-rose .light .mat-mdc-fab.mat-primary,
.theme-rose.light .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #f43f5e;
  --mat-fab-foreground-color: #4c0519;
  --mat-fab-state-layer-color: #4c0519;
  --mat-fab-ripple-color: rgba(76, 5, 25, 0.1);
}
.theme-rose .light .mat-mdc-fab.mat-accent,
.theme-rose.light .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #1e293b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-rose .light .mat-mdc-fab.mat-warn,
.theme-rose.light .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #dc2626;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-rose .light .mat-mdc-mini-fab.mat-primary,
.theme-rose.light .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #f43f5e;
  --mat-fab-small-foreground-color: #4c0519;
  --mat-fab-small-state-layer-color: #4c0519;
  --mat-fab-small-ripple-color: rgba(76, 5, 25, 0.1);
}
.theme-rose .light .mat-mdc-mini-fab.mat-accent,
.theme-rose.light .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #1e293b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-rose .light .mat-mdc-mini-fab.mat-warn,
.theme-rose.light .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #dc2626;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-rose .light .mat-accent,
.theme-rose.light .mat-accent {
  --mdc-circular-progress-active-indicator-color: #1e293b;
}
.theme-rose .light .mat-warn,
.theme-rose.light .mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}
.theme-rose .light .mat-badge-accent,
.theme-rose.light .mat-badge-accent {
  --mat-badge-background-color: #1e293b;
  --mat-badge-text-color: white;
}
.theme-rose .light .mat-badge-warn,
.theme-rose.light .mat-badge-warn {
  --mat-badge-background-color: #dc2626;
  --mat-badge-text-color: white;
}
.theme-rose .light .mat-datepicker-content.mat-accent,
.theme-rose.light .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #1e293b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(30, 41, 59, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(30, 41, 59, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-rose .light .mat-datepicker-content.mat-warn,
.theme-rose.light .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #dc2626;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(220, 38, 38, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(220, 38, 38, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-rose .light .mat-datepicker-toggle-active.mat-accent,
.theme-rose.light .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #1e293b;
}
.theme-rose .light .mat-datepicker-toggle-active.mat-warn,
.theme-rose.light .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #dc2626;
}
.theme-rose .light .mat-icon.mat-primary,
.theme-rose.light .mat-icon.mat-primary {
  --mat-icon-color: #f43f5e;
}
.theme-rose .light .mat-icon.mat-accent,
.theme-rose.light .mat-icon.mat-accent {
  --mat-icon-color: #1e293b;
}
.theme-rose .light .mat-icon.mat-warn,
.theme-rose.light .mat-icon.mat-warn {
  --mat-icon-color: #dc2626;
}
.theme-rose .light .mat-step-header.mat-accent,
.theme-rose.light .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #1e293b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #1e293b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #1e293b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-rose .light .mat-step-header.mat-warn,
.theme-rose.light .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #dc2626;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #dc2626;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #dc2626;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-rose .light .mat-toolbar.mat-primary,
.theme-rose.light .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #f43f5e;
  --mat-toolbar-container-text-color: #4c0519;
}
.theme-rose .light .mat-toolbar.mat-accent,
.theme-rose.light .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #1e293b;
  --mat-toolbar-container-text-color: white;
}
.theme-rose .light .mat-toolbar.mat-warn,
.theme-rose.light .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #dc2626;
  --mat-toolbar-container-text-color: white;
}

.theme-rose .dark,
.theme-rose.dark {
  --mat-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-option-selected-state-label-text-color: #f43f5e;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-optgroup-label-text-color: white;
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
  --mat-app-background-color: #0f172a;
  --mat-app-text-color: white;
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: #1e293b;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: #1e293b;
  --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: #94a3b8;
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #64748b;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #f43f5e;
  --mdc-filled-text-field-focus-active-indicator-color: #f43f5e;
  --mdc-filled-text-field-focus-label-text-color: rgba(244, 63, 94, 0.87);
  --mdc-filled-text-field-container-color: #273243;
  --mdc-filled-text-field-disabled-container-color: #232d3f;
  --mdc-filled-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(71, 85, 105, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(71, 85, 105, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #dc2626;
  --mdc-filled-text-field-error-focus-label-text-color: #dc2626;
  --mdc-filled-text-field-error-label-text-color: #dc2626;
  --mdc-filled-text-field-error-caret-color: #dc2626;
  --mdc-filled-text-field-active-indicator-color: rgba(241, 245, 249, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(241, 245, 249, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(241, 245, 249, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-hover-active-indicator-color: #dc2626;
  --mdc-outlined-text-field-caret-color: #f43f5e;
  --mdc-outlined-text-field-focus-outline-color: #f43f5e;
  --mdc-outlined-text-field-focus-label-text-color: rgba(244, 63, 94, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(71, 85, 105, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(71, 85, 105, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-error-caret-color: #dc2626;
  --mdc-outlined-text-field-error-focus-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-hover-label-text-color: #dc2626;
  --mdc-outlined-text-field-outline-color: rgba(241, 245, 249, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(241, 245, 249, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(241, 245, 249, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-error-hover-outline-color: #dc2626;
  --mdc-outlined-text-field-error-outline-color: #dc2626;
  --mat-form-field-focus-select-arrow-color: rgba(244, 63, 94, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(241, 245, 249, 0.38);
  --mat-form-field-state-layer-color: rgba(255, 255, 255, 0.87);
  --mat-form-field-error-text-color: #dc2626;
  --mat-form-field-select-option-text-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-select-disabled-option-text-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(241, 245, 249, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.08;
  --mat-form-field-focus-state-layer-opacity: 0.24;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: #1e293b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(71, 85, 105, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(244, 63, 94, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: #1e293b;
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: #1e293b;
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
  --mdc-switch-selected-focus-state-layer-color: #fda4af;
  --mdc-switch-selected-handle-color: #fda4af;
  --mdc-switch-selected-hover-state-layer-color: #fda4af;
  --mdc-switch-selected-pressed-state-layer-color: #fda4af;
  --mdc-switch-selected-focus-handle-color: #fecdd3;
  --mdc-switch-selected-hover-handle-color: #fecdd3;
  --mdc-switch-selected-pressed-handle-color: #fecdd3;
  --mdc-switch-selected-focus-track-color: #e11d48;
  --mdc-switch-selected-hover-track-color: #e11d48;
  --mdc-switch-selected-pressed-track-color: #e11d48;
  --mdc-switch-selected-track-color: #e11d48;
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-icon-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-disabled-label-text-color: #475569;
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-color: #f43f5e;
  --mdc-slider-focus-handle-color: #f43f5e;
  --mdc-slider-hover-handle-color: #f43f5e;
  --mdc-slider-active-track-color: #f43f5e;
  --mdc-slider-inactive-track-color: #f43f5e;
  --mdc-slider-with-tick-marks-inactive-container-color: #f43f5e;
  --mdc-slider-with-tick-marks-active-container-color: #4c0519;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-label-container-color: #fff;
  --mdc-slider-label-label-text-color: #000;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: #f43f5e;
  --mat-slider-hover-state-layer-color: rgba(244, 63, 94, 0.05);
  --mat-slider-focus-state-layer-color: rgba(244, 63, 94, 0.2);
  --mat-slider-value-indicator-opacity: 0.9;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-item-label-text-color: white;
  --mat-menu-item-icon-color: white;
  --mat-menu-item-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-container-color: #1e293b;
  --mat-menu-divider-color: rgba(241, 245, 249, 0.12);
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 0;
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: #94a3b8;
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: #64748b;
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: white;
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
  --mat-paginator-container-text-color: rgba(255, 255, 255, 0.87);
  --mat-paginator-container-background-color: #1e293b;
  --mat-paginator-enabled-icon-color: rgba(255, 255, 255, 0.54);
  --mat-paginator-disabled-icon-color: rgba(255, 255, 255, 0.12);
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
  --mat-checkbox-disabled-label-color: #475569;
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: white;
  --mdc-text-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mat-text-button-state-layer-color: white;
  --mat-text-button-disabled-state-layer-color: white;
  --mat-text-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.08;
  --mat-text-button-focus-state-layer-opacity: 0.24;
  --mat-text-button-pressed-state-layer-opacity: 0.24;
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: white;
  --mdc-filled-button-disabled-container-color: rgba(30, 41, 59, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-disabled-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.08;
  --mat-filled-button-focus-state-layer-opacity: 0.24;
  --mat-filled-button-pressed-state-layer-opacity: 0.24;
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: white;
  --mdc-protected-button-disabled-container-color: rgba(30, 41, 59, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-disabled-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.08;
  --mat-protected-button-focus-state-layer-opacity: 0.24;
  --mat-protected-button-pressed-state-layer-opacity: 0.24;
  --mdc-outlined-button-disabled-outline-color: rgba(241, 245, 249, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mdc-outlined-button-label-text-color: white;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: white;
  --mat-outlined-button-disabled-state-layer-color: white;
  --mat-outlined-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.08;
  --mat-outlined-button-focus-state-layer-opacity: 0.24;
  --mat-outlined-button-pressed-state-layer-opacity: 0.24;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
  --mat-icon-button-state-layer-color: white;
  --mat-icon-button-disabled-state-layer-color: white;
  --mat-icon-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.08;
  --mat-icon-button-focus-state-layer-opacity: 0.24;
  --mat-icon-button-pressed-state-layer-opacity: 0.24;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-fab-container-color: #1e293b;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-disabled-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.08;
  --mat-fab-focus-state-layer-opacity: 0.24;
  --mat-fab-pressed-state-layer-opacity: 0.24;
  --mat-fab-disabled-state-container-color: rgba(15, 23, 42, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(30, 41, 59, 0.5);
  --mdc-fab-small-container-color: #1e293b;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-disabled-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.08;
  --mat-fab-small-focus-state-layer-opacity: 0.24;
  --mat-fab-small-pressed-state-layer-opacity: 0.24;
  --mat-fab-small-disabled-state-container-color: rgba(15, 23, 42, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(30, 41, 59, 0.5);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #d2d4d8;
  --mdc-snackbar-supporting-text-color: rgba(30, 41, 59, 0.87);
  --mat-snack-bar-button-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: #1e293b;
  --mat-table-header-headline-color: white;
  --mat-table-row-item-label-text-color: white;
  --mat-table-row-item-outline-color: rgba(241, 245, 249, 0.12);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #f43f5e;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #f43f5e;
  --mat-badge-text-color: #4c0519;
  --mat-badge-disabled-state-background-color: #1e293b;
  --mat-badge-disabled-state-text-color: #475569;
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: white;
  --mat-bottom-sheet-container-background-color: #1e293b;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: #64748b;
  --mat-legacy-button-toggle-state-layer-color: #e2e8f0;
  --mat-legacy-button-toggle-selected-state-text-color: #94a3b8;
  --mat-legacy-button-toggle-selected-state-background-color: rgba(255, 255, 255, 0.05);
  --mat-legacy-button-toggle-disabled-state-text-color: #1e293b;
  --mat-legacy-button-toggle-disabled-state-background-color: #0f172a;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #1e293b;
  --mat-standard-button-toggle-text-color: white;
  --mat-standard-button-toggle-background-color: #1e293b;
  --mat-standard-button-toggle-state-layer-color: #e2e8f0;
  --mat-standard-button-toggle-selected-state-background-color: rgba(255, 255, 255, 0.05);
  --mat-standard-button-toggle-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-state-text-color: #1e293b;
  --mat-standard-button-toggle-disabled-state-background-color: #1e293b;
  --mat-standard-button-toggle-disabled-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #1e293b;
  --mat-standard-button-toggle-divider-color: #374152;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: #4c0519;
  --mat-datepicker-calendar-date-selected-state-background-color: #f43f5e;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 63, 94, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #4c0519;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 63, 94, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 63, 94, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #f43f5e;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 63, 94, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: #f1f5f9;
  --mat-datepicker-calendar-body-label-text-color: #94a3b8;
  --mat-datepicker-calendar-period-button-text-color: white;
  --mat-datepicker-calendar-period-button-icon-color: #f1f5f9;
  --mat-datepicker-calendar-navigation-button-icon-color: #f1f5f9;
  --mat-datepicker-calendar-header-divider-color: rgba(241, 245, 249, 0.12);
  --mat-datepicker-calendar-header-text-color: #94a3b8;
  --mat-datepicker-calendar-date-today-outline-color: #64748b;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(100, 116, 139, 0.8);
  --mat-datepicker-calendar-date-text-color: white;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: #475569;
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(241, 245, 249, 0.24);
  --mat-datepicker-range-input-separator-color: white;
  --mat-datepicker-range-input-disabled-state-separator-color: #475569;
  --mat-datepicker-range-input-disabled-state-text-color: #475569;
  --mat-datepicker-calendar-container-background-color: #1e293b;
  --mat-datepicker-calendar-container-text-color: white;
  --mat-divider-width: 1px;
  --mat-divider-color: rgba(241, 245, 249, 0.12);
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: #1e293b;
  --mat-expansion-container-text-color: white;
  --mat-expansion-actions-divider-color: rgba(241, 245, 249, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-expansion-header-focus-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-expansion-header-disabled-state-text-color: #1e293b;
  --mat-expansion-header-text-color: white;
  --mat-expansion-header-description-color: #94a3b8;
  --mat-expansion-header-indicator-color: #94a3b8;
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: rgba(241, 245, 249, 0.12);
  --mat-sidenav-container-background-color: #1e293b;
  --mat-sidenav-container-text-color: white;
  --mat-sidenav-content-background-color: #0f172a;
  --mat-sidenav-content-text-color: white;
  --mat-sidenav-scrim-color: rgba(225, 214, 196, 0.6);
  --mat-stepper-header-icon-foreground-color: #4c0519;
  --mat-stepper-header-selected-state-icon-background-color: #f43f5e;
  --mat-stepper-header-selected-state-icon-foreground-color: #4c0519;
  --mat-stepper-header-done-state-icon-background-color: #f43f5e;
  --mat-stepper-header-done-state-icon-foreground-color: #4c0519;
  --mat-stepper-header-edit-state-icon-background-color: #f43f5e;
  --mat-stepper-header-edit-state-icon-foreground-color: #4c0519;
  --mat-stepper-container-color: #1e293b;
  --mat-stepper-line-color: rgba(241, 245, 249, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-stepper-header-focus-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-stepper-header-label-text-color: #94a3b8;
  --mat-stepper-header-optional-label-text-color: #94a3b8;
  --mat-stepper-header-selected-state-label-text-color: white;
  --mat-stepper-header-error-state-label-text-color: #dc2626;
  --mat-stepper-header-icon-background-color: #94a3b8;
  --mat-stepper-header-error-state-icon-foreground-color: #dc2626;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #94a3b8;
  --mat-toolbar-container-background-color: #0f172a;
  --mat-toolbar-container-text-color: white;
  --mat-tree-container-background-color: #1e293b;
  --mat-tree-node-text-color: white;
}
.theme-rose .dark .mat-accent,
.theme-rose.dark .mat-accent {
  --mat-option-selected-state-label-text-color: #1e293b;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.theme-rose .dark .mat-warn,
.theme-rose.dark .mat-warn {
  --mat-option-selected-state-label-text-color: #dc2626;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.theme-rose .dark .mat-primary,
.theme-rose.dark .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #f43f5e;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f43f5e;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-rose .dark .mat-accent,
.theme-rose.dark .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-rose .dark .mat-warn,
.theme-rose.dark .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #dc2626;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #dc2626;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-rose .dark .mat-elevation-z0, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z0,
.theme-rose.dark .mat-elevation-z0,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z1, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z1,
.theme-rose.dark .mat-elevation-z1,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z2, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z2,
.theme-rose.dark .mat-elevation-z2,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z3, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z3,
.theme-rose.dark .mat-elevation-z3,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z4, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z4,
.theme-rose.dark .mat-elevation-z4,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z5, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z5,
.theme-rose.dark .mat-elevation-z5,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z6, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z6,
.theme-rose.dark .mat-elevation-z6,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z7, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z7,
.theme-rose.dark .mat-elevation-z7,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z8, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z8,
.theme-rose.dark .mat-elevation-z8,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z9, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z9,
.theme-rose.dark .mat-elevation-z9,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z10, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z10,
.theme-rose.dark .mat-elevation-z10,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z11, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z11,
.theme-rose.dark .mat-elevation-z11,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z12, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z12,
.theme-rose.dark .mat-elevation-z12,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z13, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z13,
.theme-rose.dark .mat-elevation-z13,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z14, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z14,
.theme-rose.dark .mat-elevation-z14,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z15, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z15,
.theme-rose.dark .mat-elevation-z15,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z16, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z16,
.theme-rose.dark .mat-elevation-z16,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z17, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z17,
.theme-rose.dark .mat-elevation-z17,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z18, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z18,
.theme-rose.dark .mat-elevation-z18,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z19, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z19,
.theme-rose.dark .mat-elevation-z19,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z20, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z20,
.theme-rose.dark .mat-elevation-z20,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z21, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z21,
.theme-rose.dark .mat-elevation-z21,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z22, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z22,
.theme-rose.dark .mat-elevation-z22,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z23, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z23,
.theme-rose.dark .mat-elevation-z23,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-rose .dark .mat-elevation-z24, .theme-rose .dark .mat-mdc-elevation-specific.mat-elevation-z24,
.theme-rose.dark .mat-elevation-z24,
.theme-rose.dark .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-rose .dark .mat-mdc-progress-bar,
.theme-rose.dark .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #f43f5e;
  --mdc-linear-progress-track-color: rgba(244, 63, 94, 0.25);
}
.theme-rose .dark .mat-mdc-progress-bar.mat-accent,
.theme-rose.dark .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1e293b;
  --mdc-linear-progress-track-color: rgba(30, 41, 59, 0.25);
}
.theme-rose .dark .mat-mdc-progress-bar.mat-warn,
.theme-rose.dark .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc2626;
  --mdc-linear-progress-track-color: rgba(220, 38, 38, 0.25);
}
.theme-rose .dark .mat-mdc-form-field.mat-accent,
.theme-rose.dark .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #1e293b;
  --mdc-filled-text-field-focus-active-indicator-color: #1e293b;
  --mdc-filled-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mdc-outlined-text-field-caret-color: #1e293b;
  --mdc-outlined-text-field-focus-outline-color: #1e293b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(30, 41, 59, 0.87);
}
.theme-rose .dark .mat-mdc-form-field.mat-warn,
.theme-rose.dark .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #dc2626;
  --mdc-filled-text-field-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mdc-outlined-text-field-caret-color: #dc2626;
  --mdc-outlined-text-field-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-rose .dark .mat-mdc-form-field.mat-accent,
.theme-rose.dark .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: #1e293b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(71, 85, 105, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(30, 41, 59, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-rose .dark .mat-mdc-form-field.mat-warn,
.theme-rose.dark .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: #1e293b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(71, 85, 105, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-rose .dark .mat-mdc-standard-chip,
.theme-rose.dark .mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.theme-rose .dark .mat-mdc-standard-chip,
.theme-rose.dark .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-elevated-container-color: #394353;
  --mdc-chip-elevated-selected-container-color: #394353;
  --mdc-chip-elevated-disabled-container-color: #394353;
  --mdc-chip-flat-disabled-selected-container-color: #394353;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-selected-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
  --mat-chip-selected-disabled-trailing-icon-color: #fafafa;
  --mat-chip-selected-trailing-icon-color: #fafafa;
}
.theme-rose .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .theme-rose .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.theme-rose.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.theme-rose.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: #4c0519;
  --mdc-chip-elevated-container-color: #f43f5e;
  --mdc-chip-elevated-selected-container-color: #f43f5e;
  --mdc-chip-elevated-disabled-container-color: #f43f5e;
  --mdc-chip-flat-disabled-selected-container-color: #f43f5e;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #4c0519;
  --mdc-chip-selected-label-text-color: #4c0519;
  --mdc-chip-with-icon-icon-color: #4c0519;
  --mdc-chip-with-icon-disabled-icon-color: #4c0519;
  --mdc-chip-with-icon-selected-icon-color: #4c0519;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #4c0519;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #4c0519;
  --mat-chip-selected-disabled-trailing-icon-color: #4c0519;
  --mat-chip-selected-trailing-icon-color: #4c0519;
}
.theme-rose .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .theme-rose .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.theme-rose.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.theme-rose.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #1e293b;
  --mdc-chip-elevated-selected-container-color: #1e293b;
  --mdc-chip-elevated-disabled-container-color: #1e293b;
  --mdc-chip-flat-disabled-selected-container-color: #1e293b;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-rose .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .theme-rose .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.theme-rose.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.theme-rose.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-selected-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-flat-disabled-selected-container-color: #dc2626;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-rose .dark .mat-mdc-slide-toggle,
.theme-rose.dark .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: white;
}
.theme-rose .dark .mat-mdc-slide-toggle.mat-accent,
.theme-rose.dark .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #cbd5e1;
  --mdc-switch-selected-handle-color: #cbd5e1;
  --mdc-switch-selected-hover-state-layer-color: #cbd5e1;
  --mdc-switch-selected-pressed-state-layer-color: #cbd5e1;
  --mdc-switch-selected-focus-handle-color: #e2e8f0;
  --mdc-switch-selected-hover-handle-color: #e2e8f0;
  --mdc-switch-selected-pressed-handle-color: #e2e8f0;
  --mdc-switch-selected-focus-track-color: #475569;
  --mdc-switch-selected-hover-track-color: #475569;
  --mdc-switch-selected-pressed-track-color: #475569;
  --mdc-switch-selected-track-color: #475569;
}
.theme-rose .dark .mat-mdc-slide-toggle.mat-warn,
.theme-rose.dark .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #fca5a5;
  --mdc-switch-selected-handle-color: #fca5a5;
  --mdc-switch-selected-hover-state-layer-color: #fca5a5;
  --mdc-switch-selected-pressed-state-layer-color: #fca5a5;
  --mdc-switch-selected-focus-handle-color: #fecaca;
  --mdc-switch-selected-hover-handle-color: #fecaca;
  --mdc-switch-selected-pressed-handle-color: #fecaca;
  --mdc-switch-selected-focus-track-color: #dc2626;
  --mdc-switch-selected-hover-track-color: #dc2626;
  --mdc-switch-selected-pressed-track-color: #dc2626;
  --mdc-switch-selected-track-color: #dc2626;
}
.theme-rose .dark .mat-mdc-radio-button,
.theme-rose.dark .mat-mdc-radio-button {
  --mdc-form-field-label-text-color: white;
}
.theme-rose .dark .mat-mdc-radio-button.mat-primary,
.theme-rose.dark .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #f43f5e;
  --mdc-radio-selected-hover-icon-color: #f43f5e;
  --mdc-radio-selected-icon-color: #f43f5e;
  --mdc-radio-selected-pressed-icon-color: #f43f5e;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #f43f5e;
  --mat-radio-disabled-label-color: #475569;
}
.theme-rose .dark .mat-mdc-radio-button.mat-accent,
.theme-rose.dark .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #1e293b;
  --mat-radio-disabled-label-color: #475569;
}
.theme-rose .dark .mat-mdc-radio-button.mat-warn,
.theme-rose.dark .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #dc2626;
  --mat-radio-disabled-label-color: #475569;
}
.theme-rose .dark .mat-accent,
.theme-rose.dark .mat-accent {
  --mat-slider-ripple-color: #1e293b;
  --mat-slider-hover-state-layer-color: rgba(30, 41, 59, 0.05);
  --mat-slider-focus-state-layer-color: rgba(30, 41, 59, 0.2);
  --mdc-slider-handle-color: #1e293b;
  --mdc-slider-focus-handle-color: #1e293b;
  --mdc-slider-hover-handle-color: #1e293b;
  --mdc-slider-active-track-color: #1e293b;
  --mdc-slider-inactive-track-color: #1e293b;
  --mdc-slider-with-tick-marks-inactive-container-color: #1e293b;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-rose .dark .mat-warn,
.theme-rose.dark .mat-warn {
  --mat-slider-ripple-color: #dc2626;
  --mat-slider-hover-state-layer-color: rgba(220, 38, 38, 0.05);
  --mat-slider-focus-state-layer-color: rgba(220, 38, 38, 0.2);
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-rose .dark .mdc-list-item__start,
.theme-rose .dark .mdc-list-item__end,
.theme-rose.dark .mdc-list-item__start,
.theme-rose.dark .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #f43f5e;
  --mdc-radio-selected-hover-icon-color: #f43f5e;
  --mdc-radio-selected-icon-color: #f43f5e;
  --mdc-radio-selected-pressed-icon-color: #f43f5e;
}
.theme-rose .dark .mat-accent .mdc-list-item__start,
.theme-rose .dark .mat-accent .mdc-list-item__end,
.theme-rose.dark .mat-accent .mdc-list-item__start,
.theme-rose.dark .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
}
.theme-rose .dark .mat-warn .mdc-list-item__start,
.theme-rose .dark .mat-warn .mdc-list-item__end,
.theme-rose.dark .mat-warn .mdc-list-item__start,
.theme-rose.dark .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
}
.theme-rose .dark .mat-mdc-list-option,
.theme-rose.dark .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #4c0519;
  --mdc-checkbox-selected-focus-icon-color: #f43f5e;
  --mdc-checkbox-selected-hover-icon-color: #f43f5e;
  --mdc-checkbox-selected-icon-color: #f43f5e;
  --mdc-checkbox-selected-pressed-icon-color: #f43f5e;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f43f5e;
  --mdc-checkbox-selected-hover-state-layer-color: #f43f5e;
  --mdc-checkbox-selected-pressed-state-layer-color: #f43f5e;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-rose .dark .mat-mdc-list-option.mat-accent,
.theme-rose.dark .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-rose .dark .mat-mdc-list-option.mat-warn,
.theme-rose.dark .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-rose .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-rose .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-rose.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-rose.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #f43f5e;
}
.theme-rose .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-rose.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #f43f5e;
}
.theme-rose .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-rose .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-rose .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.theme-rose.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-rose.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-rose.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.theme-rose .dark .mat-mdc-tab-group, .theme-rose .dark .mat-mdc-tab-nav-bar,
.theme-rose.dark .mat-mdc-tab-group,
.theme-rose.dark .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #f43f5e;
  --mat-tab-header-disabled-ripple-color: #475569;
  --mat-tab-header-pagination-icon-color: #f1f5f9;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #f43f5e;
  --mat-tab-header-active-ripple-color: #f43f5e;
  --mat-tab-header-inactive-ripple-color: #f43f5e;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f43f5e;
  --mat-tab-header-active-hover-label-text-color: #f43f5e;
  --mat-tab-header-active-focus-indicator-color: #f43f5e;
  --mat-tab-header-active-hover-indicator-color: #f43f5e;
}
.theme-rose .dark .mat-mdc-tab-group.mat-accent, .theme-rose .dark .mat-mdc-tab-nav-bar.mat-accent,
.theme-rose.dark .mat-mdc-tab-group.mat-accent,
.theme-rose.dark .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #1e293b;
  --mat-tab-header-disabled-ripple-color: #475569;
  --mat-tab-header-pagination-icon-color: #f1f5f9;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #1e293b;
  --mat-tab-header-active-ripple-color: #1e293b;
  --mat-tab-header-inactive-ripple-color: #1e293b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #1e293b;
  --mat-tab-header-active-hover-label-text-color: #1e293b;
  --mat-tab-header-active-focus-indicator-color: #1e293b;
  --mat-tab-header-active-hover-indicator-color: #1e293b;
}
.theme-rose .dark .mat-mdc-tab-group.mat-warn, .theme-rose .dark .mat-mdc-tab-nav-bar.mat-warn,
.theme-rose.dark .mat-mdc-tab-group.mat-warn,
.theme-rose.dark .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #dc2626;
  --mat-tab-header-disabled-ripple-color: #475569;
  --mat-tab-header-pagination-icon-color: #f1f5f9;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #dc2626;
  --mat-tab-header-active-ripple-color: #dc2626;
  --mat-tab-header-inactive-ripple-color: #dc2626;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #dc2626;
  --mat-tab-header-active-hover-label-text-color: #dc2626;
  --mat-tab-header-active-focus-indicator-color: #dc2626;
  --mat-tab-header-active-hover-indicator-color: #dc2626;
}
.theme-rose .dark .mat-mdc-tab-group.mat-background-primary, .theme-rose .dark .mat-mdc-tab-nav-bar.mat-background-primary,
.theme-rose.dark .mat-mdc-tab-group.mat-background-primary,
.theme-rose.dark .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #f43f5e;
  --mat-tab-header-with-background-foreground-color: #4c0519;
}
.theme-rose .dark .mat-mdc-tab-group.mat-background-accent, .theme-rose .dark .mat-mdc-tab-nav-bar.mat-background-accent,
.theme-rose.dark .mat-mdc-tab-group.mat-background-accent,
.theme-rose.dark .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #1e293b;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-rose .dark .mat-mdc-tab-group.mat-background-warn, .theme-rose .dark .mat-mdc-tab-nav-bar.mat-background-warn,
.theme-rose.dark .mat-mdc-tab-group.mat-background-warn,
.theme-rose.dark .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #dc2626;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-rose .dark .mat-mdc-checkbox,
.theme-rose.dark .mat-mdc-checkbox {
  --mdc-form-field-label-text-color: white;
}
.theme-rose .dark .mat-mdc-checkbox.mat-primary,
.theme-rose.dark .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #4c0519;
  --mdc-checkbox-selected-focus-icon-color: #f43f5e;
  --mdc-checkbox-selected-hover-icon-color: #f43f5e;
  --mdc-checkbox-selected-icon-color: #f43f5e;
  --mdc-checkbox-selected-pressed-icon-color: #f43f5e;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f43f5e;
  --mdc-checkbox-selected-hover-state-layer-color: #f43f5e;
  --mdc-checkbox-selected-pressed-state-layer-color: #f43f5e;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-rose .dark .mat-mdc-checkbox.mat-warn,
.theme-rose.dark .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-rose .dark .mat-mdc-button.mat-primary,
.theme-rose.dark .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #f43f5e;
  --mat-text-button-state-layer-color: #f43f5e;
  --mat-text-button-ripple-color: rgba(244, 63, 94, 0.1);
}
.theme-rose .dark .mat-mdc-button.mat-accent,
.theme-rose.dark .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1e293b;
  --mat-text-button-state-layer-color: #1e293b;
  --mat-text-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-rose .dark .mat-mdc-button.mat-warn,
.theme-rose.dark .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc2626;
  --mat-text-button-state-layer-color: #dc2626;
  --mat-text-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-rose .dark .mat-mdc-unelevated-button.mat-primary,
.theme-rose.dark .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #f43f5e;
  --mdc-filled-button-label-text-color: #4c0519;
  --mat-filled-button-state-layer-color: #4c0519;
  --mat-filled-button-ripple-color: rgba(76, 5, 25, 0.1);
}
.theme-rose .dark .mat-mdc-unelevated-button.mat-accent,
.theme-rose.dark .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-rose .dark .mat-mdc-unelevated-button.mat-warn,
.theme-rose.dark .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-rose .dark .mat-mdc-raised-button.mat-primary,
.theme-rose.dark .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #f43f5e;
  --mdc-protected-button-label-text-color: #4c0519;
  --mat-protected-button-state-layer-color: #4c0519;
  --mat-protected-button-ripple-color: rgba(76, 5, 25, 0.1);
}
.theme-rose .dark .mat-mdc-raised-button.mat-accent,
.theme-rose.dark .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-rose .dark .mat-mdc-raised-button.mat-warn,
.theme-rose.dark .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-rose .dark .mat-mdc-outlined-button.mat-primary,
.theme-rose.dark .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #f43f5e;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: #f43f5e;
  --mat-outlined-button-ripple-color: rgba(244, 63, 94, 0.1);
}
.theme-rose .dark .mat-mdc-outlined-button.mat-accent,
.theme-rose.dark .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1e293b;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: #1e293b;
  --mat-outlined-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-rose .dark .mat-mdc-outlined-button.mat-warn,
.theme-rose.dark .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc2626;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: #dc2626;
  --mat-outlined-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-rose .dark .mat-mdc-icon-button.mat-primary,
.theme-rose.dark .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #f43f5e;
  --mat-icon-button-state-layer-color: #f43f5e;
  --mat-icon-button-ripple-color: rgba(244, 63, 94, 0.1);
}
.theme-rose .dark .mat-mdc-icon-button.mat-accent,
.theme-rose.dark .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1e293b;
  --mat-icon-button-state-layer-color: #1e293b;
  --mat-icon-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-rose .dark .mat-mdc-icon-button.mat-warn,
.theme-rose.dark .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc2626;
  --mat-icon-button-state-layer-color: #dc2626;
  --mat-icon-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-rose .dark .mat-mdc-fab.mat-primary,
.theme-rose.dark .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #f43f5e;
  --mat-fab-foreground-color: #4c0519;
  --mat-fab-state-layer-color: #4c0519;
  --mat-fab-ripple-color: rgba(76, 5, 25, 0.1);
}
.theme-rose .dark .mat-mdc-fab.mat-accent,
.theme-rose.dark .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #1e293b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-rose .dark .mat-mdc-fab.mat-warn,
.theme-rose.dark .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #dc2626;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-rose .dark .mat-mdc-mini-fab.mat-primary,
.theme-rose.dark .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #f43f5e;
  --mat-fab-small-foreground-color: #4c0519;
  --mat-fab-small-state-layer-color: #4c0519;
  --mat-fab-small-ripple-color: rgba(76, 5, 25, 0.1);
}
.theme-rose .dark .mat-mdc-mini-fab.mat-accent,
.theme-rose.dark .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #1e293b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-rose .dark .mat-mdc-mini-fab.mat-warn,
.theme-rose.dark .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #dc2626;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-rose .dark .mat-accent,
.theme-rose.dark .mat-accent {
  --mdc-circular-progress-active-indicator-color: #1e293b;
}
.theme-rose .dark .mat-warn,
.theme-rose.dark .mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}
.theme-rose .dark .mat-badge-accent,
.theme-rose.dark .mat-badge-accent {
  --mat-badge-background-color: #1e293b;
  --mat-badge-text-color: white;
}
.theme-rose .dark .mat-badge-warn,
.theme-rose.dark .mat-badge-warn {
  --mat-badge-background-color: #dc2626;
  --mat-badge-text-color: white;
}
.theme-rose .dark .mat-datepicker-content.mat-accent,
.theme-rose.dark .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #1e293b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(30, 41, 59, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(30, 41, 59, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-rose .dark .mat-datepicker-content.mat-warn,
.theme-rose.dark .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #dc2626;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(220, 38, 38, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(220, 38, 38, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-rose .dark .mat-datepicker-toggle-active.mat-accent,
.theme-rose.dark .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #1e293b;
}
.theme-rose .dark .mat-datepicker-toggle-active.mat-warn,
.theme-rose.dark .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #dc2626;
}
.theme-rose .dark .mat-icon.mat-primary,
.theme-rose.dark .mat-icon.mat-primary {
  --mat-icon-color: #f43f5e;
}
.theme-rose .dark .mat-icon.mat-accent,
.theme-rose.dark .mat-icon.mat-accent {
  --mat-icon-color: #1e293b;
}
.theme-rose .dark .mat-icon.mat-warn,
.theme-rose.dark .mat-icon.mat-warn {
  --mat-icon-color: #dc2626;
}
.theme-rose .dark .mat-step-header.mat-accent,
.theme-rose.dark .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #1e293b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #1e293b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #1e293b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-rose .dark .mat-step-header.mat-warn,
.theme-rose.dark .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #dc2626;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #dc2626;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #dc2626;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-rose .dark .mat-toolbar.mat-primary,
.theme-rose.dark .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #f43f5e;
  --mat-toolbar-container-text-color: #4c0519;
}
.theme-rose .dark .mat-toolbar.mat-accent,
.theme-rose.dark .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #1e293b;
  --mat-toolbar-container-text-color: white;
}
.theme-rose .dark .mat-toolbar.mat-warn,
.theme-rose.dark .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #dc2626;
  --mat-toolbar-container-text-color: white;
}

/* Generate the palettes */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define a light & dark Angular Material theme with the generated palettes */
/* Merge the custom base colors with the generated themes */
/* Generate and encapsulate Angular Material themes */
.theme-purple .light,
.theme-purple.light {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-option-selected-state-label-text-color: #9333ea;
  --mat-option-label-text-color: #1e293b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-optgroup-label-text-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
  --mat-app-background-color: #f1f5f9;
  --mat-app-text-color: #1e293b;
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: #64748b;
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #1e293b;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #9333ea;
  --mdc-filled-text-field-focus-active-indicator-color: #9333ea;
  --mdc-filled-text-field-focus-label-text-color: rgba(147, 51, 234, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(30, 41, 59, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(148, 163, 184, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(30, 41, 59, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #dc2626;
  --mdc-filled-text-field-error-focus-label-text-color: #dc2626;
  --mdc-filled-text-field-error-label-text-color: #dc2626;
  --mdc-filled-text-field-error-caret-color: #dc2626;
  --mdc-filled-text-field-active-indicator-color: rgba(226, 232, 240, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(226, 232, 240, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(226, 232, 240, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-hover-active-indicator-color: #dc2626;
  --mdc-outlined-text-field-caret-color: #9333ea;
  --mdc-outlined-text-field-focus-outline-color: #9333ea;
  --mdc-outlined-text-field-focus-label-text-color: rgba(147, 51, 234, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(30, 41, 59, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(148, 163, 184, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(30, 41, 59, 0.6);
  --mdc-outlined-text-field-error-caret-color: #dc2626;
  --mdc-outlined-text-field-error-focus-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-hover-label-text-color: #dc2626;
  --mdc-outlined-text-field-outline-color: rgba(226, 232, 240, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(226, 232, 240, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(226, 232, 240, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-error-hover-outline-color: #dc2626;
  --mdc-outlined-text-field-error-outline-color: #dc2626;
  --mat-form-field-focus-select-arrow-color: rgba(147, 51, 234, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(100, 116, 139, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #dc2626;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(100, 116, 139, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(100, 116, 139, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(30, 41, 59, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(148, 163, 184, 0.38);
  --mat-select-placeholder-text-color: rgba(30, 41, 59, 0.6);
  --mat-select-enabled-arrow-color: rgba(226, 232, 240, 0.54);
  --mat-select-disabled-arrow-color: rgba(30, 41, 59, 0.38);
  --mat-select-focused-arrow-color: rgba(147, 51, 234, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: white;
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(30, 41, 59, 0.87);
  --mdc-dialog-supporting-text-color: rgba(30, 41, 59, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
  --mdc-switch-selected-focus-state-layer-color: #9333ea;
  --mdc-switch-selected-handle-color: #9333ea;
  --mdc-switch-selected-hover-state-layer-color: #9333ea;
  --mdc-switch-selected-pressed-state-layer-color: #9333ea;
  --mdc-switch-selected-focus-handle-color: #581c87;
  --mdc-switch-selected-hover-handle-color: #581c87;
  --mdc-switch-selected-pressed-handle-color: #581c87;
  --mdc-switch-selected-focus-track-color: #d8b4fe;
  --mdc-switch-selected-hover-track-color: #d8b4fe;
  --mdc-switch-selected-pressed-track-color: #d8b4fe;
  --mdc-switch-selected-track-color: #d8b4fe;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-disabled-label-text-color: #94a3b8;
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-color: #9333ea;
  --mdc-slider-focus-handle-color: #9333ea;
  --mdc-slider-hover-handle-color: #9333ea;
  --mdc-slider-active-track-color: #9333ea;
  --mdc-slider-inactive-track-color: #9333ea;
  --mdc-slider-with-tick-marks-inactive-container-color: #9333ea;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: #9333ea;
  --mat-slider-hover-state-layer-color: rgba(147, 51, 234, 0.05);
  --mat-slider-focus-state-layer-color: rgba(147, 51, 234, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-item-label-text-color: #1e293b;
  --mat-menu-item-icon-color: #1e293b;
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: #e2e8f0;
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 0;
  --mdc-list-list-item-label-text-color: #1e293b;
  --mdc-list-list-item-supporting-text-color: #64748b;
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: #94a3b8;
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: #1e293b;
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: #1e293b;
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: #94a3b8;
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: #1e293b;
  --mdc-text-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: #1e293b;
  --mdc-filled-button-disabled-container-color: rgba(148, 163, 184, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: #1e293b;
  --mdc-protected-button-disabled-container-color: rgba(148, 163, 184, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: #e2e8f0;
  --mdc-outlined-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-outlined-button-label-text-color: #1e293b;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-fab-container-color: white;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(148, 163, 184, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(148, 163, 184, 0.38);
  --mdc-fab-small-container-color: white;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(148, 163, 184, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(148, 163, 184, 0.38);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #1e293b;
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: white;
  --mat-table-header-headline-color: #1e293b;
  --mat-table-row-item-label-text-color: #1e293b;
  --mat-table-row-item-outline-color: #e2e8f0;
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #9333ea;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #9333ea;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #94a3b8;
  --mat-badge-disabled-state-text-color: #94a3b8;
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: #1e293b;
  --mat-bottom-sheet-container-background-color: white;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: #94a3b8;
  --mat-legacy-button-toggle-state-layer-color: #64748b;
  --mat-legacy-button-toggle-selected-state-text-color: #64748b;
  --mat-legacy-button-toggle-selected-state-background-color: #e2e8f0;
  --mat-legacy-button-toggle-disabled-state-text-color: #94a3b8;
  --mat-legacy-button-toggle-disabled-state-background-color: #cbd5e1;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #e2e8f0;
  --mat-standard-button-toggle-text-color: #1e293b;
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: #64748b;
  --mat-standard-button-toggle-selected-state-background-color: #e2e8f0;
  --mat-standard-button-toggle-selected-state-text-color: #1e293b;
  --mat-standard-button-toggle-disabled-state-text-color: #94a3b8;
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: #1e293b;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #e2e8f0;
  --mat-standard-button-toggle-divider-color: #e2e8f0;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #9333ea;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(147, 51, 234, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(147, 51, 234, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(147, 51, 234, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #9333ea;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(147, 51, 234, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: #64748b;
  --mat-datepicker-calendar-body-label-text-color: #64748b;
  --mat-datepicker-calendar-period-button-text-color: #1e293b;
  --mat-datepicker-calendar-period-button-icon-color: #64748b;
  --mat-datepicker-calendar-navigation-button-icon-color: #64748b;
  --mat-datepicker-calendar-header-divider-color: #e2e8f0;
  --mat-datepicker-calendar-header-text-color: #64748b;
  --mat-datepicker-calendar-date-today-outline-color: #94a3b8;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(148, 163, 184, 0.8);
  --mat-datepicker-calendar-date-text-color: #1e293b;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: #94a3b8;
  --mat-datepicker-calendar-date-preview-state-outline-color: #e2e8f0;
  --mat-datepicker-range-input-separator-color: #1e293b;
  --mat-datepicker-range-input-disabled-state-separator-color: #94a3b8;
  --mat-datepicker-range-input-disabled-state-text-color: #94a3b8;
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: #1e293b;
  --mat-divider-width: 1px;
  --mat-divider-color: #e2e8f0;
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: #1e293b;
  --mat-expansion-actions-divider-color: #e2e8f0;
  --mat-expansion-header-hover-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-expansion-header-focus-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-expansion-header-disabled-state-text-color: #94a3b8;
  --mat-expansion-header-text-color: #1e293b;
  --mat-expansion-header-description-color: #64748b;
  --mat-expansion-header-indicator-color: #64748b;
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: #e2e8f0;
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: #1e293b;
  --mat-sidenav-content-background-color: #f1f5f9;
  --mat-sidenav-content-text-color: #1e293b;
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #9333ea;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #9333ea;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #9333ea;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: #e2e8f0;
  --mat-stepper-header-hover-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-stepper-header-focus-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-stepper-header-label-text-color: #64748b;
  --mat-stepper-header-optional-label-text-color: #64748b;
  --mat-stepper-header-selected-state-label-text-color: #1e293b;
  --mat-stepper-header-error-state-label-text-color: #dc2626;
  --mat-stepper-header-icon-background-color: #64748b;
  --mat-stepper-header-error-state-icon-foreground-color: #dc2626;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #64748b;
  --mat-toolbar-container-background-color: white;
  --mat-toolbar-container-text-color: #1e293b;
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: #1e293b;
}
.theme-purple .light .mat-accent,
.theme-purple.light .mat-accent {
  --mat-option-selected-state-label-text-color: #1e293b;
  --mat-option-label-text-color: #1e293b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.theme-purple .light .mat-warn,
.theme-purple.light .mat-warn {
  --mat-option-selected-state-label-text-color: #dc2626;
  --mat-option-label-text-color: #1e293b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.theme-purple .light .mat-primary,
.theme-purple.light .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #9333ea;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #9333ea;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-purple .light .mat-accent,
.theme-purple.light .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-purple .light .mat-warn,
.theme-purple.light .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #dc2626;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #dc2626;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-purple .light .mat-elevation-z0, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z0,
.theme-purple.light .mat-elevation-z0,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z1, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z1,
.theme-purple.light .mat-elevation-z1,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z2, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z2,
.theme-purple.light .mat-elevation-z2,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z3, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z3,
.theme-purple.light .mat-elevation-z3,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z4, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z4,
.theme-purple.light .mat-elevation-z4,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z5, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z5,
.theme-purple.light .mat-elevation-z5,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z6, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z6,
.theme-purple.light .mat-elevation-z6,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z7, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z7,
.theme-purple.light .mat-elevation-z7,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z8, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z8,
.theme-purple.light .mat-elevation-z8,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z9, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z9,
.theme-purple.light .mat-elevation-z9,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z10, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z10,
.theme-purple.light .mat-elevation-z10,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z11, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z11,
.theme-purple.light .mat-elevation-z11,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z12, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z12,
.theme-purple.light .mat-elevation-z12,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z13, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z13,
.theme-purple.light .mat-elevation-z13,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z14, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z14,
.theme-purple.light .mat-elevation-z14,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z15, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z15,
.theme-purple.light .mat-elevation-z15,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z16, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z16,
.theme-purple.light .mat-elevation-z16,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z17, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z17,
.theme-purple.light .mat-elevation-z17,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z18, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z18,
.theme-purple.light .mat-elevation-z18,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z19, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z19,
.theme-purple.light .mat-elevation-z19,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z20, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z20,
.theme-purple.light .mat-elevation-z20,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z21, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z21,
.theme-purple.light .mat-elevation-z21,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z22, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z22,
.theme-purple.light .mat-elevation-z22,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z23, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z23,
.theme-purple.light .mat-elevation-z23,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-purple .light .mat-elevation-z24, .theme-purple .light .mat-mdc-elevation-specific.mat-elevation-z24,
.theme-purple.light .mat-elevation-z24,
.theme-purple.light .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-purple .light .mat-mdc-progress-bar,
.theme-purple.light .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #9333ea;
  --mdc-linear-progress-track-color: rgba(147, 51, 234, 0.25);
}
.theme-purple .light .mat-mdc-progress-bar.mat-accent,
.theme-purple.light .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1e293b;
  --mdc-linear-progress-track-color: rgba(30, 41, 59, 0.25);
}
.theme-purple .light .mat-mdc-progress-bar.mat-warn,
.theme-purple.light .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc2626;
  --mdc-linear-progress-track-color: rgba(220, 38, 38, 0.25);
}
.theme-purple .light .mat-mdc-form-field.mat-accent,
.theme-purple.light .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #1e293b;
  --mdc-filled-text-field-focus-active-indicator-color: #1e293b;
  --mdc-filled-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mdc-outlined-text-field-caret-color: #1e293b;
  --mdc-outlined-text-field-focus-outline-color: #1e293b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(30, 41, 59, 0.87);
}
.theme-purple .light .mat-mdc-form-field.mat-warn,
.theme-purple.light .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #dc2626;
  --mdc-filled-text-field-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mdc-outlined-text-field-caret-color: #dc2626;
  --mdc-outlined-text-field-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-purple .light .mat-mdc-form-field.mat-accent,
.theme-purple.light .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(30, 41, 59, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(148, 163, 184, 0.38);
  --mat-select-placeholder-text-color: rgba(30, 41, 59, 0.6);
  --mat-select-enabled-arrow-color: rgba(226, 232, 240, 0.54);
  --mat-select-disabled-arrow-color: rgba(30, 41, 59, 0.38);
  --mat-select-focused-arrow-color: rgba(30, 41, 59, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-purple .light .mat-mdc-form-field.mat-warn,
.theme-purple.light .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(30, 41, 59, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(148, 163, 184, 0.38);
  --mat-select-placeholder-text-color: rgba(30, 41, 59, 0.6);
  --mat-select-enabled-arrow-color: rgba(226, 232, 240, 0.54);
  --mat-select-disabled-arrow-color: rgba(30, 41, 59, 0.38);
  --mat-select-focused-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-purple .light .mat-mdc-standard-chip,
.theme-purple.light .mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.theme-purple .light .mat-mdc-standard-chip,
.theme-purple.light .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.theme-purple .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .theme-purple .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.theme-purple.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.theme-purple.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #9333ea;
  --mdc-chip-elevated-selected-container-color: #9333ea;
  --mdc-chip-elevated-disabled-container-color: #9333ea;
  --mdc-chip-flat-disabled-selected-container-color: #9333ea;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-purple .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .theme-purple .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.theme-purple.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.theme-purple.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #1e293b;
  --mdc-chip-elevated-selected-container-color: #1e293b;
  --mdc-chip-elevated-disabled-container-color: #1e293b;
  --mdc-chip-flat-disabled-selected-container-color: #1e293b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-purple .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .theme-purple .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.theme-purple.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.theme-purple.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-selected-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-flat-disabled-selected-container-color: #dc2626;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-purple .light .mat-mdc-slide-toggle,
.theme-purple.light .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: #1e293b;
}
.theme-purple .light .mat-mdc-slide-toggle.mat-accent,
.theme-purple.light .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #475569;
  --mdc-switch-selected-handle-color: #475569;
  --mdc-switch-selected-hover-state-layer-color: #475569;
  --mdc-switch-selected-pressed-state-layer-color: #475569;
  --mdc-switch-selected-focus-handle-color: #0f172a;
  --mdc-switch-selected-hover-handle-color: #0f172a;
  --mdc-switch-selected-pressed-handle-color: #0f172a;
  --mdc-switch-selected-focus-track-color: #cbd5e1;
  --mdc-switch-selected-hover-track-color: #cbd5e1;
  --mdc-switch-selected-pressed-track-color: #cbd5e1;
  --mdc-switch-selected-track-color: #cbd5e1;
}
.theme-purple .light .mat-mdc-slide-toggle.mat-warn,
.theme-purple.light .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.theme-purple .light .mat-mdc-radio-button,
.theme-purple.light .mat-mdc-radio-button {
  --mdc-form-field-label-text-color: #1e293b;
}
.theme-purple .light .mat-mdc-radio-button.mat-primary,
.theme-purple.light .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #9333ea;
  --mdc-radio-selected-hover-icon-color: #9333ea;
  --mdc-radio-selected-icon-color: #9333ea;
  --mdc-radio-selected-pressed-icon-color: #9333ea;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #9333ea;
  --mat-radio-disabled-label-color: #94a3b8;
}
.theme-purple .light .mat-mdc-radio-button.mat-accent,
.theme-purple.light .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #1e293b;
  --mat-radio-disabled-label-color: #94a3b8;
}
.theme-purple .light .mat-mdc-radio-button.mat-warn,
.theme-purple.light .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #dc2626;
  --mat-radio-disabled-label-color: #94a3b8;
}
.theme-purple .light .mat-accent,
.theme-purple.light .mat-accent {
  --mat-slider-ripple-color: #1e293b;
  --mat-slider-hover-state-layer-color: rgba(30, 41, 59, 0.05);
  --mat-slider-focus-state-layer-color: rgba(30, 41, 59, 0.2);
  --mdc-slider-handle-color: #1e293b;
  --mdc-slider-focus-handle-color: #1e293b;
  --mdc-slider-hover-handle-color: #1e293b;
  --mdc-slider-active-track-color: #1e293b;
  --mdc-slider-inactive-track-color: #1e293b;
  --mdc-slider-with-tick-marks-inactive-container-color: #1e293b;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-purple .light .mat-warn,
.theme-purple.light .mat-warn {
  --mat-slider-ripple-color: #dc2626;
  --mat-slider-hover-state-layer-color: rgba(220, 38, 38, 0.05);
  --mat-slider-focus-state-layer-color: rgba(220, 38, 38, 0.2);
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-purple .light .mdc-list-item__start,
.theme-purple .light .mdc-list-item__end,
.theme-purple.light .mdc-list-item__start,
.theme-purple.light .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #9333ea;
  --mdc-radio-selected-hover-icon-color: #9333ea;
  --mdc-radio-selected-icon-color: #9333ea;
  --mdc-radio-selected-pressed-icon-color: #9333ea;
}
.theme-purple .light .mat-accent .mdc-list-item__start,
.theme-purple .light .mat-accent .mdc-list-item__end,
.theme-purple.light .mat-accent .mdc-list-item__start,
.theme-purple.light .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
}
.theme-purple .light .mat-warn .mdc-list-item__start,
.theme-purple .light .mat-warn .mdc-list-item__end,
.theme-purple.light .mat-warn .mdc-list-item__start,
.theme-purple.light .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
}
.theme-purple .light .mat-mdc-list-option,
.theme-purple.light .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #9333ea;
  --mdc-checkbox-selected-hover-icon-color: #9333ea;
  --mdc-checkbox-selected-icon-color: #9333ea;
  --mdc-checkbox-selected-pressed-icon-color: #9333ea;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #9333ea;
  --mdc-checkbox-selected-hover-state-layer-color: #9333ea;
  --mdc-checkbox-selected-pressed-state-layer-color: #9333ea;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-purple .light .mat-mdc-list-option.mat-accent,
.theme-purple.light .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-purple .light .mat-mdc-list-option.mat-warn,
.theme-purple.light .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-purple .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-purple .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-purple.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-purple.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #9333ea;
}
.theme-purple .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #9333ea;
}
.theme-purple .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-purple .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-purple .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.theme-purple.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-purple.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-purple.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.theme-purple .light .mat-mdc-tab-group, .theme-purple .light .mat-mdc-tab-nav-bar,
.theme-purple.light .mat-mdc-tab-group,
.theme-purple.light .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #9333ea;
  --mat-tab-header-disabled-ripple-color: #94a3b8;
  --mat-tab-header-pagination-icon-color: #64748b;
  --mat-tab-header-inactive-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-label-text-color: #9333ea;
  --mat-tab-header-active-ripple-color: #9333ea;
  --mat-tab-header-inactive-ripple-color: #9333ea;
  --mat-tab-header-inactive-focus-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-focus-label-text-color: #9333ea;
  --mat-tab-header-active-hover-label-text-color: #9333ea;
  --mat-tab-header-active-focus-indicator-color: #9333ea;
  --mat-tab-header-active-hover-indicator-color: #9333ea;
}
.theme-purple .light .mat-mdc-tab-group.mat-accent, .theme-purple .light .mat-mdc-tab-nav-bar.mat-accent,
.theme-purple.light .mat-mdc-tab-group.mat-accent,
.theme-purple.light .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #1e293b;
  --mat-tab-header-disabled-ripple-color: #94a3b8;
  --mat-tab-header-pagination-icon-color: #64748b;
  --mat-tab-header-inactive-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-label-text-color: #1e293b;
  --mat-tab-header-active-ripple-color: #1e293b;
  --mat-tab-header-inactive-ripple-color: #1e293b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-focus-label-text-color: #1e293b;
  --mat-tab-header-active-hover-label-text-color: #1e293b;
  --mat-tab-header-active-focus-indicator-color: #1e293b;
  --mat-tab-header-active-hover-indicator-color: #1e293b;
}
.theme-purple .light .mat-mdc-tab-group.mat-warn, .theme-purple .light .mat-mdc-tab-nav-bar.mat-warn,
.theme-purple.light .mat-mdc-tab-group.mat-warn,
.theme-purple.light .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #dc2626;
  --mat-tab-header-disabled-ripple-color: #94a3b8;
  --mat-tab-header-pagination-icon-color: #64748b;
  --mat-tab-header-inactive-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-label-text-color: #dc2626;
  --mat-tab-header-active-ripple-color: #dc2626;
  --mat-tab-header-inactive-ripple-color: #dc2626;
  --mat-tab-header-inactive-focus-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-focus-label-text-color: #dc2626;
  --mat-tab-header-active-hover-label-text-color: #dc2626;
  --mat-tab-header-active-focus-indicator-color: #dc2626;
  --mat-tab-header-active-hover-indicator-color: #dc2626;
}
.theme-purple .light .mat-mdc-tab-group.mat-background-primary, .theme-purple .light .mat-mdc-tab-nav-bar.mat-background-primary,
.theme-purple.light .mat-mdc-tab-group.mat-background-primary,
.theme-purple.light .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #9333ea;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-purple .light .mat-mdc-tab-group.mat-background-accent, .theme-purple .light .mat-mdc-tab-nav-bar.mat-background-accent,
.theme-purple.light .mat-mdc-tab-group.mat-background-accent,
.theme-purple.light .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #1e293b;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-purple .light .mat-mdc-tab-group.mat-background-warn, .theme-purple .light .mat-mdc-tab-nav-bar.mat-background-warn,
.theme-purple.light .mat-mdc-tab-group.mat-background-warn,
.theme-purple.light .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #dc2626;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-purple .light .mat-mdc-checkbox,
.theme-purple.light .mat-mdc-checkbox {
  --mdc-form-field-label-text-color: #1e293b;
}
.theme-purple .light .mat-mdc-checkbox.mat-primary,
.theme-purple.light .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #9333ea;
  --mdc-checkbox-selected-hover-icon-color: #9333ea;
  --mdc-checkbox-selected-icon-color: #9333ea;
  --mdc-checkbox-selected-pressed-icon-color: #9333ea;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #9333ea;
  --mdc-checkbox-selected-hover-state-layer-color: #9333ea;
  --mdc-checkbox-selected-pressed-state-layer-color: #9333ea;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-purple .light .mat-mdc-checkbox.mat-warn,
.theme-purple.light .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-purple .light .mat-mdc-button.mat-primary,
.theme-purple.light .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #9333ea;
  --mat-text-button-state-layer-color: #9333ea;
  --mat-text-button-ripple-color: rgba(147, 51, 234, 0.1);
}
.theme-purple .light .mat-mdc-button.mat-accent,
.theme-purple.light .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1e293b;
  --mat-text-button-state-layer-color: #1e293b;
  --mat-text-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-purple .light .mat-mdc-button.mat-warn,
.theme-purple.light .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc2626;
  --mat-text-button-state-layer-color: #dc2626;
  --mat-text-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-purple .light .mat-mdc-unelevated-button.mat-primary,
.theme-purple.light .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #9333ea;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .light .mat-mdc-unelevated-button.mat-accent,
.theme-purple.light .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .light .mat-mdc-unelevated-button.mat-warn,
.theme-purple.light .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .light .mat-mdc-raised-button.mat-primary,
.theme-purple.light .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #9333ea;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .light .mat-mdc-raised-button.mat-accent,
.theme-purple.light .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .light .mat-mdc-raised-button.mat-warn,
.theme-purple.light .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .light .mat-mdc-outlined-button.mat-primary,
.theme-purple.light .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #9333ea;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: #9333ea;
  --mat-outlined-button-ripple-color: rgba(147, 51, 234, 0.1);
}
.theme-purple .light .mat-mdc-outlined-button.mat-accent,
.theme-purple.light .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1e293b;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: #1e293b;
  --mat-outlined-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-purple .light .mat-mdc-outlined-button.mat-warn,
.theme-purple.light .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc2626;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: #dc2626;
  --mat-outlined-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-purple .light .mat-mdc-icon-button.mat-primary,
.theme-purple.light .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #9333ea;
  --mat-icon-button-state-layer-color: #9333ea;
  --mat-icon-button-ripple-color: rgba(147, 51, 234, 0.1);
}
.theme-purple .light .mat-mdc-icon-button.mat-accent,
.theme-purple.light .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1e293b;
  --mat-icon-button-state-layer-color: #1e293b;
  --mat-icon-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-purple .light .mat-mdc-icon-button.mat-warn,
.theme-purple.light .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc2626;
  --mat-icon-button-state-layer-color: #dc2626;
  --mat-icon-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-purple .light .mat-mdc-fab.mat-primary,
.theme-purple.light .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #9333ea;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .light .mat-mdc-fab.mat-accent,
.theme-purple.light .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #1e293b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .light .mat-mdc-fab.mat-warn,
.theme-purple.light .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #dc2626;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .light .mat-mdc-mini-fab.mat-primary,
.theme-purple.light .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #9333ea;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .light .mat-mdc-mini-fab.mat-accent,
.theme-purple.light .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #1e293b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .light .mat-mdc-mini-fab.mat-warn,
.theme-purple.light .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #dc2626;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .light .mat-accent,
.theme-purple.light .mat-accent {
  --mdc-circular-progress-active-indicator-color: #1e293b;
}
.theme-purple .light .mat-warn,
.theme-purple.light .mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}
.theme-purple .light .mat-badge-accent,
.theme-purple.light .mat-badge-accent {
  --mat-badge-background-color: #1e293b;
  --mat-badge-text-color: white;
}
.theme-purple .light .mat-badge-warn,
.theme-purple.light .mat-badge-warn {
  --mat-badge-background-color: #dc2626;
  --mat-badge-text-color: white;
}
.theme-purple .light .mat-datepicker-content.mat-accent,
.theme-purple.light .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #1e293b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(30, 41, 59, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(30, 41, 59, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-purple .light .mat-datepicker-content.mat-warn,
.theme-purple.light .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #dc2626;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(220, 38, 38, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(220, 38, 38, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-purple .light .mat-datepicker-toggle-active.mat-accent,
.theme-purple.light .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #1e293b;
}
.theme-purple .light .mat-datepicker-toggle-active.mat-warn,
.theme-purple.light .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #dc2626;
}
.theme-purple .light .mat-icon.mat-primary,
.theme-purple.light .mat-icon.mat-primary {
  --mat-icon-color: #9333ea;
}
.theme-purple .light .mat-icon.mat-accent,
.theme-purple.light .mat-icon.mat-accent {
  --mat-icon-color: #1e293b;
}
.theme-purple .light .mat-icon.mat-warn,
.theme-purple.light .mat-icon.mat-warn {
  --mat-icon-color: #dc2626;
}
.theme-purple .light .mat-step-header.mat-accent,
.theme-purple.light .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #1e293b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #1e293b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #1e293b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-purple .light .mat-step-header.mat-warn,
.theme-purple.light .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #dc2626;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #dc2626;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #dc2626;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-purple .light .mat-toolbar.mat-primary,
.theme-purple.light .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #9333ea;
  --mat-toolbar-container-text-color: white;
}
.theme-purple .light .mat-toolbar.mat-accent,
.theme-purple.light .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #1e293b;
  --mat-toolbar-container-text-color: white;
}
.theme-purple .light .mat-toolbar.mat-warn,
.theme-purple.light .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #dc2626;
  --mat-toolbar-container-text-color: white;
}

.theme-purple .dark,
.theme-purple.dark {
  --mat-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-option-selected-state-label-text-color: #9333ea;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-optgroup-label-text-color: white;
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
  --mat-app-background-color: #0f172a;
  --mat-app-text-color: white;
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: #1e293b;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: #1e293b;
  --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: #94a3b8;
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #64748b;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #9333ea;
  --mdc-filled-text-field-focus-active-indicator-color: #9333ea;
  --mdc-filled-text-field-focus-label-text-color: rgba(147, 51, 234, 0.87);
  --mdc-filled-text-field-container-color: #273243;
  --mdc-filled-text-field-disabled-container-color: #232d3f;
  --mdc-filled-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(71, 85, 105, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(71, 85, 105, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #dc2626;
  --mdc-filled-text-field-error-focus-label-text-color: #dc2626;
  --mdc-filled-text-field-error-label-text-color: #dc2626;
  --mdc-filled-text-field-error-caret-color: #dc2626;
  --mdc-filled-text-field-active-indicator-color: rgba(241, 245, 249, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(241, 245, 249, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(241, 245, 249, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-hover-active-indicator-color: #dc2626;
  --mdc-outlined-text-field-caret-color: #9333ea;
  --mdc-outlined-text-field-focus-outline-color: #9333ea;
  --mdc-outlined-text-field-focus-label-text-color: rgba(147, 51, 234, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(71, 85, 105, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(71, 85, 105, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-error-caret-color: #dc2626;
  --mdc-outlined-text-field-error-focus-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-hover-label-text-color: #dc2626;
  --mdc-outlined-text-field-outline-color: rgba(241, 245, 249, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(241, 245, 249, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(241, 245, 249, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-error-hover-outline-color: #dc2626;
  --mdc-outlined-text-field-error-outline-color: #dc2626;
  --mat-form-field-focus-select-arrow-color: rgba(147, 51, 234, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(241, 245, 249, 0.38);
  --mat-form-field-state-layer-color: rgba(255, 255, 255, 0.87);
  --mat-form-field-error-text-color: #dc2626;
  --mat-form-field-select-option-text-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-select-disabled-option-text-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(241, 245, 249, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.08;
  --mat-form-field-focus-state-layer-opacity: 0.24;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: #1e293b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(71, 85, 105, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(147, 51, 234, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: #1e293b;
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: #1e293b;
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
  --mdc-switch-selected-focus-state-layer-color: #d8b4fe;
  --mdc-switch-selected-handle-color: #d8b4fe;
  --mdc-switch-selected-hover-state-layer-color: #d8b4fe;
  --mdc-switch-selected-pressed-state-layer-color: #d8b4fe;
  --mdc-switch-selected-focus-handle-color: #e9d5ff;
  --mdc-switch-selected-hover-handle-color: #e9d5ff;
  --mdc-switch-selected-pressed-handle-color: #e9d5ff;
  --mdc-switch-selected-focus-track-color: #9333ea;
  --mdc-switch-selected-hover-track-color: #9333ea;
  --mdc-switch-selected-pressed-track-color: #9333ea;
  --mdc-switch-selected-track-color: #9333ea;
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-icon-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-disabled-label-text-color: #475569;
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-color: #9333ea;
  --mdc-slider-focus-handle-color: #9333ea;
  --mdc-slider-hover-handle-color: #9333ea;
  --mdc-slider-active-track-color: #9333ea;
  --mdc-slider-inactive-track-color: #9333ea;
  --mdc-slider-with-tick-marks-inactive-container-color: #9333ea;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-label-container-color: #fff;
  --mdc-slider-label-label-text-color: #000;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: #9333ea;
  --mat-slider-hover-state-layer-color: rgba(147, 51, 234, 0.05);
  --mat-slider-focus-state-layer-color: rgba(147, 51, 234, 0.2);
  --mat-slider-value-indicator-opacity: 0.9;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-item-label-text-color: white;
  --mat-menu-item-icon-color: white;
  --mat-menu-item-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-container-color: #1e293b;
  --mat-menu-divider-color: rgba(241, 245, 249, 0.12);
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 0;
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: #94a3b8;
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: #64748b;
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: white;
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
  --mat-paginator-container-text-color: rgba(255, 255, 255, 0.87);
  --mat-paginator-container-background-color: #1e293b;
  --mat-paginator-enabled-icon-color: rgba(255, 255, 255, 0.54);
  --mat-paginator-disabled-icon-color: rgba(255, 255, 255, 0.12);
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
  --mat-checkbox-disabled-label-color: #475569;
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: white;
  --mdc-text-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mat-text-button-state-layer-color: white;
  --mat-text-button-disabled-state-layer-color: white;
  --mat-text-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.08;
  --mat-text-button-focus-state-layer-opacity: 0.24;
  --mat-text-button-pressed-state-layer-opacity: 0.24;
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: white;
  --mdc-filled-button-disabled-container-color: rgba(30, 41, 59, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-disabled-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.08;
  --mat-filled-button-focus-state-layer-opacity: 0.24;
  --mat-filled-button-pressed-state-layer-opacity: 0.24;
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: white;
  --mdc-protected-button-disabled-container-color: rgba(30, 41, 59, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-disabled-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.08;
  --mat-protected-button-focus-state-layer-opacity: 0.24;
  --mat-protected-button-pressed-state-layer-opacity: 0.24;
  --mdc-outlined-button-disabled-outline-color: rgba(241, 245, 249, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mdc-outlined-button-label-text-color: white;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: white;
  --mat-outlined-button-disabled-state-layer-color: white;
  --mat-outlined-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.08;
  --mat-outlined-button-focus-state-layer-opacity: 0.24;
  --mat-outlined-button-pressed-state-layer-opacity: 0.24;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
  --mat-icon-button-state-layer-color: white;
  --mat-icon-button-disabled-state-layer-color: white;
  --mat-icon-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.08;
  --mat-icon-button-focus-state-layer-opacity: 0.24;
  --mat-icon-button-pressed-state-layer-opacity: 0.24;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-fab-container-color: #1e293b;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-disabled-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.08;
  --mat-fab-focus-state-layer-opacity: 0.24;
  --mat-fab-pressed-state-layer-opacity: 0.24;
  --mat-fab-disabled-state-container-color: rgba(15, 23, 42, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(30, 41, 59, 0.5);
  --mdc-fab-small-container-color: #1e293b;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-disabled-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.08;
  --mat-fab-small-focus-state-layer-opacity: 0.24;
  --mat-fab-small-pressed-state-layer-opacity: 0.24;
  --mat-fab-small-disabled-state-container-color: rgba(15, 23, 42, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(30, 41, 59, 0.5);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #d2d4d8;
  --mdc-snackbar-supporting-text-color: rgba(30, 41, 59, 0.87);
  --mat-snack-bar-button-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: #1e293b;
  --mat-table-header-headline-color: white;
  --mat-table-row-item-label-text-color: white;
  --mat-table-row-item-outline-color: rgba(241, 245, 249, 0.12);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #9333ea;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #9333ea;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #1e293b;
  --mat-badge-disabled-state-text-color: #475569;
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: white;
  --mat-bottom-sheet-container-background-color: #1e293b;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: #64748b;
  --mat-legacy-button-toggle-state-layer-color: #e2e8f0;
  --mat-legacy-button-toggle-selected-state-text-color: #94a3b8;
  --mat-legacy-button-toggle-selected-state-background-color: rgba(255, 255, 255, 0.05);
  --mat-legacy-button-toggle-disabled-state-text-color: #1e293b;
  --mat-legacy-button-toggle-disabled-state-background-color: #0f172a;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #1e293b;
  --mat-standard-button-toggle-text-color: white;
  --mat-standard-button-toggle-background-color: #1e293b;
  --mat-standard-button-toggle-state-layer-color: #e2e8f0;
  --mat-standard-button-toggle-selected-state-background-color: rgba(255, 255, 255, 0.05);
  --mat-standard-button-toggle-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-state-text-color: #1e293b;
  --mat-standard-button-toggle-disabled-state-background-color: #1e293b;
  --mat-standard-button-toggle-disabled-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #1e293b;
  --mat-standard-button-toggle-divider-color: #374152;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #9333ea;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(147, 51, 234, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(147, 51, 234, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(147, 51, 234, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #9333ea;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(147, 51, 234, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: #f1f5f9;
  --mat-datepicker-calendar-body-label-text-color: #94a3b8;
  --mat-datepicker-calendar-period-button-text-color: white;
  --mat-datepicker-calendar-period-button-icon-color: #f1f5f9;
  --mat-datepicker-calendar-navigation-button-icon-color: #f1f5f9;
  --mat-datepicker-calendar-header-divider-color: rgba(241, 245, 249, 0.12);
  --mat-datepicker-calendar-header-text-color: #94a3b8;
  --mat-datepicker-calendar-date-today-outline-color: #64748b;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(100, 116, 139, 0.8);
  --mat-datepicker-calendar-date-text-color: white;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: #475569;
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(241, 245, 249, 0.24);
  --mat-datepicker-range-input-separator-color: white;
  --mat-datepicker-range-input-disabled-state-separator-color: #475569;
  --mat-datepicker-range-input-disabled-state-text-color: #475569;
  --mat-datepicker-calendar-container-background-color: #1e293b;
  --mat-datepicker-calendar-container-text-color: white;
  --mat-divider-width: 1px;
  --mat-divider-color: rgba(241, 245, 249, 0.12);
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: #1e293b;
  --mat-expansion-container-text-color: white;
  --mat-expansion-actions-divider-color: rgba(241, 245, 249, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-expansion-header-focus-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-expansion-header-disabled-state-text-color: #1e293b;
  --mat-expansion-header-text-color: white;
  --mat-expansion-header-description-color: #94a3b8;
  --mat-expansion-header-indicator-color: #94a3b8;
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: rgba(241, 245, 249, 0.12);
  --mat-sidenav-container-background-color: #1e293b;
  --mat-sidenav-container-text-color: white;
  --mat-sidenav-content-background-color: #0f172a;
  --mat-sidenav-content-text-color: white;
  --mat-sidenav-scrim-color: rgba(225, 214, 196, 0.6);
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #9333ea;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #9333ea;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #9333ea;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: #1e293b;
  --mat-stepper-line-color: rgba(241, 245, 249, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-stepper-header-focus-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-stepper-header-label-text-color: #94a3b8;
  --mat-stepper-header-optional-label-text-color: #94a3b8;
  --mat-stepper-header-selected-state-label-text-color: white;
  --mat-stepper-header-error-state-label-text-color: #dc2626;
  --mat-stepper-header-icon-background-color: #94a3b8;
  --mat-stepper-header-error-state-icon-foreground-color: #dc2626;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #94a3b8;
  --mat-toolbar-container-background-color: #0f172a;
  --mat-toolbar-container-text-color: white;
  --mat-tree-container-background-color: #1e293b;
  --mat-tree-node-text-color: white;
}
.theme-purple .dark .mat-accent,
.theme-purple.dark .mat-accent {
  --mat-option-selected-state-label-text-color: #1e293b;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.theme-purple .dark .mat-warn,
.theme-purple.dark .mat-warn {
  --mat-option-selected-state-label-text-color: #dc2626;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.theme-purple .dark .mat-primary,
.theme-purple.dark .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #9333ea;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #9333ea;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-purple .dark .mat-accent,
.theme-purple.dark .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-purple .dark .mat-warn,
.theme-purple.dark .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #dc2626;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #dc2626;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-purple .dark .mat-elevation-z0, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z0,
.theme-purple.dark .mat-elevation-z0,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z1, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z1,
.theme-purple.dark .mat-elevation-z1,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z2, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z2,
.theme-purple.dark .mat-elevation-z2,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z3, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z3,
.theme-purple.dark .mat-elevation-z3,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z4, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z4,
.theme-purple.dark .mat-elevation-z4,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z5, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z5,
.theme-purple.dark .mat-elevation-z5,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z6, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z6,
.theme-purple.dark .mat-elevation-z6,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z7, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z7,
.theme-purple.dark .mat-elevation-z7,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z8, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z8,
.theme-purple.dark .mat-elevation-z8,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z9, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z9,
.theme-purple.dark .mat-elevation-z9,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z10, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z10,
.theme-purple.dark .mat-elevation-z10,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z11, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z11,
.theme-purple.dark .mat-elevation-z11,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z12, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z12,
.theme-purple.dark .mat-elevation-z12,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z13, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z13,
.theme-purple.dark .mat-elevation-z13,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z14, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z14,
.theme-purple.dark .mat-elevation-z14,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z15, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z15,
.theme-purple.dark .mat-elevation-z15,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z16, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z16,
.theme-purple.dark .mat-elevation-z16,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z17, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z17,
.theme-purple.dark .mat-elevation-z17,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z18, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z18,
.theme-purple.dark .mat-elevation-z18,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z19, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z19,
.theme-purple.dark .mat-elevation-z19,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z20, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z20,
.theme-purple.dark .mat-elevation-z20,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z21, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z21,
.theme-purple.dark .mat-elevation-z21,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z22, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z22,
.theme-purple.dark .mat-elevation-z22,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z23, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z23,
.theme-purple.dark .mat-elevation-z23,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-purple .dark .mat-elevation-z24, .theme-purple .dark .mat-mdc-elevation-specific.mat-elevation-z24,
.theme-purple.dark .mat-elevation-z24,
.theme-purple.dark .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-purple .dark .mat-mdc-progress-bar,
.theme-purple.dark .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #9333ea;
  --mdc-linear-progress-track-color: rgba(147, 51, 234, 0.25);
}
.theme-purple .dark .mat-mdc-progress-bar.mat-accent,
.theme-purple.dark .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1e293b;
  --mdc-linear-progress-track-color: rgba(30, 41, 59, 0.25);
}
.theme-purple .dark .mat-mdc-progress-bar.mat-warn,
.theme-purple.dark .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc2626;
  --mdc-linear-progress-track-color: rgba(220, 38, 38, 0.25);
}
.theme-purple .dark .mat-mdc-form-field.mat-accent,
.theme-purple.dark .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #1e293b;
  --mdc-filled-text-field-focus-active-indicator-color: #1e293b;
  --mdc-filled-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mdc-outlined-text-field-caret-color: #1e293b;
  --mdc-outlined-text-field-focus-outline-color: #1e293b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(30, 41, 59, 0.87);
}
.theme-purple .dark .mat-mdc-form-field.mat-warn,
.theme-purple.dark .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #dc2626;
  --mdc-filled-text-field-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mdc-outlined-text-field-caret-color: #dc2626;
  --mdc-outlined-text-field-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-purple .dark .mat-mdc-form-field.mat-accent,
.theme-purple.dark .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: #1e293b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(71, 85, 105, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(30, 41, 59, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-purple .dark .mat-mdc-form-field.mat-warn,
.theme-purple.dark .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: #1e293b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(71, 85, 105, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-purple .dark .mat-mdc-standard-chip,
.theme-purple.dark .mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.theme-purple .dark .mat-mdc-standard-chip,
.theme-purple.dark .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-elevated-container-color: #394353;
  --mdc-chip-elevated-selected-container-color: #394353;
  --mdc-chip-elevated-disabled-container-color: #394353;
  --mdc-chip-flat-disabled-selected-container-color: #394353;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-selected-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
  --mat-chip-selected-disabled-trailing-icon-color: #fafafa;
  --mat-chip-selected-trailing-icon-color: #fafafa;
}
.theme-purple .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .theme-purple .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.theme-purple.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.theme-purple.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #9333ea;
  --mdc-chip-elevated-selected-container-color: #9333ea;
  --mdc-chip-elevated-disabled-container-color: #9333ea;
  --mdc-chip-flat-disabled-selected-container-color: #9333ea;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-purple .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .theme-purple .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.theme-purple.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.theme-purple.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #1e293b;
  --mdc-chip-elevated-selected-container-color: #1e293b;
  --mdc-chip-elevated-disabled-container-color: #1e293b;
  --mdc-chip-flat-disabled-selected-container-color: #1e293b;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-purple .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .theme-purple .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.theme-purple.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.theme-purple.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-selected-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-flat-disabled-selected-container-color: #dc2626;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-purple .dark .mat-mdc-slide-toggle,
.theme-purple.dark .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: white;
}
.theme-purple .dark .mat-mdc-slide-toggle.mat-accent,
.theme-purple.dark .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #cbd5e1;
  --mdc-switch-selected-handle-color: #cbd5e1;
  --mdc-switch-selected-hover-state-layer-color: #cbd5e1;
  --mdc-switch-selected-pressed-state-layer-color: #cbd5e1;
  --mdc-switch-selected-focus-handle-color: #e2e8f0;
  --mdc-switch-selected-hover-handle-color: #e2e8f0;
  --mdc-switch-selected-pressed-handle-color: #e2e8f0;
  --mdc-switch-selected-focus-track-color: #475569;
  --mdc-switch-selected-hover-track-color: #475569;
  --mdc-switch-selected-pressed-track-color: #475569;
  --mdc-switch-selected-track-color: #475569;
}
.theme-purple .dark .mat-mdc-slide-toggle.mat-warn,
.theme-purple.dark .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #fca5a5;
  --mdc-switch-selected-handle-color: #fca5a5;
  --mdc-switch-selected-hover-state-layer-color: #fca5a5;
  --mdc-switch-selected-pressed-state-layer-color: #fca5a5;
  --mdc-switch-selected-focus-handle-color: #fecaca;
  --mdc-switch-selected-hover-handle-color: #fecaca;
  --mdc-switch-selected-pressed-handle-color: #fecaca;
  --mdc-switch-selected-focus-track-color: #dc2626;
  --mdc-switch-selected-hover-track-color: #dc2626;
  --mdc-switch-selected-pressed-track-color: #dc2626;
  --mdc-switch-selected-track-color: #dc2626;
}
.theme-purple .dark .mat-mdc-radio-button,
.theme-purple.dark .mat-mdc-radio-button {
  --mdc-form-field-label-text-color: white;
}
.theme-purple .dark .mat-mdc-radio-button.mat-primary,
.theme-purple.dark .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #9333ea;
  --mdc-radio-selected-hover-icon-color: #9333ea;
  --mdc-radio-selected-icon-color: #9333ea;
  --mdc-radio-selected-pressed-icon-color: #9333ea;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #9333ea;
  --mat-radio-disabled-label-color: #475569;
}
.theme-purple .dark .mat-mdc-radio-button.mat-accent,
.theme-purple.dark .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #1e293b;
  --mat-radio-disabled-label-color: #475569;
}
.theme-purple .dark .mat-mdc-radio-button.mat-warn,
.theme-purple.dark .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #dc2626;
  --mat-radio-disabled-label-color: #475569;
}
.theme-purple .dark .mat-accent,
.theme-purple.dark .mat-accent {
  --mat-slider-ripple-color: #1e293b;
  --mat-slider-hover-state-layer-color: rgba(30, 41, 59, 0.05);
  --mat-slider-focus-state-layer-color: rgba(30, 41, 59, 0.2);
  --mdc-slider-handle-color: #1e293b;
  --mdc-slider-focus-handle-color: #1e293b;
  --mdc-slider-hover-handle-color: #1e293b;
  --mdc-slider-active-track-color: #1e293b;
  --mdc-slider-inactive-track-color: #1e293b;
  --mdc-slider-with-tick-marks-inactive-container-color: #1e293b;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-purple .dark .mat-warn,
.theme-purple.dark .mat-warn {
  --mat-slider-ripple-color: #dc2626;
  --mat-slider-hover-state-layer-color: rgba(220, 38, 38, 0.05);
  --mat-slider-focus-state-layer-color: rgba(220, 38, 38, 0.2);
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-purple .dark .mdc-list-item__start,
.theme-purple .dark .mdc-list-item__end,
.theme-purple.dark .mdc-list-item__start,
.theme-purple.dark .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #9333ea;
  --mdc-radio-selected-hover-icon-color: #9333ea;
  --mdc-radio-selected-icon-color: #9333ea;
  --mdc-radio-selected-pressed-icon-color: #9333ea;
}
.theme-purple .dark .mat-accent .mdc-list-item__start,
.theme-purple .dark .mat-accent .mdc-list-item__end,
.theme-purple.dark .mat-accent .mdc-list-item__start,
.theme-purple.dark .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
}
.theme-purple .dark .mat-warn .mdc-list-item__start,
.theme-purple .dark .mat-warn .mdc-list-item__end,
.theme-purple.dark .mat-warn .mdc-list-item__start,
.theme-purple.dark .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
}
.theme-purple .dark .mat-mdc-list-option,
.theme-purple.dark .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #9333ea;
  --mdc-checkbox-selected-hover-icon-color: #9333ea;
  --mdc-checkbox-selected-icon-color: #9333ea;
  --mdc-checkbox-selected-pressed-icon-color: #9333ea;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #9333ea;
  --mdc-checkbox-selected-hover-state-layer-color: #9333ea;
  --mdc-checkbox-selected-pressed-state-layer-color: #9333ea;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-purple .dark .mat-mdc-list-option.mat-accent,
.theme-purple.dark .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-purple .dark .mat-mdc-list-option.mat-warn,
.theme-purple.dark .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-purple .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-purple .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-purple.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-purple.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #9333ea;
}
.theme-purple .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-purple.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #9333ea;
}
.theme-purple .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-purple .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-purple .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.theme-purple.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-purple.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-purple.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.theme-purple .dark .mat-mdc-tab-group, .theme-purple .dark .mat-mdc-tab-nav-bar,
.theme-purple.dark .mat-mdc-tab-group,
.theme-purple.dark .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #9333ea;
  --mat-tab-header-disabled-ripple-color: #475569;
  --mat-tab-header-pagination-icon-color: #f1f5f9;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #9333ea;
  --mat-tab-header-active-ripple-color: #9333ea;
  --mat-tab-header-inactive-ripple-color: #9333ea;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #9333ea;
  --mat-tab-header-active-hover-label-text-color: #9333ea;
  --mat-tab-header-active-focus-indicator-color: #9333ea;
  --mat-tab-header-active-hover-indicator-color: #9333ea;
}
.theme-purple .dark .mat-mdc-tab-group.mat-accent, .theme-purple .dark .mat-mdc-tab-nav-bar.mat-accent,
.theme-purple.dark .mat-mdc-tab-group.mat-accent,
.theme-purple.dark .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #1e293b;
  --mat-tab-header-disabled-ripple-color: #475569;
  --mat-tab-header-pagination-icon-color: #f1f5f9;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #1e293b;
  --mat-tab-header-active-ripple-color: #1e293b;
  --mat-tab-header-inactive-ripple-color: #1e293b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #1e293b;
  --mat-tab-header-active-hover-label-text-color: #1e293b;
  --mat-tab-header-active-focus-indicator-color: #1e293b;
  --mat-tab-header-active-hover-indicator-color: #1e293b;
}
.theme-purple .dark .mat-mdc-tab-group.mat-warn, .theme-purple .dark .mat-mdc-tab-nav-bar.mat-warn,
.theme-purple.dark .mat-mdc-tab-group.mat-warn,
.theme-purple.dark .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #dc2626;
  --mat-tab-header-disabled-ripple-color: #475569;
  --mat-tab-header-pagination-icon-color: #f1f5f9;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #dc2626;
  --mat-tab-header-active-ripple-color: #dc2626;
  --mat-tab-header-inactive-ripple-color: #dc2626;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #dc2626;
  --mat-tab-header-active-hover-label-text-color: #dc2626;
  --mat-tab-header-active-focus-indicator-color: #dc2626;
  --mat-tab-header-active-hover-indicator-color: #dc2626;
}
.theme-purple .dark .mat-mdc-tab-group.mat-background-primary, .theme-purple .dark .mat-mdc-tab-nav-bar.mat-background-primary,
.theme-purple.dark .mat-mdc-tab-group.mat-background-primary,
.theme-purple.dark .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #9333ea;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-purple .dark .mat-mdc-tab-group.mat-background-accent, .theme-purple .dark .mat-mdc-tab-nav-bar.mat-background-accent,
.theme-purple.dark .mat-mdc-tab-group.mat-background-accent,
.theme-purple.dark .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #1e293b;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-purple .dark .mat-mdc-tab-group.mat-background-warn, .theme-purple .dark .mat-mdc-tab-nav-bar.mat-background-warn,
.theme-purple.dark .mat-mdc-tab-group.mat-background-warn,
.theme-purple.dark .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #dc2626;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-purple .dark .mat-mdc-checkbox,
.theme-purple.dark .mat-mdc-checkbox {
  --mdc-form-field-label-text-color: white;
}
.theme-purple .dark .mat-mdc-checkbox.mat-primary,
.theme-purple.dark .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #9333ea;
  --mdc-checkbox-selected-hover-icon-color: #9333ea;
  --mdc-checkbox-selected-icon-color: #9333ea;
  --mdc-checkbox-selected-pressed-icon-color: #9333ea;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #9333ea;
  --mdc-checkbox-selected-hover-state-layer-color: #9333ea;
  --mdc-checkbox-selected-pressed-state-layer-color: #9333ea;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-purple .dark .mat-mdc-checkbox.mat-warn,
.theme-purple.dark .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-purple .dark .mat-mdc-button.mat-primary,
.theme-purple.dark .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #9333ea;
  --mat-text-button-state-layer-color: #9333ea;
  --mat-text-button-ripple-color: rgba(147, 51, 234, 0.1);
}
.theme-purple .dark .mat-mdc-button.mat-accent,
.theme-purple.dark .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1e293b;
  --mat-text-button-state-layer-color: #1e293b;
  --mat-text-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-purple .dark .mat-mdc-button.mat-warn,
.theme-purple.dark .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc2626;
  --mat-text-button-state-layer-color: #dc2626;
  --mat-text-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-purple .dark .mat-mdc-unelevated-button.mat-primary,
.theme-purple.dark .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #9333ea;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .dark .mat-mdc-unelevated-button.mat-accent,
.theme-purple.dark .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .dark .mat-mdc-unelevated-button.mat-warn,
.theme-purple.dark .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .dark .mat-mdc-raised-button.mat-primary,
.theme-purple.dark .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #9333ea;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .dark .mat-mdc-raised-button.mat-accent,
.theme-purple.dark .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .dark .mat-mdc-raised-button.mat-warn,
.theme-purple.dark .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .dark .mat-mdc-outlined-button.mat-primary,
.theme-purple.dark .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #9333ea;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: #9333ea;
  --mat-outlined-button-ripple-color: rgba(147, 51, 234, 0.1);
}
.theme-purple .dark .mat-mdc-outlined-button.mat-accent,
.theme-purple.dark .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1e293b;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: #1e293b;
  --mat-outlined-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-purple .dark .mat-mdc-outlined-button.mat-warn,
.theme-purple.dark .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc2626;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: #dc2626;
  --mat-outlined-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-purple .dark .mat-mdc-icon-button.mat-primary,
.theme-purple.dark .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #9333ea;
  --mat-icon-button-state-layer-color: #9333ea;
  --mat-icon-button-ripple-color: rgba(147, 51, 234, 0.1);
}
.theme-purple .dark .mat-mdc-icon-button.mat-accent,
.theme-purple.dark .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1e293b;
  --mat-icon-button-state-layer-color: #1e293b;
  --mat-icon-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-purple .dark .mat-mdc-icon-button.mat-warn,
.theme-purple.dark .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc2626;
  --mat-icon-button-state-layer-color: #dc2626;
  --mat-icon-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-purple .dark .mat-mdc-fab.mat-primary,
.theme-purple.dark .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #9333ea;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .dark .mat-mdc-fab.mat-accent,
.theme-purple.dark .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #1e293b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .dark .mat-mdc-fab.mat-warn,
.theme-purple.dark .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #dc2626;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .dark .mat-mdc-mini-fab.mat-primary,
.theme-purple.dark .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #9333ea;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .dark .mat-mdc-mini-fab.mat-accent,
.theme-purple.dark .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #1e293b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .dark .mat-mdc-mini-fab.mat-warn,
.theme-purple.dark .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #dc2626;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-purple .dark .mat-accent,
.theme-purple.dark .mat-accent {
  --mdc-circular-progress-active-indicator-color: #1e293b;
}
.theme-purple .dark .mat-warn,
.theme-purple.dark .mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}
.theme-purple .dark .mat-badge-accent,
.theme-purple.dark .mat-badge-accent {
  --mat-badge-background-color: #1e293b;
  --mat-badge-text-color: white;
}
.theme-purple .dark .mat-badge-warn,
.theme-purple.dark .mat-badge-warn {
  --mat-badge-background-color: #dc2626;
  --mat-badge-text-color: white;
}
.theme-purple .dark .mat-datepicker-content.mat-accent,
.theme-purple.dark .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #1e293b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(30, 41, 59, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(30, 41, 59, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-purple .dark .mat-datepicker-content.mat-warn,
.theme-purple.dark .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #dc2626;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(220, 38, 38, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(220, 38, 38, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-purple .dark .mat-datepicker-toggle-active.mat-accent,
.theme-purple.dark .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #1e293b;
}
.theme-purple .dark .mat-datepicker-toggle-active.mat-warn,
.theme-purple.dark .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #dc2626;
}
.theme-purple .dark .mat-icon.mat-primary,
.theme-purple.dark .mat-icon.mat-primary {
  --mat-icon-color: #9333ea;
}
.theme-purple .dark .mat-icon.mat-accent,
.theme-purple.dark .mat-icon.mat-accent {
  --mat-icon-color: #1e293b;
}
.theme-purple .dark .mat-icon.mat-warn,
.theme-purple.dark .mat-icon.mat-warn {
  --mat-icon-color: #dc2626;
}
.theme-purple .dark .mat-step-header.mat-accent,
.theme-purple.dark .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #1e293b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #1e293b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #1e293b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-purple .dark .mat-step-header.mat-warn,
.theme-purple.dark .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #dc2626;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #dc2626;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #dc2626;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-purple .dark .mat-toolbar.mat-primary,
.theme-purple.dark .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #9333ea;
  --mat-toolbar-container-text-color: white;
}
.theme-purple .dark .mat-toolbar.mat-accent,
.theme-purple.dark .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #1e293b;
  --mat-toolbar-container-text-color: white;
}
.theme-purple .dark .mat-toolbar.mat-warn,
.theme-purple.dark .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #dc2626;
  --mat-toolbar-container-text-color: white;
}

/* Generate the palettes */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define a light & dark Angular Material theme with the generated palettes */
/* Merge the custom base colors with the generated themes */
/* Generate and encapsulate Angular Material themes */
.theme-amber .light,
.theme-amber.light {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-option-selected-state-label-text-color: #f59e0b;
  --mat-option-label-text-color: #1e293b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-optgroup-label-text-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
  --mat-app-background-color: #f1f5f9;
  --mat-app-text-color: #1e293b;
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: #64748b;
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #1e293b;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #f59e0b;
  --mdc-filled-text-field-focus-active-indicator-color: #f59e0b;
  --mdc-filled-text-field-focus-label-text-color: rgba(245, 158, 11, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(30, 41, 59, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(148, 163, 184, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(30, 41, 59, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #dc2626;
  --mdc-filled-text-field-error-focus-label-text-color: #dc2626;
  --mdc-filled-text-field-error-label-text-color: #dc2626;
  --mdc-filled-text-field-error-caret-color: #dc2626;
  --mdc-filled-text-field-active-indicator-color: rgba(226, 232, 240, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(226, 232, 240, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(226, 232, 240, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-hover-active-indicator-color: #dc2626;
  --mdc-outlined-text-field-caret-color: #f59e0b;
  --mdc-outlined-text-field-focus-outline-color: #f59e0b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(245, 158, 11, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(30, 41, 59, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(148, 163, 184, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(30, 41, 59, 0.6);
  --mdc-outlined-text-field-error-caret-color: #dc2626;
  --mdc-outlined-text-field-error-focus-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-hover-label-text-color: #dc2626;
  --mdc-outlined-text-field-outline-color: rgba(226, 232, 240, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(226, 232, 240, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(226, 232, 240, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-error-hover-outline-color: #dc2626;
  --mdc-outlined-text-field-error-outline-color: #dc2626;
  --mat-form-field-focus-select-arrow-color: rgba(245, 158, 11, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(100, 116, 139, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #dc2626;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(100, 116, 139, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(100, 116, 139, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(30, 41, 59, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(148, 163, 184, 0.38);
  --mat-select-placeholder-text-color: rgba(30, 41, 59, 0.6);
  --mat-select-enabled-arrow-color: rgba(226, 232, 240, 0.54);
  --mat-select-disabled-arrow-color: rgba(30, 41, 59, 0.38);
  --mat-select-focused-arrow-color: rgba(245, 158, 11, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: white;
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(30, 41, 59, 0.87);
  --mdc-dialog-supporting-text-color: rgba(30, 41, 59, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
  --mdc-switch-selected-focus-state-layer-color: #d97706;
  --mdc-switch-selected-handle-color: #d97706;
  --mdc-switch-selected-hover-state-layer-color: #d97706;
  --mdc-switch-selected-pressed-state-layer-color: #d97706;
  --mdc-switch-selected-focus-handle-color: #78350f;
  --mdc-switch-selected-hover-handle-color: #78350f;
  --mdc-switch-selected-pressed-handle-color: #78350f;
  --mdc-switch-selected-focus-track-color: #fcd34d;
  --mdc-switch-selected-hover-track-color: #fcd34d;
  --mdc-switch-selected-pressed-track-color: #fcd34d;
  --mdc-switch-selected-track-color: #fcd34d;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-disabled-label-text-color: #94a3b8;
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-color: #f59e0b;
  --mdc-slider-focus-handle-color: #f59e0b;
  --mdc-slider-hover-handle-color: #f59e0b;
  --mdc-slider-active-track-color: #f59e0b;
  --mdc-slider-inactive-track-color: #f59e0b;
  --mdc-slider-with-tick-marks-inactive-container-color: #f59e0b;
  --mdc-slider-with-tick-marks-active-container-color: #451a03;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: #f59e0b;
  --mat-slider-hover-state-layer-color: rgba(245, 158, 11, 0.05);
  --mat-slider-focus-state-layer-color: rgba(245, 158, 11, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-item-label-text-color: #1e293b;
  --mat-menu-item-icon-color: #1e293b;
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: #e2e8f0;
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 0;
  --mdc-list-list-item-label-text-color: #1e293b;
  --mdc-list-list-item-supporting-text-color: #64748b;
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: #94a3b8;
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: #1e293b;
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: #1e293b;
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: #94a3b8;
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: #1e293b;
  --mdc-text-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: #1e293b;
  --mdc-filled-button-disabled-container-color: rgba(148, 163, 184, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: #1e293b;
  --mdc-protected-button-disabled-container-color: rgba(148, 163, 184, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: #e2e8f0;
  --mdc-outlined-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-outlined-button-label-text-color: #1e293b;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-fab-container-color: white;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(148, 163, 184, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(148, 163, 184, 0.38);
  --mdc-fab-small-container-color: white;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(148, 163, 184, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(148, 163, 184, 0.38);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #1e293b;
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: white;
  --mat-table-header-headline-color: #1e293b;
  --mat-table-row-item-label-text-color: #1e293b;
  --mat-table-row-item-outline-color: #e2e8f0;
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #f59e0b;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #f59e0b;
  --mat-badge-text-color: #451a03;
  --mat-badge-disabled-state-background-color: #94a3b8;
  --mat-badge-disabled-state-text-color: #94a3b8;
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: #1e293b;
  --mat-bottom-sheet-container-background-color: white;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: #94a3b8;
  --mat-legacy-button-toggle-state-layer-color: #64748b;
  --mat-legacy-button-toggle-selected-state-text-color: #64748b;
  --mat-legacy-button-toggle-selected-state-background-color: #e2e8f0;
  --mat-legacy-button-toggle-disabled-state-text-color: #94a3b8;
  --mat-legacy-button-toggle-disabled-state-background-color: #cbd5e1;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #e2e8f0;
  --mat-standard-button-toggle-text-color: #1e293b;
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: #64748b;
  --mat-standard-button-toggle-selected-state-background-color: #e2e8f0;
  --mat-standard-button-toggle-selected-state-text-color: #1e293b;
  --mat-standard-button-toggle-disabled-state-text-color: #94a3b8;
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: #1e293b;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #e2e8f0;
  --mat-standard-button-toggle-divider-color: #e2e8f0;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: #451a03;
  --mat-datepicker-calendar-date-selected-state-background-color: #f59e0b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(245, 158, 11, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #451a03;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(245, 158, 11, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(245, 158, 11, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #f59e0b;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(245, 158, 11, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: #64748b;
  --mat-datepicker-calendar-body-label-text-color: #64748b;
  --mat-datepicker-calendar-period-button-text-color: #1e293b;
  --mat-datepicker-calendar-period-button-icon-color: #64748b;
  --mat-datepicker-calendar-navigation-button-icon-color: #64748b;
  --mat-datepicker-calendar-header-divider-color: #e2e8f0;
  --mat-datepicker-calendar-header-text-color: #64748b;
  --mat-datepicker-calendar-date-today-outline-color: #94a3b8;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(148, 163, 184, 0.8);
  --mat-datepicker-calendar-date-text-color: #1e293b;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: #94a3b8;
  --mat-datepicker-calendar-date-preview-state-outline-color: #e2e8f0;
  --mat-datepicker-range-input-separator-color: #1e293b;
  --mat-datepicker-range-input-disabled-state-separator-color: #94a3b8;
  --mat-datepicker-range-input-disabled-state-text-color: #94a3b8;
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: #1e293b;
  --mat-divider-width: 1px;
  --mat-divider-color: #e2e8f0;
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: #1e293b;
  --mat-expansion-actions-divider-color: #e2e8f0;
  --mat-expansion-header-hover-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-expansion-header-focus-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-expansion-header-disabled-state-text-color: #94a3b8;
  --mat-expansion-header-text-color: #1e293b;
  --mat-expansion-header-description-color: #64748b;
  --mat-expansion-header-indicator-color: #64748b;
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: #e2e8f0;
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: #1e293b;
  --mat-sidenav-content-background-color: #f1f5f9;
  --mat-sidenav-content-text-color: #1e293b;
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
  --mat-stepper-header-icon-foreground-color: #451a03;
  --mat-stepper-header-selected-state-icon-background-color: #f59e0b;
  --mat-stepper-header-selected-state-icon-foreground-color: #451a03;
  --mat-stepper-header-done-state-icon-background-color: #f59e0b;
  --mat-stepper-header-done-state-icon-foreground-color: #451a03;
  --mat-stepper-header-edit-state-icon-background-color: #f59e0b;
  --mat-stepper-header-edit-state-icon-foreground-color: #451a03;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: #e2e8f0;
  --mat-stepper-header-hover-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-stepper-header-focus-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-stepper-header-label-text-color: #64748b;
  --mat-stepper-header-optional-label-text-color: #64748b;
  --mat-stepper-header-selected-state-label-text-color: #1e293b;
  --mat-stepper-header-error-state-label-text-color: #dc2626;
  --mat-stepper-header-icon-background-color: #64748b;
  --mat-stepper-header-error-state-icon-foreground-color: #dc2626;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #64748b;
  --mat-toolbar-container-background-color: white;
  --mat-toolbar-container-text-color: #1e293b;
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: #1e293b;
}
.theme-amber .light .mat-accent,
.theme-amber.light .mat-accent {
  --mat-option-selected-state-label-text-color: #1e293b;
  --mat-option-label-text-color: #1e293b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.theme-amber .light .mat-warn,
.theme-amber.light .mat-warn {
  --mat-option-selected-state-label-text-color: #dc2626;
  --mat-option-label-text-color: #1e293b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.theme-amber .light .mat-primary,
.theme-amber.light .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #f59e0b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f59e0b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-amber .light .mat-accent,
.theme-amber.light .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-amber .light .mat-warn,
.theme-amber.light .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #dc2626;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #dc2626;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-amber .light .mat-elevation-z0, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z0,
.theme-amber.light .mat-elevation-z0,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z1, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z1,
.theme-amber.light .mat-elevation-z1,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z2, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z2,
.theme-amber.light .mat-elevation-z2,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z3, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z3,
.theme-amber.light .mat-elevation-z3,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z4, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z4,
.theme-amber.light .mat-elevation-z4,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z5, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z5,
.theme-amber.light .mat-elevation-z5,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z6, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z6,
.theme-amber.light .mat-elevation-z6,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z7, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z7,
.theme-amber.light .mat-elevation-z7,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z8, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z8,
.theme-amber.light .mat-elevation-z8,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z9, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z9,
.theme-amber.light .mat-elevation-z9,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z10, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z10,
.theme-amber.light .mat-elevation-z10,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z11, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z11,
.theme-amber.light .mat-elevation-z11,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z12, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z12,
.theme-amber.light .mat-elevation-z12,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z13, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z13,
.theme-amber.light .mat-elevation-z13,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z14, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z14,
.theme-amber.light .mat-elevation-z14,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z15, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z15,
.theme-amber.light .mat-elevation-z15,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z16, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z16,
.theme-amber.light .mat-elevation-z16,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z17, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z17,
.theme-amber.light .mat-elevation-z17,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z18, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z18,
.theme-amber.light .mat-elevation-z18,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z19, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z19,
.theme-amber.light .mat-elevation-z19,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z20, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z20,
.theme-amber.light .mat-elevation-z20,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z21, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z21,
.theme-amber.light .mat-elevation-z21,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z22, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z22,
.theme-amber.light .mat-elevation-z22,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z23, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z23,
.theme-amber.light .mat-elevation-z23,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-amber .light .mat-elevation-z24, .theme-amber .light .mat-mdc-elevation-specific.mat-elevation-z24,
.theme-amber.light .mat-elevation-z24,
.theme-amber.light .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-amber .light .mat-mdc-progress-bar,
.theme-amber.light .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #f59e0b;
  --mdc-linear-progress-track-color: rgba(245, 158, 11, 0.25);
}
.theme-amber .light .mat-mdc-progress-bar.mat-accent,
.theme-amber.light .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1e293b;
  --mdc-linear-progress-track-color: rgba(30, 41, 59, 0.25);
}
.theme-amber .light .mat-mdc-progress-bar.mat-warn,
.theme-amber.light .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc2626;
  --mdc-linear-progress-track-color: rgba(220, 38, 38, 0.25);
}
.theme-amber .light .mat-mdc-form-field.mat-accent,
.theme-amber.light .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #1e293b;
  --mdc-filled-text-field-focus-active-indicator-color: #1e293b;
  --mdc-filled-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mdc-outlined-text-field-caret-color: #1e293b;
  --mdc-outlined-text-field-focus-outline-color: #1e293b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(30, 41, 59, 0.87);
}
.theme-amber .light .mat-mdc-form-field.mat-warn,
.theme-amber.light .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #dc2626;
  --mdc-filled-text-field-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mdc-outlined-text-field-caret-color: #dc2626;
  --mdc-outlined-text-field-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-amber .light .mat-mdc-form-field.mat-accent,
.theme-amber.light .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(30, 41, 59, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(148, 163, 184, 0.38);
  --mat-select-placeholder-text-color: rgba(30, 41, 59, 0.6);
  --mat-select-enabled-arrow-color: rgba(226, 232, 240, 0.54);
  --mat-select-disabled-arrow-color: rgba(30, 41, 59, 0.38);
  --mat-select-focused-arrow-color: rgba(30, 41, 59, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-amber .light .mat-mdc-form-field.mat-warn,
.theme-amber.light .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(30, 41, 59, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(148, 163, 184, 0.38);
  --mat-select-placeholder-text-color: rgba(30, 41, 59, 0.6);
  --mat-select-enabled-arrow-color: rgba(226, 232, 240, 0.54);
  --mat-select-disabled-arrow-color: rgba(30, 41, 59, 0.38);
  --mat-select-focused-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-amber .light .mat-mdc-standard-chip,
.theme-amber.light .mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.theme-amber .light .mat-mdc-standard-chip,
.theme-amber.light .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.theme-amber .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .theme-amber .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.theme-amber.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.theme-amber.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: #451a03;
  --mdc-chip-elevated-container-color: #f59e0b;
  --mdc-chip-elevated-selected-container-color: #f59e0b;
  --mdc-chip-elevated-disabled-container-color: #f59e0b;
  --mdc-chip-flat-disabled-selected-container-color: #f59e0b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #451a03;
  --mdc-chip-selected-label-text-color: #451a03;
  --mdc-chip-with-icon-icon-color: #451a03;
  --mdc-chip-with-icon-disabled-icon-color: #451a03;
  --mdc-chip-with-icon-selected-icon-color: #451a03;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #451a03;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #451a03;
  --mat-chip-selected-disabled-trailing-icon-color: #451a03;
  --mat-chip-selected-trailing-icon-color: #451a03;
}
.theme-amber .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .theme-amber .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.theme-amber.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.theme-amber.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #1e293b;
  --mdc-chip-elevated-selected-container-color: #1e293b;
  --mdc-chip-elevated-disabled-container-color: #1e293b;
  --mdc-chip-flat-disabled-selected-container-color: #1e293b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-amber .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .theme-amber .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.theme-amber.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.theme-amber.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-selected-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-flat-disabled-selected-container-color: #dc2626;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-amber .light .mat-mdc-slide-toggle,
.theme-amber.light .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: #1e293b;
}
.theme-amber .light .mat-mdc-slide-toggle.mat-accent,
.theme-amber.light .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #475569;
  --mdc-switch-selected-handle-color: #475569;
  --mdc-switch-selected-hover-state-layer-color: #475569;
  --mdc-switch-selected-pressed-state-layer-color: #475569;
  --mdc-switch-selected-focus-handle-color: #0f172a;
  --mdc-switch-selected-hover-handle-color: #0f172a;
  --mdc-switch-selected-pressed-handle-color: #0f172a;
  --mdc-switch-selected-focus-track-color: #cbd5e1;
  --mdc-switch-selected-hover-track-color: #cbd5e1;
  --mdc-switch-selected-pressed-track-color: #cbd5e1;
  --mdc-switch-selected-track-color: #cbd5e1;
}
.theme-amber .light .mat-mdc-slide-toggle.mat-warn,
.theme-amber.light .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.theme-amber .light .mat-mdc-radio-button,
.theme-amber.light .mat-mdc-radio-button {
  --mdc-form-field-label-text-color: #1e293b;
}
.theme-amber .light .mat-mdc-radio-button.mat-primary,
.theme-amber.light .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #f59e0b;
  --mdc-radio-selected-hover-icon-color: #f59e0b;
  --mdc-radio-selected-icon-color: #f59e0b;
  --mdc-radio-selected-pressed-icon-color: #f59e0b;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #f59e0b;
  --mat-radio-disabled-label-color: #94a3b8;
}
.theme-amber .light .mat-mdc-radio-button.mat-accent,
.theme-amber.light .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #1e293b;
  --mat-radio-disabled-label-color: #94a3b8;
}
.theme-amber .light .mat-mdc-radio-button.mat-warn,
.theme-amber.light .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #dc2626;
  --mat-radio-disabled-label-color: #94a3b8;
}
.theme-amber .light .mat-accent,
.theme-amber.light .mat-accent {
  --mat-slider-ripple-color: #1e293b;
  --mat-slider-hover-state-layer-color: rgba(30, 41, 59, 0.05);
  --mat-slider-focus-state-layer-color: rgba(30, 41, 59, 0.2);
  --mdc-slider-handle-color: #1e293b;
  --mdc-slider-focus-handle-color: #1e293b;
  --mdc-slider-hover-handle-color: #1e293b;
  --mdc-slider-active-track-color: #1e293b;
  --mdc-slider-inactive-track-color: #1e293b;
  --mdc-slider-with-tick-marks-inactive-container-color: #1e293b;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-amber .light .mat-warn,
.theme-amber.light .mat-warn {
  --mat-slider-ripple-color: #dc2626;
  --mat-slider-hover-state-layer-color: rgba(220, 38, 38, 0.05);
  --mat-slider-focus-state-layer-color: rgba(220, 38, 38, 0.2);
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-amber .light .mdc-list-item__start,
.theme-amber .light .mdc-list-item__end,
.theme-amber.light .mdc-list-item__start,
.theme-amber.light .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #f59e0b;
  --mdc-radio-selected-hover-icon-color: #f59e0b;
  --mdc-radio-selected-icon-color: #f59e0b;
  --mdc-radio-selected-pressed-icon-color: #f59e0b;
}
.theme-amber .light .mat-accent .mdc-list-item__start,
.theme-amber .light .mat-accent .mdc-list-item__end,
.theme-amber.light .mat-accent .mdc-list-item__start,
.theme-amber.light .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
}
.theme-amber .light .mat-warn .mdc-list-item__start,
.theme-amber .light .mat-warn .mdc-list-item__end,
.theme-amber.light .mat-warn .mdc-list-item__start,
.theme-amber.light .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
}
.theme-amber .light .mat-mdc-list-option,
.theme-amber.light .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #451a03;
  --mdc-checkbox-selected-focus-icon-color: #f59e0b;
  --mdc-checkbox-selected-hover-icon-color: #f59e0b;
  --mdc-checkbox-selected-icon-color: #f59e0b;
  --mdc-checkbox-selected-pressed-icon-color: #f59e0b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-hover-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-pressed-state-layer-color: #f59e0b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-amber .light .mat-mdc-list-option.mat-accent,
.theme-amber.light .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-amber .light .mat-mdc-list-option.mat-warn,
.theme-amber.light .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-amber .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-amber .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-amber.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-amber.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #f59e0b;
}
.theme-amber .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #f59e0b;
}
.theme-amber .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-amber .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-amber .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.theme-amber.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-amber.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-amber.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.theme-amber .light .mat-mdc-tab-group, .theme-amber .light .mat-mdc-tab-nav-bar,
.theme-amber.light .mat-mdc-tab-group,
.theme-amber.light .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #f59e0b;
  --mat-tab-header-disabled-ripple-color: #94a3b8;
  --mat-tab-header-pagination-icon-color: #64748b;
  --mat-tab-header-inactive-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-label-text-color: #f59e0b;
  --mat-tab-header-active-ripple-color: #f59e0b;
  --mat-tab-header-inactive-ripple-color: #f59e0b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f59e0b;
  --mat-tab-header-active-hover-label-text-color: #f59e0b;
  --mat-tab-header-active-focus-indicator-color: #f59e0b;
  --mat-tab-header-active-hover-indicator-color: #f59e0b;
}
.theme-amber .light .mat-mdc-tab-group.mat-accent, .theme-amber .light .mat-mdc-tab-nav-bar.mat-accent,
.theme-amber.light .mat-mdc-tab-group.mat-accent,
.theme-amber.light .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #1e293b;
  --mat-tab-header-disabled-ripple-color: #94a3b8;
  --mat-tab-header-pagination-icon-color: #64748b;
  --mat-tab-header-inactive-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-label-text-color: #1e293b;
  --mat-tab-header-active-ripple-color: #1e293b;
  --mat-tab-header-inactive-ripple-color: #1e293b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-focus-label-text-color: #1e293b;
  --mat-tab-header-active-hover-label-text-color: #1e293b;
  --mat-tab-header-active-focus-indicator-color: #1e293b;
  --mat-tab-header-active-hover-indicator-color: #1e293b;
}
.theme-amber .light .mat-mdc-tab-group.mat-warn, .theme-amber .light .mat-mdc-tab-nav-bar.mat-warn,
.theme-amber.light .mat-mdc-tab-group.mat-warn,
.theme-amber.light .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #dc2626;
  --mat-tab-header-disabled-ripple-color: #94a3b8;
  --mat-tab-header-pagination-icon-color: #64748b;
  --mat-tab-header-inactive-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-label-text-color: #dc2626;
  --mat-tab-header-active-ripple-color: #dc2626;
  --mat-tab-header-inactive-ripple-color: #dc2626;
  --mat-tab-header-inactive-focus-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-focus-label-text-color: #dc2626;
  --mat-tab-header-active-hover-label-text-color: #dc2626;
  --mat-tab-header-active-focus-indicator-color: #dc2626;
  --mat-tab-header-active-hover-indicator-color: #dc2626;
}
.theme-amber .light .mat-mdc-tab-group.mat-background-primary, .theme-amber .light .mat-mdc-tab-nav-bar.mat-background-primary,
.theme-amber.light .mat-mdc-tab-group.mat-background-primary,
.theme-amber.light .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #f59e0b;
  --mat-tab-header-with-background-foreground-color: #451a03;
}
.theme-amber .light .mat-mdc-tab-group.mat-background-accent, .theme-amber .light .mat-mdc-tab-nav-bar.mat-background-accent,
.theme-amber.light .mat-mdc-tab-group.mat-background-accent,
.theme-amber.light .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #1e293b;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-amber .light .mat-mdc-tab-group.mat-background-warn, .theme-amber .light .mat-mdc-tab-nav-bar.mat-background-warn,
.theme-amber.light .mat-mdc-tab-group.mat-background-warn,
.theme-amber.light .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #dc2626;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-amber .light .mat-mdc-checkbox,
.theme-amber.light .mat-mdc-checkbox {
  --mdc-form-field-label-text-color: #1e293b;
}
.theme-amber .light .mat-mdc-checkbox.mat-primary,
.theme-amber.light .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #451a03;
  --mdc-checkbox-selected-focus-icon-color: #f59e0b;
  --mdc-checkbox-selected-hover-icon-color: #f59e0b;
  --mdc-checkbox-selected-icon-color: #f59e0b;
  --mdc-checkbox-selected-pressed-icon-color: #f59e0b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-hover-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-pressed-state-layer-color: #f59e0b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-amber .light .mat-mdc-checkbox.mat-warn,
.theme-amber.light .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-amber .light .mat-mdc-button.mat-primary,
.theme-amber.light .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #f59e0b;
  --mat-text-button-state-layer-color: #f59e0b;
  --mat-text-button-ripple-color: rgba(245, 158, 11, 0.1);
}
.theme-amber .light .mat-mdc-button.mat-accent,
.theme-amber.light .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1e293b;
  --mat-text-button-state-layer-color: #1e293b;
  --mat-text-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-amber .light .mat-mdc-button.mat-warn,
.theme-amber.light .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc2626;
  --mat-text-button-state-layer-color: #dc2626;
  --mat-text-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-amber .light .mat-mdc-unelevated-button.mat-primary,
.theme-amber.light .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #f59e0b;
  --mdc-filled-button-label-text-color: #451a03;
  --mat-filled-button-state-layer-color: #451a03;
  --mat-filled-button-ripple-color: rgba(69, 26, 3, 0.1);
}
.theme-amber .light .mat-mdc-unelevated-button.mat-accent,
.theme-amber.light .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-amber .light .mat-mdc-unelevated-button.mat-warn,
.theme-amber.light .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-amber .light .mat-mdc-raised-button.mat-primary,
.theme-amber.light .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #f59e0b;
  --mdc-protected-button-label-text-color: #451a03;
  --mat-protected-button-state-layer-color: #451a03;
  --mat-protected-button-ripple-color: rgba(69, 26, 3, 0.1);
}
.theme-amber .light .mat-mdc-raised-button.mat-accent,
.theme-amber.light .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-amber .light .mat-mdc-raised-button.mat-warn,
.theme-amber.light .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-amber .light .mat-mdc-outlined-button.mat-primary,
.theme-amber.light .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #f59e0b;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: #f59e0b;
  --mat-outlined-button-ripple-color: rgba(245, 158, 11, 0.1);
}
.theme-amber .light .mat-mdc-outlined-button.mat-accent,
.theme-amber.light .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1e293b;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: #1e293b;
  --mat-outlined-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-amber .light .mat-mdc-outlined-button.mat-warn,
.theme-amber.light .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc2626;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: #dc2626;
  --mat-outlined-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-amber .light .mat-mdc-icon-button.mat-primary,
.theme-amber.light .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #f59e0b;
  --mat-icon-button-state-layer-color: #f59e0b;
  --mat-icon-button-ripple-color: rgba(245, 158, 11, 0.1);
}
.theme-amber .light .mat-mdc-icon-button.mat-accent,
.theme-amber.light .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1e293b;
  --mat-icon-button-state-layer-color: #1e293b;
  --mat-icon-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-amber .light .mat-mdc-icon-button.mat-warn,
.theme-amber.light .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc2626;
  --mat-icon-button-state-layer-color: #dc2626;
  --mat-icon-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-amber .light .mat-mdc-fab.mat-primary,
.theme-amber.light .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #f59e0b;
  --mat-fab-foreground-color: #451a03;
  --mat-fab-state-layer-color: #451a03;
  --mat-fab-ripple-color: rgba(69, 26, 3, 0.1);
}
.theme-amber .light .mat-mdc-fab.mat-accent,
.theme-amber.light .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #1e293b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-amber .light .mat-mdc-fab.mat-warn,
.theme-amber.light .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #dc2626;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-amber .light .mat-mdc-mini-fab.mat-primary,
.theme-amber.light .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #f59e0b;
  --mat-fab-small-foreground-color: #451a03;
  --mat-fab-small-state-layer-color: #451a03;
  --mat-fab-small-ripple-color: rgba(69, 26, 3, 0.1);
}
.theme-amber .light .mat-mdc-mini-fab.mat-accent,
.theme-amber.light .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #1e293b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-amber .light .mat-mdc-mini-fab.mat-warn,
.theme-amber.light .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #dc2626;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-amber .light .mat-accent,
.theme-amber.light .mat-accent {
  --mdc-circular-progress-active-indicator-color: #1e293b;
}
.theme-amber .light .mat-warn,
.theme-amber.light .mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}
.theme-amber .light .mat-badge-accent,
.theme-amber.light .mat-badge-accent {
  --mat-badge-background-color: #1e293b;
  --mat-badge-text-color: white;
}
.theme-amber .light .mat-badge-warn,
.theme-amber.light .mat-badge-warn {
  --mat-badge-background-color: #dc2626;
  --mat-badge-text-color: white;
}
.theme-amber .light .mat-datepicker-content.mat-accent,
.theme-amber.light .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #1e293b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(30, 41, 59, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(30, 41, 59, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-amber .light .mat-datepicker-content.mat-warn,
.theme-amber.light .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #dc2626;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(220, 38, 38, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(220, 38, 38, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-amber .light .mat-datepicker-toggle-active.mat-accent,
.theme-amber.light .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #1e293b;
}
.theme-amber .light .mat-datepicker-toggle-active.mat-warn,
.theme-amber.light .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #dc2626;
}
.theme-amber .light .mat-icon.mat-primary,
.theme-amber.light .mat-icon.mat-primary {
  --mat-icon-color: #f59e0b;
}
.theme-amber .light .mat-icon.mat-accent,
.theme-amber.light .mat-icon.mat-accent {
  --mat-icon-color: #1e293b;
}
.theme-amber .light .mat-icon.mat-warn,
.theme-amber.light .mat-icon.mat-warn {
  --mat-icon-color: #dc2626;
}
.theme-amber .light .mat-step-header.mat-accent,
.theme-amber.light .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #1e293b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #1e293b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #1e293b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-amber .light .mat-step-header.mat-warn,
.theme-amber.light .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #dc2626;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #dc2626;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #dc2626;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-amber .light .mat-toolbar.mat-primary,
.theme-amber.light .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #f59e0b;
  --mat-toolbar-container-text-color: #451a03;
}
.theme-amber .light .mat-toolbar.mat-accent,
.theme-amber.light .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #1e293b;
  --mat-toolbar-container-text-color: white;
}
.theme-amber .light .mat-toolbar.mat-warn,
.theme-amber.light .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #dc2626;
  --mat-toolbar-container-text-color: white;
}

.theme-amber .dark,
.theme-amber.dark {
  --mat-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-option-selected-state-label-text-color: #f59e0b;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-optgroup-label-text-color: white;
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
  --mat-app-background-color: #0f172a;
  --mat-app-text-color: white;
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: #1e293b;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: #1e293b;
  --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: #94a3b8;
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #64748b;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #f59e0b;
  --mdc-filled-text-field-focus-active-indicator-color: #f59e0b;
  --mdc-filled-text-field-focus-label-text-color: rgba(245, 158, 11, 0.87);
  --mdc-filled-text-field-container-color: #273243;
  --mdc-filled-text-field-disabled-container-color: #232d3f;
  --mdc-filled-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(71, 85, 105, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(71, 85, 105, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #dc2626;
  --mdc-filled-text-field-error-focus-label-text-color: #dc2626;
  --mdc-filled-text-field-error-label-text-color: #dc2626;
  --mdc-filled-text-field-error-caret-color: #dc2626;
  --mdc-filled-text-field-active-indicator-color: rgba(241, 245, 249, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(241, 245, 249, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(241, 245, 249, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-hover-active-indicator-color: #dc2626;
  --mdc-outlined-text-field-caret-color: #f59e0b;
  --mdc-outlined-text-field-focus-outline-color: #f59e0b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(245, 158, 11, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(71, 85, 105, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(71, 85, 105, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-error-caret-color: #dc2626;
  --mdc-outlined-text-field-error-focus-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-hover-label-text-color: #dc2626;
  --mdc-outlined-text-field-outline-color: rgba(241, 245, 249, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(241, 245, 249, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(241, 245, 249, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-error-hover-outline-color: #dc2626;
  --mdc-outlined-text-field-error-outline-color: #dc2626;
  --mat-form-field-focus-select-arrow-color: rgba(245, 158, 11, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(241, 245, 249, 0.38);
  --mat-form-field-state-layer-color: rgba(255, 255, 255, 0.87);
  --mat-form-field-error-text-color: #dc2626;
  --mat-form-field-select-option-text-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-select-disabled-option-text-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(241, 245, 249, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.08;
  --mat-form-field-focus-state-layer-opacity: 0.24;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: #1e293b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(71, 85, 105, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(245, 158, 11, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: #1e293b;
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: #1e293b;
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
  --mdc-switch-selected-focus-state-layer-color: #fcd34d;
  --mdc-switch-selected-handle-color: #fcd34d;
  --mdc-switch-selected-hover-state-layer-color: #fcd34d;
  --mdc-switch-selected-pressed-state-layer-color: #fcd34d;
  --mdc-switch-selected-focus-handle-color: #fde68a;
  --mdc-switch-selected-hover-handle-color: #fde68a;
  --mdc-switch-selected-pressed-handle-color: #fde68a;
  --mdc-switch-selected-focus-track-color: #d97706;
  --mdc-switch-selected-hover-track-color: #d97706;
  --mdc-switch-selected-pressed-track-color: #d97706;
  --mdc-switch-selected-track-color: #d97706;
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-icon-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-disabled-label-text-color: #475569;
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-color: #f59e0b;
  --mdc-slider-focus-handle-color: #f59e0b;
  --mdc-slider-hover-handle-color: #f59e0b;
  --mdc-slider-active-track-color: #f59e0b;
  --mdc-slider-inactive-track-color: #f59e0b;
  --mdc-slider-with-tick-marks-inactive-container-color: #f59e0b;
  --mdc-slider-with-tick-marks-active-container-color: #451a03;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-label-container-color: #fff;
  --mdc-slider-label-label-text-color: #000;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: #f59e0b;
  --mat-slider-hover-state-layer-color: rgba(245, 158, 11, 0.05);
  --mat-slider-focus-state-layer-color: rgba(245, 158, 11, 0.2);
  --mat-slider-value-indicator-opacity: 0.9;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-item-label-text-color: white;
  --mat-menu-item-icon-color: white;
  --mat-menu-item-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-container-color: #1e293b;
  --mat-menu-divider-color: rgba(241, 245, 249, 0.12);
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 0;
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: #94a3b8;
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: #64748b;
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: white;
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
  --mat-paginator-container-text-color: rgba(255, 255, 255, 0.87);
  --mat-paginator-container-background-color: #1e293b;
  --mat-paginator-enabled-icon-color: rgba(255, 255, 255, 0.54);
  --mat-paginator-disabled-icon-color: rgba(255, 255, 255, 0.12);
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
  --mat-checkbox-disabled-label-color: #475569;
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: white;
  --mdc-text-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mat-text-button-state-layer-color: white;
  --mat-text-button-disabled-state-layer-color: white;
  --mat-text-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.08;
  --mat-text-button-focus-state-layer-opacity: 0.24;
  --mat-text-button-pressed-state-layer-opacity: 0.24;
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: white;
  --mdc-filled-button-disabled-container-color: rgba(30, 41, 59, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-disabled-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.08;
  --mat-filled-button-focus-state-layer-opacity: 0.24;
  --mat-filled-button-pressed-state-layer-opacity: 0.24;
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: white;
  --mdc-protected-button-disabled-container-color: rgba(30, 41, 59, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-disabled-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.08;
  --mat-protected-button-focus-state-layer-opacity: 0.24;
  --mat-protected-button-pressed-state-layer-opacity: 0.24;
  --mdc-outlined-button-disabled-outline-color: rgba(241, 245, 249, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mdc-outlined-button-label-text-color: white;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: white;
  --mat-outlined-button-disabled-state-layer-color: white;
  --mat-outlined-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.08;
  --mat-outlined-button-focus-state-layer-opacity: 0.24;
  --mat-outlined-button-pressed-state-layer-opacity: 0.24;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
  --mat-icon-button-state-layer-color: white;
  --mat-icon-button-disabled-state-layer-color: white;
  --mat-icon-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.08;
  --mat-icon-button-focus-state-layer-opacity: 0.24;
  --mat-icon-button-pressed-state-layer-opacity: 0.24;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-fab-container-color: #1e293b;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-disabled-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.08;
  --mat-fab-focus-state-layer-opacity: 0.24;
  --mat-fab-pressed-state-layer-opacity: 0.24;
  --mat-fab-disabled-state-container-color: rgba(15, 23, 42, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(30, 41, 59, 0.5);
  --mdc-fab-small-container-color: #1e293b;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-disabled-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.08;
  --mat-fab-small-focus-state-layer-opacity: 0.24;
  --mat-fab-small-pressed-state-layer-opacity: 0.24;
  --mat-fab-small-disabled-state-container-color: rgba(15, 23, 42, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(30, 41, 59, 0.5);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #d2d4d8;
  --mdc-snackbar-supporting-text-color: rgba(30, 41, 59, 0.87);
  --mat-snack-bar-button-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: #1e293b;
  --mat-table-header-headline-color: white;
  --mat-table-row-item-label-text-color: white;
  --mat-table-row-item-outline-color: rgba(241, 245, 249, 0.12);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #f59e0b;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #f59e0b;
  --mat-badge-text-color: #451a03;
  --mat-badge-disabled-state-background-color: #1e293b;
  --mat-badge-disabled-state-text-color: #475569;
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: white;
  --mat-bottom-sheet-container-background-color: #1e293b;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: #64748b;
  --mat-legacy-button-toggle-state-layer-color: #e2e8f0;
  --mat-legacy-button-toggle-selected-state-text-color: #94a3b8;
  --mat-legacy-button-toggle-selected-state-background-color: rgba(255, 255, 255, 0.05);
  --mat-legacy-button-toggle-disabled-state-text-color: #1e293b;
  --mat-legacy-button-toggle-disabled-state-background-color: #0f172a;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #1e293b;
  --mat-standard-button-toggle-text-color: white;
  --mat-standard-button-toggle-background-color: #1e293b;
  --mat-standard-button-toggle-state-layer-color: #e2e8f0;
  --mat-standard-button-toggle-selected-state-background-color: rgba(255, 255, 255, 0.05);
  --mat-standard-button-toggle-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-state-text-color: #1e293b;
  --mat-standard-button-toggle-disabled-state-background-color: #1e293b;
  --mat-standard-button-toggle-disabled-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #1e293b;
  --mat-standard-button-toggle-divider-color: #374152;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: #451a03;
  --mat-datepicker-calendar-date-selected-state-background-color: #f59e0b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(245, 158, 11, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #451a03;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(245, 158, 11, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(245, 158, 11, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #f59e0b;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(245, 158, 11, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: #f1f5f9;
  --mat-datepicker-calendar-body-label-text-color: #94a3b8;
  --mat-datepicker-calendar-period-button-text-color: white;
  --mat-datepicker-calendar-period-button-icon-color: #f1f5f9;
  --mat-datepicker-calendar-navigation-button-icon-color: #f1f5f9;
  --mat-datepicker-calendar-header-divider-color: rgba(241, 245, 249, 0.12);
  --mat-datepicker-calendar-header-text-color: #94a3b8;
  --mat-datepicker-calendar-date-today-outline-color: #64748b;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(100, 116, 139, 0.8);
  --mat-datepicker-calendar-date-text-color: white;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: #475569;
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(241, 245, 249, 0.24);
  --mat-datepicker-range-input-separator-color: white;
  --mat-datepicker-range-input-disabled-state-separator-color: #475569;
  --mat-datepicker-range-input-disabled-state-text-color: #475569;
  --mat-datepicker-calendar-container-background-color: #1e293b;
  --mat-datepicker-calendar-container-text-color: white;
  --mat-divider-width: 1px;
  --mat-divider-color: rgba(241, 245, 249, 0.12);
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: #1e293b;
  --mat-expansion-container-text-color: white;
  --mat-expansion-actions-divider-color: rgba(241, 245, 249, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-expansion-header-focus-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-expansion-header-disabled-state-text-color: #1e293b;
  --mat-expansion-header-text-color: white;
  --mat-expansion-header-description-color: #94a3b8;
  --mat-expansion-header-indicator-color: #94a3b8;
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: rgba(241, 245, 249, 0.12);
  --mat-sidenav-container-background-color: #1e293b;
  --mat-sidenav-container-text-color: white;
  --mat-sidenav-content-background-color: #0f172a;
  --mat-sidenav-content-text-color: white;
  --mat-sidenav-scrim-color: rgba(225, 214, 196, 0.6);
  --mat-stepper-header-icon-foreground-color: #451a03;
  --mat-stepper-header-selected-state-icon-background-color: #f59e0b;
  --mat-stepper-header-selected-state-icon-foreground-color: #451a03;
  --mat-stepper-header-done-state-icon-background-color: #f59e0b;
  --mat-stepper-header-done-state-icon-foreground-color: #451a03;
  --mat-stepper-header-edit-state-icon-background-color: #f59e0b;
  --mat-stepper-header-edit-state-icon-foreground-color: #451a03;
  --mat-stepper-container-color: #1e293b;
  --mat-stepper-line-color: rgba(241, 245, 249, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-stepper-header-focus-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-stepper-header-label-text-color: #94a3b8;
  --mat-stepper-header-optional-label-text-color: #94a3b8;
  --mat-stepper-header-selected-state-label-text-color: white;
  --mat-stepper-header-error-state-label-text-color: #dc2626;
  --mat-stepper-header-icon-background-color: #94a3b8;
  --mat-stepper-header-error-state-icon-foreground-color: #dc2626;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #94a3b8;
  --mat-toolbar-container-background-color: #0f172a;
  --mat-toolbar-container-text-color: white;
  --mat-tree-container-background-color: #1e293b;
  --mat-tree-node-text-color: white;
}
.theme-amber .dark .mat-accent,
.theme-amber.dark .mat-accent {
  --mat-option-selected-state-label-text-color: #1e293b;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.theme-amber .dark .mat-warn,
.theme-amber.dark .mat-warn {
  --mat-option-selected-state-label-text-color: #dc2626;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.theme-amber .dark .mat-primary,
.theme-amber.dark .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #f59e0b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f59e0b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-amber .dark .mat-accent,
.theme-amber.dark .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-amber .dark .mat-warn,
.theme-amber.dark .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #dc2626;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #dc2626;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-amber .dark .mat-elevation-z0, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z0,
.theme-amber.dark .mat-elevation-z0,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z1, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z1,
.theme-amber.dark .mat-elevation-z1,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z2, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z2,
.theme-amber.dark .mat-elevation-z2,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z3, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z3,
.theme-amber.dark .mat-elevation-z3,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z4, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z4,
.theme-amber.dark .mat-elevation-z4,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z5, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z5,
.theme-amber.dark .mat-elevation-z5,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z6, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z6,
.theme-amber.dark .mat-elevation-z6,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z7, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z7,
.theme-amber.dark .mat-elevation-z7,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z8, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z8,
.theme-amber.dark .mat-elevation-z8,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z9, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z9,
.theme-amber.dark .mat-elevation-z9,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z10, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z10,
.theme-amber.dark .mat-elevation-z10,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z11, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z11,
.theme-amber.dark .mat-elevation-z11,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z12, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z12,
.theme-amber.dark .mat-elevation-z12,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z13, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z13,
.theme-amber.dark .mat-elevation-z13,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z14, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z14,
.theme-amber.dark .mat-elevation-z14,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z15, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z15,
.theme-amber.dark .mat-elevation-z15,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z16, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z16,
.theme-amber.dark .mat-elevation-z16,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z17, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z17,
.theme-amber.dark .mat-elevation-z17,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z18, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z18,
.theme-amber.dark .mat-elevation-z18,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z19, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z19,
.theme-amber.dark .mat-elevation-z19,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z20, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z20,
.theme-amber.dark .mat-elevation-z20,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z21, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z21,
.theme-amber.dark .mat-elevation-z21,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z22, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z22,
.theme-amber.dark .mat-elevation-z22,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z23, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z23,
.theme-amber.dark .mat-elevation-z23,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-amber .dark .mat-elevation-z24, .theme-amber .dark .mat-mdc-elevation-specific.mat-elevation-z24,
.theme-amber.dark .mat-elevation-z24,
.theme-amber.dark .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-amber .dark .mat-mdc-progress-bar,
.theme-amber.dark .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #f59e0b;
  --mdc-linear-progress-track-color: rgba(245, 158, 11, 0.25);
}
.theme-amber .dark .mat-mdc-progress-bar.mat-accent,
.theme-amber.dark .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1e293b;
  --mdc-linear-progress-track-color: rgba(30, 41, 59, 0.25);
}
.theme-amber .dark .mat-mdc-progress-bar.mat-warn,
.theme-amber.dark .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc2626;
  --mdc-linear-progress-track-color: rgba(220, 38, 38, 0.25);
}
.theme-amber .dark .mat-mdc-form-field.mat-accent,
.theme-amber.dark .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #1e293b;
  --mdc-filled-text-field-focus-active-indicator-color: #1e293b;
  --mdc-filled-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mdc-outlined-text-field-caret-color: #1e293b;
  --mdc-outlined-text-field-focus-outline-color: #1e293b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(30, 41, 59, 0.87);
}
.theme-amber .dark .mat-mdc-form-field.mat-warn,
.theme-amber.dark .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #dc2626;
  --mdc-filled-text-field-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mdc-outlined-text-field-caret-color: #dc2626;
  --mdc-outlined-text-field-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-amber .dark .mat-mdc-form-field.mat-accent,
.theme-amber.dark .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: #1e293b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(71, 85, 105, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(30, 41, 59, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-amber .dark .mat-mdc-form-field.mat-warn,
.theme-amber.dark .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: #1e293b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(71, 85, 105, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-amber .dark .mat-mdc-standard-chip,
.theme-amber.dark .mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.theme-amber .dark .mat-mdc-standard-chip,
.theme-amber.dark .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-elevated-container-color: #394353;
  --mdc-chip-elevated-selected-container-color: #394353;
  --mdc-chip-elevated-disabled-container-color: #394353;
  --mdc-chip-flat-disabled-selected-container-color: #394353;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-selected-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
  --mat-chip-selected-disabled-trailing-icon-color: #fafafa;
  --mat-chip-selected-trailing-icon-color: #fafafa;
}
.theme-amber .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .theme-amber .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.theme-amber.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.theme-amber.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: #451a03;
  --mdc-chip-elevated-container-color: #f59e0b;
  --mdc-chip-elevated-selected-container-color: #f59e0b;
  --mdc-chip-elevated-disabled-container-color: #f59e0b;
  --mdc-chip-flat-disabled-selected-container-color: #f59e0b;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #451a03;
  --mdc-chip-selected-label-text-color: #451a03;
  --mdc-chip-with-icon-icon-color: #451a03;
  --mdc-chip-with-icon-disabled-icon-color: #451a03;
  --mdc-chip-with-icon-selected-icon-color: #451a03;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #451a03;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #451a03;
  --mat-chip-selected-disabled-trailing-icon-color: #451a03;
  --mat-chip-selected-trailing-icon-color: #451a03;
}
.theme-amber .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .theme-amber .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.theme-amber.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.theme-amber.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #1e293b;
  --mdc-chip-elevated-selected-container-color: #1e293b;
  --mdc-chip-elevated-disabled-container-color: #1e293b;
  --mdc-chip-flat-disabled-selected-container-color: #1e293b;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-amber .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .theme-amber .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.theme-amber.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.theme-amber.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-selected-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-flat-disabled-selected-container-color: #dc2626;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-amber .dark .mat-mdc-slide-toggle,
.theme-amber.dark .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: white;
}
.theme-amber .dark .mat-mdc-slide-toggle.mat-accent,
.theme-amber.dark .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #cbd5e1;
  --mdc-switch-selected-handle-color: #cbd5e1;
  --mdc-switch-selected-hover-state-layer-color: #cbd5e1;
  --mdc-switch-selected-pressed-state-layer-color: #cbd5e1;
  --mdc-switch-selected-focus-handle-color: #e2e8f0;
  --mdc-switch-selected-hover-handle-color: #e2e8f0;
  --mdc-switch-selected-pressed-handle-color: #e2e8f0;
  --mdc-switch-selected-focus-track-color: #475569;
  --mdc-switch-selected-hover-track-color: #475569;
  --mdc-switch-selected-pressed-track-color: #475569;
  --mdc-switch-selected-track-color: #475569;
}
.theme-amber .dark .mat-mdc-slide-toggle.mat-warn,
.theme-amber.dark .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #fca5a5;
  --mdc-switch-selected-handle-color: #fca5a5;
  --mdc-switch-selected-hover-state-layer-color: #fca5a5;
  --mdc-switch-selected-pressed-state-layer-color: #fca5a5;
  --mdc-switch-selected-focus-handle-color: #fecaca;
  --mdc-switch-selected-hover-handle-color: #fecaca;
  --mdc-switch-selected-pressed-handle-color: #fecaca;
  --mdc-switch-selected-focus-track-color: #dc2626;
  --mdc-switch-selected-hover-track-color: #dc2626;
  --mdc-switch-selected-pressed-track-color: #dc2626;
  --mdc-switch-selected-track-color: #dc2626;
}
.theme-amber .dark .mat-mdc-radio-button,
.theme-amber.dark .mat-mdc-radio-button {
  --mdc-form-field-label-text-color: white;
}
.theme-amber .dark .mat-mdc-radio-button.mat-primary,
.theme-amber.dark .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #f59e0b;
  --mdc-radio-selected-hover-icon-color: #f59e0b;
  --mdc-radio-selected-icon-color: #f59e0b;
  --mdc-radio-selected-pressed-icon-color: #f59e0b;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #f59e0b;
  --mat-radio-disabled-label-color: #475569;
}
.theme-amber .dark .mat-mdc-radio-button.mat-accent,
.theme-amber.dark .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #1e293b;
  --mat-radio-disabled-label-color: #475569;
}
.theme-amber .dark .mat-mdc-radio-button.mat-warn,
.theme-amber.dark .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #dc2626;
  --mat-radio-disabled-label-color: #475569;
}
.theme-amber .dark .mat-accent,
.theme-amber.dark .mat-accent {
  --mat-slider-ripple-color: #1e293b;
  --mat-slider-hover-state-layer-color: rgba(30, 41, 59, 0.05);
  --mat-slider-focus-state-layer-color: rgba(30, 41, 59, 0.2);
  --mdc-slider-handle-color: #1e293b;
  --mdc-slider-focus-handle-color: #1e293b;
  --mdc-slider-hover-handle-color: #1e293b;
  --mdc-slider-active-track-color: #1e293b;
  --mdc-slider-inactive-track-color: #1e293b;
  --mdc-slider-with-tick-marks-inactive-container-color: #1e293b;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-amber .dark .mat-warn,
.theme-amber.dark .mat-warn {
  --mat-slider-ripple-color: #dc2626;
  --mat-slider-hover-state-layer-color: rgba(220, 38, 38, 0.05);
  --mat-slider-focus-state-layer-color: rgba(220, 38, 38, 0.2);
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-amber .dark .mdc-list-item__start,
.theme-amber .dark .mdc-list-item__end,
.theme-amber.dark .mdc-list-item__start,
.theme-amber.dark .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #f59e0b;
  --mdc-radio-selected-hover-icon-color: #f59e0b;
  --mdc-radio-selected-icon-color: #f59e0b;
  --mdc-radio-selected-pressed-icon-color: #f59e0b;
}
.theme-amber .dark .mat-accent .mdc-list-item__start,
.theme-amber .dark .mat-accent .mdc-list-item__end,
.theme-amber.dark .mat-accent .mdc-list-item__start,
.theme-amber.dark .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
}
.theme-amber .dark .mat-warn .mdc-list-item__start,
.theme-amber .dark .mat-warn .mdc-list-item__end,
.theme-amber.dark .mat-warn .mdc-list-item__start,
.theme-amber.dark .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
}
.theme-amber .dark .mat-mdc-list-option,
.theme-amber.dark .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #451a03;
  --mdc-checkbox-selected-focus-icon-color: #f59e0b;
  --mdc-checkbox-selected-hover-icon-color: #f59e0b;
  --mdc-checkbox-selected-icon-color: #f59e0b;
  --mdc-checkbox-selected-pressed-icon-color: #f59e0b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-hover-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-pressed-state-layer-color: #f59e0b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-amber .dark .mat-mdc-list-option.mat-accent,
.theme-amber.dark .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-amber .dark .mat-mdc-list-option.mat-warn,
.theme-amber.dark .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-amber .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-amber .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-amber.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-amber.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #f59e0b;
}
.theme-amber .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-amber.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #f59e0b;
}
.theme-amber .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-amber .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-amber .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.theme-amber.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-amber.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-amber.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.theme-amber .dark .mat-mdc-tab-group, .theme-amber .dark .mat-mdc-tab-nav-bar,
.theme-amber.dark .mat-mdc-tab-group,
.theme-amber.dark .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #f59e0b;
  --mat-tab-header-disabled-ripple-color: #475569;
  --mat-tab-header-pagination-icon-color: #f1f5f9;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #f59e0b;
  --mat-tab-header-active-ripple-color: #f59e0b;
  --mat-tab-header-inactive-ripple-color: #f59e0b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f59e0b;
  --mat-tab-header-active-hover-label-text-color: #f59e0b;
  --mat-tab-header-active-focus-indicator-color: #f59e0b;
  --mat-tab-header-active-hover-indicator-color: #f59e0b;
}
.theme-amber .dark .mat-mdc-tab-group.mat-accent, .theme-amber .dark .mat-mdc-tab-nav-bar.mat-accent,
.theme-amber.dark .mat-mdc-tab-group.mat-accent,
.theme-amber.dark .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #1e293b;
  --mat-tab-header-disabled-ripple-color: #475569;
  --mat-tab-header-pagination-icon-color: #f1f5f9;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #1e293b;
  --mat-tab-header-active-ripple-color: #1e293b;
  --mat-tab-header-inactive-ripple-color: #1e293b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #1e293b;
  --mat-tab-header-active-hover-label-text-color: #1e293b;
  --mat-tab-header-active-focus-indicator-color: #1e293b;
  --mat-tab-header-active-hover-indicator-color: #1e293b;
}
.theme-amber .dark .mat-mdc-tab-group.mat-warn, .theme-amber .dark .mat-mdc-tab-nav-bar.mat-warn,
.theme-amber.dark .mat-mdc-tab-group.mat-warn,
.theme-amber.dark .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #dc2626;
  --mat-tab-header-disabled-ripple-color: #475569;
  --mat-tab-header-pagination-icon-color: #f1f5f9;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #dc2626;
  --mat-tab-header-active-ripple-color: #dc2626;
  --mat-tab-header-inactive-ripple-color: #dc2626;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #dc2626;
  --mat-tab-header-active-hover-label-text-color: #dc2626;
  --mat-tab-header-active-focus-indicator-color: #dc2626;
  --mat-tab-header-active-hover-indicator-color: #dc2626;
}
.theme-amber .dark .mat-mdc-tab-group.mat-background-primary, .theme-amber .dark .mat-mdc-tab-nav-bar.mat-background-primary,
.theme-amber.dark .mat-mdc-tab-group.mat-background-primary,
.theme-amber.dark .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #f59e0b;
  --mat-tab-header-with-background-foreground-color: #451a03;
}
.theme-amber .dark .mat-mdc-tab-group.mat-background-accent, .theme-amber .dark .mat-mdc-tab-nav-bar.mat-background-accent,
.theme-amber.dark .mat-mdc-tab-group.mat-background-accent,
.theme-amber.dark .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #1e293b;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-amber .dark .mat-mdc-tab-group.mat-background-warn, .theme-amber .dark .mat-mdc-tab-nav-bar.mat-background-warn,
.theme-amber.dark .mat-mdc-tab-group.mat-background-warn,
.theme-amber.dark .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #dc2626;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-amber .dark .mat-mdc-checkbox,
.theme-amber.dark .mat-mdc-checkbox {
  --mdc-form-field-label-text-color: white;
}
.theme-amber .dark .mat-mdc-checkbox.mat-primary,
.theme-amber.dark .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #451a03;
  --mdc-checkbox-selected-focus-icon-color: #f59e0b;
  --mdc-checkbox-selected-hover-icon-color: #f59e0b;
  --mdc-checkbox-selected-icon-color: #f59e0b;
  --mdc-checkbox-selected-pressed-icon-color: #f59e0b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-hover-state-layer-color: #f59e0b;
  --mdc-checkbox-selected-pressed-state-layer-color: #f59e0b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-amber .dark .mat-mdc-checkbox.mat-warn,
.theme-amber.dark .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-amber .dark .mat-mdc-button.mat-primary,
.theme-amber.dark .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #f59e0b;
  --mat-text-button-state-layer-color: #f59e0b;
  --mat-text-button-ripple-color: rgba(245, 158, 11, 0.1);
}
.theme-amber .dark .mat-mdc-button.mat-accent,
.theme-amber.dark .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1e293b;
  --mat-text-button-state-layer-color: #1e293b;
  --mat-text-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-amber .dark .mat-mdc-button.mat-warn,
.theme-amber.dark .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc2626;
  --mat-text-button-state-layer-color: #dc2626;
  --mat-text-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-amber .dark .mat-mdc-unelevated-button.mat-primary,
.theme-amber.dark .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #f59e0b;
  --mdc-filled-button-label-text-color: #451a03;
  --mat-filled-button-state-layer-color: #451a03;
  --mat-filled-button-ripple-color: rgba(69, 26, 3, 0.1);
}
.theme-amber .dark .mat-mdc-unelevated-button.mat-accent,
.theme-amber.dark .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-amber .dark .mat-mdc-unelevated-button.mat-warn,
.theme-amber.dark .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-amber .dark .mat-mdc-raised-button.mat-primary,
.theme-amber.dark .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #f59e0b;
  --mdc-protected-button-label-text-color: #451a03;
  --mat-protected-button-state-layer-color: #451a03;
  --mat-protected-button-ripple-color: rgba(69, 26, 3, 0.1);
}
.theme-amber .dark .mat-mdc-raised-button.mat-accent,
.theme-amber.dark .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-amber .dark .mat-mdc-raised-button.mat-warn,
.theme-amber.dark .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-amber .dark .mat-mdc-outlined-button.mat-primary,
.theme-amber.dark .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #f59e0b;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: #f59e0b;
  --mat-outlined-button-ripple-color: rgba(245, 158, 11, 0.1);
}
.theme-amber .dark .mat-mdc-outlined-button.mat-accent,
.theme-amber.dark .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1e293b;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: #1e293b;
  --mat-outlined-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-amber .dark .mat-mdc-outlined-button.mat-warn,
.theme-amber.dark .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc2626;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: #dc2626;
  --mat-outlined-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-amber .dark .mat-mdc-icon-button.mat-primary,
.theme-amber.dark .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #f59e0b;
  --mat-icon-button-state-layer-color: #f59e0b;
  --mat-icon-button-ripple-color: rgba(245, 158, 11, 0.1);
}
.theme-amber .dark .mat-mdc-icon-button.mat-accent,
.theme-amber.dark .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1e293b;
  --mat-icon-button-state-layer-color: #1e293b;
  --mat-icon-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-amber .dark .mat-mdc-icon-button.mat-warn,
.theme-amber.dark .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc2626;
  --mat-icon-button-state-layer-color: #dc2626;
  --mat-icon-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-amber .dark .mat-mdc-fab.mat-primary,
.theme-amber.dark .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #f59e0b;
  --mat-fab-foreground-color: #451a03;
  --mat-fab-state-layer-color: #451a03;
  --mat-fab-ripple-color: rgba(69, 26, 3, 0.1);
}
.theme-amber .dark .mat-mdc-fab.mat-accent,
.theme-amber.dark .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #1e293b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-amber .dark .mat-mdc-fab.mat-warn,
.theme-amber.dark .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #dc2626;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-amber .dark .mat-mdc-mini-fab.mat-primary,
.theme-amber.dark .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #f59e0b;
  --mat-fab-small-foreground-color: #451a03;
  --mat-fab-small-state-layer-color: #451a03;
  --mat-fab-small-ripple-color: rgba(69, 26, 3, 0.1);
}
.theme-amber .dark .mat-mdc-mini-fab.mat-accent,
.theme-amber.dark .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #1e293b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-amber .dark .mat-mdc-mini-fab.mat-warn,
.theme-amber.dark .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #dc2626;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-amber .dark .mat-accent,
.theme-amber.dark .mat-accent {
  --mdc-circular-progress-active-indicator-color: #1e293b;
}
.theme-amber .dark .mat-warn,
.theme-amber.dark .mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}
.theme-amber .dark .mat-badge-accent,
.theme-amber.dark .mat-badge-accent {
  --mat-badge-background-color: #1e293b;
  --mat-badge-text-color: white;
}
.theme-amber .dark .mat-badge-warn,
.theme-amber.dark .mat-badge-warn {
  --mat-badge-background-color: #dc2626;
  --mat-badge-text-color: white;
}
.theme-amber .dark .mat-datepicker-content.mat-accent,
.theme-amber.dark .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #1e293b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(30, 41, 59, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(30, 41, 59, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-amber .dark .mat-datepicker-content.mat-warn,
.theme-amber.dark .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #dc2626;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(220, 38, 38, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(220, 38, 38, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-amber .dark .mat-datepicker-toggle-active.mat-accent,
.theme-amber.dark .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #1e293b;
}
.theme-amber .dark .mat-datepicker-toggle-active.mat-warn,
.theme-amber.dark .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #dc2626;
}
.theme-amber .dark .mat-icon.mat-primary,
.theme-amber.dark .mat-icon.mat-primary {
  --mat-icon-color: #f59e0b;
}
.theme-amber .dark .mat-icon.mat-accent,
.theme-amber.dark .mat-icon.mat-accent {
  --mat-icon-color: #1e293b;
}
.theme-amber .dark .mat-icon.mat-warn,
.theme-amber.dark .mat-icon.mat-warn {
  --mat-icon-color: #dc2626;
}
.theme-amber .dark .mat-step-header.mat-accent,
.theme-amber.dark .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #1e293b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #1e293b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #1e293b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-amber .dark .mat-step-header.mat-warn,
.theme-amber.dark .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #dc2626;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #dc2626;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #dc2626;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-amber .dark .mat-toolbar.mat-primary,
.theme-amber.dark .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #f59e0b;
  --mat-toolbar-container-text-color: #451a03;
}
.theme-amber .dark .mat-toolbar.mat-accent,
.theme-amber.dark .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #1e293b;
  --mat-toolbar-container-text-color: white;
}
.theme-amber .dark .mat-toolbar.mat-warn,
.theme-amber.dark .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #dc2626;
  --mat-toolbar-container-text-color: white;
}

/* Generate the palettes */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define a light & dark Angular Material theme with the generated palettes */
/* Merge the custom base colors with the generated themes */
/* Generate and encapsulate Angular Material themes */
.theme-green .light,
.theme-green.light {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-option-selected-state-label-text-color: darkgreen;
  --mat-option-label-text-color: #1e293b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-optgroup-label-text-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
  --mat-app-background-color: #f1f5f9;
  --mat-app-text-color: #1e293b;
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: #64748b;
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #1e293b;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: darkgreen;
  --mdc-filled-text-field-focus-active-indicator-color: darkgreen;
  --mdc-filled-text-field-focus-label-text-color: rgba(0, 100, 0, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(30, 41, 59, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(148, 163, 184, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(30, 41, 59, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #dc2626;
  --mdc-filled-text-field-error-focus-label-text-color: #dc2626;
  --mdc-filled-text-field-error-label-text-color: #dc2626;
  --mdc-filled-text-field-error-caret-color: #dc2626;
  --mdc-filled-text-field-active-indicator-color: rgba(226, 232, 240, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(226, 232, 240, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(226, 232, 240, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-hover-active-indicator-color: #dc2626;
  --mdc-outlined-text-field-caret-color: darkgreen;
  --mdc-outlined-text-field-focus-outline-color: darkgreen;
  --mdc-outlined-text-field-focus-label-text-color: rgba(0, 100, 0, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(30, 41, 59, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(148, 163, 184, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(30, 41, 59, 0.6);
  --mdc-outlined-text-field-error-caret-color: #dc2626;
  --mdc-outlined-text-field-error-focus-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-hover-label-text-color: #dc2626;
  --mdc-outlined-text-field-outline-color: rgba(226, 232, 240, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(226, 232, 240, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(226, 232, 240, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-error-hover-outline-color: #dc2626;
  --mdc-outlined-text-field-error-outline-color: #dc2626;
  --mat-form-field-focus-select-arrow-color: rgba(0, 100, 0, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(100, 116, 139, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #dc2626;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(100, 116, 139, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(100, 116, 139, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(30, 41, 59, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(148, 163, 184, 0.38);
  --mat-select-placeholder-text-color: rgba(30, 41, 59, 0.6);
  --mat-select-enabled-arrow-color: rgba(226, 232, 240, 0.54);
  --mat-select-disabled-arrow-color: rgba(30, 41, 59, 0.38);
  --mat-select-focused-arrow-color: rgba(0, 100, 0, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: white;
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(30, 41, 59, 0.87);
  --mdc-dialog-supporting-text-color: rgba(30, 41, 59, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
  --mdc-switch-selected-focus-state-layer-color: #005b00;
  --mdc-switch-selected-handle-color: #005b00;
  --mdc-switch-selected-hover-state-layer-color: #005b00;
  --mdc-switch-selected-pressed-state-layer-color: #005b00;
  --mdc-switch-selected-focus-handle-color: #003600;
  --mdc-switch-selected-hover-handle-color: #003600;
  --mdc-switch-selected-pressed-handle-color: #003600;
  --mdc-switch-selected-focus-track-color: #9fb29f;
  --mdc-switch-selected-hover-track-color: #9fb29f;
  --mdc-switch-selected-pressed-track-color: #9fb29f;
  --mdc-switch-selected-track-color: #9fb29f;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-disabled-label-text-color: #94a3b8;
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-color: darkgreen;
  --mdc-slider-focus-handle-color: darkgreen;
  --mdc-slider-hover-handle-color: darkgreen;
  --mdc-slider-active-track-color: darkgreen;
  --mdc-slider-inactive-track-color: darkgreen;
  --mdc-slider-with-tick-marks-inactive-container-color: darkgreen;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: darkgreen;
  --mat-slider-hover-state-layer-color: rgba(0, 100, 0, 0.05);
  --mat-slider-focus-state-layer-color: rgba(0, 100, 0, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-item-label-text-color: #1e293b;
  --mat-menu-item-icon-color: #1e293b;
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: #e2e8f0;
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 0;
  --mdc-list-list-item-label-text-color: #1e293b;
  --mdc-list-list-item-supporting-text-color: #64748b;
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: #94a3b8;
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: #1e293b;
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: #1e293b;
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: #94a3b8;
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: #1e293b;
  --mdc-text-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: #1e293b;
  --mdc-filled-button-disabled-container-color: rgba(148, 163, 184, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: #1e293b;
  --mdc-protected-button-disabled-container-color: rgba(148, 163, 184, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: #e2e8f0;
  --mdc-outlined-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-outlined-button-label-text-color: #1e293b;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-fab-container-color: white;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(148, 163, 184, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(148, 163, 184, 0.38);
  --mdc-fab-small-container-color: white;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(148, 163, 184, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(148, 163, 184, 0.38);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #1e293b;
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: white;
  --mat-table-header-headline-color: #1e293b;
  --mat-table-row-item-label-text-color: #1e293b;
  --mat-table-row-item-outline-color: #e2e8f0;
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: darkgreen;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: darkgreen;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #94a3b8;
  --mat-badge-disabled-state-text-color: #94a3b8;
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: #1e293b;
  --mat-bottom-sheet-container-background-color: white;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: #94a3b8;
  --mat-legacy-button-toggle-state-layer-color: #64748b;
  --mat-legacy-button-toggle-selected-state-text-color: #64748b;
  --mat-legacy-button-toggle-selected-state-background-color: #e2e8f0;
  --mat-legacy-button-toggle-disabled-state-text-color: #94a3b8;
  --mat-legacy-button-toggle-disabled-state-background-color: #cbd5e1;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #e2e8f0;
  --mat-standard-button-toggle-text-color: #1e293b;
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: #64748b;
  --mat-standard-button-toggle-selected-state-background-color: #e2e8f0;
  --mat-standard-button-toggle-selected-state-text-color: #1e293b;
  --mat-standard-button-toggle-disabled-state-text-color: #94a3b8;
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: #1e293b;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #e2e8f0;
  --mat-standard-button-toggle-divider-color: #e2e8f0;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: darkgreen;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(0, 100, 0, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(0, 100, 0, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(0, 100, 0, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: darkgreen;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(0, 100, 0, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: #64748b;
  --mat-datepicker-calendar-body-label-text-color: #64748b;
  --mat-datepicker-calendar-period-button-text-color: #1e293b;
  --mat-datepicker-calendar-period-button-icon-color: #64748b;
  --mat-datepicker-calendar-navigation-button-icon-color: #64748b;
  --mat-datepicker-calendar-header-divider-color: #e2e8f0;
  --mat-datepicker-calendar-header-text-color: #64748b;
  --mat-datepicker-calendar-date-today-outline-color: #94a3b8;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(148, 163, 184, 0.8);
  --mat-datepicker-calendar-date-text-color: #1e293b;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: #94a3b8;
  --mat-datepicker-calendar-date-preview-state-outline-color: #e2e8f0;
  --mat-datepicker-range-input-separator-color: #1e293b;
  --mat-datepicker-range-input-disabled-state-separator-color: #94a3b8;
  --mat-datepicker-range-input-disabled-state-text-color: #94a3b8;
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: #1e293b;
  --mat-divider-width: 1px;
  --mat-divider-color: #e2e8f0;
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: #1e293b;
  --mat-expansion-actions-divider-color: #e2e8f0;
  --mat-expansion-header-hover-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-expansion-header-focus-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-expansion-header-disabled-state-text-color: #94a3b8;
  --mat-expansion-header-text-color: #1e293b;
  --mat-expansion-header-description-color: #64748b;
  --mat-expansion-header-indicator-color: #64748b;
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: #e2e8f0;
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: #1e293b;
  --mat-sidenav-content-background-color: #f1f5f9;
  --mat-sidenav-content-text-color: #1e293b;
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: darkgreen;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: darkgreen;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: darkgreen;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: #e2e8f0;
  --mat-stepper-header-hover-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-stepper-header-focus-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-stepper-header-label-text-color: #64748b;
  --mat-stepper-header-optional-label-text-color: #64748b;
  --mat-stepper-header-selected-state-label-text-color: #1e293b;
  --mat-stepper-header-error-state-label-text-color: #dc2626;
  --mat-stepper-header-icon-background-color: #64748b;
  --mat-stepper-header-error-state-icon-foreground-color: #dc2626;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #64748b;
  --mat-toolbar-container-background-color: white;
  --mat-toolbar-container-text-color: #1e293b;
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: #1e293b;
}
.theme-green .light .mat-accent,
.theme-green.light .mat-accent {
  --mat-option-selected-state-label-text-color: #1e293b;
  --mat-option-label-text-color: #1e293b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.theme-green .light .mat-warn,
.theme-green.light .mat-warn {
  --mat-option-selected-state-label-text-color: #dc2626;
  --mat-option-label-text-color: #1e293b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.theme-green .light .mat-primary,
.theme-green.light .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: darkgreen;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: darkgreen;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-green .light .mat-accent,
.theme-green.light .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-green .light .mat-warn,
.theme-green.light .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #dc2626;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #dc2626;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-green .light .mat-elevation-z0, .theme-green .light .mat-mdc-elevation-specific.mat-elevation-z0,
.theme-green.light .mat-elevation-z0,
.theme-green.light .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-green .light .mat-elevation-z1, .theme-green .light .mat-mdc-elevation-specific.mat-elevation-z1,
.theme-green.light .mat-elevation-z1,
.theme-green.light .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-green .light .mat-elevation-z2, .theme-green .light .mat-mdc-elevation-specific.mat-elevation-z2,
.theme-green.light .mat-elevation-z2,
.theme-green.light .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-green .light .mat-elevation-z3, .theme-green .light .mat-mdc-elevation-specific.mat-elevation-z3,
.theme-green.light .mat-elevation-z3,
.theme-green.light .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-green .light .mat-elevation-z4, .theme-green .light .mat-mdc-elevation-specific.mat-elevation-z4,
.theme-green.light .mat-elevation-z4,
.theme-green.light .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-green .light .mat-elevation-z5, .theme-green .light .mat-mdc-elevation-specific.mat-elevation-z5,
.theme-green.light .mat-elevation-z5,
.theme-green.light .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-green .light .mat-elevation-z6, .theme-green .light .mat-mdc-elevation-specific.mat-elevation-z6,
.theme-green.light .mat-elevation-z6,
.theme-green.light .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-green .light .mat-elevation-z7, .theme-green .light .mat-mdc-elevation-specific.mat-elevation-z7,
.theme-green.light .mat-elevation-z7,
.theme-green.light .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-green .light .mat-elevation-z8, .theme-green .light .mat-mdc-elevation-specific.mat-elevation-z8,
.theme-green.light .mat-elevation-z8,
.theme-green.light .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-green .light .mat-elevation-z9, .theme-green .light .mat-mdc-elevation-specific.mat-elevation-z9,
.theme-green.light .mat-elevation-z9,
.theme-green.light .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-green .light .mat-elevation-z10, .theme-green .light .mat-mdc-elevation-specific.mat-elevation-z10,
.theme-green.light .mat-elevation-z10,
.theme-green.light .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-green .light .mat-elevation-z11, .theme-green .light .mat-mdc-elevation-specific.mat-elevation-z11,
.theme-green.light .mat-elevation-z11,
.theme-green.light .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-green .light .mat-elevation-z12, .theme-green .light .mat-mdc-elevation-specific.mat-elevation-z12,
.theme-green.light .mat-elevation-z12,
.theme-green.light .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-green .light .mat-elevation-z13, .theme-green .light .mat-mdc-elevation-specific.mat-elevation-z13,
.theme-green.light .mat-elevation-z13,
.theme-green.light .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-green .light .mat-elevation-z14, .theme-green .light .mat-mdc-elevation-specific.mat-elevation-z14,
.theme-green.light .mat-elevation-z14,
.theme-green.light .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-green .light .mat-elevation-z15, .theme-green .light .mat-mdc-elevation-specific.mat-elevation-z15,
.theme-green.light .mat-elevation-z15,
.theme-green.light .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-green .light .mat-elevation-z16, .theme-green .light .mat-mdc-elevation-specific.mat-elevation-z16,
.theme-green.light .mat-elevation-z16,
.theme-green.light .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-green .light .mat-elevation-z17, .theme-green .light .mat-mdc-elevation-specific.mat-elevation-z17,
.theme-green.light .mat-elevation-z17,
.theme-green.light .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-green .light .mat-elevation-z18, .theme-green .light .mat-mdc-elevation-specific.mat-elevation-z18,
.theme-green.light .mat-elevation-z18,
.theme-green.light .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-green .light .mat-elevation-z19, .theme-green .light .mat-mdc-elevation-specific.mat-elevation-z19,
.theme-green.light .mat-elevation-z19,
.theme-green.light .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-green .light .mat-elevation-z20, .theme-green .light .mat-mdc-elevation-specific.mat-elevation-z20,
.theme-green.light .mat-elevation-z20,
.theme-green.light .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-green .light .mat-elevation-z21, .theme-green .light .mat-mdc-elevation-specific.mat-elevation-z21,
.theme-green.light .mat-elevation-z21,
.theme-green.light .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-green .light .mat-elevation-z22, .theme-green .light .mat-mdc-elevation-specific.mat-elevation-z22,
.theme-green.light .mat-elevation-z22,
.theme-green.light .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-green .light .mat-elevation-z23, .theme-green .light .mat-mdc-elevation-specific.mat-elevation-z23,
.theme-green.light .mat-elevation-z23,
.theme-green.light .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-green .light .mat-elevation-z24, .theme-green .light .mat-mdc-elevation-specific.mat-elevation-z24,
.theme-green.light .mat-elevation-z24,
.theme-green.light .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-green .light .mat-mdc-progress-bar,
.theme-green.light .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: darkgreen;
  --mdc-linear-progress-track-color: rgba(0, 100, 0, 0.25);
}
.theme-green .light .mat-mdc-progress-bar.mat-accent,
.theme-green.light .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1e293b;
  --mdc-linear-progress-track-color: rgba(30, 41, 59, 0.25);
}
.theme-green .light .mat-mdc-progress-bar.mat-warn,
.theme-green.light .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc2626;
  --mdc-linear-progress-track-color: rgba(220, 38, 38, 0.25);
}
.theme-green .light .mat-mdc-form-field.mat-accent,
.theme-green.light .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #1e293b;
  --mdc-filled-text-field-focus-active-indicator-color: #1e293b;
  --mdc-filled-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mdc-outlined-text-field-caret-color: #1e293b;
  --mdc-outlined-text-field-focus-outline-color: #1e293b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(30, 41, 59, 0.87);
}
.theme-green .light .mat-mdc-form-field.mat-warn,
.theme-green.light .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #dc2626;
  --mdc-filled-text-field-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mdc-outlined-text-field-caret-color: #dc2626;
  --mdc-outlined-text-field-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-green .light .mat-mdc-form-field.mat-accent,
.theme-green.light .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(30, 41, 59, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(148, 163, 184, 0.38);
  --mat-select-placeholder-text-color: rgba(30, 41, 59, 0.6);
  --mat-select-enabled-arrow-color: rgba(226, 232, 240, 0.54);
  --mat-select-disabled-arrow-color: rgba(30, 41, 59, 0.38);
  --mat-select-focused-arrow-color: rgba(30, 41, 59, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-green .light .mat-mdc-form-field.mat-warn,
.theme-green.light .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(30, 41, 59, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(148, 163, 184, 0.38);
  --mat-select-placeholder-text-color: rgba(30, 41, 59, 0.6);
  --mat-select-enabled-arrow-color: rgba(226, 232, 240, 0.54);
  --mat-select-disabled-arrow-color: rgba(30, 41, 59, 0.38);
  --mat-select-focused-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-green .light .mat-mdc-standard-chip,
.theme-green.light .mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.theme-green .light .mat-mdc-standard-chip,
.theme-green.light .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.theme-green .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .theme-green .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.theme-green.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.theme-green.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: darkgreen;
  --mdc-chip-elevated-selected-container-color: darkgreen;
  --mdc-chip-elevated-disabled-container-color: darkgreen;
  --mdc-chip-flat-disabled-selected-container-color: darkgreen;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-green .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .theme-green .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.theme-green.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.theme-green.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #1e293b;
  --mdc-chip-elevated-selected-container-color: #1e293b;
  --mdc-chip-elevated-disabled-container-color: #1e293b;
  --mdc-chip-flat-disabled-selected-container-color: #1e293b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-green .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .theme-green .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.theme-green.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.theme-green.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-selected-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-flat-disabled-selected-container-color: #dc2626;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-green .light .mat-mdc-slide-toggle,
.theme-green.light .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: #1e293b;
}
.theme-green .light .mat-mdc-slide-toggle.mat-accent,
.theme-green.light .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #475569;
  --mdc-switch-selected-handle-color: #475569;
  --mdc-switch-selected-hover-state-layer-color: #475569;
  --mdc-switch-selected-pressed-state-layer-color: #475569;
  --mdc-switch-selected-focus-handle-color: #0f172a;
  --mdc-switch-selected-hover-handle-color: #0f172a;
  --mdc-switch-selected-pressed-handle-color: #0f172a;
  --mdc-switch-selected-focus-track-color: #cbd5e1;
  --mdc-switch-selected-hover-track-color: #cbd5e1;
  --mdc-switch-selected-pressed-track-color: #cbd5e1;
  --mdc-switch-selected-track-color: #cbd5e1;
}
.theme-green .light .mat-mdc-slide-toggle.mat-warn,
.theme-green.light .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.theme-green .light .mat-mdc-radio-button,
.theme-green.light .mat-mdc-radio-button {
  --mdc-form-field-label-text-color: #1e293b;
}
.theme-green .light .mat-mdc-radio-button.mat-primary,
.theme-green.light .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: darkgreen;
  --mdc-radio-selected-hover-icon-color: darkgreen;
  --mdc-radio-selected-icon-color: darkgreen;
  --mdc-radio-selected-pressed-icon-color: darkgreen;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: darkgreen;
  --mat-radio-disabled-label-color: #94a3b8;
}
.theme-green .light .mat-mdc-radio-button.mat-accent,
.theme-green.light .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #1e293b;
  --mat-radio-disabled-label-color: #94a3b8;
}
.theme-green .light .mat-mdc-radio-button.mat-warn,
.theme-green.light .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #dc2626;
  --mat-radio-disabled-label-color: #94a3b8;
}
.theme-green .light .mat-accent,
.theme-green.light .mat-accent {
  --mat-slider-ripple-color: #1e293b;
  --mat-slider-hover-state-layer-color: rgba(30, 41, 59, 0.05);
  --mat-slider-focus-state-layer-color: rgba(30, 41, 59, 0.2);
  --mdc-slider-handle-color: #1e293b;
  --mdc-slider-focus-handle-color: #1e293b;
  --mdc-slider-hover-handle-color: #1e293b;
  --mdc-slider-active-track-color: #1e293b;
  --mdc-slider-inactive-track-color: #1e293b;
  --mdc-slider-with-tick-marks-inactive-container-color: #1e293b;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-green .light .mat-warn,
.theme-green.light .mat-warn {
  --mat-slider-ripple-color: #dc2626;
  --mat-slider-hover-state-layer-color: rgba(220, 38, 38, 0.05);
  --mat-slider-focus-state-layer-color: rgba(220, 38, 38, 0.2);
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-green .light .mdc-list-item__start,
.theme-green .light .mdc-list-item__end,
.theme-green.light .mdc-list-item__start,
.theme-green.light .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: darkgreen;
  --mdc-radio-selected-hover-icon-color: darkgreen;
  --mdc-radio-selected-icon-color: darkgreen;
  --mdc-radio-selected-pressed-icon-color: darkgreen;
}
.theme-green .light .mat-accent .mdc-list-item__start,
.theme-green .light .mat-accent .mdc-list-item__end,
.theme-green.light .mat-accent .mdc-list-item__start,
.theme-green.light .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
}
.theme-green .light .mat-warn .mdc-list-item__start,
.theme-green .light .mat-warn .mdc-list-item__end,
.theme-green.light .mat-warn .mdc-list-item__start,
.theme-green.light .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
}
.theme-green .light .mat-mdc-list-option,
.theme-green.light .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: darkgreen;
  --mdc-checkbox-selected-hover-icon-color: darkgreen;
  --mdc-checkbox-selected-icon-color: darkgreen;
  --mdc-checkbox-selected-pressed-icon-color: darkgreen;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: darkgreen;
  --mdc-checkbox-selected-hover-state-layer-color: darkgreen;
  --mdc-checkbox-selected-pressed-state-layer-color: darkgreen;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-green .light .mat-mdc-list-option.mat-accent,
.theme-green.light .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-green .light .mat-mdc-list-option.mat-warn,
.theme-green.light .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-green .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-green .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-green.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-green.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: darkgreen;
}
.theme-green .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-green .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-green.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-green.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: darkgreen;
}
.theme-green .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-green .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-green .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.theme-green.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-green.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-green.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.theme-green .light .mat-mdc-tab-group, .theme-green .light .mat-mdc-tab-nav-bar,
.theme-green.light .mat-mdc-tab-group,
.theme-green.light .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: darkgreen;
  --mat-tab-header-disabled-ripple-color: #94a3b8;
  --mat-tab-header-pagination-icon-color: #64748b;
  --mat-tab-header-inactive-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-label-text-color: darkgreen;
  --mat-tab-header-active-ripple-color: darkgreen;
  --mat-tab-header-inactive-ripple-color: darkgreen;
  --mat-tab-header-inactive-focus-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-focus-label-text-color: darkgreen;
  --mat-tab-header-active-hover-label-text-color: darkgreen;
  --mat-tab-header-active-focus-indicator-color: darkgreen;
  --mat-tab-header-active-hover-indicator-color: darkgreen;
}
.theme-green .light .mat-mdc-tab-group.mat-accent, .theme-green .light .mat-mdc-tab-nav-bar.mat-accent,
.theme-green.light .mat-mdc-tab-group.mat-accent,
.theme-green.light .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #1e293b;
  --mat-tab-header-disabled-ripple-color: #94a3b8;
  --mat-tab-header-pagination-icon-color: #64748b;
  --mat-tab-header-inactive-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-label-text-color: #1e293b;
  --mat-tab-header-active-ripple-color: #1e293b;
  --mat-tab-header-inactive-ripple-color: #1e293b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-focus-label-text-color: #1e293b;
  --mat-tab-header-active-hover-label-text-color: #1e293b;
  --mat-tab-header-active-focus-indicator-color: #1e293b;
  --mat-tab-header-active-hover-indicator-color: #1e293b;
}
.theme-green .light .mat-mdc-tab-group.mat-warn, .theme-green .light .mat-mdc-tab-nav-bar.mat-warn,
.theme-green.light .mat-mdc-tab-group.mat-warn,
.theme-green.light .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #dc2626;
  --mat-tab-header-disabled-ripple-color: #94a3b8;
  --mat-tab-header-pagination-icon-color: #64748b;
  --mat-tab-header-inactive-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-label-text-color: #dc2626;
  --mat-tab-header-active-ripple-color: #dc2626;
  --mat-tab-header-inactive-ripple-color: #dc2626;
  --mat-tab-header-inactive-focus-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-focus-label-text-color: #dc2626;
  --mat-tab-header-active-hover-label-text-color: #dc2626;
  --mat-tab-header-active-focus-indicator-color: #dc2626;
  --mat-tab-header-active-hover-indicator-color: #dc2626;
}
.theme-green .light .mat-mdc-tab-group.mat-background-primary, .theme-green .light .mat-mdc-tab-nav-bar.mat-background-primary,
.theme-green.light .mat-mdc-tab-group.mat-background-primary,
.theme-green.light .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: darkgreen;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-green .light .mat-mdc-tab-group.mat-background-accent, .theme-green .light .mat-mdc-tab-nav-bar.mat-background-accent,
.theme-green.light .mat-mdc-tab-group.mat-background-accent,
.theme-green.light .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #1e293b;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-green .light .mat-mdc-tab-group.mat-background-warn, .theme-green .light .mat-mdc-tab-nav-bar.mat-background-warn,
.theme-green.light .mat-mdc-tab-group.mat-background-warn,
.theme-green.light .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #dc2626;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-green .light .mat-mdc-checkbox,
.theme-green.light .mat-mdc-checkbox {
  --mdc-form-field-label-text-color: #1e293b;
}
.theme-green .light .mat-mdc-checkbox.mat-primary,
.theme-green.light .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: darkgreen;
  --mdc-checkbox-selected-hover-icon-color: darkgreen;
  --mdc-checkbox-selected-icon-color: darkgreen;
  --mdc-checkbox-selected-pressed-icon-color: darkgreen;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: darkgreen;
  --mdc-checkbox-selected-hover-state-layer-color: darkgreen;
  --mdc-checkbox-selected-pressed-state-layer-color: darkgreen;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-green .light .mat-mdc-checkbox.mat-warn,
.theme-green.light .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-green .light .mat-mdc-button.mat-primary,
.theme-green.light .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: darkgreen;
  --mat-text-button-state-layer-color: darkgreen;
  --mat-text-button-ripple-color: rgba(0, 100, 0, 0.1);
}
.theme-green .light .mat-mdc-button.mat-accent,
.theme-green.light .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1e293b;
  --mat-text-button-state-layer-color: #1e293b;
  --mat-text-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-green .light .mat-mdc-button.mat-warn,
.theme-green.light .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc2626;
  --mat-text-button-state-layer-color: #dc2626;
  --mat-text-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-green .light .mat-mdc-unelevated-button.mat-primary,
.theme-green.light .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: darkgreen;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-green .light .mat-mdc-unelevated-button.mat-accent,
.theme-green.light .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-green .light .mat-mdc-unelevated-button.mat-warn,
.theme-green.light .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-green .light .mat-mdc-raised-button.mat-primary,
.theme-green.light .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: darkgreen;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-green .light .mat-mdc-raised-button.mat-accent,
.theme-green.light .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-green .light .mat-mdc-raised-button.mat-warn,
.theme-green.light .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-green .light .mat-mdc-outlined-button.mat-primary,
.theme-green.light .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: darkgreen;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: darkgreen;
  --mat-outlined-button-ripple-color: rgba(0, 100, 0, 0.1);
}
.theme-green .light .mat-mdc-outlined-button.mat-accent,
.theme-green.light .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1e293b;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: #1e293b;
  --mat-outlined-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-green .light .mat-mdc-outlined-button.mat-warn,
.theme-green.light .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc2626;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: #dc2626;
  --mat-outlined-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-green .light .mat-mdc-icon-button.mat-primary,
.theme-green.light .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: darkgreen;
  --mat-icon-button-state-layer-color: darkgreen;
  --mat-icon-button-ripple-color: rgba(0, 100, 0, 0.1);
}
.theme-green .light .mat-mdc-icon-button.mat-accent,
.theme-green.light .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1e293b;
  --mat-icon-button-state-layer-color: #1e293b;
  --mat-icon-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-green .light .mat-mdc-icon-button.mat-warn,
.theme-green.light .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc2626;
  --mat-icon-button-state-layer-color: #dc2626;
  --mat-icon-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-green .light .mat-mdc-fab.mat-primary,
.theme-green.light .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: darkgreen;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-green .light .mat-mdc-fab.mat-accent,
.theme-green.light .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #1e293b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-green .light .mat-mdc-fab.mat-warn,
.theme-green.light .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #dc2626;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-green .light .mat-mdc-mini-fab.mat-primary,
.theme-green.light .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: darkgreen;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-green .light .mat-mdc-mini-fab.mat-accent,
.theme-green.light .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #1e293b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-green .light .mat-mdc-mini-fab.mat-warn,
.theme-green.light .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #dc2626;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-green .light .mat-accent,
.theme-green.light .mat-accent {
  --mdc-circular-progress-active-indicator-color: #1e293b;
}
.theme-green .light .mat-warn,
.theme-green.light .mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}
.theme-green .light .mat-badge-accent,
.theme-green.light .mat-badge-accent {
  --mat-badge-background-color: #1e293b;
  --mat-badge-text-color: white;
}
.theme-green .light .mat-badge-warn,
.theme-green.light .mat-badge-warn {
  --mat-badge-background-color: #dc2626;
  --mat-badge-text-color: white;
}
.theme-green .light .mat-datepicker-content.mat-accent,
.theme-green.light .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #1e293b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(30, 41, 59, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(30, 41, 59, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-green .light .mat-datepicker-content.mat-warn,
.theme-green.light .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #dc2626;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(220, 38, 38, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(220, 38, 38, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-green .light .mat-datepicker-toggle-active.mat-accent,
.theme-green.light .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #1e293b;
}
.theme-green .light .mat-datepicker-toggle-active.mat-warn,
.theme-green.light .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #dc2626;
}
.theme-green .light .mat-icon.mat-primary,
.theme-green.light .mat-icon.mat-primary {
  --mat-icon-color: darkgreen;
}
.theme-green .light .mat-icon.mat-accent,
.theme-green.light .mat-icon.mat-accent {
  --mat-icon-color: #1e293b;
}
.theme-green .light .mat-icon.mat-warn,
.theme-green.light .mat-icon.mat-warn {
  --mat-icon-color: #dc2626;
}
.theme-green .light .mat-step-header.mat-accent,
.theme-green.light .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #1e293b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #1e293b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #1e293b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-green .light .mat-step-header.mat-warn,
.theme-green.light .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #dc2626;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #dc2626;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #dc2626;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-green .light .mat-toolbar.mat-primary,
.theme-green.light .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: darkgreen;
  --mat-toolbar-container-text-color: white;
}
.theme-green .light .mat-toolbar.mat-accent,
.theme-green.light .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #1e293b;
  --mat-toolbar-container-text-color: white;
}
.theme-green .light .mat-toolbar.mat-warn,
.theme-green.light .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #dc2626;
  --mat-toolbar-container-text-color: white;
}

.theme-green .dark,
.theme-green.dark {
  --mat-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-option-selected-state-label-text-color: darkgreen;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-optgroup-label-text-color: white;
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
  --mat-app-background-color: #0f172a;
  --mat-app-text-color: white;
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: #1e293b;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: #1e293b;
  --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: #94a3b8;
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #64748b;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: darkgreen;
  --mdc-filled-text-field-focus-active-indicator-color: darkgreen;
  --mdc-filled-text-field-focus-label-text-color: rgba(0, 100, 0, 0.87);
  --mdc-filled-text-field-container-color: #273243;
  --mdc-filled-text-field-disabled-container-color: #232d3f;
  --mdc-filled-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(71, 85, 105, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(71, 85, 105, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #dc2626;
  --mdc-filled-text-field-error-focus-label-text-color: #dc2626;
  --mdc-filled-text-field-error-label-text-color: #dc2626;
  --mdc-filled-text-field-error-caret-color: #dc2626;
  --mdc-filled-text-field-active-indicator-color: rgba(241, 245, 249, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(241, 245, 249, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(241, 245, 249, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-hover-active-indicator-color: #dc2626;
  --mdc-outlined-text-field-caret-color: darkgreen;
  --mdc-outlined-text-field-focus-outline-color: darkgreen;
  --mdc-outlined-text-field-focus-label-text-color: rgba(0, 100, 0, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(71, 85, 105, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(71, 85, 105, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-error-caret-color: #dc2626;
  --mdc-outlined-text-field-error-focus-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-hover-label-text-color: #dc2626;
  --mdc-outlined-text-field-outline-color: rgba(241, 245, 249, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(241, 245, 249, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(241, 245, 249, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-error-hover-outline-color: #dc2626;
  --mdc-outlined-text-field-error-outline-color: #dc2626;
  --mat-form-field-focus-select-arrow-color: rgba(0, 100, 0, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(241, 245, 249, 0.38);
  --mat-form-field-state-layer-color: rgba(255, 255, 255, 0.87);
  --mat-form-field-error-text-color: #dc2626;
  --mat-form-field-select-option-text-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-select-disabled-option-text-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(241, 245, 249, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.08;
  --mat-form-field-focus-state-layer-opacity: 0.24;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: #1e293b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(71, 85, 105, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(0, 100, 0, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: #1e293b;
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: #1e293b;
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
  --mdc-switch-selected-focus-state-layer-color: #9fb29f;
  --mdc-switch-selected-handle-color: #9fb29f;
  --mdc-switch-selected-hover-state-layer-color: #9fb29f;
  --mdc-switch-selected-pressed-state-layer-color: #9fb29f;
  --mdc-switch-selected-focus-handle-color: #c3cec3;
  --mdc-switch-selected-hover-handle-color: #c3cec3;
  --mdc-switch-selected-pressed-handle-color: #c3cec3;
  --mdc-switch-selected-focus-track-color: #005b00;
  --mdc-switch-selected-hover-track-color: #005b00;
  --mdc-switch-selected-pressed-track-color: #005b00;
  --mdc-switch-selected-track-color: #005b00;
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-icon-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-disabled-label-text-color: #475569;
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-color: darkgreen;
  --mdc-slider-focus-handle-color: darkgreen;
  --mdc-slider-hover-handle-color: darkgreen;
  --mdc-slider-active-track-color: darkgreen;
  --mdc-slider-inactive-track-color: darkgreen;
  --mdc-slider-with-tick-marks-inactive-container-color: darkgreen;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-label-container-color: #fff;
  --mdc-slider-label-label-text-color: #000;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: darkgreen;
  --mat-slider-hover-state-layer-color: rgba(0, 100, 0, 0.05);
  --mat-slider-focus-state-layer-color: rgba(0, 100, 0, 0.2);
  --mat-slider-value-indicator-opacity: 0.9;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-item-label-text-color: white;
  --mat-menu-item-icon-color: white;
  --mat-menu-item-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-container-color: #1e293b;
  --mat-menu-divider-color: rgba(241, 245, 249, 0.12);
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 0;
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: #94a3b8;
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: #64748b;
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: white;
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
  --mat-paginator-container-text-color: rgba(255, 255, 255, 0.87);
  --mat-paginator-container-background-color: #1e293b;
  --mat-paginator-enabled-icon-color: rgba(255, 255, 255, 0.54);
  --mat-paginator-disabled-icon-color: rgba(255, 255, 255, 0.12);
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
  --mat-checkbox-disabled-label-color: #475569;
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: white;
  --mdc-text-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mat-text-button-state-layer-color: white;
  --mat-text-button-disabled-state-layer-color: white;
  --mat-text-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.08;
  --mat-text-button-focus-state-layer-opacity: 0.24;
  --mat-text-button-pressed-state-layer-opacity: 0.24;
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: white;
  --mdc-filled-button-disabled-container-color: rgba(30, 41, 59, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-disabled-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.08;
  --mat-filled-button-focus-state-layer-opacity: 0.24;
  --mat-filled-button-pressed-state-layer-opacity: 0.24;
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: white;
  --mdc-protected-button-disabled-container-color: rgba(30, 41, 59, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-disabled-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.08;
  --mat-protected-button-focus-state-layer-opacity: 0.24;
  --mat-protected-button-pressed-state-layer-opacity: 0.24;
  --mdc-outlined-button-disabled-outline-color: rgba(241, 245, 249, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mdc-outlined-button-label-text-color: white;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: white;
  --mat-outlined-button-disabled-state-layer-color: white;
  --mat-outlined-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.08;
  --mat-outlined-button-focus-state-layer-opacity: 0.24;
  --mat-outlined-button-pressed-state-layer-opacity: 0.24;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
  --mat-icon-button-state-layer-color: white;
  --mat-icon-button-disabled-state-layer-color: white;
  --mat-icon-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.08;
  --mat-icon-button-focus-state-layer-opacity: 0.24;
  --mat-icon-button-pressed-state-layer-opacity: 0.24;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-fab-container-color: #1e293b;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-disabled-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.08;
  --mat-fab-focus-state-layer-opacity: 0.24;
  --mat-fab-pressed-state-layer-opacity: 0.24;
  --mat-fab-disabled-state-container-color: rgba(15, 23, 42, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(30, 41, 59, 0.5);
  --mdc-fab-small-container-color: #1e293b;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-disabled-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.08;
  --mat-fab-small-focus-state-layer-opacity: 0.24;
  --mat-fab-small-pressed-state-layer-opacity: 0.24;
  --mat-fab-small-disabled-state-container-color: rgba(15, 23, 42, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(30, 41, 59, 0.5);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #d2d4d8;
  --mdc-snackbar-supporting-text-color: rgba(30, 41, 59, 0.87);
  --mat-snack-bar-button-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: #1e293b;
  --mat-table-header-headline-color: white;
  --mat-table-row-item-label-text-color: white;
  --mat-table-row-item-outline-color: rgba(241, 245, 249, 0.12);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: darkgreen;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: darkgreen;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #1e293b;
  --mat-badge-disabled-state-text-color: #475569;
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: white;
  --mat-bottom-sheet-container-background-color: #1e293b;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: #64748b;
  --mat-legacy-button-toggle-state-layer-color: #e2e8f0;
  --mat-legacy-button-toggle-selected-state-text-color: #94a3b8;
  --mat-legacy-button-toggle-selected-state-background-color: rgba(255, 255, 255, 0.05);
  --mat-legacy-button-toggle-disabled-state-text-color: #1e293b;
  --mat-legacy-button-toggle-disabled-state-background-color: #0f172a;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #1e293b;
  --mat-standard-button-toggle-text-color: white;
  --mat-standard-button-toggle-background-color: #1e293b;
  --mat-standard-button-toggle-state-layer-color: #e2e8f0;
  --mat-standard-button-toggle-selected-state-background-color: rgba(255, 255, 255, 0.05);
  --mat-standard-button-toggle-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-state-text-color: #1e293b;
  --mat-standard-button-toggle-disabled-state-background-color: #1e293b;
  --mat-standard-button-toggle-disabled-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #1e293b;
  --mat-standard-button-toggle-divider-color: #374152;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: darkgreen;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(0, 100, 0, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(0, 100, 0, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(0, 100, 0, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: darkgreen;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(0, 100, 0, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: #f1f5f9;
  --mat-datepicker-calendar-body-label-text-color: #94a3b8;
  --mat-datepicker-calendar-period-button-text-color: white;
  --mat-datepicker-calendar-period-button-icon-color: #f1f5f9;
  --mat-datepicker-calendar-navigation-button-icon-color: #f1f5f9;
  --mat-datepicker-calendar-header-divider-color: rgba(241, 245, 249, 0.12);
  --mat-datepicker-calendar-header-text-color: #94a3b8;
  --mat-datepicker-calendar-date-today-outline-color: #64748b;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(100, 116, 139, 0.8);
  --mat-datepicker-calendar-date-text-color: white;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: #475569;
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(241, 245, 249, 0.24);
  --mat-datepicker-range-input-separator-color: white;
  --mat-datepicker-range-input-disabled-state-separator-color: #475569;
  --mat-datepicker-range-input-disabled-state-text-color: #475569;
  --mat-datepicker-calendar-container-background-color: #1e293b;
  --mat-datepicker-calendar-container-text-color: white;
  --mat-divider-width: 1px;
  --mat-divider-color: rgba(241, 245, 249, 0.12);
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: #1e293b;
  --mat-expansion-container-text-color: white;
  --mat-expansion-actions-divider-color: rgba(241, 245, 249, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-expansion-header-focus-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-expansion-header-disabled-state-text-color: #1e293b;
  --mat-expansion-header-text-color: white;
  --mat-expansion-header-description-color: #94a3b8;
  --mat-expansion-header-indicator-color: #94a3b8;
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: rgba(241, 245, 249, 0.12);
  --mat-sidenav-container-background-color: #1e293b;
  --mat-sidenav-container-text-color: white;
  --mat-sidenav-content-background-color: #0f172a;
  --mat-sidenav-content-text-color: white;
  --mat-sidenav-scrim-color: rgba(225, 214, 196, 0.6);
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: darkgreen;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: darkgreen;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: darkgreen;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: #1e293b;
  --mat-stepper-line-color: rgba(241, 245, 249, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-stepper-header-focus-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-stepper-header-label-text-color: #94a3b8;
  --mat-stepper-header-optional-label-text-color: #94a3b8;
  --mat-stepper-header-selected-state-label-text-color: white;
  --mat-stepper-header-error-state-label-text-color: #dc2626;
  --mat-stepper-header-icon-background-color: #94a3b8;
  --mat-stepper-header-error-state-icon-foreground-color: #dc2626;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #94a3b8;
  --mat-toolbar-container-background-color: #0f172a;
  --mat-toolbar-container-text-color: white;
  --mat-tree-container-background-color: #1e293b;
  --mat-tree-node-text-color: white;
}
.theme-green .dark .mat-accent,
.theme-green.dark .mat-accent {
  --mat-option-selected-state-label-text-color: #1e293b;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.theme-green .dark .mat-warn,
.theme-green.dark .mat-warn {
  --mat-option-selected-state-label-text-color: #dc2626;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.theme-green .dark .mat-primary,
.theme-green.dark .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: darkgreen;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: darkgreen;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-green .dark .mat-accent,
.theme-green.dark .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-green .dark .mat-warn,
.theme-green.dark .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #dc2626;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #dc2626;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-green .dark .mat-elevation-z0, .theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z0,
.theme-green.dark .mat-elevation-z0,
.theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-green .dark .mat-elevation-z1, .theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z1,
.theme-green.dark .mat-elevation-z1,
.theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-green .dark .mat-elevation-z2, .theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z2,
.theme-green.dark .mat-elevation-z2,
.theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-green .dark .mat-elevation-z3, .theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z3,
.theme-green.dark .mat-elevation-z3,
.theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-green .dark .mat-elevation-z4, .theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z4,
.theme-green.dark .mat-elevation-z4,
.theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-green .dark .mat-elevation-z5, .theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z5,
.theme-green.dark .mat-elevation-z5,
.theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-green .dark .mat-elevation-z6, .theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z6,
.theme-green.dark .mat-elevation-z6,
.theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-green .dark .mat-elevation-z7, .theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z7,
.theme-green.dark .mat-elevation-z7,
.theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-green .dark .mat-elevation-z8, .theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z8,
.theme-green.dark .mat-elevation-z8,
.theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-green .dark .mat-elevation-z9, .theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z9,
.theme-green.dark .mat-elevation-z9,
.theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-green .dark .mat-elevation-z10, .theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z10,
.theme-green.dark .mat-elevation-z10,
.theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-green .dark .mat-elevation-z11, .theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z11,
.theme-green.dark .mat-elevation-z11,
.theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-green .dark .mat-elevation-z12, .theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z12,
.theme-green.dark .mat-elevation-z12,
.theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-green .dark .mat-elevation-z13, .theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z13,
.theme-green.dark .mat-elevation-z13,
.theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-green .dark .mat-elevation-z14, .theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z14,
.theme-green.dark .mat-elevation-z14,
.theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-green .dark .mat-elevation-z15, .theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z15,
.theme-green.dark .mat-elevation-z15,
.theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-green .dark .mat-elevation-z16, .theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z16,
.theme-green.dark .mat-elevation-z16,
.theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-green .dark .mat-elevation-z17, .theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z17,
.theme-green.dark .mat-elevation-z17,
.theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-green .dark .mat-elevation-z18, .theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z18,
.theme-green.dark .mat-elevation-z18,
.theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-green .dark .mat-elevation-z19, .theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z19,
.theme-green.dark .mat-elevation-z19,
.theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-green .dark .mat-elevation-z20, .theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z20,
.theme-green.dark .mat-elevation-z20,
.theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-green .dark .mat-elevation-z21, .theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z21,
.theme-green.dark .mat-elevation-z21,
.theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-green .dark .mat-elevation-z22, .theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z22,
.theme-green.dark .mat-elevation-z22,
.theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-green .dark .mat-elevation-z23, .theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z23,
.theme-green.dark .mat-elevation-z23,
.theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-green .dark .mat-elevation-z24, .theme-green .dark .mat-mdc-elevation-specific.mat-elevation-z24,
.theme-green.dark .mat-elevation-z24,
.theme-green.dark .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-green .dark .mat-mdc-progress-bar,
.theme-green.dark .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: darkgreen;
  --mdc-linear-progress-track-color: rgba(0, 100, 0, 0.25);
}
.theme-green .dark .mat-mdc-progress-bar.mat-accent,
.theme-green.dark .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1e293b;
  --mdc-linear-progress-track-color: rgba(30, 41, 59, 0.25);
}
.theme-green .dark .mat-mdc-progress-bar.mat-warn,
.theme-green.dark .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc2626;
  --mdc-linear-progress-track-color: rgba(220, 38, 38, 0.25);
}
.theme-green .dark .mat-mdc-form-field.mat-accent,
.theme-green.dark .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #1e293b;
  --mdc-filled-text-field-focus-active-indicator-color: #1e293b;
  --mdc-filled-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mdc-outlined-text-field-caret-color: #1e293b;
  --mdc-outlined-text-field-focus-outline-color: #1e293b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(30, 41, 59, 0.87);
}
.theme-green .dark .mat-mdc-form-field.mat-warn,
.theme-green.dark .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #dc2626;
  --mdc-filled-text-field-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mdc-outlined-text-field-caret-color: #dc2626;
  --mdc-outlined-text-field-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-green .dark .mat-mdc-form-field.mat-accent,
.theme-green.dark .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: #1e293b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(71, 85, 105, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(30, 41, 59, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-green .dark .mat-mdc-form-field.mat-warn,
.theme-green.dark .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: #1e293b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(71, 85, 105, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-green .dark .mat-mdc-standard-chip,
.theme-green.dark .mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.theme-green .dark .mat-mdc-standard-chip,
.theme-green.dark .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-elevated-container-color: #394353;
  --mdc-chip-elevated-selected-container-color: #394353;
  --mdc-chip-elevated-disabled-container-color: #394353;
  --mdc-chip-flat-disabled-selected-container-color: #394353;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-selected-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
  --mat-chip-selected-disabled-trailing-icon-color: #fafafa;
  --mat-chip-selected-trailing-icon-color: #fafafa;
}
.theme-green .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .theme-green .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.theme-green.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.theme-green.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: darkgreen;
  --mdc-chip-elevated-selected-container-color: darkgreen;
  --mdc-chip-elevated-disabled-container-color: darkgreen;
  --mdc-chip-flat-disabled-selected-container-color: darkgreen;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-green .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .theme-green .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.theme-green.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.theme-green.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #1e293b;
  --mdc-chip-elevated-selected-container-color: #1e293b;
  --mdc-chip-elevated-disabled-container-color: #1e293b;
  --mdc-chip-flat-disabled-selected-container-color: #1e293b;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-green .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .theme-green .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.theme-green.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.theme-green.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-selected-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-flat-disabled-selected-container-color: #dc2626;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-green .dark .mat-mdc-slide-toggle,
.theme-green.dark .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: white;
}
.theme-green .dark .mat-mdc-slide-toggle.mat-accent,
.theme-green.dark .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #cbd5e1;
  --mdc-switch-selected-handle-color: #cbd5e1;
  --mdc-switch-selected-hover-state-layer-color: #cbd5e1;
  --mdc-switch-selected-pressed-state-layer-color: #cbd5e1;
  --mdc-switch-selected-focus-handle-color: #e2e8f0;
  --mdc-switch-selected-hover-handle-color: #e2e8f0;
  --mdc-switch-selected-pressed-handle-color: #e2e8f0;
  --mdc-switch-selected-focus-track-color: #475569;
  --mdc-switch-selected-hover-track-color: #475569;
  --mdc-switch-selected-pressed-track-color: #475569;
  --mdc-switch-selected-track-color: #475569;
}
.theme-green .dark .mat-mdc-slide-toggle.mat-warn,
.theme-green.dark .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #fca5a5;
  --mdc-switch-selected-handle-color: #fca5a5;
  --mdc-switch-selected-hover-state-layer-color: #fca5a5;
  --mdc-switch-selected-pressed-state-layer-color: #fca5a5;
  --mdc-switch-selected-focus-handle-color: #fecaca;
  --mdc-switch-selected-hover-handle-color: #fecaca;
  --mdc-switch-selected-pressed-handle-color: #fecaca;
  --mdc-switch-selected-focus-track-color: #dc2626;
  --mdc-switch-selected-hover-track-color: #dc2626;
  --mdc-switch-selected-pressed-track-color: #dc2626;
  --mdc-switch-selected-track-color: #dc2626;
}
.theme-green .dark .mat-mdc-radio-button,
.theme-green.dark .mat-mdc-radio-button {
  --mdc-form-field-label-text-color: white;
}
.theme-green .dark .mat-mdc-radio-button.mat-primary,
.theme-green.dark .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: darkgreen;
  --mdc-radio-selected-hover-icon-color: darkgreen;
  --mdc-radio-selected-icon-color: darkgreen;
  --mdc-radio-selected-pressed-icon-color: darkgreen;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: darkgreen;
  --mat-radio-disabled-label-color: #475569;
}
.theme-green .dark .mat-mdc-radio-button.mat-accent,
.theme-green.dark .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #1e293b;
  --mat-radio-disabled-label-color: #475569;
}
.theme-green .dark .mat-mdc-radio-button.mat-warn,
.theme-green.dark .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #dc2626;
  --mat-radio-disabled-label-color: #475569;
}
.theme-green .dark .mat-accent,
.theme-green.dark .mat-accent {
  --mat-slider-ripple-color: #1e293b;
  --mat-slider-hover-state-layer-color: rgba(30, 41, 59, 0.05);
  --mat-slider-focus-state-layer-color: rgba(30, 41, 59, 0.2);
  --mdc-slider-handle-color: #1e293b;
  --mdc-slider-focus-handle-color: #1e293b;
  --mdc-slider-hover-handle-color: #1e293b;
  --mdc-slider-active-track-color: #1e293b;
  --mdc-slider-inactive-track-color: #1e293b;
  --mdc-slider-with-tick-marks-inactive-container-color: #1e293b;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-green .dark .mat-warn,
.theme-green.dark .mat-warn {
  --mat-slider-ripple-color: #dc2626;
  --mat-slider-hover-state-layer-color: rgba(220, 38, 38, 0.05);
  --mat-slider-focus-state-layer-color: rgba(220, 38, 38, 0.2);
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-green .dark .mdc-list-item__start,
.theme-green .dark .mdc-list-item__end,
.theme-green.dark .mdc-list-item__start,
.theme-green.dark .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: darkgreen;
  --mdc-radio-selected-hover-icon-color: darkgreen;
  --mdc-radio-selected-icon-color: darkgreen;
  --mdc-radio-selected-pressed-icon-color: darkgreen;
}
.theme-green .dark .mat-accent .mdc-list-item__start,
.theme-green .dark .mat-accent .mdc-list-item__end,
.theme-green.dark .mat-accent .mdc-list-item__start,
.theme-green.dark .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
}
.theme-green .dark .mat-warn .mdc-list-item__start,
.theme-green .dark .mat-warn .mdc-list-item__end,
.theme-green.dark .mat-warn .mdc-list-item__start,
.theme-green.dark .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
}
.theme-green .dark .mat-mdc-list-option,
.theme-green.dark .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: darkgreen;
  --mdc-checkbox-selected-hover-icon-color: darkgreen;
  --mdc-checkbox-selected-icon-color: darkgreen;
  --mdc-checkbox-selected-pressed-icon-color: darkgreen;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: darkgreen;
  --mdc-checkbox-selected-hover-state-layer-color: darkgreen;
  --mdc-checkbox-selected-pressed-state-layer-color: darkgreen;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-green .dark .mat-mdc-list-option.mat-accent,
.theme-green.dark .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-green .dark .mat-mdc-list-option.mat-warn,
.theme-green.dark .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-green .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-green .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-green.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-green.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: darkgreen;
}
.theme-green .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-green .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-green.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-green.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: darkgreen;
}
.theme-green .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-green .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-green .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.theme-green.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-green.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-green.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.theme-green .dark .mat-mdc-tab-group, .theme-green .dark .mat-mdc-tab-nav-bar,
.theme-green.dark .mat-mdc-tab-group,
.theme-green.dark .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: darkgreen;
  --mat-tab-header-disabled-ripple-color: #475569;
  --mat-tab-header-pagination-icon-color: #f1f5f9;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: darkgreen;
  --mat-tab-header-active-ripple-color: darkgreen;
  --mat-tab-header-inactive-ripple-color: darkgreen;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: darkgreen;
  --mat-tab-header-active-hover-label-text-color: darkgreen;
  --mat-tab-header-active-focus-indicator-color: darkgreen;
  --mat-tab-header-active-hover-indicator-color: darkgreen;
}
.theme-green .dark .mat-mdc-tab-group.mat-accent, .theme-green .dark .mat-mdc-tab-nav-bar.mat-accent,
.theme-green.dark .mat-mdc-tab-group.mat-accent,
.theme-green.dark .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #1e293b;
  --mat-tab-header-disabled-ripple-color: #475569;
  --mat-tab-header-pagination-icon-color: #f1f5f9;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #1e293b;
  --mat-tab-header-active-ripple-color: #1e293b;
  --mat-tab-header-inactive-ripple-color: #1e293b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #1e293b;
  --mat-tab-header-active-hover-label-text-color: #1e293b;
  --mat-tab-header-active-focus-indicator-color: #1e293b;
  --mat-tab-header-active-hover-indicator-color: #1e293b;
}
.theme-green .dark .mat-mdc-tab-group.mat-warn, .theme-green .dark .mat-mdc-tab-nav-bar.mat-warn,
.theme-green.dark .mat-mdc-tab-group.mat-warn,
.theme-green.dark .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #dc2626;
  --mat-tab-header-disabled-ripple-color: #475569;
  --mat-tab-header-pagination-icon-color: #f1f5f9;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #dc2626;
  --mat-tab-header-active-ripple-color: #dc2626;
  --mat-tab-header-inactive-ripple-color: #dc2626;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #dc2626;
  --mat-tab-header-active-hover-label-text-color: #dc2626;
  --mat-tab-header-active-focus-indicator-color: #dc2626;
  --mat-tab-header-active-hover-indicator-color: #dc2626;
}
.theme-green .dark .mat-mdc-tab-group.mat-background-primary, .theme-green .dark .mat-mdc-tab-nav-bar.mat-background-primary,
.theme-green.dark .mat-mdc-tab-group.mat-background-primary,
.theme-green.dark .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: darkgreen;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-green .dark .mat-mdc-tab-group.mat-background-accent, .theme-green .dark .mat-mdc-tab-nav-bar.mat-background-accent,
.theme-green.dark .mat-mdc-tab-group.mat-background-accent,
.theme-green.dark .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #1e293b;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-green .dark .mat-mdc-tab-group.mat-background-warn, .theme-green .dark .mat-mdc-tab-nav-bar.mat-background-warn,
.theme-green.dark .mat-mdc-tab-group.mat-background-warn,
.theme-green.dark .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #dc2626;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-green .dark .mat-mdc-checkbox,
.theme-green.dark .mat-mdc-checkbox {
  --mdc-form-field-label-text-color: white;
}
.theme-green .dark .mat-mdc-checkbox.mat-primary,
.theme-green.dark .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: darkgreen;
  --mdc-checkbox-selected-hover-icon-color: darkgreen;
  --mdc-checkbox-selected-icon-color: darkgreen;
  --mdc-checkbox-selected-pressed-icon-color: darkgreen;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: darkgreen;
  --mdc-checkbox-selected-hover-state-layer-color: darkgreen;
  --mdc-checkbox-selected-pressed-state-layer-color: darkgreen;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-green .dark .mat-mdc-checkbox.mat-warn,
.theme-green.dark .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-green .dark .mat-mdc-button.mat-primary,
.theme-green.dark .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: darkgreen;
  --mat-text-button-state-layer-color: darkgreen;
  --mat-text-button-ripple-color: rgba(0, 100, 0, 0.1);
}
.theme-green .dark .mat-mdc-button.mat-accent,
.theme-green.dark .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1e293b;
  --mat-text-button-state-layer-color: #1e293b;
  --mat-text-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-green .dark .mat-mdc-button.mat-warn,
.theme-green.dark .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc2626;
  --mat-text-button-state-layer-color: #dc2626;
  --mat-text-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-green .dark .mat-mdc-unelevated-button.mat-primary,
.theme-green.dark .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: darkgreen;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-green .dark .mat-mdc-unelevated-button.mat-accent,
.theme-green.dark .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-green .dark .mat-mdc-unelevated-button.mat-warn,
.theme-green.dark .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-green .dark .mat-mdc-raised-button.mat-primary,
.theme-green.dark .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: darkgreen;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-green .dark .mat-mdc-raised-button.mat-accent,
.theme-green.dark .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-green .dark .mat-mdc-raised-button.mat-warn,
.theme-green.dark .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-green .dark .mat-mdc-outlined-button.mat-primary,
.theme-green.dark .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: darkgreen;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: darkgreen;
  --mat-outlined-button-ripple-color: rgba(0, 100, 0, 0.1);
}
.theme-green .dark .mat-mdc-outlined-button.mat-accent,
.theme-green.dark .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1e293b;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: #1e293b;
  --mat-outlined-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-green .dark .mat-mdc-outlined-button.mat-warn,
.theme-green.dark .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc2626;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: #dc2626;
  --mat-outlined-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-green .dark .mat-mdc-icon-button.mat-primary,
.theme-green.dark .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: darkgreen;
  --mat-icon-button-state-layer-color: darkgreen;
  --mat-icon-button-ripple-color: rgba(0, 100, 0, 0.1);
}
.theme-green .dark .mat-mdc-icon-button.mat-accent,
.theme-green.dark .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1e293b;
  --mat-icon-button-state-layer-color: #1e293b;
  --mat-icon-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-green .dark .mat-mdc-icon-button.mat-warn,
.theme-green.dark .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc2626;
  --mat-icon-button-state-layer-color: #dc2626;
  --mat-icon-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-green .dark .mat-mdc-fab.mat-primary,
.theme-green.dark .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: darkgreen;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-green .dark .mat-mdc-fab.mat-accent,
.theme-green.dark .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #1e293b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-green .dark .mat-mdc-fab.mat-warn,
.theme-green.dark .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #dc2626;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-green .dark .mat-mdc-mini-fab.mat-primary,
.theme-green.dark .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: darkgreen;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-green .dark .mat-mdc-mini-fab.mat-accent,
.theme-green.dark .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #1e293b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-green .dark .mat-mdc-mini-fab.mat-warn,
.theme-green.dark .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #dc2626;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-green .dark .mat-accent,
.theme-green.dark .mat-accent {
  --mdc-circular-progress-active-indicator-color: #1e293b;
}
.theme-green .dark .mat-warn,
.theme-green.dark .mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}
.theme-green .dark .mat-badge-accent,
.theme-green.dark .mat-badge-accent {
  --mat-badge-background-color: #1e293b;
  --mat-badge-text-color: white;
}
.theme-green .dark .mat-badge-warn,
.theme-green.dark .mat-badge-warn {
  --mat-badge-background-color: #dc2626;
  --mat-badge-text-color: white;
}
.theme-green .dark .mat-datepicker-content.mat-accent,
.theme-green.dark .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #1e293b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(30, 41, 59, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(30, 41, 59, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-green .dark .mat-datepicker-content.mat-warn,
.theme-green.dark .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #dc2626;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(220, 38, 38, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(220, 38, 38, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-green .dark .mat-datepicker-toggle-active.mat-accent,
.theme-green.dark .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #1e293b;
}
.theme-green .dark .mat-datepicker-toggle-active.mat-warn,
.theme-green.dark .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #dc2626;
}
.theme-green .dark .mat-icon.mat-primary,
.theme-green.dark .mat-icon.mat-primary {
  --mat-icon-color: darkgreen;
}
.theme-green .dark .mat-icon.mat-accent,
.theme-green.dark .mat-icon.mat-accent {
  --mat-icon-color: #1e293b;
}
.theme-green .dark .mat-icon.mat-warn,
.theme-green.dark .mat-icon.mat-warn {
  --mat-icon-color: #dc2626;
}
.theme-green .dark .mat-step-header.mat-accent,
.theme-green.dark .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #1e293b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #1e293b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #1e293b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-green .dark .mat-step-header.mat-warn,
.theme-green.dark .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #dc2626;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #dc2626;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #dc2626;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-green .dark .mat-toolbar.mat-primary,
.theme-green.dark .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: darkgreen;
  --mat-toolbar-container-text-color: white;
}
.theme-green .dark .mat-toolbar.mat-accent,
.theme-green.dark .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #1e293b;
  --mat-toolbar-container-text-color: white;
}
.theme-green .dark .mat-toolbar.mat-warn,
.theme-green.dark .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #dc2626;
  --mat-toolbar-container-text-color: white;
}

/* Generate the palettes */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define a light & dark Angular Material theme with the generated palettes */
/* Merge the custom base colors with the generated themes */
/* Generate and encapsulate Angular Material themes */
.theme-maroon .light,
.theme-maroon.light {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-option-selected-state-label-text-color: darkmagenta;
  --mat-option-label-text-color: #1e293b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-optgroup-label-text-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
  --mat-app-background-color: #f1f5f9;
  --mat-app-text-color: #1e293b;
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: #64748b;
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #1e293b;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: darkmagenta;
  --mdc-filled-text-field-focus-active-indicator-color: darkmagenta;
  --mdc-filled-text-field-focus-label-text-color: rgba(139, 0, 139, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(30, 41, 59, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(148, 163, 184, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(30, 41, 59, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #dc2626;
  --mdc-filled-text-field-error-focus-label-text-color: #dc2626;
  --mdc-filled-text-field-error-label-text-color: #dc2626;
  --mdc-filled-text-field-error-caret-color: #dc2626;
  --mdc-filled-text-field-active-indicator-color: rgba(226, 232, 240, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(226, 232, 240, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(226, 232, 240, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-hover-active-indicator-color: #dc2626;
  --mdc-outlined-text-field-caret-color: darkmagenta;
  --mdc-outlined-text-field-focus-outline-color: darkmagenta;
  --mdc-outlined-text-field-focus-label-text-color: rgba(139, 0, 139, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(30, 41, 59, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(148, 163, 184, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(30, 41, 59, 0.6);
  --mdc-outlined-text-field-error-caret-color: #dc2626;
  --mdc-outlined-text-field-error-focus-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-hover-label-text-color: #dc2626;
  --mdc-outlined-text-field-outline-color: rgba(226, 232, 240, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(226, 232, 240, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(226, 232, 240, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-error-hover-outline-color: #dc2626;
  --mdc-outlined-text-field-error-outline-color: #dc2626;
  --mat-form-field-focus-select-arrow-color: rgba(139, 0, 139, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(100, 116, 139, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #dc2626;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(100, 116, 139, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(100, 116, 139, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(30, 41, 59, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(148, 163, 184, 0.38);
  --mat-select-placeholder-text-color: rgba(30, 41, 59, 0.6);
  --mat-select-enabled-arrow-color: rgba(226, 232, 240, 0.54);
  --mat-select-disabled-arrow-color: rgba(30, 41, 59, 0.38);
  --mat-select-focused-arrow-color: rgba(139, 0, 139, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: white;
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(30, 41, 59, 0.87);
  --mdc-dialog-supporting-text-color: rgba(30, 41, 59, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
  --mdc-switch-selected-focus-state-layer-color: #7e007e;
  --mdc-switch-selected-handle-color: #7e007e;
  --mdc-switch-selected-hover-state-layer-color: #7e007e;
  --mdc-switch-selected-pressed-state-layer-color: #7e007e;
  --mdc-switch-selected-focus-handle-color: #4b004b;
  --mdc-switch-selected-hover-handle-color: #4b004b;
  --mdc-switch-selected-pressed-handle-color: #4b004b;
  --mdc-switch-selected-focus-track-color: #c19fc1;
  --mdc-switch-selected-hover-track-color: #c19fc1;
  --mdc-switch-selected-pressed-track-color: #c19fc1;
  --mdc-switch-selected-track-color: #c19fc1;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-disabled-label-text-color: #94a3b8;
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-color: darkmagenta;
  --mdc-slider-focus-handle-color: darkmagenta;
  --mdc-slider-hover-handle-color: darkmagenta;
  --mdc-slider-active-track-color: darkmagenta;
  --mdc-slider-inactive-track-color: darkmagenta;
  --mdc-slider-with-tick-marks-inactive-container-color: darkmagenta;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: darkmagenta;
  --mat-slider-hover-state-layer-color: rgba(139, 0, 139, 0.05);
  --mat-slider-focus-state-layer-color: rgba(139, 0, 139, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-item-label-text-color: #1e293b;
  --mat-menu-item-icon-color: #1e293b;
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: #e2e8f0;
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 0;
  --mdc-list-list-item-label-text-color: #1e293b;
  --mdc-list-list-item-supporting-text-color: #64748b;
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: #94a3b8;
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: #1e293b;
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: #1e293b;
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: #94a3b8;
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: #1e293b;
  --mdc-text-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: #1e293b;
  --mdc-filled-button-disabled-container-color: rgba(148, 163, 184, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: #1e293b;
  --mdc-protected-button-disabled-container-color: rgba(148, 163, 184, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: #e2e8f0;
  --mdc-outlined-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-outlined-button-label-text-color: #1e293b;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-fab-container-color: white;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(148, 163, 184, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(148, 163, 184, 0.38);
  --mdc-fab-small-container-color: white;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(148, 163, 184, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(148, 163, 184, 0.38);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #1e293b;
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: white;
  --mat-table-header-headline-color: #1e293b;
  --mat-table-row-item-label-text-color: #1e293b;
  --mat-table-row-item-outline-color: #e2e8f0;
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: darkmagenta;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: darkmagenta;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #94a3b8;
  --mat-badge-disabled-state-text-color: #94a3b8;
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: #1e293b;
  --mat-bottom-sheet-container-background-color: white;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: #94a3b8;
  --mat-legacy-button-toggle-state-layer-color: #64748b;
  --mat-legacy-button-toggle-selected-state-text-color: #64748b;
  --mat-legacy-button-toggle-selected-state-background-color: #e2e8f0;
  --mat-legacy-button-toggle-disabled-state-text-color: #94a3b8;
  --mat-legacy-button-toggle-disabled-state-background-color: #cbd5e1;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #e2e8f0;
  --mat-standard-button-toggle-text-color: #1e293b;
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: #64748b;
  --mat-standard-button-toggle-selected-state-background-color: #e2e8f0;
  --mat-standard-button-toggle-selected-state-text-color: #1e293b;
  --mat-standard-button-toggle-disabled-state-text-color: #94a3b8;
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: #1e293b;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #e2e8f0;
  --mat-standard-button-toggle-divider-color: #e2e8f0;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: darkmagenta;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(139, 0, 139, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(139, 0, 139, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(139, 0, 139, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: darkmagenta;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(139, 0, 139, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: #64748b;
  --mat-datepicker-calendar-body-label-text-color: #64748b;
  --mat-datepicker-calendar-period-button-text-color: #1e293b;
  --mat-datepicker-calendar-period-button-icon-color: #64748b;
  --mat-datepicker-calendar-navigation-button-icon-color: #64748b;
  --mat-datepicker-calendar-header-divider-color: #e2e8f0;
  --mat-datepicker-calendar-header-text-color: #64748b;
  --mat-datepicker-calendar-date-today-outline-color: #94a3b8;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(148, 163, 184, 0.8);
  --mat-datepicker-calendar-date-text-color: #1e293b;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: #94a3b8;
  --mat-datepicker-calendar-date-preview-state-outline-color: #e2e8f0;
  --mat-datepicker-range-input-separator-color: #1e293b;
  --mat-datepicker-range-input-disabled-state-separator-color: #94a3b8;
  --mat-datepicker-range-input-disabled-state-text-color: #94a3b8;
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: #1e293b;
  --mat-divider-width: 1px;
  --mat-divider-color: #e2e8f0;
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: #1e293b;
  --mat-expansion-actions-divider-color: #e2e8f0;
  --mat-expansion-header-hover-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-expansion-header-focus-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-expansion-header-disabled-state-text-color: #94a3b8;
  --mat-expansion-header-text-color: #1e293b;
  --mat-expansion-header-description-color: #64748b;
  --mat-expansion-header-indicator-color: #64748b;
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: #e2e8f0;
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: #1e293b;
  --mat-sidenav-content-background-color: #f1f5f9;
  --mat-sidenav-content-text-color: #1e293b;
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: darkmagenta;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: darkmagenta;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: darkmagenta;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: #e2e8f0;
  --mat-stepper-header-hover-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-stepper-header-focus-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-stepper-header-label-text-color: #64748b;
  --mat-stepper-header-optional-label-text-color: #64748b;
  --mat-stepper-header-selected-state-label-text-color: #1e293b;
  --mat-stepper-header-error-state-label-text-color: #dc2626;
  --mat-stepper-header-icon-background-color: #64748b;
  --mat-stepper-header-error-state-icon-foreground-color: #dc2626;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #64748b;
  --mat-toolbar-container-background-color: white;
  --mat-toolbar-container-text-color: #1e293b;
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: #1e293b;
}
.theme-maroon .light .mat-accent,
.theme-maroon.light .mat-accent {
  --mat-option-selected-state-label-text-color: #1e293b;
  --mat-option-label-text-color: #1e293b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.theme-maroon .light .mat-warn,
.theme-maroon.light .mat-warn {
  --mat-option-selected-state-label-text-color: #dc2626;
  --mat-option-label-text-color: #1e293b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.theme-maroon .light .mat-primary,
.theme-maroon.light .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: darkmagenta;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: darkmagenta;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-maroon .light .mat-accent,
.theme-maroon.light .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-maroon .light .mat-warn,
.theme-maroon.light .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #dc2626;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #dc2626;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-maroon .light .mat-elevation-z0, .theme-maroon .light .mat-mdc-elevation-specific.mat-elevation-z0,
.theme-maroon.light .mat-elevation-z0,
.theme-maroon.light .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-maroon .light .mat-elevation-z1, .theme-maroon .light .mat-mdc-elevation-specific.mat-elevation-z1,
.theme-maroon.light .mat-elevation-z1,
.theme-maroon.light .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-maroon .light .mat-elevation-z2, .theme-maroon .light .mat-mdc-elevation-specific.mat-elevation-z2,
.theme-maroon.light .mat-elevation-z2,
.theme-maroon.light .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-maroon .light .mat-elevation-z3, .theme-maroon .light .mat-mdc-elevation-specific.mat-elevation-z3,
.theme-maroon.light .mat-elevation-z3,
.theme-maroon.light .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-maroon .light .mat-elevation-z4, .theme-maroon .light .mat-mdc-elevation-specific.mat-elevation-z4,
.theme-maroon.light .mat-elevation-z4,
.theme-maroon.light .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-maroon .light .mat-elevation-z5, .theme-maroon .light .mat-mdc-elevation-specific.mat-elevation-z5,
.theme-maroon.light .mat-elevation-z5,
.theme-maroon.light .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-maroon .light .mat-elevation-z6, .theme-maroon .light .mat-mdc-elevation-specific.mat-elevation-z6,
.theme-maroon.light .mat-elevation-z6,
.theme-maroon.light .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-maroon .light .mat-elevation-z7, .theme-maroon .light .mat-mdc-elevation-specific.mat-elevation-z7,
.theme-maroon.light .mat-elevation-z7,
.theme-maroon.light .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-maroon .light .mat-elevation-z8, .theme-maroon .light .mat-mdc-elevation-specific.mat-elevation-z8,
.theme-maroon.light .mat-elevation-z8,
.theme-maroon.light .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-maroon .light .mat-elevation-z9, .theme-maroon .light .mat-mdc-elevation-specific.mat-elevation-z9,
.theme-maroon.light .mat-elevation-z9,
.theme-maroon.light .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-maroon .light .mat-elevation-z10, .theme-maroon .light .mat-mdc-elevation-specific.mat-elevation-z10,
.theme-maroon.light .mat-elevation-z10,
.theme-maroon.light .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-maroon .light .mat-elevation-z11, .theme-maroon .light .mat-mdc-elevation-specific.mat-elevation-z11,
.theme-maroon.light .mat-elevation-z11,
.theme-maroon.light .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-maroon .light .mat-elevation-z12, .theme-maroon .light .mat-mdc-elevation-specific.mat-elevation-z12,
.theme-maroon.light .mat-elevation-z12,
.theme-maroon.light .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-maroon .light .mat-elevation-z13, .theme-maroon .light .mat-mdc-elevation-specific.mat-elevation-z13,
.theme-maroon.light .mat-elevation-z13,
.theme-maroon.light .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-maroon .light .mat-elevation-z14, .theme-maroon .light .mat-mdc-elevation-specific.mat-elevation-z14,
.theme-maroon.light .mat-elevation-z14,
.theme-maroon.light .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-maroon .light .mat-elevation-z15, .theme-maroon .light .mat-mdc-elevation-specific.mat-elevation-z15,
.theme-maroon.light .mat-elevation-z15,
.theme-maroon.light .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-maroon .light .mat-elevation-z16, .theme-maroon .light .mat-mdc-elevation-specific.mat-elevation-z16,
.theme-maroon.light .mat-elevation-z16,
.theme-maroon.light .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-maroon .light .mat-elevation-z17, .theme-maroon .light .mat-mdc-elevation-specific.mat-elevation-z17,
.theme-maroon.light .mat-elevation-z17,
.theme-maroon.light .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-maroon .light .mat-elevation-z18, .theme-maroon .light .mat-mdc-elevation-specific.mat-elevation-z18,
.theme-maroon.light .mat-elevation-z18,
.theme-maroon.light .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-maroon .light .mat-elevation-z19, .theme-maroon .light .mat-mdc-elevation-specific.mat-elevation-z19,
.theme-maroon.light .mat-elevation-z19,
.theme-maroon.light .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-maroon .light .mat-elevation-z20, .theme-maroon .light .mat-mdc-elevation-specific.mat-elevation-z20,
.theme-maroon.light .mat-elevation-z20,
.theme-maroon.light .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-maroon .light .mat-elevation-z21, .theme-maroon .light .mat-mdc-elevation-specific.mat-elevation-z21,
.theme-maroon.light .mat-elevation-z21,
.theme-maroon.light .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-maroon .light .mat-elevation-z22, .theme-maroon .light .mat-mdc-elevation-specific.mat-elevation-z22,
.theme-maroon.light .mat-elevation-z22,
.theme-maroon.light .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-maroon .light .mat-elevation-z23, .theme-maroon .light .mat-mdc-elevation-specific.mat-elevation-z23,
.theme-maroon.light .mat-elevation-z23,
.theme-maroon.light .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-maroon .light .mat-elevation-z24, .theme-maroon .light .mat-mdc-elevation-specific.mat-elevation-z24,
.theme-maroon.light .mat-elevation-z24,
.theme-maroon.light .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-maroon .light .mat-mdc-progress-bar,
.theme-maroon.light .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: darkmagenta;
  --mdc-linear-progress-track-color: rgba(139, 0, 139, 0.25);
}
.theme-maroon .light .mat-mdc-progress-bar.mat-accent,
.theme-maroon.light .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1e293b;
  --mdc-linear-progress-track-color: rgba(30, 41, 59, 0.25);
}
.theme-maroon .light .mat-mdc-progress-bar.mat-warn,
.theme-maroon.light .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc2626;
  --mdc-linear-progress-track-color: rgba(220, 38, 38, 0.25);
}
.theme-maroon .light .mat-mdc-form-field.mat-accent,
.theme-maroon.light .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #1e293b;
  --mdc-filled-text-field-focus-active-indicator-color: #1e293b;
  --mdc-filled-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mdc-outlined-text-field-caret-color: #1e293b;
  --mdc-outlined-text-field-focus-outline-color: #1e293b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(30, 41, 59, 0.87);
}
.theme-maroon .light .mat-mdc-form-field.mat-warn,
.theme-maroon.light .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #dc2626;
  --mdc-filled-text-field-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mdc-outlined-text-field-caret-color: #dc2626;
  --mdc-outlined-text-field-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-maroon .light .mat-mdc-form-field.mat-accent,
.theme-maroon.light .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(30, 41, 59, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(148, 163, 184, 0.38);
  --mat-select-placeholder-text-color: rgba(30, 41, 59, 0.6);
  --mat-select-enabled-arrow-color: rgba(226, 232, 240, 0.54);
  --mat-select-disabled-arrow-color: rgba(30, 41, 59, 0.38);
  --mat-select-focused-arrow-color: rgba(30, 41, 59, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-maroon .light .mat-mdc-form-field.mat-warn,
.theme-maroon.light .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(30, 41, 59, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(148, 163, 184, 0.38);
  --mat-select-placeholder-text-color: rgba(30, 41, 59, 0.6);
  --mat-select-enabled-arrow-color: rgba(226, 232, 240, 0.54);
  --mat-select-disabled-arrow-color: rgba(30, 41, 59, 0.38);
  --mat-select-focused-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-maroon .light .mat-mdc-standard-chip,
.theme-maroon.light .mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.theme-maroon .light .mat-mdc-standard-chip,
.theme-maroon.light .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.theme-maroon .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .theme-maroon .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.theme-maroon.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.theme-maroon.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: darkmagenta;
  --mdc-chip-elevated-selected-container-color: darkmagenta;
  --mdc-chip-elevated-disabled-container-color: darkmagenta;
  --mdc-chip-flat-disabled-selected-container-color: darkmagenta;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-maroon .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .theme-maroon .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.theme-maroon.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.theme-maroon.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #1e293b;
  --mdc-chip-elevated-selected-container-color: #1e293b;
  --mdc-chip-elevated-disabled-container-color: #1e293b;
  --mdc-chip-flat-disabled-selected-container-color: #1e293b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-maroon .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .theme-maroon .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.theme-maroon.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.theme-maroon.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-selected-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-flat-disabled-selected-container-color: #dc2626;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-maroon .light .mat-mdc-slide-toggle,
.theme-maroon.light .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: #1e293b;
}
.theme-maroon .light .mat-mdc-slide-toggle.mat-accent,
.theme-maroon.light .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #475569;
  --mdc-switch-selected-handle-color: #475569;
  --mdc-switch-selected-hover-state-layer-color: #475569;
  --mdc-switch-selected-pressed-state-layer-color: #475569;
  --mdc-switch-selected-focus-handle-color: #0f172a;
  --mdc-switch-selected-hover-handle-color: #0f172a;
  --mdc-switch-selected-pressed-handle-color: #0f172a;
  --mdc-switch-selected-focus-track-color: #cbd5e1;
  --mdc-switch-selected-hover-track-color: #cbd5e1;
  --mdc-switch-selected-pressed-track-color: #cbd5e1;
  --mdc-switch-selected-track-color: #cbd5e1;
}
.theme-maroon .light .mat-mdc-slide-toggle.mat-warn,
.theme-maroon.light .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.theme-maroon .light .mat-mdc-radio-button,
.theme-maroon.light .mat-mdc-radio-button {
  --mdc-form-field-label-text-color: #1e293b;
}
.theme-maroon .light .mat-mdc-radio-button.mat-primary,
.theme-maroon.light .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: darkmagenta;
  --mdc-radio-selected-hover-icon-color: darkmagenta;
  --mdc-radio-selected-icon-color: darkmagenta;
  --mdc-radio-selected-pressed-icon-color: darkmagenta;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: darkmagenta;
  --mat-radio-disabled-label-color: #94a3b8;
}
.theme-maroon .light .mat-mdc-radio-button.mat-accent,
.theme-maroon.light .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #1e293b;
  --mat-radio-disabled-label-color: #94a3b8;
}
.theme-maroon .light .mat-mdc-radio-button.mat-warn,
.theme-maroon.light .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #dc2626;
  --mat-radio-disabled-label-color: #94a3b8;
}
.theme-maroon .light .mat-accent,
.theme-maroon.light .mat-accent {
  --mat-slider-ripple-color: #1e293b;
  --mat-slider-hover-state-layer-color: rgba(30, 41, 59, 0.05);
  --mat-slider-focus-state-layer-color: rgba(30, 41, 59, 0.2);
  --mdc-slider-handle-color: #1e293b;
  --mdc-slider-focus-handle-color: #1e293b;
  --mdc-slider-hover-handle-color: #1e293b;
  --mdc-slider-active-track-color: #1e293b;
  --mdc-slider-inactive-track-color: #1e293b;
  --mdc-slider-with-tick-marks-inactive-container-color: #1e293b;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-maroon .light .mat-warn,
.theme-maroon.light .mat-warn {
  --mat-slider-ripple-color: #dc2626;
  --mat-slider-hover-state-layer-color: rgba(220, 38, 38, 0.05);
  --mat-slider-focus-state-layer-color: rgba(220, 38, 38, 0.2);
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-maroon .light .mdc-list-item__start,
.theme-maroon .light .mdc-list-item__end,
.theme-maroon.light .mdc-list-item__start,
.theme-maroon.light .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: darkmagenta;
  --mdc-radio-selected-hover-icon-color: darkmagenta;
  --mdc-radio-selected-icon-color: darkmagenta;
  --mdc-radio-selected-pressed-icon-color: darkmagenta;
}
.theme-maroon .light .mat-accent .mdc-list-item__start,
.theme-maroon .light .mat-accent .mdc-list-item__end,
.theme-maroon.light .mat-accent .mdc-list-item__start,
.theme-maroon.light .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
}
.theme-maroon .light .mat-warn .mdc-list-item__start,
.theme-maroon .light .mat-warn .mdc-list-item__end,
.theme-maroon.light .mat-warn .mdc-list-item__start,
.theme-maroon.light .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
}
.theme-maroon .light .mat-mdc-list-option,
.theme-maroon.light .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: darkmagenta;
  --mdc-checkbox-selected-hover-icon-color: darkmagenta;
  --mdc-checkbox-selected-icon-color: darkmagenta;
  --mdc-checkbox-selected-pressed-icon-color: darkmagenta;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: darkmagenta;
  --mdc-checkbox-selected-hover-state-layer-color: darkmagenta;
  --mdc-checkbox-selected-pressed-state-layer-color: darkmagenta;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-maroon .light .mat-mdc-list-option.mat-accent,
.theme-maroon.light .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-maroon .light .mat-mdc-list-option.mat-warn,
.theme-maroon.light .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-maroon .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-maroon .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-maroon.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-maroon.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: darkmagenta;
}
.theme-maroon .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-maroon .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-maroon.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-maroon.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: darkmagenta;
}
.theme-maroon .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-maroon .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-maroon .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.theme-maroon.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-maroon.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-maroon.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.theme-maroon .light .mat-mdc-tab-group, .theme-maroon .light .mat-mdc-tab-nav-bar,
.theme-maroon.light .mat-mdc-tab-group,
.theme-maroon.light .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: darkmagenta;
  --mat-tab-header-disabled-ripple-color: #94a3b8;
  --mat-tab-header-pagination-icon-color: #64748b;
  --mat-tab-header-inactive-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-label-text-color: darkmagenta;
  --mat-tab-header-active-ripple-color: darkmagenta;
  --mat-tab-header-inactive-ripple-color: darkmagenta;
  --mat-tab-header-inactive-focus-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-focus-label-text-color: darkmagenta;
  --mat-tab-header-active-hover-label-text-color: darkmagenta;
  --mat-tab-header-active-focus-indicator-color: darkmagenta;
  --mat-tab-header-active-hover-indicator-color: darkmagenta;
}
.theme-maroon .light .mat-mdc-tab-group.mat-accent, .theme-maroon .light .mat-mdc-tab-nav-bar.mat-accent,
.theme-maroon.light .mat-mdc-tab-group.mat-accent,
.theme-maroon.light .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #1e293b;
  --mat-tab-header-disabled-ripple-color: #94a3b8;
  --mat-tab-header-pagination-icon-color: #64748b;
  --mat-tab-header-inactive-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-label-text-color: #1e293b;
  --mat-tab-header-active-ripple-color: #1e293b;
  --mat-tab-header-inactive-ripple-color: #1e293b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-focus-label-text-color: #1e293b;
  --mat-tab-header-active-hover-label-text-color: #1e293b;
  --mat-tab-header-active-focus-indicator-color: #1e293b;
  --mat-tab-header-active-hover-indicator-color: #1e293b;
}
.theme-maroon .light .mat-mdc-tab-group.mat-warn, .theme-maroon .light .mat-mdc-tab-nav-bar.mat-warn,
.theme-maroon.light .mat-mdc-tab-group.mat-warn,
.theme-maroon.light .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #dc2626;
  --mat-tab-header-disabled-ripple-color: #94a3b8;
  --mat-tab-header-pagination-icon-color: #64748b;
  --mat-tab-header-inactive-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-label-text-color: #dc2626;
  --mat-tab-header-active-ripple-color: #dc2626;
  --mat-tab-header-inactive-ripple-color: #dc2626;
  --mat-tab-header-inactive-focus-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-focus-label-text-color: #dc2626;
  --mat-tab-header-active-hover-label-text-color: #dc2626;
  --mat-tab-header-active-focus-indicator-color: #dc2626;
  --mat-tab-header-active-hover-indicator-color: #dc2626;
}
.theme-maroon .light .mat-mdc-tab-group.mat-background-primary, .theme-maroon .light .mat-mdc-tab-nav-bar.mat-background-primary,
.theme-maroon.light .mat-mdc-tab-group.mat-background-primary,
.theme-maroon.light .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: darkmagenta;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-maroon .light .mat-mdc-tab-group.mat-background-accent, .theme-maroon .light .mat-mdc-tab-nav-bar.mat-background-accent,
.theme-maroon.light .mat-mdc-tab-group.mat-background-accent,
.theme-maroon.light .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #1e293b;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-maroon .light .mat-mdc-tab-group.mat-background-warn, .theme-maroon .light .mat-mdc-tab-nav-bar.mat-background-warn,
.theme-maroon.light .mat-mdc-tab-group.mat-background-warn,
.theme-maroon.light .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #dc2626;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-maroon .light .mat-mdc-checkbox,
.theme-maroon.light .mat-mdc-checkbox {
  --mdc-form-field-label-text-color: #1e293b;
}
.theme-maroon .light .mat-mdc-checkbox.mat-primary,
.theme-maroon.light .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: darkmagenta;
  --mdc-checkbox-selected-hover-icon-color: darkmagenta;
  --mdc-checkbox-selected-icon-color: darkmagenta;
  --mdc-checkbox-selected-pressed-icon-color: darkmagenta;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: darkmagenta;
  --mdc-checkbox-selected-hover-state-layer-color: darkmagenta;
  --mdc-checkbox-selected-pressed-state-layer-color: darkmagenta;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-maroon .light .mat-mdc-checkbox.mat-warn,
.theme-maroon.light .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-maroon .light .mat-mdc-button.mat-primary,
.theme-maroon.light .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: darkmagenta;
  --mat-text-button-state-layer-color: darkmagenta;
  --mat-text-button-ripple-color: rgba(139, 0, 139, 0.1);
}
.theme-maroon .light .mat-mdc-button.mat-accent,
.theme-maroon.light .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1e293b;
  --mat-text-button-state-layer-color: #1e293b;
  --mat-text-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-maroon .light .mat-mdc-button.mat-warn,
.theme-maroon.light .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc2626;
  --mat-text-button-state-layer-color: #dc2626;
  --mat-text-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-maroon .light .mat-mdc-unelevated-button.mat-primary,
.theme-maroon.light .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: darkmagenta;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-maroon .light .mat-mdc-unelevated-button.mat-accent,
.theme-maroon.light .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-maroon .light .mat-mdc-unelevated-button.mat-warn,
.theme-maroon.light .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-maroon .light .mat-mdc-raised-button.mat-primary,
.theme-maroon.light .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: darkmagenta;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-maroon .light .mat-mdc-raised-button.mat-accent,
.theme-maroon.light .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-maroon .light .mat-mdc-raised-button.mat-warn,
.theme-maroon.light .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-maroon .light .mat-mdc-outlined-button.mat-primary,
.theme-maroon.light .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: darkmagenta;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: darkmagenta;
  --mat-outlined-button-ripple-color: rgba(139, 0, 139, 0.1);
}
.theme-maroon .light .mat-mdc-outlined-button.mat-accent,
.theme-maroon.light .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1e293b;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: #1e293b;
  --mat-outlined-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-maroon .light .mat-mdc-outlined-button.mat-warn,
.theme-maroon.light .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc2626;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: #dc2626;
  --mat-outlined-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-maroon .light .mat-mdc-icon-button.mat-primary,
.theme-maroon.light .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: darkmagenta;
  --mat-icon-button-state-layer-color: darkmagenta;
  --mat-icon-button-ripple-color: rgba(139, 0, 139, 0.1);
}
.theme-maroon .light .mat-mdc-icon-button.mat-accent,
.theme-maroon.light .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1e293b;
  --mat-icon-button-state-layer-color: #1e293b;
  --mat-icon-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-maroon .light .mat-mdc-icon-button.mat-warn,
.theme-maroon.light .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc2626;
  --mat-icon-button-state-layer-color: #dc2626;
  --mat-icon-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-maroon .light .mat-mdc-fab.mat-primary,
.theme-maroon.light .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: darkmagenta;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-maroon .light .mat-mdc-fab.mat-accent,
.theme-maroon.light .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #1e293b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-maroon .light .mat-mdc-fab.mat-warn,
.theme-maroon.light .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #dc2626;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-maroon .light .mat-mdc-mini-fab.mat-primary,
.theme-maroon.light .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: darkmagenta;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-maroon .light .mat-mdc-mini-fab.mat-accent,
.theme-maroon.light .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #1e293b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-maroon .light .mat-mdc-mini-fab.mat-warn,
.theme-maroon.light .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #dc2626;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-maroon .light .mat-accent,
.theme-maroon.light .mat-accent {
  --mdc-circular-progress-active-indicator-color: #1e293b;
}
.theme-maroon .light .mat-warn,
.theme-maroon.light .mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}
.theme-maroon .light .mat-badge-accent,
.theme-maroon.light .mat-badge-accent {
  --mat-badge-background-color: #1e293b;
  --mat-badge-text-color: white;
}
.theme-maroon .light .mat-badge-warn,
.theme-maroon.light .mat-badge-warn {
  --mat-badge-background-color: #dc2626;
  --mat-badge-text-color: white;
}
.theme-maroon .light .mat-datepicker-content.mat-accent,
.theme-maroon.light .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #1e293b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(30, 41, 59, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(30, 41, 59, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-maroon .light .mat-datepicker-content.mat-warn,
.theme-maroon.light .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #dc2626;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(220, 38, 38, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(220, 38, 38, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-maroon .light .mat-datepicker-toggle-active.mat-accent,
.theme-maroon.light .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #1e293b;
}
.theme-maroon .light .mat-datepicker-toggle-active.mat-warn,
.theme-maroon.light .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #dc2626;
}
.theme-maroon .light .mat-icon.mat-primary,
.theme-maroon.light .mat-icon.mat-primary {
  --mat-icon-color: darkmagenta;
}
.theme-maroon .light .mat-icon.mat-accent,
.theme-maroon.light .mat-icon.mat-accent {
  --mat-icon-color: #1e293b;
}
.theme-maroon .light .mat-icon.mat-warn,
.theme-maroon.light .mat-icon.mat-warn {
  --mat-icon-color: #dc2626;
}
.theme-maroon .light .mat-step-header.mat-accent,
.theme-maroon.light .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #1e293b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #1e293b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #1e293b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-maroon .light .mat-step-header.mat-warn,
.theme-maroon.light .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #dc2626;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #dc2626;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #dc2626;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-maroon .light .mat-toolbar.mat-primary,
.theme-maroon.light .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: darkmagenta;
  --mat-toolbar-container-text-color: white;
}
.theme-maroon .light .mat-toolbar.mat-accent,
.theme-maroon.light .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #1e293b;
  --mat-toolbar-container-text-color: white;
}
.theme-maroon .light .mat-toolbar.mat-warn,
.theme-maroon.light .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #dc2626;
  --mat-toolbar-container-text-color: white;
}

.theme-maroon .dark,
.theme-maroon.dark {
  --mat-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-option-selected-state-label-text-color: darkmagenta;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-optgroup-label-text-color: white;
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
  --mat-app-background-color: #0f172a;
  --mat-app-text-color: white;
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: #1e293b;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: #1e293b;
  --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: #94a3b8;
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #64748b;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: darkmagenta;
  --mdc-filled-text-field-focus-active-indicator-color: darkmagenta;
  --mdc-filled-text-field-focus-label-text-color: rgba(139, 0, 139, 0.87);
  --mdc-filled-text-field-container-color: #273243;
  --mdc-filled-text-field-disabled-container-color: #232d3f;
  --mdc-filled-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(71, 85, 105, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(71, 85, 105, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #dc2626;
  --mdc-filled-text-field-error-focus-label-text-color: #dc2626;
  --mdc-filled-text-field-error-label-text-color: #dc2626;
  --mdc-filled-text-field-error-caret-color: #dc2626;
  --mdc-filled-text-field-active-indicator-color: rgba(241, 245, 249, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(241, 245, 249, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(241, 245, 249, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-hover-active-indicator-color: #dc2626;
  --mdc-outlined-text-field-caret-color: darkmagenta;
  --mdc-outlined-text-field-focus-outline-color: darkmagenta;
  --mdc-outlined-text-field-focus-label-text-color: rgba(139, 0, 139, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(71, 85, 105, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(71, 85, 105, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-error-caret-color: #dc2626;
  --mdc-outlined-text-field-error-focus-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-hover-label-text-color: #dc2626;
  --mdc-outlined-text-field-outline-color: rgba(241, 245, 249, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(241, 245, 249, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(241, 245, 249, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-error-hover-outline-color: #dc2626;
  --mdc-outlined-text-field-error-outline-color: #dc2626;
  --mat-form-field-focus-select-arrow-color: rgba(139, 0, 139, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(241, 245, 249, 0.38);
  --mat-form-field-state-layer-color: rgba(255, 255, 255, 0.87);
  --mat-form-field-error-text-color: #dc2626;
  --mat-form-field-select-option-text-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-select-disabled-option-text-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(241, 245, 249, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.08;
  --mat-form-field-focus-state-layer-opacity: 0.24;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: #1e293b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(71, 85, 105, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(139, 0, 139, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: #1e293b;
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: #1e293b;
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
  --mdc-switch-selected-focus-state-layer-color: #c19fc1;
  --mdc-switch-selected-handle-color: #c19fc1;
  --mdc-switch-selected-hover-state-layer-color: #c19fc1;
  --mdc-switch-selected-pressed-state-layer-color: #c19fc1;
  --mdc-switch-selected-focus-handle-color: #d7c3d7;
  --mdc-switch-selected-hover-handle-color: #d7c3d7;
  --mdc-switch-selected-pressed-handle-color: #d7c3d7;
  --mdc-switch-selected-focus-track-color: #7e007e;
  --mdc-switch-selected-hover-track-color: #7e007e;
  --mdc-switch-selected-pressed-track-color: #7e007e;
  --mdc-switch-selected-track-color: #7e007e;
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-icon-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-disabled-label-text-color: #475569;
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-color: darkmagenta;
  --mdc-slider-focus-handle-color: darkmagenta;
  --mdc-slider-hover-handle-color: darkmagenta;
  --mdc-slider-active-track-color: darkmagenta;
  --mdc-slider-inactive-track-color: darkmagenta;
  --mdc-slider-with-tick-marks-inactive-container-color: darkmagenta;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-label-container-color: #fff;
  --mdc-slider-label-label-text-color: #000;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: darkmagenta;
  --mat-slider-hover-state-layer-color: rgba(139, 0, 139, 0.05);
  --mat-slider-focus-state-layer-color: rgba(139, 0, 139, 0.2);
  --mat-slider-value-indicator-opacity: 0.9;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-item-label-text-color: white;
  --mat-menu-item-icon-color: white;
  --mat-menu-item-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-container-color: #1e293b;
  --mat-menu-divider-color: rgba(241, 245, 249, 0.12);
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 0;
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: #94a3b8;
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: #64748b;
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: white;
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
  --mat-paginator-container-text-color: rgba(255, 255, 255, 0.87);
  --mat-paginator-container-background-color: #1e293b;
  --mat-paginator-enabled-icon-color: rgba(255, 255, 255, 0.54);
  --mat-paginator-disabled-icon-color: rgba(255, 255, 255, 0.12);
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
  --mat-checkbox-disabled-label-color: #475569;
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: white;
  --mdc-text-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mat-text-button-state-layer-color: white;
  --mat-text-button-disabled-state-layer-color: white;
  --mat-text-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.08;
  --mat-text-button-focus-state-layer-opacity: 0.24;
  --mat-text-button-pressed-state-layer-opacity: 0.24;
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: white;
  --mdc-filled-button-disabled-container-color: rgba(30, 41, 59, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-disabled-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.08;
  --mat-filled-button-focus-state-layer-opacity: 0.24;
  --mat-filled-button-pressed-state-layer-opacity: 0.24;
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: white;
  --mdc-protected-button-disabled-container-color: rgba(30, 41, 59, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-disabled-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.08;
  --mat-protected-button-focus-state-layer-opacity: 0.24;
  --mat-protected-button-pressed-state-layer-opacity: 0.24;
  --mdc-outlined-button-disabled-outline-color: rgba(241, 245, 249, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mdc-outlined-button-label-text-color: white;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: white;
  --mat-outlined-button-disabled-state-layer-color: white;
  --mat-outlined-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.08;
  --mat-outlined-button-focus-state-layer-opacity: 0.24;
  --mat-outlined-button-pressed-state-layer-opacity: 0.24;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
  --mat-icon-button-state-layer-color: white;
  --mat-icon-button-disabled-state-layer-color: white;
  --mat-icon-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.08;
  --mat-icon-button-focus-state-layer-opacity: 0.24;
  --mat-icon-button-pressed-state-layer-opacity: 0.24;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-fab-container-color: #1e293b;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-disabled-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.08;
  --mat-fab-focus-state-layer-opacity: 0.24;
  --mat-fab-pressed-state-layer-opacity: 0.24;
  --mat-fab-disabled-state-container-color: rgba(15, 23, 42, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(30, 41, 59, 0.5);
  --mdc-fab-small-container-color: #1e293b;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-disabled-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.08;
  --mat-fab-small-focus-state-layer-opacity: 0.24;
  --mat-fab-small-pressed-state-layer-opacity: 0.24;
  --mat-fab-small-disabled-state-container-color: rgba(15, 23, 42, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(30, 41, 59, 0.5);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #d2d4d8;
  --mdc-snackbar-supporting-text-color: rgba(30, 41, 59, 0.87);
  --mat-snack-bar-button-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: #1e293b;
  --mat-table-header-headline-color: white;
  --mat-table-row-item-label-text-color: white;
  --mat-table-row-item-outline-color: rgba(241, 245, 249, 0.12);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: darkmagenta;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: darkmagenta;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #1e293b;
  --mat-badge-disabled-state-text-color: #475569;
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: white;
  --mat-bottom-sheet-container-background-color: #1e293b;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: #64748b;
  --mat-legacy-button-toggle-state-layer-color: #e2e8f0;
  --mat-legacy-button-toggle-selected-state-text-color: #94a3b8;
  --mat-legacy-button-toggle-selected-state-background-color: rgba(255, 255, 255, 0.05);
  --mat-legacy-button-toggle-disabled-state-text-color: #1e293b;
  --mat-legacy-button-toggle-disabled-state-background-color: #0f172a;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #1e293b;
  --mat-standard-button-toggle-text-color: white;
  --mat-standard-button-toggle-background-color: #1e293b;
  --mat-standard-button-toggle-state-layer-color: #e2e8f0;
  --mat-standard-button-toggle-selected-state-background-color: rgba(255, 255, 255, 0.05);
  --mat-standard-button-toggle-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-state-text-color: #1e293b;
  --mat-standard-button-toggle-disabled-state-background-color: #1e293b;
  --mat-standard-button-toggle-disabled-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #1e293b;
  --mat-standard-button-toggle-divider-color: #374152;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: darkmagenta;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(139, 0, 139, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(139, 0, 139, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(139, 0, 139, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: darkmagenta;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(139, 0, 139, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: #f1f5f9;
  --mat-datepicker-calendar-body-label-text-color: #94a3b8;
  --mat-datepicker-calendar-period-button-text-color: white;
  --mat-datepicker-calendar-period-button-icon-color: #f1f5f9;
  --mat-datepicker-calendar-navigation-button-icon-color: #f1f5f9;
  --mat-datepicker-calendar-header-divider-color: rgba(241, 245, 249, 0.12);
  --mat-datepicker-calendar-header-text-color: #94a3b8;
  --mat-datepicker-calendar-date-today-outline-color: #64748b;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(100, 116, 139, 0.8);
  --mat-datepicker-calendar-date-text-color: white;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: #475569;
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(241, 245, 249, 0.24);
  --mat-datepicker-range-input-separator-color: white;
  --mat-datepicker-range-input-disabled-state-separator-color: #475569;
  --mat-datepicker-range-input-disabled-state-text-color: #475569;
  --mat-datepicker-calendar-container-background-color: #1e293b;
  --mat-datepicker-calendar-container-text-color: white;
  --mat-divider-width: 1px;
  --mat-divider-color: rgba(241, 245, 249, 0.12);
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: #1e293b;
  --mat-expansion-container-text-color: white;
  --mat-expansion-actions-divider-color: rgba(241, 245, 249, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-expansion-header-focus-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-expansion-header-disabled-state-text-color: #1e293b;
  --mat-expansion-header-text-color: white;
  --mat-expansion-header-description-color: #94a3b8;
  --mat-expansion-header-indicator-color: #94a3b8;
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: rgba(241, 245, 249, 0.12);
  --mat-sidenav-container-background-color: #1e293b;
  --mat-sidenav-container-text-color: white;
  --mat-sidenav-content-background-color: #0f172a;
  --mat-sidenav-content-text-color: white;
  --mat-sidenav-scrim-color: rgba(225, 214, 196, 0.6);
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: darkmagenta;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: darkmagenta;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: darkmagenta;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: #1e293b;
  --mat-stepper-line-color: rgba(241, 245, 249, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-stepper-header-focus-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-stepper-header-label-text-color: #94a3b8;
  --mat-stepper-header-optional-label-text-color: #94a3b8;
  --mat-stepper-header-selected-state-label-text-color: white;
  --mat-stepper-header-error-state-label-text-color: #dc2626;
  --mat-stepper-header-icon-background-color: #94a3b8;
  --mat-stepper-header-error-state-icon-foreground-color: #dc2626;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #94a3b8;
  --mat-toolbar-container-background-color: #0f172a;
  --mat-toolbar-container-text-color: white;
  --mat-tree-container-background-color: #1e293b;
  --mat-tree-node-text-color: white;
}
.theme-maroon .dark .mat-accent,
.theme-maroon.dark .mat-accent {
  --mat-option-selected-state-label-text-color: #1e293b;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.theme-maroon .dark .mat-warn,
.theme-maroon.dark .mat-warn {
  --mat-option-selected-state-label-text-color: #dc2626;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.theme-maroon .dark .mat-primary,
.theme-maroon.dark .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: darkmagenta;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: darkmagenta;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-maroon .dark .mat-accent,
.theme-maroon.dark .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-maroon .dark .mat-warn,
.theme-maroon.dark .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #dc2626;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #dc2626;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-maroon .dark .mat-elevation-z0, .theme-maroon .dark .mat-mdc-elevation-specific.mat-elevation-z0,
.theme-maroon.dark .mat-elevation-z0,
.theme-maroon.dark .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-maroon .dark .mat-elevation-z1, .theme-maroon .dark .mat-mdc-elevation-specific.mat-elevation-z1,
.theme-maroon.dark .mat-elevation-z1,
.theme-maroon.dark .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-maroon .dark .mat-elevation-z2, .theme-maroon .dark .mat-mdc-elevation-specific.mat-elevation-z2,
.theme-maroon.dark .mat-elevation-z2,
.theme-maroon.dark .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-maroon .dark .mat-elevation-z3, .theme-maroon .dark .mat-mdc-elevation-specific.mat-elevation-z3,
.theme-maroon.dark .mat-elevation-z3,
.theme-maroon.dark .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-maroon .dark .mat-elevation-z4, .theme-maroon .dark .mat-mdc-elevation-specific.mat-elevation-z4,
.theme-maroon.dark .mat-elevation-z4,
.theme-maroon.dark .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-maroon .dark .mat-elevation-z5, .theme-maroon .dark .mat-mdc-elevation-specific.mat-elevation-z5,
.theme-maroon.dark .mat-elevation-z5,
.theme-maroon.dark .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-maroon .dark .mat-elevation-z6, .theme-maroon .dark .mat-mdc-elevation-specific.mat-elevation-z6,
.theme-maroon.dark .mat-elevation-z6,
.theme-maroon.dark .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-maroon .dark .mat-elevation-z7, .theme-maroon .dark .mat-mdc-elevation-specific.mat-elevation-z7,
.theme-maroon.dark .mat-elevation-z7,
.theme-maroon.dark .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-maroon .dark .mat-elevation-z8, .theme-maroon .dark .mat-mdc-elevation-specific.mat-elevation-z8,
.theme-maroon.dark .mat-elevation-z8,
.theme-maroon.dark .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-maroon .dark .mat-elevation-z9, .theme-maroon .dark .mat-mdc-elevation-specific.mat-elevation-z9,
.theme-maroon.dark .mat-elevation-z9,
.theme-maroon.dark .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-maroon .dark .mat-elevation-z10, .theme-maroon .dark .mat-mdc-elevation-specific.mat-elevation-z10,
.theme-maroon.dark .mat-elevation-z10,
.theme-maroon.dark .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-maroon .dark .mat-elevation-z11, .theme-maroon .dark .mat-mdc-elevation-specific.mat-elevation-z11,
.theme-maroon.dark .mat-elevation-z11,
.theme-maroon.dark .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-maroon .dark .mat-elevation-z12, .theme-maroon .dark .mat-mdc-elevation-specific.mat-elevation-z12,
.theme-maroon.dark .mat-elevation-z12,
.theme-maroon.dark .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-maroon .dark .mat-elevation-z13, .theme-maroon .dark .mat-mdc-elevation-specific.mat-elevation-z13,
.theme-maroon.dark .mat-elevation-z13,
.theme-maroon.dark .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-maroon .dark .mat-elevation-z14, .theme-maroon .dark .mat-mdc-elevation-specific.mat-elevation-z14,
.theme-maroon.dark .mat-elevation-z14,
.theme-maroon.dark .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-maroon .dark .mat-elevation-z15, .theme-maroon .dark .mat-mdc-elevation-specific.mat-elevation-z15,
.theme-maroon.dark .mat-elevation-z15,
.theme-maroon.dark .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-maroon .dark .mat-elevation-z16, .theme-maroon .dark .mat-mdc-elevation-specific.mat-elevation-z16,
.theme-maroon.dark .mat-elevation-z16,
.theme-maroon.dark .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-maroon .dark .mat-elevation-z17, .theme-maroon .dark .mat-mdc-elevation-specific.mat-elevation-z17,
.theme-maroon.dark .mat-elevation-z17,
.theme-maroon.dark .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-maroon .dark .mat-elevation-z18, .theme-maroon .dark .mat-mdc-elevation-specific.mat-elevation-z18,
.theme-maroon.dark .mat-elevation-z18,
.theme-maroon.dark .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-maroon .dark .mat-elevation-z19, .theme-maroon .dark .mat-mdc-elevation-specific.mat-elevation-z19,
.theme-maroon.dark .mat-elevation-z19,
.theme-maroon.dark .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-maroon .dark .mat-elevation-z20, .theme-maroon .dark .mat-mdc-elevation-specific.mat-elevation-z20,
.theme-maroon.dark .mat-elevation-z20,
.theme-maroon.dark .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-maroon .dark .mat-elevation-z21, .theme-maroon .dark .mat-mdc-elevation-specific.mat-elevation-z21,
.theme-maroon.dark .mat-elevation-z21,
.theme-maroon.dark .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-maroon .dark .mat-elevation-z22, .theme-maroon .dark .mat-mdc-elevation-specific.mat-elevation-z22,
.theme-maroon.dark .mat-elevation-z22,
.theme-maroon.dark .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-maroon .dark .mat-elevation-z23, .theme-maroon .dark .mat-mdc-elevation-specific.mat-elevation-z23,
.theme-maroon.dark .mat-elevation-z23,
.theme-maroon.dark .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-maroon .dark .mat-elevation-z24, .theme-maroon .dark .mat-mdc-elevation-specific.mat-elevation-z24,
.theme-maroon.dark .mat-elevation-z24,
.theme-maroon.dark .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-maroon .dark .mat-mdc-progress-bar,
.theme-maroon.dark .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: darkmagenta;
  --mdc-linear-progress-track-color: rgba(139, 0, 139, 0.25);
}
.theme-maroon .dark .mat-mdc-progress-bar.mat-accent,
.theme-maroon.dark .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1e293b;
  --mdc-linear-progress-track-color: rgba(30, 41, 59, 0.25);
}
.theme-maroon .dark .mat-mdc-progress-bar.mat-warn,
.theme-maroon.dark .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc2626;
  --mdc-linear-progress-track-color: rgba(220, 38, 38, 0.25);
}
.theme-maroon .dark .mat-mdc-form-field.mat-accent,
.theme-maroon.dark .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #1e293b;
  --mdc-filled-text-field-focus-active-indicator-color: #1e293b;
  --mdc-filled-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mdc-outlined-text-field-caret-color: #1e293b;
  --mdc-outlined-text-field-focus-outline-color: #1e293b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(30, 41, 59, 0.87);
}
.theme-maroon .dark .mat-mdc-form-field.mat-warn,
.theme-maroon.dark .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #dc2626;
  --mdc-filled-text-field-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mdc-outlined-text-field-caret-color: #dc2626;
  --mdc-outlined-text-field-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-maroon .dark .mat-mdc-form-field.mat-accent,
.theme-maroon.dark .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: #1e293b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(71, 85, 105, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(30, 41, 59, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-maroon .dark .mat-mdc-form-field.mat-warn,
.theme-maroon.dark .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: #1e293b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(71, 85, 105, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-maroon .dark .mat-mdc-standard-chip,
.theme-maroon.dark .mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.theme-maroon .dark .mat-mdc-standard-chip,
.theme-maroon.dark .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-elevated-container-color: #394353;
  --mdc-chip-elevated-selected-container-color: #394353;
  --mdc-chip-elevated-disabled-container-color: #394353;
  --mdc-chip-flat-disabled-selected-container-color: #394353;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-selected-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
  --mat-chip-selected-disabled-trailing-icon-color: #fafafa;
  --mat-chip-selected-trailing-icon-color: #fafafa;
}
.theme-maroon .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .theme-maroon .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.theme-maroon.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.theme-maroon.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: darkmagenta;
  --mdc-chip-elevated-selected-container-color: darkmagenta;
  --mdc-chip-elevated-disabled-container-color: darkmagenta;
  --mdc-chip-flat-disabled-selected-container-color: darkmagenta;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-maroon .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .theme-maroon .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.theme-maroon.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.theme-maroon.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #1e293b;
  --mdc-chip-elevated-selected-container-color: #1e293b;
  --mdc-chip-elevated-disabled-container-color: #1e293b;
  --mdc-chip-flat-disabled-selected-container-color: #1e293b;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-maroon .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .theme-maroon .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.theme-maroon.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.theme-maroon.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-selected-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-flat-disabled-selected-container-color: #dc2626;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-maroon .dark .mat-mdc-slide-toggle,
.theme-maroon.dark .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: white;
}
.theme-maroon .dark .mat-mdc-slide-toggle.mat-accent,
.theme-maroon.dark .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #cbd5e1;
  --mdc-switch-selected-handle-color: #cbd5e1;
  --mdc-switch-selected-hover-state-layer-color: #cbd5e1;
  --mdc-switch-selected-pressed-state-layer-color: #cbd5e1;
  --mdc-switch-selected-focus-handle-color: #e2e8f0;
  --mdc-switch-selected-hover-handle-color: #e2e8f0;
  --mdc-switch-selected-pressed-handle-color: #e2e8f0;
  --mdc-switch-selected-focus-track-color: #475569;
  --mdc-switch-selected-hover-track-color: #475569;
  --mdc-switch-selected-pressed-track-color: #475569;
  --mdc-switch-selected-track-color: #475569;
}
.theme-maroon .dark .mat-mdc-slide-toggle.mat-warn,
.theme-maroon.dark .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #fca5a5;
  --mdc-switch-selected-handle-color: #fca5a5;
  --mdc-switch-selected-hover-state-layer-color: #fca5a5;
  --mdc-switch-selected-pressed-state-layer-color: #fca5a5;
  --mdc-switch-selected-focus-handle-color: #fecaca;
  --mdc-switch-selected-hover-handle-color: #fecaca;
  --mdc-switch-selected-pressed-handle-color: #fecaca;
  --mdc-switch-selected-focus-track-color: #dc2626;
  --mdc-switch-selected-hover-track-color: #dc2626;
  --mdc-switch-selected-pressed-track-color: #dc2626;
  --mdc-switch-selected-track-color: #dc2626;
}
.theme-maroon .dark .mat-mdc-radio-button,
.theme-maroon.dark .mat-mdc-radio-button {
  --mdc-form-field-label-text-color: white;
}
.theme-maroon .dark .mat-mdc-radio-button.mat-primary,
.theme-maroon.dark .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: darkmagenta;
  --mdc-radio-selected-hover-icon-color: darkmagenta;
  --mdc-radio-selected-icon-color: darkmagenta;
  --mdc-radio-selected-pressed-icon-color: darkmagenta;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: darkmagenta;
  --mat-radio-disabled-label-color: #475569;
}
.theme-maroon .dark .mat-mdc-radio-button.mat-accent,
.theme-maroon.dark .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #1e293b;
  --mat-radio-disabled-label-color: #475569;
}
.theme-maroon .dark .mat-mdc-radio-button.mat-warn,
.theme-maroon.dark .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #dc2626;
  --mat-radio-disabled-label-color: #475569;
}
.theme-maroon .dark .mat-accent,
.theme-maroon.dark .mat-accent {
  --mat-slider-ripple-color: #1e293b;
  --mat-slider-hover-state-layer-color: rgba(30, 41, 59, 0.05);
  --mat-slider-focus-state-layer-color: rgba(30, 41, 59, 0.2);
  --mdc-slider-handle-color: #1e293b;
  --mdc-slider-focus-handle-color: #1e293b;
  --mdc-slider-hover-handle-color: #1e293b;
  --mdc-slider-active-track-color: #1e293b;
  --mdc-slider-inactive-track-color: #1e293b;
  --mdc-slider-with-tick-marks-inactive-container-color: #1e293b;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-maroon .dark .mat-warn,
.theme-maroon.dark .mat-warn {
  --mat-slider-ripple-color: #dc2626;
  --mat-slider-hover-state-layer-color: rgba(220, 38, 38, 0.05);
  --mat-slider-focus-state-layer-color: rgba(220, 38, 38, 0.2);
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-maroon .dark .mdc-list-item__start,
.theme-maroon .dark .mdc-list-item__end,
.theme-maroon.dark .mdc-list-item__start,
.theme-maroon.dark .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: darkmagenta;
  --mdc-radio-selected-hover-icon-color: darkmagenta;
  --mdc-radio-selected-icon-color: darkmagenta;
  --mdc-radio-selected-pressed-icon-color: darkmagenta;
}
.theme-maroon .dark .mat-accent .mdc-list-item__start,
.theme-maroon .dark .mat-accent .mdc-list-item__end,
.theme-maroon.dark .mat-accent .mdc-list-item__start,
.theme-maroon.dark .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
}
.theme-maroon .dark .mat-warn .mdc-list-item__start,
.theme-maroon .dark .mat-warn .mdc-list-item__end,
.theme-maroon.dark .mat-warn .mdc-list-item__start,
.theme-maroon.dark .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
}
.theme-maroon .dark .mat-mdc-list-option,
.theme-maroon.dark .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: darkmagenta;
  --mdc-checkbox-selected-hover-icon-color: darkmagenta;
  --mdc-checkbox-selected-icon-color: darkmagenta;
  --mdc-checkbox-selected-pressed-icon-color: darkmagenta;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: darkmagenta;
  --mdc-checkbox-selected-hover-state-layer-color: darkmagenta;
  --mdc-checkbox-selected-pressed-state-layer-color: darkmagenta;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-maroon .dark .mat-mdc-list-option.mat-accent,
.theme-maroon.dark .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-maroon .dark .mat-mdc-list-option.mat-warn,
.theme-maroon.dark .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-maroon .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-maroon .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-maroon.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-maroon.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: darkmagenta;
}
.theme-maroon .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-maroon .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-maroon.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-maroon.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: darkmagenta;
}
.theme-maroon .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-maroon .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-maroon .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.theme-maroon.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-maroon.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-maroon.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.theme-maroon .dark .mat-mdc-tab-group, .theme-maroon .dark .mat-mdc-tab-nav-bar,
.theme-maroon.dark .mat-mdc-tab-group,
.theme-maroon.dark .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: darkmagenta;
  --mat-tab-header-disabled-ripple-color: #475569;
  --mat-tab-header-pagination-icon-color: #f1f5f9;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: darkmagenta;
  --mat-tab-header-active-ripple-color: darkmagenta;
  --mat-tab-header-inactive-ripple-color: darkmagenta;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: darkmagenta;
  --mat-tab-header-active-hover-label-text-color: darkmagenta;
  --mat-tab-header-active-focus-indicator-color: darkmagenta;
  --mat-tab-header-active-hover-indicator-color: darkmagenta;
}
.theme-maroon .dark .mat-mdc-tab-group.mat-accent, .theme-maroon .dark .mat-mdc-tab-nav-bar.mat-accent,
.theme-maroon.dark .mat-mdc-tab-group.mat-accent,
.theme-maroon.dark .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #1e293b;
  --mat-tab-header-disabled-ripple-color: #475569;
  --mat-tab-header-pagination-icon-color: #f1f5f9;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #1e293b;
  --mat-tab-header-active-ripple-color: #1e293b;
  --mat-tab-header-inactive-ripple-color: #1e293b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #1e293b;
  --mat-tab-header-active-hover-label-text-color: #1e293b;
  --mat-tab-header-active-focus-indicator-color: #1e293b;
  --mat-tab-header-active-hover-indicator-color: #1e293b;
}
.theme-maroon .dark .mat-mdc-tab-group.mat-warn, .theme-maroon .dark .mat-mdc-tab-nav-bar.mat-warn,
.theme-maroon.dark .mat-mdc-tab-group.mat-warn,
.theme-maroon.dark .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #dc2626;
  --mat-tab-header-disabled-ripple-color: #475569;
  --mat-tab-header-pagination-icon-color: #f1f5f9;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #dc2626;
  --mat-tab-header-active-ripple-color: #dc2626;
  --mat-tab-header-inactive-ripple-color: #dc2626;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #dc2626;
  --mat-tab-header-active-hover-label-text-color: #dc2626;
  --mat-tab-header-active-focus-indicator-color: #dc2626;
  --mat-tab-header-active-hover-indicator-color: #dc2626;
}
.theme-maroon .dark .mat-mdc-tab-group.mat-background-primary, .theme-maroon .dark .mat-mdc-tab-nav-bar.mat-background-primary,
.theme-maroon.dark .mat-mdc-tab-group.mat-background-primary,
.theme-maroon.dark .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: darkmagenta;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-maroon .dark .mat-mdc-tab-group.mat-background-accent, .theme-maroon .dark .mat-mdc-tab-nav-bar.mat-background-accent,
.theme-maroon.dark .mat-mdc-tab-group.mat-background-accent,
.theme-maroon.dark .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #1e293b;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-maroon .dark .mat-mdc-tab-group.mat-background-warn, .theme-maroon .dark .mat-mdc-tab-nav-bar.mat-background-warn,
.theme-maroon.dark .mat-mdc-tab-group.mat-background-warn,
.theme-maroon.dark .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #dc2626;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-maroon .dark .mat-mdc-checkbox,
.theme-maroon.dark .mat-mdc-checkbox {
  --mdc-form-field-label-text-color: white;
}
.theme-maroon .dark .mat-mdc-checkbox.mat-primary,
.theme-maroon.dark .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: darkmagenta;
  --mdc-checkbox-selected-hover-icon-color: darkmagenta;
  --mdc-checkbox-selected-icon-color: darkmagenta;
  --mdc-checkbox-selected-pressed-icon-color: darkmagenta;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: darkmagenta;
  --mdc-checkbox-selected-hover-state-layer-color: darkmagenta;
  --mdc-checkbox-selected-pressed-state-layer-color: darkmagenta;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-maroon .dark .mat-mdc-checkbox.mat-warn,
.theme-maroon.dark .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-maroon .dark .mat-mdc-button.mat-primary,
.theme-maroon.dark .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: darkmagenta;
  --mat-text-button-state-layer-color: darkmagenta;
  --mat-text-button-ripple-color: rgba(139, 0, 139, 0.1);
}
.theme-maroon .dark .mat-mdc-button.mat-accent,
.theme-maroon.dark .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1e293b;
  --mat-text-button-state-layer-color: #1e293b;
  --mat-text-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-maroon .dark .mat-mdc-button.mat-warn,
.theme-maroon.dark .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc2626;
  --mat-text-button-state-layer-color: #dc2626;
  --mat-text-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-maroon .dark .mat-mdc-unelevated-button.mat-primary,
.theme-maroon.dark .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: darkmagenta;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-maroon .dark .mat-mdc-unelevated-button.mat-accent,
.theme-maroon.dark .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-maroon .dark .mat-mdc-unelevated-button.mat-warn,
.theme-maroon.dark .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-maroon .dark .mat-mdc-raised-button.mat-primary,
.theme-maroon.dark .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: darkmagenta;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-maroon .dark .mat-mdc-raised-button.mat-accent,
.theme-maroon.dark .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-maroon .dark .mat-mdc-raised-button.mat-warn,
.theme-maroon.dark .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-maroon .dark .mat-mdc-outlined-button.mat-primary,
.theme-maroon.dark .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: darkmagenta;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: darkmagenta;
  --mat-outlined-button-ripple-color: rgba(139, 0, 139, 0.1);
}
.theme-maroon .dark .mat-mdc-outlined-button.mat-accent,
.theme-maroon.dark .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1e293b;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: #1e293b;
  --mat-outlined-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-maroon .dark .mat-mdc-outlined-button.mat-warn,
.theme-maroon.dark .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc2626;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: #dc2626;
  --mat-outlined-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-maroon .dark .mat-mdc-icon-button.mat-primary,
.theme-maroon.dark .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: darkmagenta;
  --mat-icon-button-state-layer-color: darkmagenta;
  --mat-icon-button-ripple-color: rgba(139, 0, 139, 0.1);
}
.theme-maroon .dark .mat-mdc-icon-button.mat-accent,
.theme-maroon.dark .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1e293b;
  --mat-icon-button-state-layer-color: #1e293b;
  --mat-icon-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-maroon .dark .mat-mdc-icon-button.mat-warn,
.theme-maroon.dark .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc2626;
  --mat-icon-button-state-layer-color: #dc2626;
  --mat-icon-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-maroon .dark .mat-mdc-fab.mat-primary,
.theme-maroon.dark .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: darkmagenta;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-maroon .dark .mat-mdc-fab.mat-accent,
.theme-maroon.dark .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #1e293b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-maroon .dark .mat-mdc-fab.mat-warn,
.theme-maroon.dark .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #dc2626;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-maroon .dark .mat-mdc-mini-fab.mat-primary,
.theme-maroon.dark .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: darkmagenta;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-maroon .dark .mat-mdc-mini-fab.mat-accent,
.theme-maroon.dark .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #1e293b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-maroon .dark .mat-mdc-mini-fab.mat-warn,
.theme-maroon.dark .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #dc2626;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-maroon .dark .mat-accent,
.theme-maroon.dark .mat-accent {
  --mdc-circular-progress-active-indicator-color: #1e293b;
}
.theme-maroon .dark .mat-warn,
.theme-maroon.dark .mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}
.theme-maroon .dark .mat-badge-accent,
.theme-maroon.dark .mat-badge-accent {
  --mat-badge-background-color: #1e293b;
  --mat-badge-text-color: white;
}
.theme-maroon .dark .mat-badge-warn,
.theme-maroon.dark .mat-badge-warn {
  --mat-badge-background-color: #dc2626;
  --mat-badge-text-color: white;
}
.theme-maroon .dark .mat-datepicker-content.mat-accent,
.theme-maroon.dark .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #1e293b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(30, 41, 59, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(30, 41, 59, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-maroon .dark .mat-datepicker-content.mat-warn,
.theme-maroon.dark .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #dc2626;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(220, 38, 38, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(220, 38, 38, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-maroon .dark .mat-datepicker-toggle-active.mat-accent,
.theme-maroon.dark .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #1e293b;
}
.theme-maroon .dark .mat-datepicker-toggle-active.mat-warn,
.theme-maroon.dark .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #dc2626;
}
.theme-maroon .dark .mat-icon.mat-primary,
.theme-maroon.dark .mat-icon.mat-primary {
  --mat-icon-color: darkmagenta;
}
.theme-maroon .dark .mat-icon.mat-accent,
.theme-maroon.dark .mat-icon.mat-accent {
  --mat-icon-color: #1e293b;
}
.theme-maroon .dark .mat-icon.mat-warn,
.theme-maroon.dark .mat-icon.mat-warn {
  --mat-icon-color: #dc2626;
}
.theme-maroon .dark .mat-step-header.mat-accent,
.theme-maroon.dark .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #1e293b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #1e293b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #1e293b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-maroon .dark .mat-step-header.mat-warn,
.theme-maroon.dark .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #dc2626;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #dc2626;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #dc2626;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-maroon .dark .mat-toolbar.mat-primary,
.theme-maroon.dark .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: darkmagenta;
  --mat-toolbar-container-text-color: white;
}
.theme-maroon .dark .mat-toolbar.mat-accent,
.theme-maroon.dark .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #1e293b;
  --mat-toolbar-container-text-color: white;
}
.theme-maroon .dark .mat-toolbar.mat-warn,
.theme-maroon.dark .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #dc2626;
  --mat-toolbar-container-text-color: white;
}

/* Generate the palettes */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define a light & dark Angular Material theme with the generated palettes */
/* Merge the custom base colors with the generated themes */
/* Generate and encapsulate Angular Material themes */
.theme-brown .light,
.theme-brown.light {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-option-selected-state-label-text-color: brown;
  --mat-option-label-text-color: #1e293b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-optgroup-label-text-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
  --mat-app-background-color: #f1f5f9;
  --mat-app-text-color: #1e293b;
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: #64748b;
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #1e293b;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: brown;
  --mdc-filled-text-field-focus-active-indicator-color: brown;
  --mdc-filled-text-field-focus-label-text-color: rgba(165, 42, 42, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(30, 41, 59, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(148, 163, 184, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(30, 41, 59, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #dc2626;
  --mdc-filled-text-field-error-focus-label-text-color: #dc2626;
  --mdc-filled-text-field-error-label-text-color: #dc2626;
  --mdc-filled-text-field-error-caret-color: #dc2626;
  --mdc-filled-text-field-active-indicator-color: rgba(226, 232, 240, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(226, 232, 240, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(226, 232, 240, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-hover-active-indicator-color: #dc2626;
  --mdc-outlined-text-field-caret-color: brown;
  --mdc-outlined-text-field-focus-outline-color: brown;
  --mdc-outlined-text-field-focus-label-text-color: rgba(165, 42, 42, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(30, 41, 59, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(148, 163, 184, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(30, 41, 59, 0.6);
  --mdc-outlined-text-field-error-caret-color: #dc2626;
  --mdc-outlined-text-field-error-focus-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-hover-label-text-color: #dc2626;
  --mdc-outlined-text-field-outline-color: rgba(226, 232, 240, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(226, 232, 240, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(226, 232, 240, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-error-hover-outline-color: #dc2626;
  --mdc-outlined-text-field-error-outline-color: #dc2626;
  --mat-form-field-focus-select-arrow-color: rgba(165, 42, 42, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(100, 116, 139, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #dc2626;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(100, 116, 139, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(100, 116, 139, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(30, 41, 59, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(148, 163, 184, 0.38);
  --mat-select-placeholder-text-color: rgba(30, 41, 59, 0.6);
  --mat-select-enabled-arrow-color: rgba(226, 232, 240, 0.54);
  --mat-select-disabled-arrow-color: rgba(30, 41, 59, 0.38);
  --mat-select-focused-arrow-color: rgba(165, 42, 42, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: white;
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(30, 41, 59, 0.87);
  --mdc-dialog-supporting-text-color: rgba(30, 41, 59, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
  --mdc-switch-selected-focus-state-layer-color: #962626;
  --mdc-switch-selected-handle-color: #962626;
  --mdc-switch-selected-hover-state-layer-color: #962626;
  --mdc-switch-selected-pressed-state-layer-color: #962626;
  --mdc-switch-selected-focus-handle-color: #591717;
  --mdc-switch-selected-hover-handle-color: #591717;
  --mdc-switch-selected-pressed-handle-color: #591717;
  --mdc-switch-selected-focus-track-color: #cda3a3;
  --mdc-switch-selected-hover-track-color: #cda3a3;
  --mdc-switch-selected-pressed-track-color: #cda3a3;
  --mdc-switch-selected-track-color: #cda3a3;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-disabled-label-text-color: #94a3b8;
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-color: brown;
  --mdc-slider-focus-handle-color: brown;
  --mdc-slider-hover-handle-color: brown;
  --mdc-slider-active-track-color: brown;
  --mdc-slider-inactive-track-color: brown;
  --mdc-slider-with-tick-marks-inactive-container-color: brown;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: brown;
  --mat-slider-hover-state-layer-color: rgba(165, 42, 42, 0.05);
  --mat-slider-focus-state-layer-color: rgba(165, 42, 42, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-item-label-text-color: #1e293b;
  --mat-menu-item-icon-color: #1e293b;
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: #e2e8f0;
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 0;
  --mdc-list-list-item-label-text-color: #1e293b;
  --mdc-list-list-item-supporting-text-color: #64748b;
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: #94a3b8;
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: #1e293b;
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: #1e293b;
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: #94a3b8;
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: #1e293b;
  --mdc-text-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: #1e293b;
  --mdc-filled-button-disabled-container-color: rgba(148, 163, 184, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: #1e293b;
  --mdc-protected-button-disabled-container-color: rgba(148, 163, 184, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: #e2e8f0;
  --mdc-outlined-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-outlined-button-label-text-color: #1e293b;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-fab-container-color: white;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(148, 163, 184, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(148, 163, 184, 0.38);
  --mdc-fab-small-container-color: white;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(148, 163, 184, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(148, 163, 184, 0.38);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #1e293b;
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: white;
  --mat-table-header-headline-color: #1e293b;
  --mat-table-row-item-label-text-color: #1e293b;
  --mat-table-row-item-outline-color: #e2e8f0;
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: brown;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: brown;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #94a3b8;
  --mat-badge-disabled-state-text-color: #94a3b8;
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: #1e293b;
  --mat-bottom-sheet-container-background-color: white;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: #94a3b8;
  --mat-legacy-button-toggle-state-layer-color: #64748b;
  --mat-legacy-button-toggle-selected-state-text-color: #64748b;
  --mat-legacy-button-toggle-selected-state-background-color: #e2e8f0;
  --mat-legacy-button-toggle-disabled-state-text-color: #94a3b8;
  --mat-legacy-button-toggle-disabled-state-background-color: #cbd5e1;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #e2e8f0;
  --mat-standard-button-toggle-text-color: #1e293b;
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: #64748b;
  --mat-standard-button-toggle-selected-state-background-color: #e2e8f0;
  --mat-standard-button-toggle-selected-state-text-color: #1e293b;
  --mat-standard-button-toggle-disabled-state-text-color: #94a3b8;
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: #1e293b;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #e2e8f0;
  --mat-standard-button-toggle-divider-color: #e2e8f0;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: brown;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(165, 42, 42, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(165, 42, 42, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(165, 42, 42, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: brown;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(165, 42, 42, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: #64748b;
  --mat-datepicker-calendar-body-label-text-color: #64748b;
  --mat-datepicker-calendar-period-button-text-color: #1e293b;
  --mat-datepicker-calendar-period-button-icon-color: #64748b;
  --mat-datepicker-calendar-navigation-button-icon-color: #64748b;
  --mat-datepicker-calendar-header-divider-color: #e2e8f0;
  --mat-datepicker-calendar-header-text-color: #64748b;
  --mat-datepicker-calendar-date-today-outline-color: #94a3b8;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(148, 163, 184, 0.8);
  --mat-datepicker-calendar-date-text-color: #1e293b;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: #94a3b8;
  --mat-datepicker-calendar-date-preview-state-outline-color: #e2e8f0;
  --mat-datepicker-range-input-separator-color: #1e293b;
  --mat-datepicker-range-input-disabled-state-separator-color: #94a3b8;
  --mat-datepicker-range-input-disabled-state-text-color: #94a3b8;
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: #1e293b;
  --mat-divider-width: 1px;
  --mat-divider-color: #e2e8f0;
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: #1e293b;
  --mat-expansion-actions-divider-color: #e2e8f0;
  --mat-expansion-header-hover-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-expansion-header-focus-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-expansion-header-disabled-state-text-color: #94a3b8;
  --mat-expansion-header-text-color: #1e293b;
  --mat-expansion-header-description-color: #64748b;
  --mat-expansion-header-indicator-color: #64748b;
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: #e2e8f0;
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: #1e293b;
  --mat-sidenav-content-background-color: #f1f5f9;
  --mat-sidenav-content-text-color: #1e293b;
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: brown;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: brown;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: brown;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: #e2e8f0;
  --mat-stepper-header-hover-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-stepper-header-focus-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-stepper-header-label-text-color: #64748b;
  --mat-stepper-header-optional-label-text-color: #64748b;
  --mat-stepper-header-selected-state-label-text-color: #1e293b;
  --mat-stepper-header-error-state-label-text-color: #dc2626;
  --mat-stepper-header-icon-background-color: #64748b;
  --mat-stepper-header-error-state-icon-foreground-color: #dc2626;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #64748b;
  --mat-toolbar-container-background-color: white;
  --mat-toolbar-container-text-color: #1e293b;
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: #1e293b;
}
.theme-brown .light .mat-accent,
.theme-brown.light .mat-accent {
  --mat-option-selected-state-label-text-color: #1e293b;
  --mat-option-label-text-color: #1e293b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.theme-brown .light .mat-warn,
.theme-brown.light .mat-warn {
  --mat-option-selected-state-label-text-color: #dc2626;
  --mat-option-label-text-color: #1e293b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.theme-brown .light .mat-primary,
.theme-brown.light .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: brown;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: brown;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-brown .light .mat-accent,
.theme-brown.light .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-brown .light .mat-warn,
.theme-brown.light .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #dc2626;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #dc2626;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-brown .light .mat-elevation-z0, .theme-brown .light .mat-mdc-elevation-specific.mat-elevation-z0,
.theme-brown.light .mat-elevation-z0,
.theme-brown.light .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-brown .light .mat-elevation-z1, .theme-brown .light .mat-mdc-elevation-specific.mat-elevation-z1,
.theme-brown.light .mat-elevation-z1,
.theme-brown.light .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-brown .light .mat-elevation-z2, .theme-brown .light .mat-mdc-elevation-specific.mat-elevation-z2,
.theme-brown.light .mat-elevation-z2,
.theme-brown.light .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-brown .light .mat-elevation-z3, .theme-brown .light .mat-mdc-elevation-specific.mat-elevation-z3,
.theme-brown.light .mat-elevation-z3,
.theme-brown.light .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-brown .light .mat-elevation-z4, .theme-brown .light .mat-mdc-elevation-specific.mat-elevation-z4,
.theme-brown.light .mat-elevation-z4,
.theme-brown.light .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-brown .light .mat-elevation-z5, .theme-brown .light .mat-mdc-elevation-specific.mat-elevation-z5,
.theme-brown.light .mat-elevation-z5,
.theme-brown.light .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-brown .light .mat-elevation-z6, .theme-brown .light .mat-mdc-elevation-specific.mat-elevation-z6,
.theme-brown.light .mat-elevation-z6,
.theme-brown.light .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-brown .light .mat-elevation-z7, .theme-brown .light .mat-mdc-elevation-specific.mat-elevation-z7,
.theme-brown.light .mat-elevation-z7,
.theme-brown.light .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-brown .light .mat-elevation-z8, .theme-brown .light .mat-mdc-elevation-specific.mat-elevation-z8,
.theme-brown.light .mat-elevation-z8,
.theme-brown.light .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-brown .light .mat-elevation-z9, .theme-brown .light .mat-mdc-elevation-specific.mat-elevation-z9,
.theme-brown.light .mat-elevation-z9,
.theme-brown.light .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-brown .light .mat-elevation-z10, .theme-brown .light .mat-mdc-elevation-specific.mat-elevation-z10,
.theme-brown.light .mat-elevation-z10,
.theme-brown.light .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-brown .light .mat-elevation-z11, .theme-brown .light .mat-mdc-elevation-specific.mat-elevation-z11,
.theme-brown.light .mat-elevation-z11,
.theme-brown.light .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-brown .light .mat-elevation-z12, .theme-brown .light .mat-mdc-elevation-specific.mat-elevation-z12,
.theme-brown.light .mat-elevation-z12,
.theme-brown.light .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-brown .light .mat-elevation-z13, .theme-brown .light .mat-mdc-elevation-specific.mat-elevation-z13,
.theme-brown.light .mat-elevation-z13,
.theme-brown.light .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-brown .light .mat-elevation-z14, .theme-brown .light .mat-mdc-elevation-specific.mat-elevation-z14,
.theme-brown.light .mat-elevation-z14,
.theme-brown.light .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-brown .light .mat-elevation-z15, .theme-brown .light .mat-mdc-elevation-specific.mat-elevation-z15,
.theme-brown.light .mat-elevation-z15,
.theme-brown.light .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-brown .light .mat-elevation-z16, .theme-brown .light .mat-mdc-elevation-specific.mat-elevation-z16,
.theme-brown.light .mat-elevation-z16,
.theme-brown.light .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-brown .light .mat-elevation-z17, .theme-brown .light .mat-mdc-elevation-specific.mat-elevation-z17,
.theme-brown.light .mat-elevation-z17,
.theme-brown.light .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-brown .light .mat-elevation-z18, .theme-brown .light .mat-mdc-elevation-specific.mat-elevation-z18,
.theme-brown.light .mat-elevation-z18,
.theme-brown.light .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-brown .light .mat-elevation-z19, .theme-brown .light .mat-mdc-elevation-specific.mat-elevation-z19,
.theme-brown.light .mat-elevation-z19,
.theme-brown.light .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-brown .light .mat-elevation-z20, .theme-brown .light .mat-mdc-elevation-specific.mat-elevation-z20,
.theme-brown.light .mat-elevation-z20,
.theme-brown.light .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-brown .light .mat-elevation-z21, .theme-brown .light .mat-mdc-elevation-specific.mat-elevation-z21,
.theme-brown.light .mat-elevation-z21,
.theme-brown.light .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-brown .light .mat-elevation-z22, .theme-brown .light .mat-mdc-elevation-specific.mat-elevation-z22,
.theme-brown.light .mat-elevation-z22,
.theme-brown.light .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-brown .light .mat-elevation-z23, .theme-brown .light .mat-mdc-elevation-specific.mat-elevation-z23,
.theme-brown.light .mat-elevation-z23,
.theme-brown.light .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-brown .light .mat-elevation-z24, .theme-brown .light .mat-mdc-elevation-specific.mat-elevation-z24,
.theme-brown.light .mat-elevation-z24,
.theme-brown.light .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-brown .light .mat-mdc-progress-bar,
.theme-brown.light .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: brown;
  --mdc-linear-progress-track-color: rgba(165, 42, 42, 0.25);
}
.theme-brown .light .mat-mdc-progress-bar.mat-accent,
.theme-brown.light .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1e293b;
  --mdc-linear-progress-track-color: rgba(30, 41, 59, 0.25);
}
.theme-brown .light .mat-mdc-progress-bar.mat-warn,
.theme-brown.light .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc2626;
  --mdc-linear-progress-track-color: rgba(220, 38, 38, 0.25);
}
.theme-brown .light .mat-mdc-form-field.mat-accent,
.theme-brown.light .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #1e293b;
  --mdc-filled-text-field-focus-active-indicator-color: #1e293b;
  --mdc-filled-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mdc-outlined-text-field-caret-color: #1e293b;
  --mdc-outlined-text-field-focus-outline-color: #1e293b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(30, 41, 59, 0.87);
}
.theme-brown .light .mat-mdc-form-field.mat-warn,
.theme-brown.light .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #dc2626;
  --mdc-filled-text-field-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mdc-outlined-text-field-caret-color: #dc2626;
  --mdc-outlined-text-field-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-brown .light .mat-mdc-form-field.mat-accent,
.theme-brown.light .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(30, 41, 59, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(148, 163, 184, 0.38);
  --mat-select-placeholder-text-color: rgba(30, 41, 59, 0.6);
  --mat-select-enabled-arrow-color: rgba(226, 232, 240, 0.54);
  --mat-select-disabled-arrow-color: rgba(30, 41, 59, 0.38);
  --mat-select-focused-arrow-color: rgba(30, 41, 59, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-brown .light .mat-mdc-form-field.mat-warn,
.theme-brown.light .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(30, 41, 59, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(148, 163, 184, 0.38);
  --mat-select-placeholder-text-color: rgba(30, 41, 59, 0.6);
  --mat-select-enabled-arrow-color: rgba(226, 232, 240, 0.54);
  --mat-select-disabled-arrow-color: rgba(30, 41, 59, 0.38);
  --mat-select-focused-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-brown .light .mat-mdc-standard-chip,
.theme-brown.light .mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.theme-brown .light .mat-mdc-standard-chip,
.theme-brown.light .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.theme-brown .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .theme-brown .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.theme-brown.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.theme-brown.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: brown;
  --mdc-chip-elevated-selected-container-color: brown;
  --mdc-chip-elevated-disabled-container-color: brown;
  --mdc-chip-flat-disabled-selected-container-color: brown;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-brown .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .theme-brown .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.theme-brown.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.theme-brown.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #1e293b;
  --mdc-chip-elevated-selected-container-color: #1e293b;
  --mdc-chip-elevated-disabled-container-color: #1e293b;
  --mdc-chip-flat-disabled-selected-container-color: #1e293b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-brown .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .theme-brown .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.theme-brown.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.theme-brown.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-selected-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-flat-disabled-selected-container-color: #dc2626;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-brown .light .mat-mdc-slide-toggle,
.theme-brown.light .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: #1e293b;
}
.theme-brown .light .mat-mdc-slide-toggle.mat-accent,
.theme-brown.light .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #475569;
  --mdc-switch-selected-handle-color: #475569;
  --mdc-switch-selected-hover-state-layer-color: #475569;
  --mdc-switch-selected-pressed-state-layer-color: #475569;
  --mdc-switch-selected-focus-handle-color: #0f172a;
  --mdc-switch-selected-hover-handle-color: #0f172a;
  --mdc-switch-selected-pressed-handle-color: #0f172a;
  --mdc-switch-selected-focus-track-color: #cbd5e1;
  --mdc-switch-selected-hover-track-color: #cbd5e1;
  --mdc-switch-selected-pressed-track-color: #cbd5e1;
  --mdc-switch-selected-track-color: #cbd5e1;
}
.theme-brown .light .mat-mdc-slide-toggle.mat-warn,
.theme-brown.light .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.theme-brown .light .mat-mdc-radio-button,
.theme-brown.light .mat-mdc-radio-button {
  --mdc-form-field-label-text-color: #1e293b;
}
.theme-brown .light .mat-mdc-radio-button.mat-primary,
.theme-brown.light .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: brown;
  --mdc-radio-selected-hover-icon-color: brown;
  --mdc-radio-selected-icon-color: brown;
  --mdc-radio-selected-pressed-icon-color: brown;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: brown;
  --mat-radio-disabled-label-color: #94a3b8;
}
.theme-brown .light .mat-mdc-radio-button.mat-accent,
.theme-brown.light .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #1e293b;
  --mat-radio-disabled-label-color: #94a3b8;
}
.theme-brown .light .mat-mdc-radio-button.mat-warn,
.theme-brown.light .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #dc2626;
  --mat-radio-disabled-label-color: #94a3b8;
}
.theme-brown .light .mat-accent,
.theme-brown.light .mat-accent {
  --mat-slider-ripple-color: #1e293b;
  --mat-slider-hover-state-layer-color: rgba(30, 41, 59, 0.05);
  --mat-slider-focus-state-layer-color: rgba(30, 41, 59, 0.2);
  --mdc-slider-handle-color: #1e293b;
  --mdc-slider-focus-handle-color: #1e293b;
  --mdc-slider-hover-handle-color: #1e293b;
  --mdc-slider-active-track-color: #1e293b;
  --mdc-slider-inactive-track-color: #1e293b;
  --mdc-slider-with-tick-marks-inactive-container-color: #1e293b;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-brown .light .mat-warn,
.theme-brown.light .mat-warn {
  --mat-slider-ripple-color: #dc2626;
  --mat-slider-hover-state-layer-color: rgba(220, 38, 38, 0.05);
  --mat-slider-focus-state-layer-color: rgba(220, 38, 38, 0.2);
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-brown .light .mdc-list-item__start,
.theme-brown .light .mdc-list-item__end,
.theme-brown.light .mdc-list-item__start,
.theme-brown.light .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: brown;
  --mdc-radio-selected-hover-icon-color: brown;
  --mdc-radio-selected-icon-color: brown;
  --mdc-radio-selected-pressed-icon-color: brown;
}
.theme-brown .light .mat-accent .mdc-list-item__start,
.theme-brown .light .mat-accent .mdc-list-item__end,
.theme-brown.light .mat-accent .mdc-list-item__start,
.theme-brown.light .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
}
.theme-brown .light .mat-warn .mdc-list-item__start,
.theme-brown .light .mat-warn .mdc-list-item__end,
.theme-brown.light .mat-warn .mdc-list-item__start,
.theme-brown.light .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
}
.theme-brown .light .mat-mdc-list-option,
.theme-brown.light .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: brown;
  --mdc-checkbox-selected-hover-icon-color: brown;
  --mdc-checkbox-selected-icon-color: brown;
  --mdc-checkbox-selected-pressed-icon-color: brown;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: brown;
  --mdc-checkbox-selected-hover-state-layer-color: brown;
  --mdc-checkbox-selected-pressed-state-layer-color: brown;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-brown .light .mat-mdc-list-option.mat-accent,
.theme-brown.light .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-brown .light .mat-mdc-list-option.mat-warn,
.theme-brown.light .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-brown .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-brown .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-brown.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-brown.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: brown;
}
.theme-brown .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brown .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brown.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brown.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: brown;
}
.theme-brown .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-brown .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-brown .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.theme-brown.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-brown.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-brown.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.theme-brown .light .mat-mdc-tab-group, .theme-brown .light .mat-mdc-tab-nav-bar,
.theme-brown.light .mat-mdc-tab-group,
.theme-brown.light .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: brown;
  --mat-tab-header-disabled-ripple-color: #94a3b8;
  --mat-tab-header-pagination-icon-color: #64748b;
  --mat-tab-header-inactive-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-label-text-color: brown;
  --mat-tab-header-active-ripple-color: brown;
  --mat-tab-header-inactive-ripple-color: brown;
  --mat-tab-header-inactive-focus-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-focus-label-text-color: brown;
  --mat-tab-header-active-hover-label-text-color: brown;
  --mat-tab-header-active-focus-indicator-color: brown;
  --mat-tab-header-active-hover-indicator-color: brown;
}
.theme-brown .light .mat-mdc-tab-group.mat-accent, .theme-brown .light .mat-mdc-tab-nav-bar.mat-accent,
.theme-brown.light .mat-mdc-tab-group.mat-accent,
.theme-brown.light .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #1e293b;
  --mat-tab-header-disabled-ripple-color: #94a3b8;
  --mat-tab-header-pagination-icon-color: #64748b;
  --mat-tab-header-inactive-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-label-text-color: #1e293b;
  --mat-tab-header-active-ripple-color: #1e293b;
  --mat-tab-header-inactive-ripple-color: #1e293b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-focus-label-text-color: #1e293b;
  --mat-tab-header-active-hover-label-text-color: #1e293b;
  --mat-tab-header-active-focus-indicator-color: #1e293b;
  --mat-tab-header-active-hover-indicator-color: #1e293b;
}
.theme-brown .light .mat-mdc-tab-group.mat-warn, .theme-brown .light .mat-mdc-tab-nav-bar.mat-warn,
.theme-brown.light .mat-mdc-tab-group.mat-warn,
.theme-brown.light .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #dc2626;
  --mat-tab-header-disabled-ripple-color: #94a3b8;
  --mat-tab-header-pagination-icon-color: #64748b;
  --mat-tab-header-inactive-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-label-text-color: #dc2626;
  --mat-tab-header-active-ripple-color: #dc2626;
  --mat-tab-header-inactive-ripple-color: #dc2626;
  --mat-tab-header-inactive-focus-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-focus-label-text-color: #dc2626;
  --mat-tab-header-active-hover-label-text-color: #dc2626;
  --mat-tab-header-active-focus-indicator-color: #dc2626;
  --mat-tab-header-active-hover-indicator-color: #dc2626;
}
.theme-brown .light .mat-mdc-tab-group.mat-background-primary, .theme-brown .light .mat-mdc-tab-nav-bar.mat-background-primary,
.theme-brown.light .mat-mdc-tab-group.mat-background-primary,
.theme-brown.light .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: brown;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-brown .light .mat-mdc-tab-group.mat-background-accent, .theme-brown .light .mat-mdc-tab-nav-bar.mat-background-accent,
.theme-brown.light .mat-mdc-tab-group.mat-background-accent,
.theme-brown.light .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #1e293b;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-brown .light .mat-mdc-tab-group.mat-background-warn, .theme-brown .light .mat-mdc-tab-nav-bar.mat-background-warn,
.theme-brown.light .mat-mdc-tab-group.mat-background-warn,
.theme-brown.light .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #dc2626;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-brown .light .mat-mdc-checkbox,
.theme-brown.light .mat-mdc-checkbox {
  --mdc-form-field-label-text-color: #1e293b;
}
.theme-brown .light .mat-mdc-checkbox.mat-primary,
.theme-brown.light .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: brown;
  --mdc-checkbox-selected-hover-icon-color: brown;
  --mdc-checkbox-selected-icon-color: brown;
  --mdc-checkbox-selected-pressed-icon-color: brown;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: brown;
  --mdc-checkbox-selected-hover-state-layer-color: brown;
  --mdc-checkbox-selected-pressed-state-layer-color: brown;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-brown .light .mat-mdc-checkbox.mat-warn,
.theme-brown.light .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-brown .light .mat-mdc-button.mat-primary,
.theme-brown.light .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: brown;
  --mat-text-button-state-layer-color: brown;
  --mat-text-button-ripple-color: rgba(165, 42, 42, 0.1);
}
.theme-brown .light .mat-mdc-button.mat-accent,
.theme-brown.light .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1e293b;
  --mat-text-button-state-layer-color: #1e293b;
  --mat-text-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-brown .light .mat-mdc-button.mat-warn,
.theme-brown.light .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc2626;
  --mat-text-button-state-layer-color: #dc2626;
  --mat-text-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-brown .light .mat-mdc-unelevated-button.mat-primary,
.theme-brown.light .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: brown;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brown .light .mat-mdc-unelevated-button.mat-accent,
.theme-brown.light .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brown .light .mat-mdc-unelevated-button.mat-warn,
.theme-brown.light .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brown .light .mat-mdc-raised-button.mat-primary,
.theme-brown.light .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: brown;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brown .light .mat-mdc-raised-button.mat-accent,
.theme-brown.light .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brown .light .mat-mdc-raised-button.mat-warn,
.theme-brown.light .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brown .light .mat-mdc-outlined-button.mat-primary,
.theme-brown.light .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: brown;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: brown;
  --mat-outlined-button-ripple-color: rgba(165, 42, 42, 0.1);
}
.theme-brown .light .mat-mdc-outlined-button.mat-accent,
.theme-brown.light .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1e293b;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: #1e293b;
  --mat-outlined-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-brown .light .mat-mdc-outlined-button.mat-warn,
.theme-brown.light .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc2626;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: #dc2626;
  --mat-outlined-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-brown .light .mat-mdc-icon-button.mat-primary,
.theme-brown.light .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: brown;
  --mat-icon-button-state-layer-color: brown;
  --mat-icon-button-ripple-color: rgba(165, 42, 42, 0.1);
}
.theme-brown .light .mat-mdc-icon-button.mat-accent,
.theme-brown.light .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1e293b;
  --mat-icon-button-state-layer-color: #1e293b;
  --mat-icon-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-brown .light .mat-mdc-icon-button.mat-warn,
.theme-brown.light .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc2626;
  --mat-icon-button-state-layer-color: #dc2626;
  --mat-icon-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-brown .light .mat-mdc-fab.mat-primary,
.theme-brown.light .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: brown;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brown .light .mat-mdc-fab.mat-accent,
.theme-brown.light .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #1e293b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brown .light .mat-mdc-fab.mat-warn,
.theme-brown.light .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #dc2626;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brown .light .mat-mdc-mini-fab.mat-primary,
.theme-brown.light .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: brown;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brown .light .mat-mdc-mini-fab.mat-accent,
.theme-brown.light .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #1e293b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brown .light .mat-mdc-mini-fab.mat-warn,
.theme-brown.light .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #dc2626;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brown .light .mat-accent,
.theme-brown.light .mat-accent {
  --mdc-circular-progress-active-indicator-color: #1e293b;
}
.theme-brown .light .mat-warn,
.theme-brown.light .mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}
.theme-brown .light .mat-badge-accent,
.theme-brown.light .mat-badge-accent {
  --mat-badge-background-color: #1e293b;
  --mat-badge-text-color: white;
}
.theme-brown .light .mat-badge-warn,
.theme-brown.light .mat-badge-warn {
  --mat-badge-background-color: #dc2626;
  --mat-badge-text-color: white;
}
.theme-brown .light .mat-datepicker-content.mat-accent,
.theme-brown.light .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #1e293b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(30, 41, 59, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(30, 41, 59, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-brown .light .mat-datepicker-content.mat-warn,
.theme-brown.light .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #dc2626;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(220, 38, 38, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(220, 38, 38, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-brown .light .mat-datepicker-toggle-active.mat-accent,
.theme-brown.light .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #1e293b;
}
.theme-brown .light .mat-datepicker-toggle-active.mat-warn,
.theme-brown.light .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #dc2626;
}
.theme-brown .light .mat-icon.mat-primary,
.theme-brown.light .mat-icon.mat-primary {
  --mat-icon-color: brown;
}
.theme-brown .light .mat-icon.mat-accent,
.theme-brown.light .mat-icon.mat-accent {
  --mat-icon-color: #1e293b;
}
.theme-brown .light .mat-icon.mat-warn,
.theme-brown.light .mat-icon.mat-warn {
  --mat-icon-color: #dc2626;
}
.theme-brown .light .mat-step-header.mat-accent,
.theme-brown.light .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #1e293b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #1e293b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #1e293b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-brown .light .mat-step-header.mat-warn,
.theme-brown.light .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #dc2626;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #dc2626;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #dc2626;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-brown .light .mat-toolbar.mat-primary,
.theme-brown.light .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: brown;
  --mat-toolbar-container-text-color: white;
}
.theme-brown .light .mat-toolbar.mat-accent,
.theme-brown.light .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #1e293b;
  --mat-toolbar-container-text-color: white;
}
.theme-brown .light .mat-toolbar.mat-warn,
.theme-brown.light .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #dc2626;
  --mat-toolbar-container-text-color: white;
}

.theme-brown .dark,
.theme-brown.dark {
  --mat-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-option-selected-state-label-text-color: brown;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-optgroup-label-text-color: white;
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
  --mat-app-background-color: #0f172a;
  --mat-app-text-color: white;
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: #1e293b;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: #1e293b;
  --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: #94a3b8;
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #64748b;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: brown;
  --mdc-filled-text-field-focus-active-indicator-color: brown;
  --mdc-filled-text-field-focus-label-text-color: rgba(165, 42, 42, 0.87);
  --mdc-filled-text-field-container-color: #273243;
  --mdc-filled-text-field-disabled-container-color: #232d3f;
  --mdc-filled-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(71, 85, 105, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(71, 85, 105, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #dc2626;
  --mdc-filled-text-field-error-focus-label-text-color: #dc2626;
  --mdc-filled-text-field-error-label-text-color: #dc2626;
  --mdc-filled-text-field-error-caret-color: #dc2626;
  --mdc-filled-text-field-active-indicator-color: rgba(241, 245, 249, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(241, 245, 249, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(241, 245, 249, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-hover-active-indicator-color: #dc2626;
  --mdc-outlined-text-field-caret-color: brown;
  --mdc-outlined-text-field-focus-outline-color: brown;
  --mdc-outlined-text-field-focus-label-text-color: rgba(165, 42, 42, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(71, 85, 105, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(71, 85, 105, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-error-caret-color: #dc2626;
  --mdc-outlined-text-field-error-focus-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-hover-label-text-color: #dc2626;
  --mdc-outlined-text-field-outline-color: rgba(241, 245, 249, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(241, 245, 249, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(241, 245, 249, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-error-hover-outline-color: #dc2626;
  --mdc-outlined-text-field-error-outline-color: #dc2626;
  --mat-form-field-focus-select-arrow-color: rgba(165, 42, 42, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(241, 245, 249, 0.38);
  --mat-form-field-state-layer-color: rgba(255, 255, 255, 0.87);
  --mat-form-field-error-text-color: #dc2626;
  --mat-form-field-select-option-text-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-select-disabled-option-text-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(241, 245, 249, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.08;
  --mat-form-field-focus-state-layer-opacity: 0.24;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: #1e293b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(71, 85, 105, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(165, 42, 42, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: #1e293b;
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: #1e293b;
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
  --mdc-switch-selected-focus-state-layer-color: #cda3a3;
  --mdc-switch-selected-handle-color: #cda3a3;
  --mdc-switch-selected-hover-state-layer-color: #cda3a3;
  --mdc-switch-selected-pressed-state-layer-color: #cda3a3;
  --mdc-switch-selected-focus-handle-color: #dec5c5;
  --mdc-switch-selected-hover-handle-color: #dec5c5;
  --mdc-switch-selected-pressed-handle-color: #dec5c5;
  --mdc-switch-selected-focus-track-color: #962626;
  --mdc-switch-selected-hover-track-color: #962626;
  --mdc-switch-selected-pressed-track-color: #962626;
  --mdc-switch-selected-track-color: #962626;
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-icon-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-disabled-label-text-color: #475569;
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-color: brown;
  --mdc-slider-focus-handle-color: brown;
  --mdc-slider-hover-handle-color: brown;
  --mdc-slider-active-track-color: brown;
  --mdc-slider-inactive-track-color: brown;
  --mdc-slider-with-tick-marks-inactive-container-color: brown;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-label-container-color: #fff;
  --mdc-slider-label-label-text-color: #000;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: brown;
  --mat-slider-hover-state-layer-color: rgba(165, 42, 42, 0.05);
  --mat-slider-focus-state-layer-color: rgba(165, 42, 42, 0.2);
  --mat-slider-value-indicator-opacity: 0.9;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-item-label-text-color: white;
  --mat-menu-item-icon-color: white;
  --mat-menu-item-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-container-color: #1e293b;
  --mat-menu-divider-color: rgba(241, 245, 249, 0.12);
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 0;
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: #94a3b8;
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: #64748b;
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: white;
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
  --mat-paginator-container-text-color: rgba(255, 255, 255, 0.87);
  --mat-paginator-container-background-color: #1e293b;
  --mat-paginator-enabled-icon-color: rgba(255, 255, 255, 0.54);
  --mat-paginator-disabled-icon-color: rgba(255, 255, 255, 0.12);
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
  --mat-checkbox-disabled-label-color: #475569;
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: white;
  --mdc-text-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mat-text-button-state-layer-color: white;
  --mat-text-button-disabled-state-layer-color: white;
  --mat-text-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.08;
  --mat-text-button-focus-state-layer-opacity: 0.24;
  --mat-text-button-pressed-state-layer-opacity: 0.24;
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: white;
  --mdc-filled-button-disabled-container-color: rgba(30, 41, 59, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-disabled-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.08;
  --mat-filled-button-focus-state-layer-opacity: 0.24;
  --mat-filled-button-pressed-state-layer-opacity: 0.24;
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: white;
  --mdc-protected-button-disabled-container-color: rgba(30, 41, 59, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-disabled-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.08;
  --mat-protected-button-focus-state-layer-opacity: 0.24;
  --mat-protected-button-pressed-state-layer-opacity: 0.24;
  --mdc-outlined-button-disabled-outline-color: rgba(241, 245, 249, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mdc-outlined-button-label-text-color: white;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: white;
  --mat-outlined-button-disabled-state-layer-color: white;
  --mat-outlined-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.08;
  --mat-outlined-button-focus-state-layer-opacity: 0.24;
  --mat-outlined-button-pressed-state-layer-opacity: 0.24;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
  --mat-icon-button-state-layer-color: white;
  --mat-icon-button-disabled-state-layer-color: white;
  --mat-icon-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.08;
  --mat-icon-button-focus-state-layer-opacity: 0.24;
  --mat-icon-button-pressed-state-layer-opacity: 0.24;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-fab-container-color: #1e293b;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-disabled-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.08;
  --mat-fab-focus-state-layer-opacity: 0.24;
  --mat-fab-pressed-state-layer-opacity: 0.24;
  --mat-fab-disabled-state-container-color: rgba(15, 23, 42, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(30, 41, 59, 0.5);
  --mdc-fab-small-container-color: #1e293b;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-disabled-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.08;
  --mat-fab-small-focus-state-layer-opacity: 0.24;
  --mat-fab-small-pressed-state-layer-opacity: 0.24;
  --mat-fab-small-disabled-state-container-color: rgba(15, 23, 42, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(30, 41, 59, 0.5);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #d2d4d8;
  --mdc-snackbar-supporting-text-color: rgba(30, 41, 59, 0.87);
  --mat-snack-bar-button-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: #1e293b;
  --mat-table-header-headline-color: white;
  --mat-table-row-item-label-text-color: white;
  --mat-table-row-item-outline-color: rgba(241, 245, 249, 0.12);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: brown;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: brown;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #1e293b;
  --mat-badge-disabled-state-text-color: #475569;
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: white;
  --mat-bottom-sheet-container-background-color: #1e293b;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: #64748b;
  --mat-legacy-button-toggle-state-layer-color: #e2e8f0;
  --mat-legacy-button-toggle-selected-state-text-color: #94a3b8;
  --mat-legacy-button-toggle-selected-state-background-color: rgba(255, 255, 255, 0.05);
  --mat-legacy-button-toggle-disabled-state-text-color: #1e293b;
  --mat-legacy-button-toggle-disabled-state-background-color: #0f172a;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #1e293b;
  --mat-standard-button-toggle-text-color: white;
  --mat-standard-button-toggle-background-color: #1e293b;
  --mat-standard-button-toggle-state-layer-color: #e2e8f0;
  --mat-standard-button-toggle-selected-state-background-color: rgba(255, 255, 255, 0.05);
  --mat-standard-button-toggle-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-state-text-color: #1e293b;
  --mat-standard-button-toggle-disabled-state-background-color: #1e293b;
  --mat-standard-button-toggle-disabled-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #1e293b;
  --mat-standard-button-toggle-divider-color: #374152;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: brown;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(165, 42, 42, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(165, 42, 42, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(165, 42, 42, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: brown;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(165, 42, 42, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: #f1f5f9;
  --mat-datepicker-calendar-body-label-text-color: #94a3b8;
  --mat-datepicker-calendar-period-button-text-color: white;
  --mat-datepicker-calendar-period-button-icon-color: #f1f5f9;
  --mat-datepicker-calendar-navigation-button-icon-color: #f1f5f9;
  --mat-datepicker-calendar-header-divider-color: rgba(241, 245, 249, 0.12);
  --mat-datepicker-calendar-header-text-color: #94a3b8;
  --mat-datepicker-calendar-date-today-outline-color: #64748b;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(100, 116, 139, 0.8);
  --mat-datepicker-calendar-date-text-color: white;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: #475569;
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(241, 245, 249, 0.24);
  --mat-datepicker-range-input-separator-color: white;
  --mat-datepicker-range-input-disabled-state-separator-color: #475569;
  --mat-datepicker-range-input-disabled-state-text-color: #475569;
  --mat-datepicker-calendar-container-background-color: #1e293b;
  --mat-datepicker-calendar-container-text-color: white;
  --mat-divider-width: 1px;
  --mat-divider-color: rgba(241, 245, 249, 0.12);
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: #1e293b;
  --mat-expansion-container-text-color: white;
  --mat-expansion-actions-divider-color: rgba(241, 245, 249, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-expansion-header-focus-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-expansion-header-disabled-state-text-color: #1e293b;
  --mat-expansion-header-text-color: white;
  --mat-expansion-header-description-color: #94a3b8;
  --mat-expansion-header-indicator-color: #94a3b8;
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: rgba(241, 245, 249, 0.12);
  --mat-sidenav-container-background-color: #1e293b;
  --mat-sidenav-container-text-color: white;
  --mat-sidenav-content-background-color: #0f172a;
  --mat-sidenav-content-text-color: white;
  --mat-sidenav-scrim-color: rgba(225, 214, 196, 0.6);
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: brown;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: brown;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: brown;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: #1e293b;
  --mat-stepper-line-color: rgba(241, 245, 249, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-stepper-header-focus-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-stepper-header-label-text-color: #94a3b8;
  --mat-stepper-header-optional-label-text-color: #94a3b8;
  --mat-stepper-header-selected-state-label-text-color: white;
  --mat-stepper-header-error-state-label-text-color: #dc2626;
  --mat-stepper-header-icon-background-color: #94a3b8;
  --mat-stepper-header-error-state-icon-foreground-color: #dc2626;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #94a3b8;
  --mat-toolbar-container-background-color: #0f172a;
  --mat-toolbar-container-text-color: white;
  --mat-tree-container-background-color: #1e293b;
  --mat-tree-node-text-color: white;
}
.theme-brown .dark .mat-accent,
.theme-brown.dark .mat-accent {
  --mat-option-selected-state-label-text-color: #1e293b;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.theme-brown .dark .mat-warn,
.theme-brown.dark .mat-warn {
  --mat-option-selected-state-label-text-color: #dc2626;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.theme-brown .dark .mat-primary,
.theme-brown.dark .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: brown;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: brown;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-brown .dark .mat-accent,
.theme-brown.dark .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-brown .dark .mat-warn,
.theme-brown.dark .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #dc2626;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #dc2626;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-brown .dark .mat-elevation-z0, .theme-brown .dark .mat-mdc-elevation-specific.mat-elevation-z0,
.theme-brown.dark .mat-elevation-z0,
.theme-brown.dark .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-brown .dark .mat-elevation-z1, .theme-brown .dark .mat-mdc-elevation-specific.mat-elevation-z1,
.theme-brown.dark .mat-elevation-z1,
.theme-brown.dark .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-brown .dark .mat-elevation-z2, .theme-brown .dark .mat-mdc-elevation-specific.mat-elevation-z2,
.theme-brown.dark .mat-elevation-z2,
.theme-brown.dark .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-brown .dark .mat-elevation-z3, .theme-brown .dark .mat-mdc-elevation-specific.mat-elevation-z3,
.theme-brown.dark .mat-elevation-z3,
.theme-brown.dark .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-brown .dark .mat-elevation-z4, .theme-brown .dark .mat-mdc-elevation-specific.mat-elevation-z4,
.theme-brown.dark .mat-elevation-z4,
.theme-brown.dark .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-brown .dark .mat-elevation-z5, .theme-brown .dark .mat-mdc-elevation-specific.mat-elevation-z5,
.theme-brown.dark .mat-elevation-z5,
.theme-brown.dark .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-brown .dark .mat-elevation-z6, .theme-brown .dark .mat-mdc-elevation-specific.mat-elevation-z6,
.theme-brown.dark .mat-elevation-z6,
.theme-brown.dark .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-brown .dark .mat-elevation-z7, .theme-brown .dark .mat-mdc-elevation-specific.mat-elevation-z7,
.theme-brown.dark .mat-elevation-z7,
.theme-brown.dark .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-brown .dark .mat-elevation-z8, .theme-brown .dark .mat-mdc-elevation-specific.mat-elevation-z8,
.theme-brown.dark .mat-elevation-z8,
.theme-brown.dark .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-brown .dark .mat-elevation-z9, .theme-brown .dark .mat-mdc-elevation-specific.mat-elevation-z9,
.theme-brown.dark .mat-elevation-z9,
.theme-brown.dark .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-brown .dark .mat-elevation-z10, .theme-brown .dark .mat-mdc-elevation-specific.mat-elevation-z10,
.theme-brown.dark .mat-elevation-z10,
.theme-brown.dark .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-brown .dark .mat-elevation-z11, .theme-brown .dark .mat-mdc-elevation-specific.mat-elevation-z11,
.theme-brown.dark .mat-elevation-z11,
.theme-brown.dark .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-brown .dark .mat-elevation-z12, .theme-brown .dark .mat-mdc-elevation-specific.mat-elevation-z12,
.theme-brown.dark .mat-elevation-z12,
.theme-brown.dark .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-brown .dark .mat-elevation-z13, .theme-brown .dark .mat-mdc-elevation-specific.mat-elevation-z13,
.theme-brown.dark .mat-elevation-z13,
.theme-brown.dark .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-brown .dark .mat-elevation-z14, .theme-brown .dark .mat-mdc-elevation-specific.mat-elevation-z14,
.theme-brown.dark .mat-elevation-z14,
.theme-brown.dark .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-brown .dark .mat-elevation-z15, .theme-brown .dark .mat-mdc-elevation-specific.mat-elevation-z15,
.theme-brown.dark .mat-elevation-z15,
.theme-brown.dark .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-brown .dark .mat-elevation-z16, .theme-brown .dark .mat-mdc-elevation-specific.mat-elevation-z16,
.theme-brown.dark .mat-elevation-z16,
.theme-brown.dark .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-brown .dark .mat-elevation-z17, .theme-brown .dark .mat-mdc-elevation-specific.mat-elevation-z17,
.theme-brown.dark .mat-elevation-z17,
.theme-brown.dark .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-brown .dark .mat-elevation-z18, .theme-brown .dark .mat-mdc-elevation-specific.mat-elevation-z18,
.theme-brown.dark .mat-elevation-z18,
.theme-brown.dark .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-brown .dark .mat-elevation-z19, .theme-brown .dark .mat-mdc-elevation-specific.mat-elevation-z19,
.theme-brown.dark .mat-elevation-z19,
.theme-brown.dark .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-brown .dark .mat-elevation-z20, .theme-brown .dark .mat-mdc-elevation-specific.mat-elevation-z20,
.theme-brown.dark .mat-elevation-z20,
.theme-brown.dark .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-brown .dark .mat-elevation-z21, .theme-brown .dark .mat-mdc-elevation-specific.mat-elevation-z21,
.theme-brown.dark .mat-elevation-z21,
.theme-brown.dark .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-brown .dark .mat-elevation-z22, .theme-brown .dark .mat-mdc-elevation-specific.mat-elevation-z22,
.theme-brown.dark .mat-elevation-z22,
.theme-brown.dark .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-brown .dark .mat-elevation-z23, .theme-brown .dark .mat-mdc-elevation-specific.mat-elevation-z23,
.theme-brown.dark .mat-elevation-z23,
.theme-brown.dark .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-brown .dark .mat-elevation-z24, .theme-brown .dark .mat-mdc-elevation-specific.mat-elevation-z24,
.theme-brown.dark .mat-elevation-z24,
.theme-brown.dark .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-brown .dark .mat-mdc-progress-bar,
.theme-brown.dark .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: brown;
  --mdc-linear-progress-track-color: rgba(165, 42, 42, 0.25);
}
.theme-brown .dark .mat-mdc-progress-bar.mat-accent,
.theme-brown.dark .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1e293b;
  --mdc-linear-progress-track-color: rgba(30, 41, 59, 0.25);
}
.theme-brown .dark .mat-mdc-progress-bar.mat-warn,
.theme-brown.dark .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc2626;
  --mdc-linear-progress-track-color: rgba(220, 38, 38, 0.25);
}
.theme-brown .dark .mat-mdc-form-field.mat-accent,
.theme-brown.dark .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #1e293b;
  --mdc-filled-text-field-focus-active-indicator-color: #1e293b;
  --mdc-filled-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mdc-outlined-text-field-caret-color: #1e293b;
  --mdc-outlined-text-field-focus-outline-color: #1e293b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(30, 41, 59, 0.87);
}
.theme-brown .dark .mat-mdc-form-field.mat-warn,
.theme-brown.dark .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #dc2626;
  --mdc-filled-text-field-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mdc-outlined-text-field-caret-color: #dc2626;
  --mdc-outlined-text-field-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-brown .dark .mat-mdc-form-field.mat-accent,
.theme-brown.dark .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: #1e293b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(71, 85, 105, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(30, 41, 59, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-brown .dark .mat-mdc-form-field.mat-warn,
.theme-brown.dark .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: #1e293b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(71, 85, 105, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-brown .dark .mat-mdc-standard-chip,
.theme-brown.dark .mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.theme-brown .dark .mat-mdc-standard-chip,
.theme-brown.dark .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-elevated-container-color: #394353;
  --mdc-chip-elevated-selected-container-color: #394353;
  --mdc-chip-elevated-disabled-container-color: #394353;
  --mdc-chip-flat-disabled-selected-container-color: #394353;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-selected-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
  --mat-chip-selected-disabled-trailing-icon-color: #fafafa;
  --mat-chip-selected-trailing-icon-color: #fafafa;
}
.theme-brown .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .theme-brown .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.theme-brown.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.theme-brown.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: brown;
  --mdc-chip-elevated-selected-container-color: brown;
  --mdc-chip-elevated-disabled-container-color: brown;
  --mdc-chip-flat-disabled-selected-container-color: brown;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-brown .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .theme-brown .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.theme-brown.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.theme-brown.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #1e293b;
  --mdc-chip-elevated-selected-container-color: #1e293b;
  --mdc-chip-elevated-disabled-container-color: #1e293b;
  --mdc-chip-flat-disabled-selected-container-color: #1e293b;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-brown .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .theme-brown .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.theme-brown.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.theme-brown.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-selected-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-flat-disabled-selected-container-color: #dc2626;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-brown .dark .mat-mdc-slide-toggle,
.theme-brown.dark .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: white;
}
.theme-brown .dark .mat-mdc-slide-toggle.mat-accent,
.theme-brown.dark .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #cbd5e1;
  --mdc-switch-selected-handle-color: #cbd5e1;
  --mdc-switch-selected-hover-state-layer-color: #cbd5e1;
  --mdc-switch-selected-pressed-state-layer-color: #cbd5e1;
  --mdc-switch-selected-focus-handle-color: #e2e8f0;
  --mdc-switch-selected-hover-handle-color: #e2e8f0;
  --mdc-switch-selected-pressed-handle-color: #e2e8f0;
  --mdc-switch-selected-focus-track-color: #475569;
  --mdc-switch-selected-hover-track-color: #475569;
  --mdc-switch-selected-pressed-track-color: #475569;
  --mdc-switch-selected-track-color: #475569;
}
.theme-brown .dark .mat-mdc-slide-toggle.mat-warn,
.theme-brown.dark .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #fca5a5;
  --mdc-switch-selected-handle-color: #fca5a5;
  --mdc-switch-selected-hover-state-layer-color: #fca5a5;
  --mdc-switch-selected-pressed-state-layer-color: #fca5a5;
  --mdc-switch-selected-focus-handle-color: #fecaca;
  --mdc-switch-selected-hover-handle-color: #fecaca;
  --mdc-switch-selected-pressed-handle-color: #fecaca;
  --mdc-switch-selected-focus-track-color: #dc2626;
  --mdc-switch-selected-hover-track-color: #dc2626;
  --mdc-switch-selected-pressed-track-color: #dc2626;
  --mdc-switch-selected-track-color: #dc2626;
}
.theme-brown .dark .mat-mdc-radio-button,
.theme-brown.dark .mat-mdc-radio-button {
  --mdc-form-field-label-text-color: white;
}
.theme-brown .dark .mat-mdc-radio-button.mat-primary,
.theme-brown.dark .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: brown;
  --mdc-radio-selected-hover-icon-color: brown;
  --mdc-radio-selected-icon-color: brown;
  --mdc-radio-selected-pressed-icon-color: brown;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: brown;
  --mat-radio-disabled-label-color: #475569;
}
.theme-brown .dark .mat-mdc-radio-button.mat-accent,
.theme-brown.dark .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #1e293b;
  --mat-radio-disabled-label-color: #475569;
}
.theme-brown .dark .mat-mdc-radio-button.mat-warn,
.theme-brown.dark .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #dc2626;
  --mat-radio-disabled-label-color: #475569;
}
.theme-brown .dark .mat-accent,
.theme-brown.dark .mat-accent {
  --mat-slider-ripple-color: #1e293b;
  --mat-slider-hover-state-layer-color: rgba(30, 41, 59, 0.05);
  --mat-slider-focus-state-layer-color: rgba(30, 41, 59, 0.2);
  --mdc-slider-handle-color: #1e293b;
  --mdc-slider-focus-handle-color: #1e293b;
  --mdc-slider-hover-handle-color: #1e293b;
  --mdc-slider-active-track-color: #1e293b;
  --mdc-slider-inactive-track-color: #1e293b;
  --mdc-slider-with-tick-marks-inactive-container-color: #1e293b;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-brown .dark .mat-warn,
.theme-brown.dark .mat-warn {
  --mat-slider-ripple-color: #dc2626;
  --mat-slider-hover-state-layer-color: rgba(220, 38, 38, 0.05);
  --mat-slider-focus-state-layer-color: rgba(220, 38, 38, 0.2);
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-brown .dark .mdc-list-item__start,
.theme-brown .dark .mdc-list-item__end,
.theme-brown.dark .mdc-list-item__start,
.theme-brown.dark .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: brown;
  --mdc-radio-selected-hover-icon-color: brown;
  --mdc-radio-selected-icon-color: brown;
  --mdc-radio-selected-pressed-icon-color: brown;
}
.theme-brown .dark .mat-accent .mdc-list-item__start,
.theme-brown .dark .mat-accent .mdc-list-item__end,
.theme-brown.dark .mat-accent .mdc-list-item__start,
.theme-brown.dark .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
}
.theme-brown .dark .mat-warn .mdc-list-item__start,
.theme-brown .dark .mat-warn .mdc-list-item__end,
.theme-brown.dark .mat-warn .mdc-list-item__start,
.theme-brown.dark .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
}
.theme-brown .dark .mat-mdc-list-option,
.theme-brown.dark .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: brown;
  --mdc-checkbox-selected-hover-icon-color: brown;
  --mdc-checkbox-selected-icon-color: brown;
  --mdc-checkbox-selected-pressed-icon-color: brown;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: brown;
  --mdc-checkbox-selected-hover-state-layer-color: brown;
  --mdc-checkbox-selected-pressed-state-layer-color: brown;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-brown .dark .mat-mdc-list-option.mat-accent,
.theme-brown.dark .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-brown .dark .mat-mdc-list-option.mat-warn,
.theme-brown.dark .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-brown .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-brown .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-brown.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-brown.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: brown;
}
.theme-brown .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brown .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brown.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-brown.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: brown;
}
.theme-brown .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-brown .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-brown .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.theme-brown.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-brown.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-brown.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.theme-brown .dark .mat-mdc-tab-group, .theme-brown .dark .mat-mdc-tab-nav-bar,
.theme-brown.dark .mat-mdc-tab-group,
.theme-brown.dark .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: brown;
  --mat-tab-header-disabled-ripple-color: #475569;
  --mat-tab-header-pagination-icon-color: #f1f5f9;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: brown;
  --mat-tab-header-active-ripple-color: brown;
  --mat-tab-header-inactive-ripple-color: brown;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: brown;
  --mat-tab-header-active-hover-label-text-color: brown;
  --mat-tab-header-active-focus-indicator-color: brown;
  --mat-tab-header-active-hover-indicator-color: brown;
}
.theme-brown .dark .mat-mdc-tab-group.mat-accent, .theme-brown .dark .mat-mdc-tab-nav-bar.mat-accent,
.theme-brown.dark .mat-mdc-tab-group.mat-accent,
.theme-brown.dark .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #1e293b;
  --mat-tab-header-disabled-ripple-color: #475569;
  --mat-tab-header-pagination-icon-color: #f1f5f9;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #1e293b;
  --mat-tab-header-active-ripple-color: #1e293b;
  --mat-tab-header-inactive-ripple-color: #1e293b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #1e293b;
  --mat-tab-header-active-hover-label-text-color: #1e293b;
  --mat-tab-header-active-focus-indicator-color: #1e293b;
  --mat-tab-header-active-hover-indicator-color: #1e293b;
}
.theme-brown .dark .mat-mdc-tab-group.mat-warn, .theme-brown .dark .mat-mdc-tab-nav-bar.mat-warn,
.theme-brown.dark .mat-mdc-tab-group.mat-warn,
.theme-brown.dark .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #dc2626;
  --mat-tab-header-disabled-ripple-color: #475569;
  --mat-tab-header-pagination-icon-color: #f1f5f9;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #dc2626;
  --mat-tab-header-active-ripple-color: #dc2626;
  --mat-tab-header-inactive-ripple-color: #dc2626;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #dc2626;
  --mat-tab-header-active-hover-label-text-color: #dc2626;
  --mat-tab-header-active-focus-indicator-color: #dc2626;
  --mat-tab-header-active-hover-indicator-color: #dc2626;
}
.theme-brown .dark .mat-mdc-tab-group.mat-background-primary, .theme-brown .dark .mat-mdc-tab-nav-bar.mat-background-primary,
.theme-brown.dark .mat-mdc-tab-group.mat-background-primary,
.theme-brown.dark .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: brown;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-brown .dark .mat-mdc-tab-group.mat-background-accent, .theme-brown .dark .mat-mdc-tab-nav-bar.mat-background-accent,
.theme-brown.dark .mat-mdc-tab-group.mat-background-accent,
.theme-brown.dark .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #1e293b;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-brown .dark .mat-mdc-tab-group.mat-background-warn, .theme-brown .dark .mat-mdc-tab-nav-bar.mat-background-warn,
.theme-brown.dark .mat-mdc-tab-group.mat-background-warn,
.theme-brown.dark .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #dc2626;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-brown .dark .mat-mdc-checkbox,
.theme-brown.dark .mat-mdc-checkbox {
  --mdc-form-field-label-text-color: white;
}
.theme-brown .dark .mat-mdc-checkbox.mat-primary,
.theme-brown.dark .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: brown;
  --mdc-checkbox-selected-hover-icon-color: brown;
  --mdc-checkbox-selected-icon-color: brown;
  --mdc-checkbox-selected-pressed-icon-color: brown;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: brown;
  --mdc-checkbox-selected-hover-state-layer-color: brown;
  --mdc-checkbox-selected-pressed-state-layer-color: brown;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-brown .dark .mat-mdc-checkbox.mat-warn,
.theme-brown.dark .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-brown .dark .mat-mdc-button.mat-primary,
.theme-brown.dark .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: brown;
  --mat-text-button-state-layer-color: brown;
  --mat-text-button-ripple-color: rgba(165, 42, 42, 0.1);
}
.theme-brown .dark .mat-mdc-button.mat-accent,
.theme-brown.dark .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1e293b;
  --mat-text-button-state-layer-color: #1e293b;
  --mat-text-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-brown .dark .mat-mdc-button.mat-warn,
.theme-brown.dark .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc2626;
  --mat-text-button-state-layer-color: #dc2626;
  --mat-text-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-brown .dark .mat-mdc-unelevated-button.mat-primary,
.theme-brown.dark .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: brown;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brown .dark .mat-mdc-unelevated-button.mat-accent,
.theme-brown.dark .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brown .dark .mat-mdc-unelevated-button.mat-warn,
.theme-brown.dark .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brown .dark .mat-mdc-raised-button.mat-primary,
.theme-brown.dark .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: brown;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brown .dark .mat-mdc-raised-button.mat-accent,
.theme-brown.dark .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brown .dark .mat-mdc-raised-button.mat-warn,
.theme-brown.dark .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brown .dark .mat-mdc-outlined-button.mat-primary,
.theme-brown.dark .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: brown;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: brown;
  --mat-outlined-button-ripple-color: rgba(165, 42, 42, 0.1);
}
.theme-brown .dark .mat-mdc-outlined-button.mat-accent,
.theme-brown.dark .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1e293b;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: #1e293b;
  --mat-outlined-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-brown .dark .mat-mdc-outlined-button.mat-warn,
.theme-brown.dark .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc2626;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: #dc2626;
  --mat-outlined-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-brown .dark .mat-mdc-icon-button.mat-primary,
.theme-brown.dark .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: brown;
  --mat-icon-button-state-layer-color: brown;
  --mat-icon-button-ripple-color: rgba(165, 42, 42, 0.1);
}
.theme-brown .dark .mat-mdc-icon-button.mat-accent,
.theme-brown.dark .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1e293b;
  --mat-icon-button-state-layer-color: #1e293b;
  --mat-icon-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-brown .dark .mat-mdc-icon-button.mat-warn,
.theme-brown.dark .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc2626;
  --mat-icon-button-state-layer-color: #dc2626;
  --mat-icon-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-brown .dark .mat-mdc-fab.mat-primary,
.theme-brown.dark .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: brown;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brown .dark .mat-mdc-fab.mat-accent,
.theme-brown.dark .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #1e293b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brown .dark .mat-mdc-fab.mat-warn,
.theme-brown.dark .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #dc2626;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brown .dark .mat-mdc-mini-fab.mat-primary,
.theme-brown.dark .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: brown;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brown .dark .mat-mdc-mini-fab.mat-accent,
.theme-brown.dark .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #1e293b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brown .dark .mat-mdc-mini-fab.mat-warn,
.theme-brown.dark .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #dc2626;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-brown .dark .mat-accent,
.theme-brown.dark .mat-accent {
  --mdc-circular-progress-active-indicator-color: #1e293b;
}
.theme-brown .dark .mat-warn,
.theme-brown.dark .mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}
.theme-brown .dark .mat-badge-accent,
.theme-brown.dark .mat-badge-accent {
  --mat-badge-background-color: #1e293b;
  --mat-badge-text-color: white;
}
.theme-brown .dark .mat-badge-warn,
.theme-brown.dark .mat-badge-warn {
  --mat-badge-background-color: #dc2626;
  --mat-badge-text-color: white;
}
.theme-brown .dark .mat-datepicker-content.mat-accent,
.theme-brown.dark .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #1e293b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(30, 41, 59, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(30, 41, 59, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-brown .dark .mat-datepicker-content.mat-warn,
.theme-brown.dark .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #dc2626;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(220, 38, 38, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(220, 38, 38, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-brown .dark .mat-datepicker-toggle-active.mat-accent,
.theme-brown.dark .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #1e293b;
}
.theme-brown .dark .mat-datepicker-toggle-active.mat-warn,
.theme-brown.dark .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #dc2626;
}
.theme-brown .dark .mat-icon.mat-primary,
.theme-brown.dark .mat-icon.mat-primary {
  --mat-icon-color: brown;
}
.theme-brown .dark .mat-icon.mat-accent,
.theme-brown.dark .mat-icon.mat-accent {
  --mat-icon-color: #1e293b;
}
.theme-brown .dark .mat-icon.mat-warn,
.theme-brown.dark .mat-icon.mat-warn {
  --mat-icon-color: #dc2626;
}
.theme-brown .dark .mat-step-header.mat-accent,
.theme-brown.dark .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #1e293b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #1e293b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #1e293b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-brown .dark .mat-step-header.mat-warn,
.theme-brown.dark .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #dc2626;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #dc2626;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #dc2626;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-brown .dark .mat-toolbar.mat-primary,
.theme-brown.dark .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: brown;
  --mat-toolbar-container-text-color: white;
}
.theme-brown .dark .mat-toolbar.mat-accent,
.theme-brown.dark .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #1e293b;
  --mat-toolbar-container-text-color: white;
}
.theme-brown .dark .mat-toolbar.mat-warn,
.theme-brown.dark .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #dc2626;
  --mat-toolbar-container-text-color: white;
}

/* Generate the palettes */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define a light & dark Angular Material theme with the generated palettes */
/* Merge the custom base colors with the generated themes */
/* Generate and encapsulate Angular Material themes */
.theme-red .light,
.theme-red.light {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-option-selected-state-label-text-color: #ef4444;
  --mat-option-label-text-color: #1e293b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-optgroup-label-text-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
  --mat-app-background-color: #f1f5f9;
  --mat-app-text-color: #1e293b;
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: #64748b;
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #1e293b;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #ef4444;
  --mdc-filled-text-field-focus-active-indicator-color: #ef4444;
  --mdc-filled-text-field-focus-label-text-color: rgba(239, 68, 68, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(30, 41, 59, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(148, 163, 184, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(30, 41, 59, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #dc2626;
  --mdc-filled-text-field-error-focus-label-text-color: #dc2626;
  --mdc-filled-text-field-error-label-text-color: #dc2626;
  --mdc-filled-text-field-error-caret-color: #dc2626;
  --mdc-filled-text-field-active-indicator-color: rgba(226, 232, 240, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(226, 232, 240, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(226, 232, 240, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-hover-active-indicator-color: #dc2626;
  --mdc-outlined-text-field-caret-color: #ef4444;
  --mdc-outlined-text-field-focus-outline-color: #ef4444;
  --mdc-outlined-text-field-focus-label-text-color: rgba(239, 68, 68, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(30, 41, 59, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(148, 163, 184, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(30, 41, 59, 0.6);
  --mdc-outlined-text-field-error-caret-color: #dc2626;
  --mdc-outlined-text-field-error-focus-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-hover-label-text-color: #dc2626;
  --mdc-outlined-text-field-outline-color: rgba(226, 232, 240, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(226, 232, 240, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(226, 232, 240, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-error-hover-outline-color: #dc2626;
  --mdc-outlined-text-field-error-outline-color: #dc2626;
  --mat-form-field-focus-select-arrow-color: rgba(239, 68, 68, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(100, 116, 139, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #dc2626;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(100, 116, 139, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(100, 116, 139, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(30, 41, 59, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(148, 163, 184, 0.38);
  --mat-select-placeholder-text-color: rgba(30, 41, 59, 0.6);
  --mat-select-enabled-arrow-color: rgba(226, 232, 240, 0.54);
  --mat-select-disabled-arrow-color: rgba(30, 41, 59, 0.38);
  --mat-select-focused-arrow-color: rgba(239, 68, 68, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: white;
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(30, 41, 59, 0.87);
  --mdc-dialog-supporting-text-color: rgba(30, 41, 59, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-disabled-label-text-color: #94a3b8;
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-color: #ef4444;
  --mdc-slider-focus-handle-color: #ef4444;
  --mdc-slider-hover-handle-color: #ef4444;
  --mdc-slider-active-track-color: #ef4444;
  --mdc-slider-inactive-track-color: #ef4444;
  --mdc-slider-with-tick-marks-inactive-container-color: #ef4444;
  --mdc-slider-with-tick-marks-active-container-color: #450a0a;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: #ef4444;
  --mat-slider-hover-state-layer-color: rgba(239, 68, 68, 0.05);
  --mat-slider-focus-state-layer-color: rgba(239, 68, 68, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-item-label-text-color: #1e293b;
  --mat-menu-item-icon-color: #1e293b;
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: #e2e8f0;
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 0;
  --mdc-list-list-item-label-text-color: #1e293b;
  --mdc-list-list-item-supporting-text-color: #64748b;
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: #94a3b8;
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: #1e293b;
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: #1e293b;
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: #94a3b8;
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: #1e293b;
  --mdc-text-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: #1e293b;
  --mdc-filled-button-disabled-container-color: rgba(148, 163, 184, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: #1e293b;
  --mdc-protected-button-disabled-container-color: rgba(148, 163, 184, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: #e2e8f0;
  --mdc-outlined-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-outlined-button-label-text-color: #1e293b;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-fab-container-color: white;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(148, 163, 184, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(148, 163, 184, 0.38);
  --mdc-fab-small-container-color: white;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(148, 163, 184, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(148, 163, 184, 0.38);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #1e293b;
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: white;
  --mat-table-header-headline-color: #1e293b;
  --mat-table-row-item-label-text-color: #1e293b;
  --mat-table-row-item-outline-color: #e2e8f0;
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #ef4444;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #ef4444;
  --mat-badge-text-color: #450a0a;
  --mat-badge-disabled-state-background-color: #94a3b8;
  --mat-badge-disabled-state-text-color: #94a3b8;
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: #1e293b;
  --mat-bottom-sheet-container-background-color: white;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: #94a3b8;
  --mat-legacy-button-toggle-state-layer-color: #64748b;
  --mat-legacy-button-toggle-selected-state-text-color: #64748b;
  --mat-legacy-button-toggle-selected-state-background-color: #e2e8f0;
  --mat-legacy-button-toggle-disabled-state-text-color: #94a3b8;
  --mat-legacy-button-toggle-disabled-state-background-color: #cbd5e1;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #e2e8f0;
  --mat-standard-button-toggle-text-color: #1e293b;
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: #64748b;
  --mat-standard-button-toggle-selected-state-background-color: #e2e8f0;
  --mat-standard-button-toggle-selected-state-text-color: #1e293b;
  --mat-standard-button-toggle-disabled-state-text-color: #94a3b8;
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: #1e293b;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #e2e8f0;
  --mat-standard-button-toggle-divider-color: #e2e8f0;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: #450a0a;
  --mat-datepicker-calendar-date-selected-state-background-color: #ef4444;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(239, 68, 68, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #450a0a;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(239, 68, 68, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(239, 68, 68, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #ef4444;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(239, 68, 68, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: #64748b;
  --mat-datepicker-calendar-body-label-text-color: #64748b;
  --mat-datepicker-calendar-period-button-text-color: #1e293b;
  --mat-datepicker-calendar-period-button-icon-color: #64748b;
  --mat-datepicker-calendar-navigation-button-icon-color: #64748b;
  --mat-datepicker-calendar-header-divider-color: #e2e8f0;
  --mat-datepicker-calendar-header-text-color: #64748b;
  --mat-datepicker-calendar-date-today-outline-color: #94a3b8;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(148, 163, 184, 0.8);
  --mat-datepicker-calendar-date-text-color: #1e293b;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: #94a3b8;
  --mat-datepicker-calendar-date-preview-state-outline-color: #e2e8f0;
  --mat-datepicker-range-input-separator-color: #1e293b;
  --mat-datepicker-range-input-disabled-state-separator-color: #94a3b8;
  --mat-datepicker-range-input-disabled-state-text-color: #94a3b8;
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: #1e293b;
  --mat-divider-width: 1px;
  --mat-divider-color: #e2e8f0;
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: #1e293b;
  --mat-expansion-actions-divider-color: #e2e8f0;
  --mat-expansion-header-hover-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-expansion-header-focus-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-expansion-header-disabled-state-text-color: #94a3b8;
  --mat-expansion-header-text-color: #1e293b;
  --mat-expansion-header-description-color: #64748b;
  --mat-expansion-header-indicator-color: #64748b;
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: #e2e8f0;
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: #1e293b;
  --mat-sidenav-content-background-color: #f1f5f9;
  --mat-sidenav-content-text-color: #1e293b;
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
  --mat-stepper-header-icon-foreground-color: #450a0a;
  --mat-stepper-header-selected-state-icon-background-color: #ef4444;
  --mat-stepper-header-selected-state-icon-foreground-color: #450a0a;
  --mat-stepper-header-done-state-icon-background-color: #ef4444;
  --mat-stepper-header-done-state-icon-foreground-color: #450a0a;
  --mat-stepper-header-edit-state-icon-background-color: #ef4444;
  --mat-stepper-header-edit-state-icon-foreground-color: #450a0a;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: #e2e8f0;
  --mat-stepper-header-hover-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-stepper-header-focus-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-stepper-header-label-text-color: #64748b;
  --mat-stepper-header-optional-label-text-color: #64748b;
  --mat-stepper-header-selected-state-label-text-color: #1e293b;
  --mat-stepper-header-error-state-label-text-color: #dc2626;
  --mat-stepper-header-icon-background-color: #64748b;
  --mat-stepper-header-error-state-icon-foreground-color: #dc2626;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #64748b;
  --mat-toolbar-container-background-color: white;
  --mat-toolbar-container-text-color: #1e293b;
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: #1e293b;
}
.theme-red .light .mat-accent,
.theme-red.light .mat-accent {
  --mat-option-selected-state-label-text-color: #1e293b;
  --mat-option-label-text-color: #1e293b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.theme-red .light .mat-warn,
.theme-red.light .mat-warn {
  --mat-option-selected-state-label-text-color: #dc2626;
  --mat-option-label-text-color: #1e293b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.theme-red .light .mat-primary,
.theme-red.light .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #ef4444;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #ef4444;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-red .light .mat-accent,
.theme-red.light .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-red .light .mat-warn,
.theme-red.light .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #dc2626;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #dc2626;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-red .light .mat-elevation-z0, .theme-red .light .mat-mdc-elevation-specific.mat-elevation-z0,
.theme-red.light .mat-elevation-z0,
.theme-red.light .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-red .light .mat-elevation-z1, .theme-red .light .mat-mdc-elevation-specific.mat-elevation-z1,
.theme-red.light .mat-elevation-z1,
.theme-red.light .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-red .light .mat-elevation-z2, .theme-red .light .mat-mdc-elevation-specific.mat-elevation-z2,
.theme-red.light .mat-elevation-z2,
.theme-red.light .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-red .light .mat-elevation-z3, .theme-red .light .mat-mdc-elevation-specific.mat-elevation-z3,
.theme-red.light .mat-elevation-z3,
.theme-red.light .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-red .light .mat-elevation-z4, .theme-red .light .mat-mdc-elevation-specific.mat-elevation-z4,
.theme-red.light .mat-elevation-z4,
.theme-red.light .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-red .light .mat-elevation-z5, .theme-red .light .mat-mdc-elevation-specific.mat-elevation-z5,
.theme-red.light .mat-elevation-z5,
.theme-red.light .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-red .light .mat-elevation-z6, .theme-red .light .mat-mdc-elevation-specific.mat-elevation-z6,
.theme-red.light .mat-elevation-z6,
.theme-red.light .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-red .light .mat-elevation-z7, .theme-red .light .mat-mdc-elevation-specific.mat-elevation-z7,
.theme-red.light .mat-elevation-z7,
.theme-red.light .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-red .light .mat-elevation-z8, .theme-red .light .mat-mdc-elevation-specific.mat-elevation-z8,
.theme-red.light .mat-elevation-z8,
.theme-red.light .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-red .light .mat-elevation-z9, .theme-red .light .mat-mdc-elevation-specific.mat-elevation-z9,
.theme-red.light .mat-elevation-z9,
.theme-red.light .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-red .light .mat-elevation-z10, .theme-red .light .mat-mdc-elevation-specific.mat-elevation-z10,
.theme-red.light .mat-elevation-z10,
.theme-red.light .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-red .light .mat-elevation-z11, .theme-red .light .mat-mdc-elevation-specific.mat-elevation-z11,
.theme-red.light .mat-elevation-z11,
.theme-red.light .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-red .light .mat-elevation-z12, .theme-red .light .mat-mdc-elevation-specific.mat-elevation-z12,
.theme-red.light .mat-elevation-z12,
.theme-red.light .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-red .light .mat-elevation-z13, .theme-red .light .mat-mdc-elevation-specific.mat-elevation-z13,
.theme-red.light .mat-elevation-z13,
.theme-red.light .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-red .light .mat-elevation-z14, .theme-red .light .mat-mdc-elevation-specific.mat-elevation-z14,
.theme-red.light .mat-elevation-z14,
.theme-red.light .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-red .light .mat-elevation-z15, .theme-red .light .mat-mdc-elevation-specific.mat-elevation-z15,
.theme-red.light .mat-elevation-z15,
.theme-red.light .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-red .light .mat-elevation-z16, .theme-red .light .mat-mdc-elevation-specific.mat-elevation-z16,
.theme-red.light .mat-elevation-z16,
.theme-red.light .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-red .light .mat-elevation-z17, .theme-red .light .mat-mdc-elevation-specific.mat-elevation-z17,
.theme-red.light .mat-elevation-z17,
.theme-red.light .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-red .light .mat-elevation-z18, .theme-red .light .mat-mdc-elevation-specific.mat-elevation-z18,
.theme-red.light .mat-elevation-z18,
.theme-red.light .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-red .light .mat-elevation-z19, .theme-red .light .mat-mdc-elevation-specific.mat-elevation-z19,
.theme-red.light .mat-elevation-z19,
.theme-red.light .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-red .light .mat-elevation-z20, .theme-red .light .mat-mdc-elevation-specific.mat-elevation-z20,
.theme-red.light .mat-elevation-z20,
.theme-red.light .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-red .light .mat-elevation-z21, .theme-red .light .mat-mdc-elevation-specific.mat-elevation-z21,
.theme-red.light .mat-elevation-z21,
.theme-red.light .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-red .light .mat-elevation-z22, .theme-red .light .mat-mdc-elevation-specific.mat-elevation-z22,
.theme-red.light .mat-elevation-z22,
.theme-red.light .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-red .light .mat-elevation-z23, .theme-red .light .mat-mdc-elevation-specific.mat-elevation-z23,
.theme-red.light .mat-elevation-z23,
.theme-red.light .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-red .light .mat-elevation-z24, .theme-red .light .mat-mdc-elevation-specific.mat-elevation-z24,
.theme-red.light .mat-elevation-z24,
.theme-red.light .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-red .light .mat-mdc-progress-bar,
.theme-red.light .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #ef4444;
  --mdc-linear-progress-track-color: rgba(239, 68, 68, 0.25);
}
.theme-red .light .mat-mdc-progress-bar.mat-accent,
.theme-red.light .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1e293b;
  --mdc-linear-progress-track-color: rgba(30, 41, 59, 0.25);
}
.theme-red .light .mat-mdc-progress-bar.mat-warn,
.theme-red.light .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc2626;
  --mdc-linear-progress-track-color: rgba(220, 38, 38, 0.25);
}
.theme-red .light .mat-mdc-form-field.mat-accent,
.theme-red.light .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #1e293b;
  --mdc-filled-text-field-focus-active-indicator-color: #1e293b;
  --mdc-filled-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mdc-outlined-text-field-caret-color: #1e293b;
  --mdc-outlined-text-field-focus-outline-color: #1e293b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(30, 41, 59, 0.87);
}
.theme-red .light .mat-mdc-form-field.mat-warn,
.theme-red.light .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #dc2626;
  --mdc-filled-text-field-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mdc-outlined-text-field-caret-color: #dc2626;
  --mdc-outlined-text-field-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-red .light .mat-mdc-form-field.mat-accent,
.theme-red.light .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(30, 41, 59, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(148, 163, 184, 0.38);
  --mat-select-placeholder-text-color: rgba(30, 41, 59, 0.6);
  --mat-select-enabled-arrow-color: rgba(226, 232, 240, 0.54);
  --mat-select-disabled-arrow-color: rgba(30, 41, 59, 0.38);
  --mat-select-focused-arrow-color: rgba(30, 41, 59, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-red .light .mat-mdc-form-field.mat-warn,
.theme-red.light .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(30, 41, 59, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(148, 163, 184, 0.38);
  --mat-select-placeholder-text-color: rgba(30, 41, 59, 0.6);
  --mat-select-enabled-arrow-color: rgba(226, 232, 240, 0.54);
  --mat-select-disabled-arrow-color: rgba(30, 41, 59, 0.38);
  --mat-select-focused-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-red .light .mat-mdc-standard-chip,
.theme-red.light .mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.theme-red .light .mat-mdc-standard-chip,
.theme-red.light .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.theme-red .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .theme-red .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.theme-red.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.theme-red.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: #450a0a;
  --mdc-chip-elevated-container-color: #ef4444;
  --mdc-chip-elevated-selected-container-color: #ef4444;
  --mdc-chip-elevated-disabled-container-color: #ef4444;
  --mdc-chip-flat-disabled-selected-container-color: #ef4444;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #450a0a;
  --mdc-chip-selected-label-text-color: #450a0a;
  --mdc-chip-with-icon-icon-color: #450a0a;
  --mdc-chip-with-icon-disabled-icon-color: #450a0a;
  --mdc-chip-with-icon-selected-icon-color: #450a0a;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #450a0a;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #450a0a;
  --mat-chip-selected-disabled-trailing-icon-color: #450a0a;
  --mat-chip-selected-trailing-icon-color: #450a0a;
}
.theme-red .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .theme-red .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.theme-red.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.theme-red.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #1e293b;
  --mdc-chip-elevated-selected-container-color: #1e293b;
  --mdc-chip-elevated-disabled-container-color: #1e293b;
  --mdc-chip-flat-disabled-selected-container-color: #1e293b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-red .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .theme-red .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.theme-red.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.theme-red.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-selected-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-flat-disabled-selected-container-color: #dc2626;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-red .light .mat-mdc-slide-toggle,
.theme-red.light .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: #1e293b;
}
.theme-red .light .mat-mdc-slide-toggle.mat-accent,
.theme-red.light .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #475569;
  --mdc-switch-selected-handle-color: #475569;
  --mdc-switch-selected-hover-state-layer-color: #475569;
  --mdc-switch-selected-pressed-state-layer-color: #475569;
  --mdc-switch-selected-focus-handle-color: #0f172a;
  --mdc-switch-selected-hover-handle-color: #0f172a;
  --mdc-switch-selected-pressed-handle-color: #0f172a;
  --mdc-switch-selected-focus-track-color: #cbd5e1;
  --mdc-switch-selected-hover-track-color: #cbd5e1;
  --mdc-switch-selected-pressed-track-color: #cbd5e1;
  --mdc-switch-selected-track-color: #cbd5e1;
}
.theme-red .light .mat-mdc-slide-toggle.mat-warn,
.theme-red.light .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.theme-red .light .mat-mdc-radio-button,
.theme-red.light .mat-mdc-radio-button {
  --mdc-form-field-label-text-color: #1e293b;
}
.theme-red .light .mat-mdc-radio-button.mat-primary,
.theme-red.light .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #ef4444;
  --mdc-radio-selected-hover-icon-color: #ef4444;
  --mdc-radio-selected-icon-color: #ef4444;
  --mdc-radio-selected-pressed-icon-color: #ef4444;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #ef4444;
  --mat-radio-disabled-label-color: #94a3b8;
}
.theme-red .light .mat-mdc-radio-button.mat-accent,
.theme-red.light .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #1e293b;
  --mat-radio-disabled-label-color: #94a3b8;
}
.theme-red .light .mat-mdc-radio-button.mat-warn,
.theme-red.light .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #dc2626;
  --mat-radio-disabled-label-color: #94a3b8;
}
.theme-red .light .mat-accent,
.theme-red.light .mat-accent {
  --mat-slider-ripple-color: #1e293b;
  --mat-slider-hover-state-layer-color: rgba(30, 41, 59, 0.05);
  --mat-slider-focus-state-layer-color: rgba(30, 41, 59, 0.2);
  --mdc-slider-handle-color: #1e293b;
  --mdc-slider-focus-handle-color: #1e293b;
  --mdc-slider-hover-handle-color: #1e293b;
  --mdc-slider-active-track-color: #1e293b;
  --mdc-slider-inactive-track-color: #1e293b;
  --mdc-slider-with-tick-marks-inactive-container-color: #1e293b;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-red .light .mat-warn,
.theme-red.light .mat-warn {
  --mat-slider-ripple-color: #dc2626;
  --mat-slider-hover-state-layer-color: rgba(220, 38, 38, 0.05);
  --mat-slider-focus-state-layer-color: rgba(220, 38, 38, 0.2);
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-red .light .mdc-list-item__start,
.theme-red .light .mdc-list-item__end,
.theme-red.light .mdc-list-item__start,
.theme-red.light .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #ef4444;
  --mdc-radio-selected-hover-icon-color: #ef4444;
  --mdc-radio-selected-icon-color: #ef4444;
  --mdc-radio-selected-pressed-icon-color: #ef4444;
}
.theme-red .light .mat-accent .mdc-list-item__start,
.theme-red .light .mat-accent .mdc-list-item__end,
.theme-red.light .mat-accent .mdc-list-item__start,
.theme-red.light .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
}
.theme-red .light .mat-warn .mdc-list-item__start,
.theme-red .light .mat-warn .mdc-list-item__end,
.theme-red.light .mat-warn .mdc-list-item__start,
.theme-red.light .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
}
.theme-red .light .mat-mdc-list-option,
.theme-red.light .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #450a0a;
  --mdc-checkbox-selected-focus-icon-color: #ef4444;
  --mdc-checkbox-selected-hover-icon-color: #ef4444;
  --mdc-checkbox-selected-icon-color: #ef4444;
  --mdc-checkbox-selected-pressed-icon-color: #ef4444;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ef4444;
  --mdc-checkbox-selected-hover-state-layer-color: #ef4444;
  --mdc-checkbox-selected-pressed-state-layer-color: #ef4444;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-red .light .mat-mdc-list-option.mat-accent,
.theme-red.light .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-red .light .mat-mdc-list-option.mat-warn,
.theme-red.light .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-red .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-red .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-red.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-red.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #ef4444;
}
.theme-red .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-red .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-red.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-red.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #ef4444;
}
.theme-red .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-red .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-red .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.theme-red.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-red.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-red.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.theme-red .light .mat-mdc-tab-group, .theme-red .light .mat-mdc-tab-nav-bar,
.theme-red.light .mat-mdc-tab-group,
.theme-red.light .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #ef4444;
  --mat-tab-header-disabled-ripple-color: #94a3b8;
  --mat-tab-header-pagination-icon-color: #64748b;
  --mat-tab-header-inactive-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-label-text-color: #ef4444;
  --mat-tab-header-active-ripple-color: #ef4444;
  --mat-tab-header-inactive-ripple-color: #ef4444;
  --mat-tab-header-inactive-focus-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ef4444;
  --mat-tab-header-active-hover-label-text-color: #ef4444;
  --mat-tab-header-active-focus-indicator-color: #ef4444;
  --mat-tab-header-active-hover-indicator-color: #ef4444;
}
.theme-red .light .mat-mdc-tab-group.mat-accent, .theme-red .light .mat-mdc-tab-nav-bar.mat-accent,
.theme-red.light .mat-mdc-tab-group.mat-accent,
.theme-red.light .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #1e293b;
  --mat-tab-header-disabled-ripple-color: #94a3b8;
  --mat-tab-header-pagination-icon-color: #64748b;
  --mat-tab-header-inactive-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-label-text-color: #1e293b;
  --mat-tab-header-active-ripple-color: #1e293b;
  --mat-tab-header-inactive-ripple-color: #1e293b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-focus-label-text-color: #1e293b;
  --mat-tab-header-active-hover-label-text-color: #1e293b;
  --mat-tab-header-active-focus-indicator-color: #1e293b;
  --mat-tab-header-active-hover-indicator-color: #1e293b;
}
.theme-red .light .mat-mdc-tab-group.mat-warn, .theme-red .light .mat-mdc-tab-nav-bar.mat-warn,
.theme-red.light .mat-mdc-tab-group.mat-warn,
.theme-red.light .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #dc2626;
  --mat-tab-header-disabled-ripple-color: #94a3b8;
  --mat-tab-header-pagination-icon-color: #64748b;
  --mat-tab-header-inactive-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-label-text-color: #dc2626;
  --mat-tab-header-active-ripple-color: #dc2626;
  --mat-tab-header-inactive-ripple-color: #dc2626;
  --mat-tab-header-inactive-focus-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-focus-label-text-color: #dc2626;
  --mat-tab-header-active-hover-label-text-color: #dc2626;
  --mat-tab-header-active-focus-indicator-color: #dc2626;
  --mat-tab-header-active-hover-indicator-color: #dc2626;
}
.theme-red .light .mat-mdc-tab-group.mat-background-primary, .theme-red .light .mat-mdc-tab-nav-bar.mat-background-primary,
.theme-red.light .mat-mdc-tab-group.mat-background-primary,
.theme-red.light .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #ef4444;
  --mat-tab-header-with-background-foreground-color: #450a0a;
}
.theme-red .light .mat-mdc-tab-group.mat-background-accent, .theme-red .light .mat-mdc-tab-nav-bar.mat-background-accent,
.theme-red.light .mat-mdc-tab-group.mat-background-accent,
.theme-red.light .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #1e293b;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-red .light .mat-mdc-tab-group.mat-background-warn, .theme-red .light .mat-mdc-tab-nav-bar.mat-background-warn,
.theme-red.light .mat-mdc-tab-group.mat-background-warn,
.theme-red.light .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #dc2626;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-red .light .mat-mdc-checkbox,
.theme-red.light .mat-mdc-checkbox {
  --mdc-form-field-label-text-color: #1e293b;
}
.theme-red .light .mat-mdc-checkbox.mat-primary,
.theme-red.light .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #450a0a;
  --mdc-checkbox-selected-focus-icon-color: #ef4444;
  --mdc-checkbox-selected-hover-icon-color: #ef4444;
  --mdc-checkbox-selected-icon-color: #ef4444;
  --mdc-checkbox-selected-pressed-icon-color: #ef4444;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ef4444;
  --mdc-checkbox-selected-hover-state-layer-color: #ef4444;
  --mdc-checkbox-selected-pressed-state-layer-color: #ef4444;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-red .light .mat-mdc-checkbox.mat-warn,
.theme-red.light .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-red .light .mat-mdc-button.mat-primary,
.theme-red.light .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #ef4444;
  --mat-text-button-state-layer-color: #ef4444;
  --mat-text-button-ripple-color: rgba(239, 68, 68, 0.1);
}
.theme-red .light .mat-mdc-button.mat-accent,
.theme-red.light .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1e293b;
  --mat-text-button-state-layer-color: #1e293b;
  --mat-text-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-red .light .mat-mdc-button.mat-warn,
.theme-red.light .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc2626;
  --mat-text-button-state-layer-color: #dc2626;
  --mat-text-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-red .light .mat-mdc-unelevated-button.mat-primary,
.theme-red.light .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #ef4444;
  --mdc-filled-button-label-text-color: #450a0a;
  --mat-filled-button-state-layer-color: #450a0a;
  --mat-filled-button-ripple-color: rgba(69, 10, 10, 0.1);
}
.theme-red .light .mat-mdc-unelevated-button.mat-accent,
.theme-red.light .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-red .light .mat-mdc-unelevated-button.mat-warn,
.theme-red.light .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-red .light .mat-mdc-raised-button.mat-primary,
.theme-red.light .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #ef4444;
  --mdc-protected-button-label-text-color: #450a0a;
  --mat-protected-button-state-layer-color: #450a0a;
  --mat-protected-button-ripple-color: rgba(69, 10, 10, 0.1);
}
.theme-red .light .mat-mdc-raised-button.mat-accent,
.theme-red.light .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-red .light .mat-mdc-raised-button.mat-warn,
.theme-red.light .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-red .light .mat-mdc-outlined-button.mat-primary,
.theme-red.light .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #ef4444;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: #ef4444;
  --mat-outlined-button-ripple-color: rgba(239, 68, 68, 0.1);
}
.theme-red .light .mat-mdc-outlined-button.mat-accent,
.theme-red.light .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1e293b;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: #1e293b;
  --mat-outlined-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-red .light .mat-mdc-outlined-button.mat-warn,
.theme-red.light .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc2626;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: #dc2626;
  --mat-outlined-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-red .light .mat-mdc-icon-button.mat-primary,
.theme-red.light .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #ef4444;
  --mat-icon-button-state-layer-color: #ef4444;
  --mat-icon-button-ripple-color: rgba(239, 68, 68, 0.1);
}
.theme-red .light .mat-mdc-icon-button.mat-accent,
.theme-red.light .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1e293b;
  --mat-icon-button-state-layer-color: #1e293b;
  --mat-icon-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-red .light .mat-mdc-icon-button.mat-warn,
.theme-red.light .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc2626;
  --mat-icon-button-state-layer-color: #dc2626;
  --mat-icon-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-red .light .mat-mdc-fab.mat-primary,
.theme-red.light .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #ef4444;
  --mat-fab-foreground-color: #450a0a;
  --mat-fab-state-layer-color: #450a0a;
  --mat-fab-ripple-color: rgba(69, 10, 10, 0.1);
}
.theme-red .light .mat-mdc-fab.mat-accent,
.theme-red.light .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #1e293b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-red .light .mat-mdc-fab.mat-warn,
.theme-red.light .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #dc2626;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-red .light .mat-mdc-mini-fab.mat-primary,
.theme-red.light .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #ef4444;
  --mat-fab-small-foreground-color: #450a0a;
  --mat-fab-small-state-layer-color: #450a0a;
  --mat-fab-small-ripple-color: rgba(69, 10, 10, 0.1);
}
.theme-red .light .mat-mdc-mini-fab.mat-accent,
.theme-red.light .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #1e293b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-red .light .mat-mdc-mini-fab.mat-warn,
.theme-red.light .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #dc2626;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-red .light .mat-accent,
.theme-red.light .mat-accent {
  --mdc-circular-progress-active-indicator-color: #1e293b;
}
.theme-red .light .mat-warn,
.theme-red.light .mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}
.theme-red .light .mat-badge-accent,
.theme-red.light .mat-badge-accent {
  --mat-badge-background-color: #1e293b;
  --mat-badge-text-color: white;
}
.theme-red .light .mat-badge-warn,
.theme-red.light .mat-badge-warn {
  --mat-badge-background-color: #dc2626;
  --mat-badge-text-color: white;
}
.theme-red .light .mat-datepicker-content.mat-accent,
.theme-red.light .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #1e293b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(30, 41, 59, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(30, 41, 59, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-red .light .mat-datepicker-content.mat-warn,
.theme-red.light .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #dc2626;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(220, 38, 38, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(220, 38, 38, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-red .light .mat-datepicker-toggle-active.mat-accent,
.theme-red.light .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #1e293b;
}
.theme-red .light .mat-datepicker-toggle-active.mat-warn,
.theme-red.light .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #dc2626;
}
.theme-red .light .mat-icon.mat-primary,
.theme-red.light .mat-icon.mat-primary {
  --mat-icon-color: #ef4444;
}
.theme-red .light .mat-icon.mat-accent,
.theme-red.light .mat-icon.mat-accent {
  --mat-icon-color: #1e293b;
}
.theme-red .light .mat-icon.mat-warn,
.theme-red.light .mat-icon.mat-warn {
  --mat-icon-color: #dc2626;
}
.theme-red .light .mat-step-header.mat-accent,
.theme-red.light .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #1e293b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #1e293b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #1e293b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-red .light .mat-step-header.mat-warn,
.theme-red.light .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #dc2626;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #dc2626;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #dc2626;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-red .light .mat-toolbar.mat-primary,
.theme-red.light .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #ef4444;
  --mat-toolbar-container-text-color: #450a0a;
}
.theme-red .light .mat-toolbar.mat-accent,
.theme-red.light .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #1e293b;
  --mat-toolbar-container-text-color: white;
}
.theme-red .light .mat-toolbar.mat-warn,
.theme-red.light .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #dc2626;
  --mat-toolbar-container-text-color: white;
}

.theme-red .dark,
.theme-red.dark {
  --mat-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-option-selected-state-label-text-color: #ef4444;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-optgroup-label-text-color: white;
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
  --mat-app-background-color: #0f172a;
  --mat-app-text-color: white;
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: #1e293b;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: #1e293b;
  --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: #94a3b8;
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #64748b;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #ef4444;
  --mdc-filled-text-field-focus-active-indicator-color: #ef4444;
  --mdc-filled-text-field-focus-label-text-color: rgba(239, 68, 68, 0.87);
  --mdc-filled-text-field-container-color: #273243;
  --mdc-filled-text-field-disabled-container-color: #232d3f;
  --mdc-filled-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(71, 85, 105, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(71, 85, 105, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #dc2626;
  --mdc-filled-text-field-error-focus-label-text-color: #dc2626;
  --mdc-filled-text-field-error-label-text-color: #dc2626;
  --mdc-filled-text-field-error-caret-color: #dc2626;
  --mdc-filled-text-field-active-indicator-color: rgba(241, 245, 249, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(241, 245, 249, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(241, 245, 249, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-hover-active-indicator-color: #dc2626;
  --mdc-outlined-text-field-caret-color: #ef4444;
  --mdc-outlined-text-field-focus-outline-color: #ef4444;
  --mdc-outlined-text-field-focus-label-text-color: rgba(239, 68, 68, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(71, 85, 105, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(71, 85, 105, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-error-caret-color: #dc2626;
  --mdc-outlined-text-field-error-focus-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-hover-label-text-color: #dc2626;
  --mdc-outlined-text-field-outline-color: rgba(241, 245, 249, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(241, 245, 249, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(241, 245, 249, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-error-hover-outline-color: #dc2626;
  --mdc-outlined-text-field-error-outline-color: #dc2626;
  --mat-form-field-focus-select-arrow-color: rgba(239, 68, 68, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(241, 245, 249, 0.38);
  --mat-form-field-state-layer-color: rgba(255, 255, 255, 0.87);
  --mat-form-field-error-text-color: #dc2626;
  --mat-form-field-select-option-text-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-select-disabled-option-text-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(241, 245, 249, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.08;
  --mat-form-field-focus-state-layer-opacity: 0.24;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: #1e293b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(71, 85, 105, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(239, 68, 68, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: #1e293b;
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: #1e293b;
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
  --mdc-switch-selected-focus-state-layer-color: #fca5a5;
  --mdc-switch-selected-handle-color: #fca5a5;
  --mdc-switch-selected-hover-state-layer-color: #fca5a5;
  --mdc-switch-selected-pressed-state-layer-color: #fca5a5;
  --mdc-switch-selected-focus-handle-color: #fecaca;
  --mdc-switch-selected-hover-handle-color: #fecaca;
  --mdc-switch-selected-pressed-handle-color: #fecaca;
  --mdc-switch-selected-focus-track-color: #dc2626;
  --mdc-switch-selected-hover-track-color: #dc2626;
  --mdc-switch-selected-pressed-track-color: #dc2626;
  --mdc-switch-selected-track-color: #dc2626;
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-icon-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-disabled-label-text-color: #475569;
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-color: #ef4444;
  --mdc-slider-focus-handle-color: #ef4444;
  --mdc-slider-hover-handle-color: #ef4444;
  --mdc-slider-active-track-color: #ef4444;
  --mdc-slider-inactive-track-color: #ef4444;
  --mdc-slider-with-tick-marks-inactive-container-color: #ef4444;
  --mdc-slider-with-tick-marks-active-container-color: #450a0a;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-label-container-color: #fff;
  --mdc-slider-label-label-text-color: #000;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: #ef4444;
  --mat-slider-hover-state-layer-color: rgba(239, 68, 68, 0.05);
  --mat-slider-focus-state-layer-color: rgba(239, 68, 68, 0.2);
  --mat-slider-value-indicator-opacity: 0.9;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-item-label-text-color: white;
  --mat-menu-item-icon-color: white;
  --mat-menu-item-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-container-color: #1e293b;
  --mat-menu-divider-color: rgba(241, 245, 249, 0.12);
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 0;
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: #94a3b8;
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: #64748b;
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: white;
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
  --mat-paginator-container-text-color: rgba(255, 255, 255, 0.87);
  --mat-paginator-container-background-color: #1e293b;
  --mat-paginator-enabled-icon-color: rgba(255, 255, 255, 0.54);
  --mat-paginator-disabled-icon-color: rgba(255, 255, 255, 0.12);
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
  --mat-checkbox-disabled-label-color: #475569;
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: white;
  --mdc-text-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mat-text-button-state-layer-color: white;
  --mat-text-button-disabled-state-layer-color: white;
  --mat-text-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.08;
  --mat-text-button-focus-state-layer-opacity: 0.24;
  --mat-text-button-pressed-state-layer-opacity: 0.24;
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: white;
  --mdc-filled-button-disabled-container-color: rgba(30, 41, 59, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-disabled-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.08;
  --mat-filled-button-focus-state-layer-opacity: 0.24;
  --mat-filled-button-pressed-state-layer-opacity: 0.24;
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: white;
  --mdc-protected-button-disabled-container-color: rgba(30, 41, 59, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-disabled-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.08;
  --mat-protected-button-focus-state-layer-opacity: 0.24;
  --mat-protected-button-pressed-state-layer-opacity: 0.24;
  --mdc-outlined-button-disabled-outline-color: rgba(241, 245, 249, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mdc-outlined-button-label-text-color: white;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: white;
  --mat-outlined-button-disabled-state-layer-color: white;
  --mat-outlined-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.08;
  --mat-outlined-button-focus-state-layer-opacity: 0.24;
  --mat-outlined-button-pressed-state-layer-opacity: 0.24;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
  --mat-icon-button-state-layer-color: white;
  --mat-icon-button-disabled-state-layer-color: white;
  --mat-icon-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.08;
  --mat-icon-button-focus-state-layer-opacity: 0.24;
  --mat-icon-button-pressed-state-layer-opacity: 0.24;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-fab-container-color: #1e293b;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-disabled-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.08;
  --mat-fab-focus-state-layer-opacity: 0.24;
  --mat-fab-pressed-state-layer-opacity: 0.24;
  --mat-fab-disabled-state-container-color: rgba(15, 23, 42, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(30, 41, 59, 0.5);
  --mdc-fab-small-container-color: #1e293b;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-disabled-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.08;
  --mat-fab-small-focus-state-layer-opacity: 0.24;
  --mat-fab-small-pressed-state-layer-opacity: 0.24;
  --mat-fab-small-disabled-state-container-color: rgba(15, 23, 42, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(30, 41, 59, 0.5);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #d2d4d8;
  --mdc-snackbar-supporting-text-color: rgba(30, 41, 59, 0.87);
  --mat-snack-bar-button-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: #1e293b;
  --mat-table-header-headline-color: white;
  --mat-table-row-item-label-text-color: white;
  --mat-table-row-item-outline-color: rgba(241, 245, 249, 0.12);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #ef4444;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #ef4444;
  --mat-badge-text-color: #450a0a;
  --mat-badge-disabled-state-background-color: #1e293b;
  --mat-badge-disabled-state-text-color: #475569;
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: white;
  --mat-bottom-sheet-container-background-color: #1e293b;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: #64748b;
  --mat-legacy-button-toggle-state-layer-color: #e2e8f0;
  --mat-legacy-button-toggle-selected-state-text-color: #94a3b8;
  --mat-legacy-button-toggle-selected-state-background-color: rgba(255, 255, 255, 0.05);
  --mat-legacy-button-toggle-disabled-state-text-color: #1e293b;
  --mat-legacy-button-toggle-disabled-state-background-color: #0f172a;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #1e293b;
  --mat-standard-button-toggle-text-color: white;
  --mat-standard-button-toggle-background-color: #1e293b;
  --mat-standard-button-toggle-state-layer-color: #e2e8f0;
  --mat-standard-button-toggle-selected-state-background-color: rgba(255, 255, 255, 0.05);
  --mat-standard-button-toggle-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-state-text-color: #1e293b;
  --mat-standard-button-toggle-disabled-state-background-color: #1e293b;
  --mat-standard-button-toggle-disabled-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #1e293b;
  --mat-standard-button-toggle-divider-color: #374152;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: #450a0a;
  --mat-datepicker-calendar-date-selected-state-background-color: #ef4444;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(239, 68, 68, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #450a0a;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(239, 68, 68, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(239, 68, 68, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #ef4444;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(239, 68, 68, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: #f1f5f9;
  --mat-datepicker-calendar-body-label-text-color: #94a3b8;
  --mat-datepicker-calendar-period-button-text-color: white;
  --mat-datepicker-calendar-period-button-icon-color: #f1f5f9;
  --mat-datepicker-calendar-navigation-button-icon-color: #f1f5f9;
  --mat-datepicker-calendar-header-divider-color: rgba(241, 245, 249, 0.12);
  --mat-datepicker-calendar-header-text-color: #94a3b8;
  --mat-datepicker-calendar-date-today-outline-color: #64748b;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(100, 116, 139, 0.8);
  --mat-datepicker-calendar-date-text-color: white;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: #475569;
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(241, 245, 249, 0.24);
  --mat-datepicker-range-input-separator-color: white;
  --mat-datepicker-range-input-disabled-state-separator-color: #475569;
  --mat-datepicker-range-input-disabled-state-text-color: #475569;
  --mat-datepicker-calendar-container-background-color: #1e293b;
  --mat-datepicker-calendar-container-text-color: white;
  --mat-divider-width: 1px;
  --mat-divider-color: rgba(241, 245, 249, 0.12);
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: #1e293b;
  --mat-expansion-container-text-color: white;
  --mat-expansion-actions-divider-color: rgba(241, 245, 249, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-expansion-header-focus-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-expansion-header-disabled-state-text-color: #1e293b;
  --mat-expansion-header-text-color: white;
  --mat-expansion-header-description-color: #94a3b8;
  --mat-expansion-header-indicator-color: #94a3b8;
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: rgba(241, 245, 249, 0.12);
  --mat-sidenav-container-background-color: #1e293b;
  --mat-sidenav-container-text-color: white;
  --mat-sidenav-content-background-color: #0f172a;
  --mat-sidenav-content-text-color: white;
  --mat-sidenav-scrim-color: rgba(225, 214, 196, 0.6);
  --mat-stepper-header-icon-foreground-color: #450a0a;
  --mat-stepper-header-selected-state-icon-background-color: #ef4444;
  --mat-stepper-header-selected-state-icon-foreground-color: #450a0a;
  --mat-stepper-header-done-state-icon-background-color: #ef4444;
  --mat-stepper-header-done-state-icon-foreground-color: #450a0a;
  --mat-stepper-header-edit-state-icon-background-color: #ef4444;
  --mat-stepper-header-edit-state-icon-foreground-color: #450a0a;
  --mat-stepper-container-color: #1e293b;
  --mat-stepper-line-color: rgba(241, 245, 249, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-stepper-header-focus-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-stepper-header-label-text-color: #94a3b8;
  --mat-stepper-header-optional-label-text-color: #94a3b8;
  --mat-stepper-header-selected-state-label-text-color: white;
  --mat-stepper-header-error-state-label-text-color: #dc2626;
  --mat-stepper-header-icon-background-color: #94a3b8;
  --mat-stepper-header-error-state-icon-foreground-color: #dc2626;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #94a3b8;
  --mat-toolbar-container-background-color: #0f172a;
  --mat-toolbar-container-text-color: white;
  --mat-tree-container-background-color: #1e293b;
  --mat-tree-node-text-color: white;
}
.theme-red .dark .mat-accent,
.theme-red.dark .mat-accent {
  --mat-option-selected-state-label-text-color: #1e293b;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.theme-red .dark .mat-warn,
.theme-red.dark .mat-warn {
  --mat-option-selected-state-label-text-color: #dc2626;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.theme-red .dark .mat-primary,
.theme-red.dark .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #ef4444;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #ef4444;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-red .dark .mat-accent,
.theme-red.dark .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-red .dark .mat-warn,
.theme-red.dark .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #dc2626;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #dc2626;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-red .dark .mat-elevation-z0, .theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z0,
.theme-red.dark .mat-elevation-z0,
.theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-red .dark .mat-elevation-z1, .theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z1,
.theme-red.dark .mat-elevation-z1,
.theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-red .dark .mat-elevation-z2, .theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z2,
.theme-red.dark .mat-elevation-z2,
.theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-red .dark .mat-elevation-z3, .theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z3,
.theme-red.dark .mat-elevation-z3,
.theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-red .dark .mat-elevation-z4, .theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z4,
.theme-red.dark .mat-elevation-z4,
.theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-red .dark .mat-elevation-z5, .theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z5,
.theme-red.dark .mat-elevation-z5,
.theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-red .dark .mat-elevation-z6, .theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z6,
.theme-red.dark .mat-elevation-z6,
.theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-red .dark .mat-elevation-z7, .theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z7,
.theme-red.dark .mat-elevation-z7,
.theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-red .dark .mat-elevation-z8, .theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z8,
.theme-red.dark .mat-elevation-z8,
.theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-red .dark .mat-elevation-z9, .theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z9,
.theme-red.dark .mat-elevation-z9,
.theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-red .dark .mat-elevation-z10, .theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z10,
.theme-red.dark .mat-elevation-z10,
.theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-red .dark .mat-elevation-z11, .theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z11,
.theme-red.dark .mat-elevation-z11,
.theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-red .dark .mat-elevation-z12, .theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z12,
.theme-red.dark .mat-elevation-z12,
.theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-red .dark .mat-elevation-z13, .theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z13,
.theme-red.dark .mat-elevation-z13,
.theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-red .dark .mat-elevation-z14, .theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z14,
.theme-red.dark .mat-elevation-z14,
.theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-red .dark .mat-elevation-z15, .theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z15,
.theme-red.dark .mat-elevation-z15,
.theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-red .dark .mat-elevation-z16, .theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z16,
.theme-red.dark .mat-elevation-z16,
.theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-red .dark .mat-elevation-z17, .theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z17,
.theme-red.dark .mat-elevation-z17,
.theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-red .dark .mat-elevation-z18, .theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z18,
.theme-red.dark .mat-elevation-z18,
.theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-red .dark .mat-elevation-z19, .theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z19,
.theme-red.dark .mat-elevation-z19,
.theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-red .dark .mat-elevation-z20, .theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z20,
.theme-red.dark .mat-elevation-z20,
.theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-red .dark .mat-elevation-z21, .theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z21,
.theme-red.dark .mat-elevation-z21,
.theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-red .dark .mat-elevation-z22, .theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z22,
.theme-red.dark .mat-elevation-z22,
.theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-red .dark .mat-elevation-z23, .theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z23,
.theme-red.dark .mat-elevation-z23,
.theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-red .dark .mat-elevation-z24, .theme-red .dark .mat-mdc-elevation-specific.mat-elevation-z24,
.theme-red.dark .mat-elevation-z24,
.theme-red.dark .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-red .dark .mat-mdc-progress-bar,
.theme-red.dark .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #ef4444;
  --mdc-linear-progress-track-color: rgba(239, 68, 68, 0.25);
}
.theme-red .dark .mat-mdc-progress-bar.mat-accent,
.theme-red.dark .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1e293b;
  --mdc-linear-progress-track-color: rgba(30, 41, 59, 0.25);
}
.theme-red .dark .mat-mdc-progress-bar.mat-warn,
.theme-red.dark .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc2626;
  --mdc-linear-progress-track-color: rgba(220, 38, 38, 0.25);
}
.theme-red .dark .mat-mdc-form-field.mat-accent,
.theme-red.dark .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #1e293b;
  --mdc-filled-text-field-focus-active-indicator-color: #1e293b;
  --mdc-filled-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mdc-outlined-text-field-caret-color: #1e293b;
  --mdc-outlined-text-field-focus-outline-color: #1e293b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(30, 41, 59, 0.87);
}
.theme-red .dark .mat-mdc-form-field.mat-warn,
.theme-red.dark .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #dc2626;
  --mdc-filled-text-field-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mdc-outlined-text-field-caret-color: #dc2626;
  --mdc-outlined-text-field-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-red .dark .mat-mdc-form-field.mat-accent,
.theme-red.dark .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: #1e293b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(71, 85, 105, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(30, 41, 59, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-red .dark .mat-mdc-form-field.mat-warn,
.theme-red.dark .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: #1e293b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(71, 85, 105, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-red .dark .mat-mdc-standard-chip,
.theme-red.dark .mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.theme-red .dark .mat-mdc-standard-chip,
.theme-red.dark .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-elevated-container-color: #394353;
  --mdc-chip-elevated-selected-container-color: #394353;
  --mdc-chip-elevated-disabled-container-color: #394353;
  --mdc-chip-flat-disabled-selected-container-color: #394353;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-selected-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
  --mat-chip-selected-disabled-trailing-icon-color: #fafafa;
  --mat-chip-selected-trailing-icon-color: #fafafa;
}
.theme-red .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .theme-red .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.theme-red.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.theme-red.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: #450a0a;
  --mdc-chip-elevated-container-color: #ef4444;
  --mdc-chip-elevated-selected-container-color: #ef4444;
  --mdc-chip-elevated-disabled-container-color: #ef4444;
  --mdc-chip-flat-disabled-selected-container-color: #ef4444;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #450a0a;
  --mdc-chip-selected-label-text-color: #450a0a;
  --mdc-chip-with-icon-icon-color: #450a0a;
  --mdc-chip-with-icon-disabled-icon-color: #450a0a;
  --mdc-chip-with-icon-selected-icon-color: #450a0a;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #450a0a;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #450a0a;
  --mat-chip-selected-disabled-trailing-icon-color: #450a0a;
  --mat-chip-selected-trailing-icon-color: #450a0a;
}
.theme-red .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .theme-red .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.theme-red.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.theme-red.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #1e293b;
  --mdc-chip-elevated-selected-container-color: #1e293b;
  --mdc-chip-elevated-disabled-container-color: #1e293b;
  --mdc-chip-flat-disabled-selected-container-color: #1e293b;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-red .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .theme-red .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.theme-red.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.theme-red.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-selected-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-flat-disabled-selected-container-color: #dc2626;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-red .dark .mat-mdc-slide-toggle,
.theme-red.dark .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: white;
}
.theme-red .dark .mat-mdc-slide-toggle.mat-accent,
.theme-red.dark .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #cbd5e1;
  --mdc-switch-selected-handle-color: #cbd5e1;
  --mdc-switch-selected-hover-state-layer-color: #cbd5e1;
  --mdc-switch-selected-pressed-state-layer-color: #cbd5e1;
  --mdc-switch-selected-focus-handle-color: #e2e8f0;
  --mdc-switch-selected-hover-handle-color: #e2e8f0;
  --mdc-switch-selected-pressed-handle-color: #e2e8f0;
  --mdc-switch-selected-focus-track-color: #475569;
  --mdc-switch-selected-hover-track-color: #475569;
  --mdc-switch-selected-pressed-track-color: #475569;
  --mdc-switch-selected-track-color: #475569;
}
.theme-red .dark .mat-mdc-slide-toggle.mat-warn,
.theme-red.dark .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #fca5a5;
  --mdc-switch-selected-handle-color: #fca5a5;
  --mdc-switch-selected-hover-state-layer-color: #fca5a5;
  --mdc-switch-selected-pressed-state-layer-color: #fca5a5;
  --mdc-switch-selected-focus-handle-color: #fecaca;
  --mdc-switch-selected-hover-handle-color: #fecaca;
  --mdc-switch-selected-pressed-handle-color: #fecaca;
  --mdc-switch-selected-focus-track-color: #dc2626;
  --mdc-switch-selected-hover-track-color: #dc2626;
  --mdc-switch-selected-pressed-track-color: #dc2626;
  --mdc-switch-selected-track-color: #dc2626;
}
.theme-red .dark .mat-mdc-radio-button,
.theme-red.dark .mat-mdc-radio-button {
  --mdc-form-field-label-text-color: white;
}
.theme-red .dark .mat-mdc-radio-button.mat-primary,
.theme-red.dark .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #ef4444;
  --mdc-radio-selected-hover-icon-color: #ef4444;
  --mdc-radio-selected-icon-color: #ef4444;
  --mdc-radio-selected-pressed-icon-color: #ef4444;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #ef4444;
  --mat-radio-disabled-label-color: #475569;
}
.theme-red .dark .mat-mdc-radio-button.mat-accent,
.theme-red.dark .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #1e293b;
  --mat-radio-disabled-label-color: #475569;
}
.theme-red .dark .mat-mdc-radio-button.mat-warn,
.theme-red.dark .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #dc2626;
  --mat-radio-disabled-label-color: #475569;
}
.theme-red .dark .mat-accent,
.theme-red.dark .mat-accent {
  --mat-slider-ripple-color: #1e293b;
  --mat-slider-hover-state-layer-color: rgba(30, 41, 59, 0.05);
  --mat-slider-focus-state-layer-color: rgba(30, 41, 59, 0.2);
  --mdc-slider-handle-color: #1e293b;
  --mdc-slider-focus-handle-color: #1e293b;
  --mdc-slider-hover-handle-color: #1e293b;
  --mdc-slider-active-track-color: #1e293b;
  --mdc-slider-inactive-track-color: #1e293b;
  --mdc-slider-with-tick-marks-inactive-container-color: #1e293b;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-red .dark .mat-warn,
.theme-red.dark .mat-warn {
  --mat-slider-ripple-color: #dc2626;
  --mat-slider-hover-state-layer-color: rgba(220, 38, 38, 0.05);
  --mat-slider-focus-state-layer-color: rgba(220, 38, 38, 0.2);
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-red .dark .mdc-list-item__start,
.theme-red .dark .mdc-list-item__end,
.theme-red.dark .mdc-list-item__start,
.theme-red.dark .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #ef4444;
  --mdc-radio-selected-hover-icon-color: #ef4444;
  --mdc-radio-selected-icon-color: #ef4444;
  --mdc-radio-selected-pressed-icon-color: #ef4444;
}
.theme-red .dark .mat-accent .mdc-list-item__start,
.theme-red .dark .mat-accent .mdc-list-item__end,
.theme-red.dark .mat-accent .mdc-list-item__start,
.theme-red.dark .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
}
.theme-red .dark .mat-warn .mdc-list-item__start,
.theme-red .dark .mat-warn .mdc-list-item__end,
.theme-red.dark .mat-warn .mdc-list-item__start,
.theme-red.dark .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
}
.theme-red .dark .mat-mdc-list-option,
.theme-red.dark .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #450a0a;
  --mdc-checkbox-selected-focus-icon-color: #ef4444;
  --mdc-checkbox-selected-hover-icon-color: #ef4444;
  --mdc-checkbox-selected-icon-color: #ef4444;
  --mdc-checkbox-selected-pressed-icon-color: #ef4444;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ef4444;
  --mdc-checkbox-selected-hover-state-layer-color: #ef4444;
  --mdc-checkbox-selected-pressed-state-layer-color: #ef4444;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-red .dark .mat-mdc-list-option.mat-accent,
.theme-red.dark .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-red .dark .mat-mdc-list-option.mat-warn,
.theme-red.dark .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-red .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-red .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-red.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-red.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #ef4444;
}
.theme-red .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-red .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-red.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-red.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #ef4444;
}
.theme-red .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-red .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-red .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.theme-red.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-red.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-red.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.theme-red .dark .mat-mdc-tab-group, .theme-red .dark .mat-mdc-tab-nav-bar,
.theme-red.dark .mat-mdc-tab-group,
.theme-red.dark .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #ef4444;
  --mat-tab-header-disabled-ripple-color: #475569;
  --mat-tab-header-pagination-icon-color: #f1f5f9;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #ef4444;
  --mat-tab-header-active-ripple-color: #ef4444;
  --mat-tab-header-inactive-ripple-color: #ef4444;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ef4444;
  --mat-tab-header-active-hover-label-text-color: #ef4444;
  --mat-tab-header-active-focus-indicator-color: #ef4444;
  --mat-tab-header-active-hover-indicator-color: #ef4444;
}
.theme-red .dark .mat-mdc-tab-group.mat-accent, .theme-red .dark .mat-mdc-tab-nav-bar.mat-accent,
.theme-red.dark .mat-mdc-tab-group.mat-accent,
.theme-red.dark .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #1e293b;
  --mat-tab-header-disabled-ripple-color: #475569;
  --mat-tab-header-pagination-icon-color: #f1f5f9;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #1e293b;
  --mat-tab-header-active-ripple-color: #1e293b;
  --mat-tab-header-inactive-ripple-color: #1e293b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #1e293b;
  --mat-tab-header-active-hover-label-text-color: #1e293b;
  --mat-tab-header-active-focus-indicator-color: #1e293b;
  --mat-tab-header-active-hover-indicator-color: #1e293b;
}
.theme-red .dark .mat-mdc-tab-group.mat-warn, .theme-red .dark .mat-mdc-tab-nav-bar.mat-warn,
.theme-red.dark .mat-mdc-tab-group.mat-warn,
.theme-red.dark .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #dc2626;
  --mat-tab-header-disabled-ripple-color: #475569;
  --mat-tab-header-pagination-icon-color: #f1f5f9;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #dc2626;
  --mat-tab-header-active-ripple-color: #dc2626;
  --mat-tab-header-inactive-ripple-color: #dc2626;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #dc2626;
  --mat-tab-header-active-hover-label-text-color: #dc2626;
  --mat-tab-header-active-focus-indicator-color: #dc2626;
  --mat-tab-header-active-hover-indicator-color: #dc2626;
}
.theme-red .dark .mat-mdc-tab-group.mat-background-primary, .theme-red .dark .mat-mdc-tab-nav-bar.mat-background-primary,
.theme-red.dark .mat-mdc-tab-group.mat-background-primary,
.theme-red.dark .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #ef4444;
  --mat-tab-header-with-background-foreground-color: #450a0a;
}
.theme-red .dark .mat-mdc-tab-group.mat-background-accent, .theme-red .dark .mat-mdc-tab-nav-bar.mat-background-accent,
.theme-red.dark .mat-mdc-tab-group.mat-background-accent,
.theme-red.dark .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #1e293b;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-red .dark .mat-mdc-tab-group.mat-background-warn, .theme-red .dark .mat-mdc-tab-nav-bar.mat-background-warn,
.theme-red.dark .mat-mdc-tab-group.mat-background-warn,
.theme-red.dark .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #dc2626;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-red .dark .mat-mdc-checkbox,
.theme-red.dark .mat-mdc-checkbox {
  --mdc-form-field-label-text-color: white;
}
.theme-red .dark .mat-mdc-checkbox.mat-primary,
.theme-red.dark .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #450a0a;
  --mdc-checkbox-selected-focus-icon-color: #ef4444;
  --mdc-checkbox-selected-hover-icon-color: #ef4444;
  --mdc-checkbox-selected-icon-color: #ef4444;
  --mdc-checkbox-selected-pressed-icon-color: #ef4444;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ef4444;
  --mdc-checkbox-selected-hover-state-layer-color: #ef4444;
  --mdc-checkbox-selected-pressed-state-layer-color: #ef4444;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-red .dark .mat-mdc-checkbox.mat-warn,
.theme-red.dark .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-red .dark .mat-mdc-button.mat-primary,
.theme-red.dark .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #ef4444;
  --mat-text-button-state-layer-color: #ef4444;
  --mat-text-button-ripple-color: rgba(239, 68, 68, 0.1);
}
.theme-red .dark .mat-mdc-button.mat-accent,
.theme-red.dark .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1e293b;
  --mat-text-button-state-layer-color: #1e293b;
  --mat-text-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-red .dark .mat-mdc-button.mat-warn,
.theme-red.dark .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc2626;
  --mat-text-button-state-layer-color: #dc2626;
  --mat-text-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-red .dark .mat-mdc-unelevated-button.mat-primary,
.theme-red.dark .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #ef4444;
  --mdc-filled-button-label-text-color: #450a0a;
  --mat-filled-button-state-layer-color: #450a0a;
  --mat-filled-button-ripple-color: rgba(69, 10, 10, 0.1);
}
.theme-red .dark .mat-mdc-unelevated-button.mat-accent,
.theme-red.dark .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-red .dark .mat-mdc-unelevated-button.mat-warn,
.theme-red.dark .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-red .dark .mat-mdc-raised-button.mat-primary,
.theme-red.dark .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #ef4444;
  --mdc-protected-button-label-text-color: #450a0a;
  --mat-protected-button-state-layer-color: #450a0a;
  --mat-protected-button-ripple-color: rgba(69, 10, 10, 0.1);
}
.theme-red .dark .mat-mdc-raised-button.mat-accent,
.theme-red.dark .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-red .dark .mat-mdc-raised-button.mat-warn,
.theme-red.dark .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-red .dark .mat-mdc-outlined-button.mat-primary,
.theme-red.dark .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #ef4444;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: #ef4444;
  --mat-outlined-button-ripple-color: rgba(239, 68, 68, 0.1);
}
.theme-red .dark .mat-mdc-outlined-button.mat-accent,
.theme-red.dark .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1e293b;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: #1e293b;
  --mat-outlined-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-red .dark .mat-mdc-outlined-button.mat-warn,
.theme-red.dark .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc2626;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: #dc2626;
  --mat-outlined-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-red .dark .mat-mdc-icon-button.mat-primary,
.theme-red.dark .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #ef4444;
  --mat-icon-button-state-layer-color: #ef4444;
  --mat-icon-button-ripple-color: rgba(239, 68, 68, 0.1);
}
.theme-red .dark .mat-mdc-icon-button.mat-accent,
.theme-red.dark .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1e293b;
  --mat-icon-button-state-layer-color: #1e293b;
  --mat-icon-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-red .dark .mat-mdc-icon-button.mat-warn,
.theme-red.dark .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc2626;
  --mat-icon-button-state-layer-color: #dc2626;
  --mat-icon-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-red .dark .mat-mdc-fab.mat-primary,
.theme-red.dark .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #ef4444;
  --mat-fab-foreground-color: #450a0a;
  --mat-fab-state-layer-color: #450a0a;
  --mat-fab-ripple-color: rgba(69, 10, 10, 0.1);
}
.theme-red .dark .mat-mdc-fab.mat-accent,
.theme-red.dark .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #1e293b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-red .dark .mat-mdc-fab.mat-warn,
.theme-red.dark .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #dc2626;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-red .dark .mat-mdc-mini-fab.mat-primary,
.theme-red.dark .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #ef4444;
  --mat-fab-small-foreground-color: #450a0a;
  --mat-fab-small-state-layer-color: #450a0a;
  --mat-fab-small-ripple-color: rgba(69, 10, 10, 0.1);
}
.theme-red .dark .mat-mdc-mini-fab.mat-accent,
.theme-red.dark .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #1e293b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-red .dark .mat-mdc-mini-fab.mat-warn,
.theme-red.dark .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #dc2626;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-red .dark .mat-accent,
.theme-red.dark .mat-accent {
  --mdc-circular-progress-active-indicator-color: #1e293b;
}
.theme-red .dark .mat-warn,
.theme-red.dark .mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}
.theme-red .dark .mat-badge-accent,
.theme-red.dark .mat-badge-accent {
  --mat-badge-background-color: #1e293b;
  --mat-badge-text-color: white;
}
.theme-red .dark .mat-badge-warn,
.theme-red.dark .mat-badge-warn {
  --mat-badge-background-color: #dc2626;
  --mat-badge-text-color: white;
}
.theme-red .dark .mat-datepicker-content.mat-accent,
.theme-red.dark .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #1e293b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(30, 41, 59, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(30, 41, 59, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-red .dark .mat-datepicker-content.mat-warn,
.theme-red.dark .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #dc2626;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(220, 38, 38, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(220, 38, 38, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-red .dark .mat-datepicker-toggle-active.mat-accent,
.theme-red.dark .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #1e293b;
}
.theme-red .dark .mat-datepicker-toggle-active.mat-warn,
.theme-red.dark .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #dc2626;
}
.theme-red .dark .mat-icon.mat-primary,
.theme-red.dark .mat-icon.mat-primary {
  --mat-icon-color: #ef4444;
}
.theme-red .dark .mat-icon.mat-accent,
.theme-red.dark .mat-icon.mat-accent {
  --mat-icon-color: #1e293b;
}
.theme-red .dark .mat-icon.mat-warn,
.theme-red.dark .mat-icon.mat-warn {
  --mat-icon-color: #dc2626;
}
.theme-red .dark .mat-step-header.mat-accent,
.theme-red.dark .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #1e293b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #1e293b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #1e293b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-red .dark .mat-step-header.mat-warn,
.theme-red.dark .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #dc2626;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #dc2626;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #dc2626;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-red .dark .mat-toolbar.mat-primary,
.theme-red.dark .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #ef4444;
  --mat-toolbar-container-text-color: #450a0a;
}
.theme-red .dark .mat-toolbar.mat-accent,
.theme-red.dark .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #1e293b;
  --mat-toolbar-container-text-color: white;
}
.theme-red .dark .mat-toolbar.mat-warn,
.theme-red.dark .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #dc2626;
  --mat-toolbar-container-text-color: white;
}

/* Generate the palettes */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define the Angular Material theme */
/* Replace the default colors on the defined Material palette */
/* Define a light & dark Angular Material theme with the generated palettes */
/* Merge the custom base colors with the generated themes */
/* Generate and encapsulate Angular Material themes */
.theme-orange .light,
.theme-orange.light {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-option-selected-state-label-text-color: #f97316;
  --mat-option-label-text-color: #1e293b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-optgroup-label-text-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
  --mat-app-background-color: #f1f5f9;
  --mat-app-text-color: #1e293b;
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: #64748b;
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #1e293b;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #f97316;
  --mdc-filled-text-field-focus-active-indicator-color: #f97316;
  --mdc-filled-text-field-focus-label-text-color: rgba(249, 115, 22, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(30, 41, 59, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(148, 163, 184, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(30, 41, 59, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #dc2626;
  --mdc-filled-text-field-error-focus-label-text-color: #dc2626;
  --mdc-filled-text-field-error-label-text-color: #dc2626;
  --mdc-filled-text-field-error-caret-color: #dc2626;
  --mdc-filled-text-field-active-indicator-color: rgba(226, 232, 240, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(226, 232, 240, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(226, 232, 240, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-hover-active-indicator-color: #dc2626;
  --mdc-outlined-text-field-caret-color: #f97316;
  --mdc-outlined-text-field-focus-outline-color: #f97316;
  --mdc-outlined-text-field-focus-label-text-color: rgba(249, 115, 22, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(30, 41, 59, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(148, 163, 184, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(30, 41, 59, 0.6);
  --mdc-outlined-text-field-error-caret-color: #dc2626;
  --mdc-outlined-text-field-error-focus-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-hover-label-text-color: #dc2626;
  --mdc-outlined-text-field-outline-color: rgba(226, 232, 240, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(226, 232, 240, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(226, 232, 240, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-error-hover-outline-color: #dc2626;
  --mdc-outlined-text-field-error-outline-color: #dc2626;
  --mat-form-field-focus-select-arrow-color: rgba(249, 115, 22, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(100, 116, 139, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #dc2626;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(100, 116, 139, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(100, 116, 139, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(30, 41, 59, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(148, 163, 184, 0.38);
  --mat-select-placeholder-text-color: rgba(30, 41, 59, 0.6);
  --mat-select-enabled-arrow-color: rgba(226, 232, 240, 0.54);
  --mat-select-disabled-arrow-color: rgba(30, 41, 59, 0.38);
  --mat-select-focused-arrow-color: rgba(249, 115, 22, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: white;
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(30, 41, 59, 0.87);
  --mdc-dialog-supporting-text-color: rgba(30, 41, 59, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
  --mdc-switch-selected-focus-state-layer-color: #ea580c;
  --mdc-switch-selected-handle-color: #ea580c;
  --mdc-switch-selected-hover-state-layer-color: #ea580c;
  --mdc-switch-selected-pressed-state-layer-color: #ea580c;
  --mdc-switch-selected-focus-handle-color: #7c2d12;
  --mdc-switch-selected-hover-handle-color: #7c2d12;
  --mdc-switch-selected-pressed-handle-color: #7c2d12;
  --mdc-switch-selected-focus-track-color: #fdba74;
  --mdc-switch-selected-hover-track-color: #fdba74;
  --mdc-switch-selected-pressed-track-color: #fdba74;
  --mdc-switch-selected-track-color: #fdba74;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-disabled-label-text-color: #94a3b8;
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-color: #f97316;
  --mdc-slider-focus-handle-color: #f97316;
  --mdc-slider-hover-handle-color: #f97316;
  --mdc-slider-active-track-color: #f97316;
  --mdc-slider-inactive-track-color: #f97316;
  --mdc-slider-with-tick-marks-inactive-container-color: #f97316;
  --mdc-slider-with-tick-marks-active-container-color: #431407;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: #f97316;
  --mat-slider-hover-state-layer-color: rgba(249, 115, 22, 0.05);
  --mat-slider-focus-state-layer-color: rgba(249, 115, 22, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-item-label-text-color: #1e293b;
  --mat-menu-item-icon-color: #1e293b;
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: #e2e8f0;
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 0;
  --mdc-list-list-item-label-text-color: #1e293b;
  --mdc-list-list-item-supporting-text-color: #64748b;
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: #94a3b8;
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: #1e293b;
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: #1e293b;
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: #94a3b8;
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: #1e293b;
  --mdc-text-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: #1e293b;
  --mdc-filled-button-disabled-container-color: rgba(148, 163, 184, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: #1e293b;
  --mdc-protected-button-disabled-container-color: rgba(148, 163, 184, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: #e2e8f0;
  --mdc-outlined-button-disabled-label-text-color: rgba(148, 163, 184, 0.38);
  --mdc-outlined-button-label-text-color: #1e293b;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-fab-container-color: white;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(148, 163, 184, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(148, 163, 184, 0.38);
  --mdc-fab-small-container-color: white;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(148, 163, 184, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(148, 163, 184, 0.38);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #1e293b;
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: white;
  --mat-table-header-headline-color: #1e293b;
  --mat-table-row-item-label-text-color: #1e293b;
  --mat-table-row-item-outline-color: #e2e8f0;
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #f97316;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #f97316;
  --mat-badge-text-color: #431407;
  --mat-badge-disabled-state-background-color: #94a3b8;
  --mat-badge-disabled-state-text-color: #94a3b8;
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: #1e293b;
  --mat-bottom-sheet-container-background-color: white;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: #94a3b8;
  --mat-legacy-button-toggle-state-layer-color: #64748b;
  --mat-legacy-button-toggle-selected-state-text-color: #64748b;
  --mat-legacy-button-toggle-selected-state-background-color: #e2e8f0;
  --mat-legacy-button-toggle-disabled-state-text-color: #94a3b8;
  --mat-legacy-button-toggle-disabled-state-background-color: #cbd5e1;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #e2e8f0;
  --mat-standard-button-toggle-text-color: #1e293b;
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: #64748b;
  --mat-standard-button-toggle-selected-state-background-color: #e2e8f0;
  --mat-standard-button-toggle-selected-state-text-color: #1e293b;
  --mat-standard-button-toggle-disabled-state-text-color: #94a3b8;
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: #1e293b;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #e2e8f0;
  --mat-standard-button-toggle-divider-color: #e2e8f0;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: #431407;
  --mat-datepicker-calendar-date-selected-state-background-color: #f97316;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(249, 115, 22, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #431407;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(249, 115, 22, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(249, 115, 22, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #f97316;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(249, 115, 22, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: #64748b;
  --mat-datepicker-calendar-body-label-text-color: #64748b;
  --mat-datepicker-calendar-period-button-text-color: #1e293b;
  --mat-datepicker-calendar-period-button-icon-color: #64748b;
  --mat-datepicker-calendar-navigation-button-icon-color: #64748b;
  --mat-datepicker-calendar-header-divider-color: #e2e8f0;
  --mat-datepicker-calendar-header-text-color: #64748b;
  --mat-datepicker-calendar-date-today-outline-color: #94a3b8;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(148, 163, 184, 0.8);
  --mat-datepicker-calendar-date-text-color: #1e293b;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: #94a3b8;
  --mat-datepicker-calendar-date-preview-state-outline-color: #e2e8f0;
  --mat-datepicker-range-input-separator-color: #1e293b;
  --mat-datepicker-range-input-disabled-state-separator-color: #94a3b8;
  --mat-datepicker-range-input-disabled-state-text-color: #94a3b8;
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: #1e293b;
  --mat-divider-width: 1px;
  --mat-divider-color: #e2e8f0;
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: #1e293b;
  --mat-expansion-actions-divider-color: #e2e8f0;
  --mat-expansion-header-hover-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-expansion-header-focus-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-expansion-header-disabled-state-text-color: #94a3b8;
  --mat-expansion-header-text-color: #1e293b;
  --mat-expansion-header-description-color: #64748b;
  --mat-expansion-header-indicator-color: #64748b;
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: #e2e8f0;
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: #1e293b;
  --mat-sidenav-content-background-color: #f1f5f9;
  --mat-sidenav-content-text-color: #1e293b;
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
  --mat-stepper-header-icon-foreground-color: #431407;
  --mat-stepper-header-selected-state-icon-background-color: #f97316;
  --mat-stepper-header-selected-state-icon-foreground-color: #431407;
  --mat-stepper-header-done-state-icon-background-color: #f97316;
  --mat-stepper-header-done-state-icon-foreground-color: #431407;
  --mat-stepper-header-edit-state-icon-background-color: #f97316;
  --mat-stepper-header-edit-state-icon-foreground-color: #431407;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: #e2e8f0;
  --mat-stepper-header-hover-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-stepper-header-focus-state-layer-color: rgba(148, 163, 184, 0.12);
  --mat-stepper-header-label-text-color: #64748b;
  --mat-stepper-header-optional-label-text-color: #64748b;
  --mat-stepper-header-selected-state-label-text-color: #1e293b;
  --mat-stepper-header-error-state-label-text-color: #dc2626;
  --mat-stepper-header-icon-background-color: #64748b;
  --mat-stepper-header-error-state-icon-foreground-color: #dc2626;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #64748b;
  --mat-toolbar-container-background-color: white;
  --mat-toolbar-container-text-color: #1e293b;
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: #1e293b;
}
.theme-orange .light .mat-accent,
.theme-orange.light .mat-accent {
  --mat-option-selected-state-label-text-color: #1e293b;
  --mat-option-label-text-color: #1e293b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.theme-orange .light .mat-warn,
.theme-orange.light .mat-warn {
  --mat-option-selected-state-label-text-color: #dc2626;
  --mat-option-label-text-color: #1e293b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}
.theme-orange .light .mat-primary,
.theme-orange.light .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #f97316;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f97316;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-orange .light .mat-accent,
.theme-orange.light .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-orange .light .mat-warn,
.theme-orange.light .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #dc2626;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-unselected-icon-color: #64748b;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #f1f5f9;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #dc2626;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
.theme-orange .light .mat-elevation-z0, .theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z0,
.theme-orange.light .mat-elevation-z0,
.theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-orange .light .mat-elevation-z1, .theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z1,
.theme-orange.light .mat-elevation-z1,
.theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-orange .light .mat-elevation-z2, .theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z2,
.theme-orange.light .mat-elevation-z2,
.theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-orange .light .mat-elevation-z3, .theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z3,
.theme-orange.light .mat-elevation-z3,
.theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-orange .light .mat-elevation-z4, .theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z4,
.theme-orange.light .mat-elevation-z4,
.theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-orange .light .mat-elevation-z5, .theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z5,
.theme-orange.light .mat-elevation-z5,
.theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-orange .light .mat-elevation-z6, .theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z6,
.theme-orange.light .mat-elevation-z6,
.theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-orange .light .mat-elevation-z7, .theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z7,
.theme-orange.light .mat-elevation-z7,
.theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-orange .light .mat-elevation-z8, .theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z8,
.theme-orange.light .mat-elevation-z8,
.theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-orange .light .mat-elevation-z9, .theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z9,
.theme-orange.light .mat-elevation-z9,
.theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-orange .light .mat-elevation-z10, .theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z10,
.theme-orange.light .mat-elevation-z10,
.theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-orange .light .mat-elevation-z11, .theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z11,
.theme-orange.light .mat-elevation-z11,
.theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-orange .light .mat-elevation-z12, .theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z12,
.theme-orange.light .mat-elevation-z12,
.theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-orange .light .mat-elevation-z13, .theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z13,
.theme-orange.light .mat-elevation-z13,
.theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-orange .light .mat-elevation-z14, .theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z14,
.theme-orange.light .mat-elevation-z14,
.theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-orange .light .mat-elevation-z15, .theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z15,
.theme-orange.light .mat-elevation-z15,
.theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-orange .light .mat-elevation-z16, .theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z16,
.theme-orange.light .mat-elevation-z16,
.theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-orange .light .mat-elevation-z17, .theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z17,
.theme-orange.light .mat-elevation-z17,
.theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-orange .light .mat-elevation-z18, .theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z18,
.theme-orange.light .mat-elevation-z18,
.theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-orange .light .mat-elevation-z19, .theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z19,
.theme-orange.light .mat-elevation-z19,
.theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-orange .light .mat-elevation-z20, .theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z20,
.theme-orange.light .mat-elevation-z20,
.theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-orange .light .mat-elevation-z21, .theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z21,
.theme-orange.light .mat-elevation-z21,
.theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-orange .light .mat-elevation-z22, .theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z22,
.theme-orange.light .mat-elevation-z22,
.theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-orange .light .mat-elevation-z23, .theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z23,
.theme-orange.light .mat-elevation-z23,
.theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-orange .light .mat-elevation-z24, .theme-orange .light .mat-mdc-elevation-specific.mat-elevation-z24,
.theme-orange.light .mat-elevation-z24,
.theme-orange.light .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-orange .light .mat-mdc-progress-bar,
.theme-orange.light .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #f97316;
  --mdc-linear-progress-track-color: rgba(249, 115, 22, 0.25);
}
.theme-orange .light .mat-mdc-progress-bar.mat-accent,
.theme-orange.light .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1e293b;
  --mdc-linear-progress-track-color: rgba(30, 41, 59, 0.25);
}
.theme-orange .light .mat-mdc-progress-bar.mat-warn,
.theme-orange.light .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc2626;
  --mdc-linear-progress-track-color: rgba(220, 38, 38, 0.25);
}
.theme-orange .light .mat-mdc-form-field.mat-accent,
.theme-orange.light .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #1e293b;
  --mdc-filled-text-field-focus-active-indicator-color: #1e293b;
  --mdc-filled-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mdc-outlined-text-field-caret-color: #1e293b;
  --mdc-outlined-text-field-focus-outline-color: #1e293b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(30, 41, 59, 0.87);
}
.theme-orange .light .mat-mdc-form-field.mat-warn,
.theme-orange.light .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #dc2626;
  --mdc-filled-text-field-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mdc-outlined-text-field-caret-color: #dc2626;
  --mdc-outlined-text-field-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-orange .light .mat-mdc-form-field.mat-accent,
.theme-orange.light .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(30, 41, 59, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(148, 163, 184, 0.38);
  --mat-select-placeholder-text-color: rgba(30, 41, 59, 0.6);
  --mat-select-enabled-arrow-color: rgba(226, 232, 240, 0.54);
  --mat-select-disabled-arrow-color: rgba(30, 41, 59, 0.38);
  --mat-select-focused-arrow-color: rgba(30, 41, 59, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-orange .light .mat-mdc-form-field.mat-warn,
.theme-orange.light .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(30, 41, 59, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(148, 163, 184, 0.38);
  --mat-select-placeholder-text-color: rgba(30, 41, 59, 0.6);
  --mat-select-enabled-arrow-color: rgba(226, 232, 240, 0.54);
  --mat-select-disabled-arrow-color: rgba(30, 41, 59, 0.38);
  --mat-select-focused-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-orange .light .mat-mdc-standard-chip,
.theme-orange.light .mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.theme-orange .light .mat-mdc-standard-chip,
.theme-orange.light .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.theme-orange .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .theme-orange .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.theme-orange.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.theme-orange.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: #431407;
  --mdc-chip-elevated-container-color: #f97316;
  --mdc-chip-elevated-selected-container-color: #f97316;
  --mdc-chip-elevated-disabled-container-color: #f97316;
  --mdc-chip-flat-disabled-selected-container-color: #f97316;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #431407;
  --mdc-chip-selected-label-text-color: #431407;
  --mdc-chip-with-icon-icon-color: #431407;
  --mdc-chip-with-icon-disabled-icon-color: #431407;
  --mdc-chip-with-icon-selected-icon-color: #431407;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #431407;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #431407;
  --mat-chip-selected-disabled-trailing-icon-color: #431407;
  --mat-chip-selected-trailing-icon-color: #431407;
}
.theme-orange .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .theme-orange .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.theme-orange.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.theme-orange.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #1e293b;
  --mdc-chip-elevated-selected-container-color: #1e293b;
  --mdc-chip-elevated-disabled-container-color: #1e293b;
  --mdc-chip-flat-disabled-selected-container-color: #1e293b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-orange .light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .theme-orange .light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.theme-orange.light .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.theme-orange.light .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-selected-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-flat-disabled-selected-container-color: #dc2626;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-orange .light .mat-mdc-slide-toggle,
.theme-orange.light .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: #1e293b;
}
.theme-orange .light .mat-mdc-slide-toggle.mat-accent,
.theme-orange.light .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #475569;
  --mdc-switch-selected-handle-color: #475569;
  --mdc-switch-selected-hover-state-layer-color: #475569;
  --mdc-switch-selected-pressed-state-layer-color: #475569;
  --mdc-switch-selected-focus-handle-color: #0f172a;
  --mdc-switch-selected-hover-handle-color: #0f172a;
  --mdc-switch-selected-pressed-handle-color: #0f172a;
  --mdc-switch-selected-focus-track-color: #cbd5e1;
  --mdc-switch-selected-hover-track-color: #cbd5e1;
  --mdc-switch-selected-pressed-track-color: #cbd5e1;
  --mdc-switch-selected-track-color: #cbd5e1;
}
.theme-orange .light .mat-mdc-slide-toggle.mat-warn,
.theme-orange.light .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #dc2626;
  --mdc-switch-selected-handle-color: #dc2626;
  --mdc-switch-selected-hover-state-layer-color: #dc2626;
  --mdc-switch-selected-pressed-state-layer-color: #dc2626;
  --mdc-switch-selected-focus-handle-color: #7f1d1d;
  --mdc-switch-selected-hover-handle-color: #7f1d1d;
  --mdc-switch-selected-pressed-handle-color: #7f1d1d;
  --mdc-switch-selected-focus-track-color: #fca5a5;
  --mdc-switch-selected-hover-track-color: #fca5a5;
  --mdc-switch-selected-pressed-track-color: #fca5a5;
  --mdc-switch-selected-track-color: #fca5a5;
}
.theme-orange .light .mat-mdc-radio-button,
.theme-orange.light .mat-mdc-radio-button {
  --mdc-form-field-label-text-color: #1e293b;
}
.theme-orange .light .mat-mdc-radio-button.mat-primary,
.theme-orange.light .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #f97316;
  --mdc-radio-selected-hover-icon-color: #f97316;
  --mdc-radio-selected-icon-color: #f97316;
  --mdc-radio-selected-pressed-icon-color: #f97316;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #f97316;
  --mat-radio-disabled-label-color: #94a3b8;
}
.theme-orange .light .mat-mdc-radio-button.mat-accent,
.theme-orange.light .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #1e293b;
  --mat-radio-disabled-label-color: #94a3b8;
}
.theme-orange .light .mat-mdc-radio-button.mat-warn,
.theme-orange.light .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #dc2626;
  --mat-radio-disabled-label-color: #94a3b8;
}
.theme-orange .light .mat-accent,
.theme-orange.light .mat-accent {
  --mat-slider-ripple-color: #1e293b;
  --mat-slider-hover-state-layer-color: rgba(30, 41, 59, 0.05);
  --mat-slider-focus-state-layer-color: rgba(30, 41, 59, 0.2);
  --mdc-slider-handle-color: #1e293b;
  --mdc-slider-focus-handle-color: #1e293b;
  --mdc-slider-hover-handle-color: #1e293b;
  --mdc-slider-active-track-color: #1e293b;
  --mdc-slider-inactive-track-color: #1e293b;
  --mdc-slider-with-tick-marks-inactive-container-color: #1e293b;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-orange .light .mat-warn,
.theme-orange.light .mat-warn {
  --mat-slider-ripple-color: #dc2626;
  --mat-slider-hover-state-layer-color: rgba(220, 38, 38, 0.05);
  --mat-slider-focus-state-layer-color: rgba(220, 38, 38, 0.2);
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-orange .light .mdc-list-item__start,
.theme-orange .light .mdc-list-item__end,
.theme-orange.light .mdc-list-item__start,
.theme-orange.light .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #f97316;
  --mdc-radio-selected-hover-icon-color: #f97316;
  --mdc-radio-selected-icon-color: #f97316;
  --mdc-radio-selected-pressed-icon-color: #f97316;
}
.theme-orange .light .mat-accent .mdc-list-item__start,
.theme-orange .light .mat-accent .mdc-list-item__end,
.theme-orange.light .mat-accent .mdc-list-item__start,
.theme-orange.light .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
}
.theme-orange .light .mat-warn .mdc-list-item__start,
.theme-orange .light .mat-warn .mdc-list-item__end,
.theme-orange.light .mat-warn .mdc-list-item__start,
.theme-orange.light .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #64748b;
  --mdc-radio-disabled-unselected-icon-color: #64748b;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(100, 116, 139, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
}
.theme-orange .light .mat-mdc-list-option,
.theme-orange.light .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #431407;
  --mdc-checkbox-selected-focus-icon-color: #f97316;
  --mdc-checkbox-selected-hover-icon-color: #f97316;
  --mdc-checkbox-selected-icon-color: #f97316;
  --mdc-checkbox-selected-pressed-icon-color: #f97316;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f97316;
  --mdc-checkbox-selected-hover-state-layer-color: #f97316;
  --mdc-checkbox-selected-pressed-state-layer-color: #f97316;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-orange .light .mat-mdc-list-option.mat-accent,
.theme-orange.light .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-orange .light .mat-mdc-list-option.mat-warn,
.theme-orange.light .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-orange .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-orange .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-orange.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-orange.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #f97316;
}
.theme-orange .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-orange .light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-orange.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-orange.light .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #f97316;
}
.theme-orange .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-orange .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-orange .light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.theme-orange.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-orange.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-orange.light .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.theme-orange .light .mat-mdc-tab-group, .theme-orange .light .mat-mdc-tab-nav-bar,
.theme-orange.light .mat-mdc-tab-group,
.theme-orange.light .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #f97316;
  --mat-tab-header-disabled-ripple-color: #94a3b8;
  --mat-tab-header-pagination-icon-color: #64748b;
  --mat-tab-header-inactive-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-label-text-color: #f97316;
  --mat-tab-header-active-ripple-color: #f97316;
  --mat-tab-header-inactive-ripple-color: #f97316;
  --mat-tab-header-inactive-focus-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f97316;
  --mat-tab-header-active-hover-label-text-color: #f97316;
  --mat-tab-header-active-focus-indicator-color: #f97316;
  --mat-tab-header-active-hover-indicator-color: #f97316;
}
.theme-orange .light .mat-mdc-tab-group.mat-accent, .theme-orange .light .mat-mdc-tab-nav-bar.mat-accent,
.theme-orange.light .mat-mdc-tab-group.mat-accent,
.theme-orange.light .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #1e293b;
  --mat-tab-header-disabled-ripple-color: #94a3b8;
  --mat-tab-header-pagination-icon-color: #64748b;
  --mat-tab-header-inactive-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-label-text-color: #1e293b;
  --mat-tab-header-active-ripple-color: #1e293b;
  --mat-tab-header-inactive-ripple-color: #1e293b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-focus-label-text-color: #1e293b;
  --mat-tab-header-active-hover-label-text-color: #1e293b;
  --mat-tab-header-active-focus-indicator-color: #1e293b;
  --mat-tab-header-active-hover-indicator-color: #1e293b;
}
.theme-orange .light .mat-mdc-tab-group.mat-warn, .theme-orange .light .mat-mdc-tab-nav-bar.mat-warn,
.theme-orange.light .mat-mdc-tab-group.mat-warn,
.theme-orange.light .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #dc2626;
  --mat-tab-header-disabled-ripple-color: #94a3b8;
  --mat-tab-header-pagination-icon-color: #64748b;
  --mat-tab-header-inactive-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-label-text-color: #dc2626;
  --mat-tab-header-active-ripple-color: #dc2626;
  --mat-tab-header-inactive-ripple-color: #dc2626;
  --mat-tab-header-inactive-focus-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(30, 41, 59, 0.6);
  --mat-tab-header-active-focus-label-text-color: #dc2626;
  --mat-tab-header-active-hover-label-text-color: #dc2626;
  --mat-tab-header-active-focus-indicator-color: #dc2626;
  --mat-tab-header-active-hover-indicator-color: #dc2626;
}
.theme-orange .light .mat-mdc-tab-group.mat-background-primary, .theme-orange .light .mat-mdc-tab-nav-bar.mat-background-primary,
.theme-orange.light .mat-mdc-tab-group.mat-background-primary,
.theme-orange.light .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #f97316;
  --mat-tab-header-with-background-foreground-color: #431407;
}
.theme-orange .light .mat-mdc-tab-group.mat-background-accent, .theme-orange .light .mat-mdc-tab-nav-bar.mat-background-accent,
.theme-orange.light .mat-mdc-tab-group.mat-background-accent,
.theme-orange.light .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #1e293b;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-orange .light .mat-mdc-tab-group.mat-background-warn, .theme-orange .light .mat-mdc-tab-nav-bar.mat-background-warn,
.theme-orange.light .mat-mdc-tab-group.mat-background-warn,
.theme-orange.light .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #dc2626;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-orange .light .mat-mdc-checkbox,
.theme-orange.light .mat-mdc-checkbox {
  --mdc-form-field-label-text-color: #1e293b;
}
.theme-orange .light .mat-mdc-checkbox.mat-primary,
.theme-orange.light .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #431407;
  --mdc-checkbox-selected-focus-icon-color: #f97316;
  --mdc-checkbox-selected-hover-icon-color: #f97316;
  --mdc-checkbox-selected-icon-color: #f97316;
  --mdc-checkbox-selected-pressed-icon-color: #f97316;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f97316;
  --mdc-checkbox-selected-hover-state-layer-color: #f97316;
  --mdc-checkbox-selected-pressed-state-layer-color: #f97316;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-orange .light .mat-mdc-checkbox.mat-warn,
.theme-orange.light .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.theme-orange .light .mat-mdc-button.mat-primary,
.theme-orange.light .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #f97316;
  --mat-text-button-state-layer-color: #f97316;
  --mat-text-button-ripple-color: rgba(249, 115, 22, 0.1);
}
.theme-orange .light .mat-mdc-button.mat-accent,
.theme-orange.light .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1e293b;
  --mat-text-button-state-layer-color: #1e293b;
  --mat-text-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-orange .light .mat-mdc-button.mat-warn,
.theme-orange.light .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc2626;
  --mat-text-button-state-layer-color: #dc2626;
  --mat-text-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-orange .light .mat-mdc-unelevated-button.mat-primary,
.theme-orange.light .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #f97316;
  --mdc-filled-button-label-text-color: #431407;
  --mat-filled-button-state-layer-color: #431407;
  --mat-filled-button-ripple-color: rgba(67, 20, 7, 0.1);
}
.theme-orange .light .mat-mdc-unelevated-button.mat-accent,
.theme-orange.light .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-orange .light .mat-mdc-unelevated-button.mat-warn,
.theme-orange.light .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-orange .light .mat-mdc-raised-button.mat-primary,
.theme-orange.light .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #f97316;
  --mdc-protected-button-label-text-color: #431407;
  --mat-protected-button-state-layer-color: #431407;
  --mat-protected-button-ripple-color: rgba(67, 20, 7, 0.1);
}
.theme-orange .light .mat-mdc-raised-button.mat-accent,
.theme-orange.light .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-orange .light .mat-mdc-raised-button.mat-warn,
.theme-orange.light .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-orange .light .mat-mdc-outlined-button.mat-primary,
.theme-orange.light .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #f97316;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: #f97316;
  --mat-outlined-button-ripple-color: rgba(249, 115, 22, 0.1);
}
.theme-orange .light .mat-mdc-outlined-button.mat-accent,
.theme-orange.light .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1e293b;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: #1e293b;
  --mat-outlined-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-orange .light .mat-mdc-outlined-button.mat-warn,
.theme-orange.light .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc2626;
  --mdc-outlined-button-outline-color: #e2e8f0;
  --mat-outlined-button-state-layer-color: #dc2626;
  --mat-outlined-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-orange .light .mat-mdc-icon-button.mat-primary,
.theme-orange.light .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #f97316;
  --mat-icon-button-state-layer-color: #f97316;
  --mat-icon-button-ripple-color: rgba(249, 115, 22, 0.1);
}
.theme-orange .light .mat-mdc-icon-button.mat-accent,
.theme-orange.light .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1e293b;
  --mat-icon-button-state-layer-color: #1e293b;
  --mat-icon-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-orange .light .mat-mdc-icon-button.mat-warn,
.theme-orange.light .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc2626;
  --mat-icon-button-state-layer-color: #dc2626;
  --mat-icon-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-orange .light .mat-mdc-fab.mat-primary,
.theme-orange.light .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #f97316;
  --mat-fab-foreground-color: #431407;
  --mat-fab-state-layer-color: #431407;
  --mat-fab-ripple-color: rgba(67, 20, 7, 0.1);
}
.theme-orange .light .mat-mdc-fab.mat-accent,
.theme-orange.light .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #1e293b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-orange .light .mat-mdc-fab.mat-warn,
.theme-orange.light .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #dc2626;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-orange .light .mat-mdc-mini-fab.mat-primary,
.theme-orange.light .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #f97316;
  --mat-fab-small-foreground-color: #431407;
  --mat-fab-small-state-layer-color: #431407;
  --mat-fab-small-ripple-color: rgba(67, 20, 7, 0.1);
}
.theme-orange .light .mat-mdc-mini-fab.mat-accent,
.theme-orange.light .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #1e293b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-orange .light .mat-mdc-mini-fab.mat-warn,
.theme-orange.light .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #dc2626;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-orange .light .mat-accent,
.theme-orange.light .mat-accent {
  --mdc-circular-progress-active-indicator-color: #1e293b;
}
.theme-orange .light .mat-warn,
.theme-orange.light .mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}
.theme-orange .light .mat-badge-accent,
.theme-orange.light .mat-badge-accent {
  --mat-badge-background-color: #1e293b;
  --mat-badge-text-color: white;
}
.theme-orange .light .mat-badge-warn,
.theme-orange.light .mat-badge-warn {
  --mat-badge-background-color: #dc2626;
  --mat-badge-text-color: white;
}
.theme-orange .light .mat-datepicker-content.mat-accent,
.theme-orange.light .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #1e293b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(30, 41, 59, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(30, 41, 59, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-orange .light .mat-datepicker-content.mat-warn,
.theme-orange.light .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #dc2626;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(220, 38, 38, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(220, 38, 38, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-orange .light .mat-datepicker-toggle-active.mat-accent,
.theme-orange.light .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #1e293b;
}
.theme-orange .light .mat-datepicker-toggle-active.mat-warn,
.theme-orange.light .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #dc2626;
}
.theme-orange .light .mat-icon.mat-primary,
.theme-orange.light .mat-icon.mat-primary {
  --mat-icon-color: #f97316;
}
.theme-orange .light .mat-icon.mat-accent,
.theme-orange.light .mat-icon.mat-accent {
  --mat-icon-color: #1e293b;
}
.theme-orange .light .mat-icon.mat-warn,
.theme-orange.light .mat-icon.mat-warn {
  --mat-icon-color: #dc2626;
}
.theme-orange .light .mat-step-header.mat-accent,
.theme-orange.light .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #1e293b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #1e293b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #1e293b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-orange .light .mat-step-header.mat-warn,
.theme-orange.light .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #dc2626;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #dc2626;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #dc2626;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-orange .light .mat-toolbar.mat-primary,
.theme-orange.light .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #f97316;
  --mat-toolbar-container-text-color: #431407;
}
.theme-orange .light .mat-toolbar.mat-accent,
.theme-orange.light .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #1e293b;
  --mat-toolbar-container-text-color: white;
}
.theme-orange .light .mat-toolbar.mat-warn,
.theme-orange.light .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #dc2626;
  --mat-toolbar-container-text-color: white;
}

.theme-orange .dark,
.theme-orange.dark {
  --mat-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-option-selected-state-label-text-color: #f97316;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-optgroup-label-text-color: white;
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
  --mat-app-background-color: #0f172a;
  --mat-app-text-color: white;
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: #1e293b;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: #1e293b;
  --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: #94a3b8;
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #64748b;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #f97316;
  --mdc-filled-text-field-focus-active-indicator-color: #f97316;
  --mdc-filled-text-field-focus-label-text-color: rgba(249, 115, 22, 0.87);
  --mdc-filled-text-field-container-color: #273243;
  --mdc-filled-text-field-disabled-container-color: #232d3f;
  --mdc-filled-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(71, 85, 105, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(71, 85, 105, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #dc2626;
  --mdc-filled-text-field-error-focus-label-text-color: #dc2626;
  --mdc-filled-text-field-error-label-text-color: #dc2626;
  --mdc-filled-text-field-error-caret-color: #dc2626;
  --mdc-filled-text-field-active-indicator-color: rgba(241, 245, 249, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(241, 245, 249, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(241, 245, 249, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-error-hover-active-indicator-color: #dc2626;
  --mdc-outlined-text-field-caret-color: #f97316;
  --mdc-outlined-text-field-focus-outline-color: #f97316;
  --mdc-outlined-text-field-focus-label-text-color: rgba(249, 115, 22, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(71, 85, 105, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(71, 85, 105, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-error-caret-color: #dc2626;
  --mdc-outlined-text-field-error-focus-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-label-text-color: #dc2626;
  --mdc-outlined-text-field-error-hover-label-text-color: #dc2626;
  --mdc-outlined-text-field-outline-color: rgba(241, 245, 249, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(241, 245, 249, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(241, 245, 249, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-error-hover-outline-color: #dc2626;
  --mdc-outlined-text-field-error-outline-color: #dc2626;
  --mat-form-field-focus-select-arrow-color: rgba(249, 115, 22, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(241, 245, 249, 0.38);
  --mat-form-field-state-layer-color: rgba(255, 255, 255, 0.87);
  --mat-form-field-error-text-color: #dc2626;
  --mat-form-field-select-option-text-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-select-disabled-option-text-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(241, 245, 249, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.08;
  --mat-form-field-focus-state-layer-opacity: 0.24;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: #1e293b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(71, 85, 105, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(249, 115, 22, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: #1e293b;
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: #1e293b;
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
  --mdc-switch-selected-focus-state-layer-color: #fdba74;
  --mdc-switch-selected-handle-color: #fdba74;
  --mdc-switch-selected-hover-state-layer-color: #fdba74;
  --mdc-switch-selected-pressed-state-layer-color: #fdba74;
  --mdc-switch-selected-focus-handle-color: #fed7aa;
  --mdc-switch-selected-hover-handle-color: #fed7aa;
  --mdc-switch-selected-pressed-handle-color: #fed7aa;
  --mdc-switch-selected-focus-track-color: #ea580c;
  --mdc-switch-selected-hover-track-color: #ea580c;
  --mdc-switch-selected-pressed-track-color: #ea580c;
  --mdc-switch-selected-track-color: #ea580c;
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-icon-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-disabled-label-text-color: #475569;
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-color: #f97316;
  --mdc-slider-focus-handle-color: #f97316;
  --mdc-slider-hover-handle-color: #f97316;
  --mdc-slider-active-track-color: #f97316;
  --mdc-slider-inactive-track-color: #f97316;
  --mdc-slider-with-tick-marks-inactive-container-color: #f97316;
  --mdc-slider-with-tick-marks-active-container-color: #431407;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-label-container-color: #fff;
  --mdc-slider-label-label-text-color: #000;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: #f97316;
  --mat-slider-hover-state-layer-color: rgba(249, 115, 22, 0.05);
  --mat-slider-focus-state-layer-color: rgba(249, 115, 22, 0.2);
  --mat-slider-value-indicator-opacity: 0.9;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-item-label-text-color: white;
  --mat-menu-item-icon-color: white;
  --mat-menu-item-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-container-color: #1e293b;
  --mat-menu-divider-color: rgba(241, 245, 249, 0.12);
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 0;
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: #94a3b8;
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: #64748b;
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: white;
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
  --mat-paginator-container-text-color: rgba(255, 255, 255, 0.87);
  --mat-paginator-container-background-color: #1e293b;
  --mat-paginator-enabled-icon-color: rgba(255, 255, 255, 0.54);
  --mat-paginator-disabled-icon-color: rgba(255, 255, 255, 0.12);
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
  --mat-checkbox-disabled-label-color: #475569;
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: white;
  --mdc-text-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mat-text-button-state-layer-color: white;
  --mat-text-button-disabled-state-layer-color: white;
  --mat-text-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.08;
  --mat-text-button-focus-state-layer-opacity: 0.24;
  --mat-text-button-pressed-state-layer-opacity: 0.24;
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: white;
  --mdc-filled-button-disabled-container-color: rgba(30, 41, 59, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-disabled-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.08;
  --mat-filled-button-focus-state-layer-opacity: 0.24;
  --mat-filled-button-pressed-state-layer-opacity: 0.24;
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: white;
  --mdc-protected-button-disabled-container-color: rgba(30, 41, 59, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-disabled-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.08;
  --mat-protected-button-focus-state-layer-opacity: 0.24;
  --mat-protected-button-pressed-state-layer-opacity: 0.24;
  --mdc-outlined-button-disabled-outline-color: rgba(241, 245, 249, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(30, 41, 59, 0.5);
  --mdc-outlined-button-label-text-color: white;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: white;
  --mat-outlined-button-disabled-state-layer-color: white;
  --mat-outlined-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.08;
  --mat-outlined-button-focus-state-layer-opacity: 0.24;
  --mat-outlined-button-pressed-state-layer-opacity: 0.24;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
  --mat-icon-button-state-layer-color: white;
  --mat-icon-button-disabled-state-layer-color: white;
  --mat-icon-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.08;
  --mat-icon-button-focus-state-layer-opacity: 0.24;
  --mat-icon-button-pressed-state-layer-opacity: 0.24;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-fab-container-color: #1e293b;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-disabled-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.08;
  --mat-fab-focus-state-layer-opacity: 0.24;
  --mat-fab-pressed-state-layer-opacity: 0.24;
  --mat-fab-disabled-state-container-color: rgba(15, 23, 42, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(30, 41, 59, 0.5);
  --mdc-fab-small-container-color: #1e293b;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-disabled-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.08;
  --mat-fab-small-focus-state-layer-opacity: 0.24;
  --mat-fab-small-pressed-state-layer-opacity: 0.24;
  --mat-fab-small-disabled-state-container-color: rgba(15, 23, 42, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(30, 41, 59, 0.5);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #d2d4d8;
  --mdc-snackbar-supporting-text-color: rgba(30, 41, 59, 0.87);
  --mat-snack-bar-button-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: #1e293b;
  --mat-table-header-headline-color: white;
  --mat-table-row-item-label-text-color: white;
  --mat-table-row-item-outline-color: rgba(241, 245, 249, 0.12);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #f97316;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #f97316;
  --mat-badge-text-color: #431407;
  --mat-badge-disabled-state-background-color: #1e293b;
  --mat-badge-disabled-state-text-color: #475569;
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: white;
  --mat-bottom-sheet-container-background-color: #1e293b;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: #64748b;
  --mat-legacy-button-toggle-state-layer-color: #e2e8f0;
  --mat-legacy-button-toggle-selected-state-text-color: #94a3b8;
  --mat-legacy-button-toggle-selected-state-background-color: rgba(255, 255, 255, 0.05);
  --mat-legacy-button-toggle-disabled-state-text-color: #1e293b;
  --mat-legacy-button-toggle-disabled-state-background-color: #0f172a;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #1e293b;
  --mat-standard-button-toggle-text-color: white;
  --mat-standard-button-toggle-background-color: #1e293b;
  --mat-standard-button-toggle-state-layer-color: #e2e8f0;
  --mat-standard-button-toggle-selected-state-background-color: rgba(255, 255, 255, 0.05);
  --mat-standard-button-toggle-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-state-text-color: #1e293b;
  --mat-standard-button-toggle-disabled-state-background-color: #1e293b;
  --mat-standard-button-toggle-disabled-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #1e293b;
  --mat-standard-button-toggle-divider-color: #374152;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: #431407;
  --mat-datepicker-calendar-date-selected-state-background-color: #f97316;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(249, 115, 22, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #431407;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(249, 115, 22, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(249, 115, 22, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #f97316;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(249, 115, 22, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: #f1f5f9;
  --mat-datepicker-calendar-body-label-text-color: #94a3b8;
  --mat-datepicker-calendar-period-button-text-color: white;
  --mat-datepicker-calendar-period-button-icon-color: #f1f5f9;
  --mat-datepicker-calendar-navigation-button-icon-color: #f1f5f9;
  --mat-datepicker-calendar-header-divider-color: rgba(241, 245, 249, 0.12);
  --mat-datepicker-calendar-header-text-color: #94a3b8;
  --mat-datepicker-calendar-date-today-outline-color: #64748b;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(100, 116, 139, 0.8);
  --mat-datepicker-calendar-date-text-color: white;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: #475569;
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(241, 245, 249, 0.24);
  --mat-datepicker-range-input-separator-color: white;
  --mat-datepicker-range-input-disabled-state-separator-color: #475569;
  --mat-datepicker-range-input-disabled-state-text-color: #475569;
  --mat-datepicker-calendar-container-background-color: #1e293b;
  --mat-datepicker-calendar-container-text-color: white;
  --mat-divider-width: 1px;
  --mat-divider-color: rgba(241, 245, 249, 0.12);
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: #1e293b;
  --mat-expansion-container-text-color: white;
  --mat-expansion-actions-divider-color: rgba(241, 245, 249, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-expansion-header-focus-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-expansion-header-disabled-state-text-color: #1e293b;
  --mat-expansion-header-text-color: white;
  --mat-expansion-header-description-color: #94a3b8;
  --mat-expansion-header-indicator-color: #94a3b8;
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: rgba(241, 245, 249, 0.12);
  --mat-sidenav-container-background-color: #1e293b;
  --mat-sidenav-container-text-color: white;
  --mat-sidenav-content-background-color: #0f172a;
  --mat-sidenav-content-text-color: white;
  --mat-sidenav-scrim-color: rgba(225, 214, 196, 0.6);
  --mat-stepper-header-icon-foreground-color: #431407;
  --mat-stepper-header-selected-state-icon-background-color: #f97316;
  --mat-stepper-header-selected-state-icon-foreground-color: #431407;
  --mat-stepper-header-done-state-icon-background-color: #f97316;
  --mat-stepper-header-done-state-icon-foreground-color: #431407;
  --mat-stepper-header-edit-state-icon-background-color: #f97316;
  --mat-stepper-header-edit-state-icon-foreground-color: #431407;
  --mat-stepper-container-color: #1e293b;
  --mat-stepper-line-color: rgba(241, 245, 249, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-stepper-header-focus-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-stepper-header-label-text-color: #94a3b8;
  --mat-stepper-header-optional-label-text-color: #94a3b8;
  --mat-stepper-header-selected-state-label-text-color: white;
  --mat-stepper-header-error-state-label-text-color: #dc2626;
  --mat-stepper-header-icon-background-color: #94a3b8;
  --mat-stepper-header-error-state-icon-foreground-color: #dc2626;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #94a3b8;
  --mat-toolbar-container-background-color: #0f172a;
  --mat-toolbar-container-text-color: white;
  --mat-tree-container-background-color: #1e293b;
  --mat-tree-node-text-color: white;
}
.theme-orange .dark .mat-accent,
.theme-orange.dark .mat-accent {
  --mat-option-selected-state-label-text-color: #1e293b;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.theme-orange .dark .mat-warn,
.theme-orange.dark .mat-warn {
  --mat-option-selected-state-label-text-color: #dc2626;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.theme-orange .dark .mat-primary,
.theme-orange.dark .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #f97316;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f97316;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-orange .dark .mat-accent,
.theme-orange.dark .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #1e293b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1e293b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-orange .dark .mat-warn,
.theme-orange.dark .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #dc2626;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-unselected-icon-color: #94a3b8;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #0f172a;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #dc2626;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-orange .dark .mat-elevation-z0, .theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z0,
.theme-orange.dark .mat-elevation-z0,
.theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-orange .dark .mat-elevation-z1, .theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z1,
.theme-orange.dark .mat-elevation-z1,
.theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-orange .dark .mat-elevation-z2, .theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z2,
.theme-orange.dark .mat-elevation-z2,
.theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-orange .dark .mat-elevation-z3, .theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z3,
.theme-orange.dark .mat-elevation-z3,
.theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-orange .dark .mat-elevation-z4, .theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z4,
.theme-orange.dark .mat-elevation-z4,
.theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-orange .dark .mat-elevation-z5, .theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z5,
.theme-orange.dark .mat-elevation-z5,
.theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-orange .dark .mat-elevation-z6, .theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z6,
.theme-orange.dark .mat-elevation-z6,
.theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-orange .dark .mat-elevation-z7, .theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z7,
.theme-orange.dark .mat-elevation-z7,
.theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-orange .dark .mat-elevation-z8, .theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z8,
.theme-orange.dark .mat-elevation-z8,
.theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-orange .dark .mat-elevation-z9, .theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z9,
.theme-orange.dark .mat-elevation-z9,
.theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-orange .dark .mat-elevation-z10, .theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z10,
.theme-orange.dark .mat-elevation-z10,
.theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-orange .dark .mat-elevation-z11, .theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z11,
.theme-orange.dark .mat-elevation-z11,
.theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-orange .dark .mat-elevation-z12, .theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z12,
.theme-orange.dark .mat-elevation-z12,
.theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-orange .dark .mat-elevation-z13, .theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z13,
.theme-orange.dark .mat-elevation-z13,
.theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-orange .dark .mat-elevation-z14, .theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z14,
.theme-orange.dark .mat-elevation-z14,
.theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-orange .dark .mat-elevation-z15, .theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z15,
.theme-orange.dark .mat-elevation-z15,
.theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-orange .dark .mat-elevation-z16, .theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z16,
.theme-orange.dark .mat-elevation-z16,
.theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-orange .dark .mat-elevation-z17, .theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z17,
.theme-orange.dark .mat-elevation-z17,
.theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-orange .dark .mat-elevation-z18, .theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z18,
.theme-orange.dark .mat-elevation-z18,
.theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-orange .dark .mat-elevation-z19, .theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z19,
.theme-orange.dark .mat-elevation-z19,
.theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-orange .dark .mat-elevation-z20, .theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z20,
.theme-orange.dark .mat-elevation-z20,
.theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-orange .dark .mat-elevation-z21, .theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z21,
.theme-orange.dark .mat-elevation-z21,
.theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-orange .dark .mat-elevation-z22, .theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z22,
.theme-orange.dark .mat-elevation-z22,
.theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-orange .dark .mat-elevation-z23, .theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z23,
.theme-orange.dark .mat-elevation-z23,
.theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-orange .dark .mat-elevation-z24, .theme-orange .dark .mat-mdc-elevation-specific.mat-elevation-z24,
.theme-orange.dark .mat-elevation-z24,
.theme-orange.dark .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-orange .dark .mat-mdc-progress-bar,
.theme-orange.dark .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #f97316;
  --mdc-linear-progress-track-color: rgba(249, 115, 22, 0.25);
}
.theme-orange .dark .mat-mdc-progress-bar.mat-accent,
.theme-orange.dark .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #1e293b;
  --mdc-linear-progress-track-color: rgba(30, 41, 59, 0.25);
}
.theme-orange .dark .mat-mdc-progress-bar.mat-warn,
.theme-orange.dark .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #dc2626;
  --mdc-linear-progress-track-color: rgba(220, 38, 38, 0.25);
}
.theme-orange .dark .mat-mdc-form-field.mat-accent,
.theme-orange.dark .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #1e293b;
  --mdc-filled-text-field-focus-active-indicator-color: #1e293b;
  --mdc-filled-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mdc-outlined-text-field-caret-color: #1e293b;
  --mdc-outlined-text-field-focus-outline-color: #1e293b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(30, 41, 59, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(30, 41, 59, 0.87);
}
.theme-orange .dark .mat-mdc-form-field.mat-warn,
.theme-orange.dark .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #dc2626;
  --mdc-filled-text-field-focus-active-indicator-color: #dc2626;
  --mdc-filled-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mdc-outlined-text-field-caret-color: #dc2626;
  --mdc-outlined-text-field-focus-outline-color: #dc2626;
  --mdc-outlined-text-field-focus-label-text-color: rgba(220, 38, 38, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-orange .dark .mat-mdc-form-field.mat-accent,
.theme-orange.dark .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: #1e293b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(71, 85, 105, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(30, 41, 59, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-orange .dark .mat-mdc-form-field.mat-warn,
.theme-orange.dark .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: #1e293b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(71, 85, 105, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(241, 245, 249, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(220, 38, 38, 0.87);
  --mat-select-invalid-arrow-color: rgba(220, 38, 38, 0.87);
}
.theme-orange .dark .mat-mdc-standard-chip,
.theme-orange.dark .mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.theme-orange .dark .mat-mdc-standard-chip,
.theme-orange.dark .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-elevated-container-color: #394353;
  --mdc-chip-elevated-selected-container-color: #394353;
  --mdc-chip-elevated-disabled-container-color: #394353;
  --mdc-chip-flat-disabled-selected-container-color: #394353;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-selected-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
  --mat-chip-selected-disabled-trailing-icon-color: #fafafa;
  --mat-chip-selected-trailing-icon-color: #fafafa;
}
.theme-orange .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .theme-orange .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary,
.theme-orange.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.theme-orange.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: #431407;
  --mdc-chip-elevated-container-color: #f97316;
  --mdc-chip-elevated-selected-container-color: #f97316;
  --mdc-chip-elevated-disabled-container-color: #f97316;
  --mdc-chip-flat-disabled-selected-container-color: #f97316;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #431407;
  --mdc-chip-selected-label-text-color: #431407;
  --mdc-chip-with-icon-icon-color: #431407;
  --mdc-chip-with-icon-disabled-icon-color: #431407;
  --mdc-chip-with-icon-selected-icon-color: #431407;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #431407;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #431407;
  --mat-chip-selected-disabled-trailing-icon-color: #431407;
  --mat-chip-selected-trailing-icon-color: #431407;
}
.theme-orange .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .theme-orange .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent,
.theme-orange.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.theme-orange.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #1e293b;
  --mdc-chip-elevated-selected-container-color: #1e293b;
  --mdc-chip-elevated-disabled-container-color: #1e293b;
  --mdc-chip-flat-disabled-selected-container-color: #1e293b;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-orange .dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .theme-orange .dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn,
.theme-orange.dark .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn,
.theme-orange.dark .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #dc2626;
  --mdc-chip-elevated-selected-container-color: #dc2626;
  --mdc-chip-elevated-disabled-container-color: #dc2626;
  --mdc-chip-flat-disabled-selected-container-color: #dc2626;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.theme-orange .dark .mat-mdc-slide-toggle,
.theme-orange.dark .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: white;
}
.theme-orange .dark .mat-mdc-slide-toggle.mat-accent,
.theme-orange.dark .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #cbd5e1;
  --mdc-switch-selected-handle-color: #cbd5e1;
  --mdc-switch-selected-hover-state-layer-color: #cbd5e1;
  --mdc-switch-selected-pressed-state-layer-color: #cbd5e1;
  --mdc-switch-selected-focus-handle-color: #e2e8f0;
  --mdc-switch-selected-hover-handle-color: #e2e8f0;
  --mdc-switch-selected-pressed-handle-color: #e2e8f0;
  --mdc-switch-selected-focus-track-color: #475569;
  --mdc-switch-selected-hover-track-color: #475569;
  --mdc-switch-selected-pressed-track-color: #475569;
  --mdc-switch-selected-track-color: #475569;
}
.theme-orange .dark .mat-mdc-slide-toggle.mat-warn,
.theme-orange.dark .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #fca5a5;
  --mdc-switch-selected-handle-color: #fca5a5;
  --mdc-switch-selected-hover-state-layer-color: #fca5a5;
  --mdc-switch-selected-pressed-state-layer-color: #fca5a5;
  --mdc-switch-selected-focus-handle-color: #fecaca;
  --mdc-switch-selected-hover-handle-color: #fecaca;
  --mdc-switch-selected-pressed-handle-color: #fecaca;
  --mdc-switch-selected-focus-track-color: #dc2626;
  --mdc-switch-selected-hover-track-color: #dc2626;
  --mdc-switch-selected-pressed-track-color: #dc2626;
  --mdc-switch-selected-track-color: #dc2626;
}
.theme-orange .dark .mat-mdc-radio-button,
.theme-orange.dark .mat-mdc-radio-button {
  --mdc-form-field-label-text-color: white;
}
.theme-orange .dark .mat-mdc-radio-button.mat-primary,
.theme-orange.dark .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #f97316;
  --mdc-radio-selected-hover-icon-color: #f97316;
  --mdc-radio-selected-icon-color: #f97316;
  --mdc-radio-selected-pressed-icon-color: #f97316;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #f97316;
  --mat-radio-disabled-label-color: #475569;
}
.theme-orange .dark .mat-mdc-radio-button.mat-accent,
.theme-orange.dark .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #1e293b;
  --mat-radio-disabled-label-color: #475569;
}
.theme-orange .dark .mat-mdc-radio-button.mat-warn,
.theme-orange.dark .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #dc2626;
  --mat-radio-disabled-label-color: #475569;
}
.theme-orange .dark .mat-accent,
.theme-orange.dark .mat-accent {
  --mat-slider-ripple-color: #1e293b;
  --mat-slider-hover-state-layer-color: rgba(30, 41, 59, 0.05);
  --mat-slider-focus-state-layer-color: rgba(30, 41, 59, 0.2);
  --mdc-slider-handle-color: #1e293b;
  --mdc-slider-focus-handle-color: #1e293b;
  --mdc-slider-hover-handle-color: #1e293b;
  --mdc-slider-active-track-color: #1e293b;
  --mdc-slider-inactive-track-color: #1e293b;
  --mdc-slider-with-tick-marks-inactive-container-color: #1e293b;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-orange .dark .mat-warn,
.theme-orange.dark .mat-warn {
  --mat-slider-ripple-color: #dc2626;
  --mat-slider-hover-state-layer-color: rgba(220, 38, 38, 0.05);
  --mat-slider-focus-state-layer-color: rgba(220, 38, 38, 0.2);
  --mdc-slider-handle-color: #dc2626;
  --mdc-slider-focus-handle-color: #dc2626;
  --mdc-slider-hover-handle-color: #dc2626;
  --mdc-slider-active-track-color: #dc2626;
  --mdc-slider-inactive-track-color: #dc2626;
  --mdc-slider-with-tick-marks-inactive-container-color: #dc2626;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.theme-orange .dark .mdc-list-item__start,
.theme-orange .dark .mdc-list-item__end,
.theme-orange.dark .mdc-list-item__start,
.theme-orange.dark .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #f97316;
  --mdc-radio-selected-hover-icon-color: #f97316;
  --mdc-radio-selected-icon-color: #f97316;
  --mdc-radio-selected-pressed-icon-color: #f97316;
}
.theme-orange .dark .mat-accent .mdc-list-item__start,
.theme-orange .dark .mat-accent .mdc-list-item__end,
.theme-orange.dark .mat-accent .mdc-list-item__start,
.theme-orange.dark .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #1e293b;
  --mdc-radio-selected-hover-icon-color: #1e293b;
  --mdc-radio-selected-icon-color: #1e293b;
  --mdc-radio-selected-pressed-icon-color: #1e293b;
}
.theme-orange .dark .mat-warn .mdc-list-item__start,
.theme-orange .dark .mat-warn .mdc-list-item__end,
.theme-orange.dark .mat-warn .mdc-list-item__start,
.theme-orange.dark .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #f1f5f9;
  --mdc-radio-disabled-unselected-icon-color: #f1f5f9;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(241, 245, 249, 0.54);
  --mdc-radio-selected-focus-icon-color: #dc2626;
  --mdc-radio-selected-hover-icon-color: #dc2626;
  --mdc-radio-selected-icon-color: #dc2626;
  --mdc-radio-selected-pressed-icon-color: #dc2626;
}
.theme-orange .dark .mat-mdc-list-option,
.theme-orange.dark .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #431407;
  --mdc-checkbox-selected-focus-icon-color: #f97316;
  --mdc-checkbox-selected-hover-icon-color: #f97316;
  --mdc-checkbox-selected-icon-color: #f97316;
  --mdc-checkbox-selected-pressed-icon-color: #f97316;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f97316;
  --mdc-checkbox-selected-hover-state-layer-color: #f97316;
  --mdc-checkbox-selected-pressed-state-layer-color: #f97316;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-orange .dark .mat-mdc-list-option.mat-accent,
.theme-orange.dark .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1e293b;
  --mdc-checkbox-selected-hover-icon-color: #1e293b;
  --mdc-checkbox-selected-icon-color: #1e293b;
  --mdc-checkbox-selected-pressed-icon-color: #1e293b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1e293b;
  --mdc-checkbox-selected-hover-state-layer-color: #1e293b;
  --mdc-checkbox-selected-pressed-state-layer-color: #1e293b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-orange .dark .mat-mdc-list-option.mat-warn,
.theme-orange.dark .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-orange .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-orange .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.theme-orange.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-orange.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #f97316;
}
.theme-orange .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-orange .dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-orange.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-orange.dark .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #f97316;
}
.theme-orange .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-orange .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-orange .dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end,
.theme-orange.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-orange.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-orange.dark .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.theme-orange .dark .mat-mdc-tab-group, .theme-orange .dark .mat-mdc-tab-nav-bar,
.theme-orange.dark .mat-mdc-tab-group,
.theme-orange.dark .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #f97316;
  --mat-tab-header-disabled-ripple-color: #475569;
  --mat-tab-header-pagination-icon-color: #f1f5f9;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #f97316;
  --mat-tab-header-active-ripple-color: #f97316;
  --mat-tab-header-inactive-ripple-color: #f97316;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f97316;
  --mat-tab-header-active-hover-label-text-color: #f97316;
  --mat-tab-header-active-focus-indicator-color: #f97316;
  --mat-tab-header-active-hover-indicator-color: #f97316;
}
.theme-orange .dark .mat-mdc-tab-group.mat-accent, .theme-orange .dark .mat-mdc-tab-nav-bar.mat-accent,
.theme-orange.dark .mat-mdc-tab-group.mat-accent,
.theme-orange.dark .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #1e293b;
  --mat-tab-header-disabled-ripple-color: #475569;
  --mat-tab-header-pagination-icon-color: #f1f5f9;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #1e293b;
  --mat-tab-header-active-ripple-color: #1e293b;
  --mat-tab-header-inactive-ripple-color: #1e293b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #1e293b;
  --mat-tab-header-active-hover-label-text-color: #1e293b;
  --mat-tab-header-active-focus-indicator-color: #1e293b;
  --mat-tab-header-active-hover-indicator-color: #1e293b;
}
.theme-orange .dark .mat-mdc-tab-group.mat-warn, .theme-orange .dark .mat-mdc-tab-nav-bar.mat-warn,
.theme-orange.dark .mat-mdc-tab-group.mat-warn,
.theme-orange.dark .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #dc2626;
  --mat-tab-header-disabled-ripple-color: #475569;
  --mat-tab-header-pagination-icon-color: #f1f5f9;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #dc2626;
  --mat-tab-header-active-ripple-color: #dc2626;
  --mat-tab-header-inactive-ripple-color: #dc2626;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #dc2626;
  --mat-tab-header-active-hover-label-text-color: #dc2626;
  --mat-tab-header-active-focus-indicator-color: #dc2626;
  --mat-tab-header-active-hover-indicator-color: #dc2626;
}
.theme-orange .dark .mat-mdc-tab-group.mat-background-primary, .theme-orange .dark .mat-mdc-tab-nav-bar.mat-background-primary,
.theme-orange.dark .mat-mdc-tab-group.mat-background-primary,
.theme-orange.dark .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #f97316;
  --mat-tab-header-with-background-foreground-color: #431407;
}
.theme-orange .dark .mat-mdc-tab-group.mat-background-accent, .theme-orange .dark .mat-mdc-tab-nav-bar.mat-background-accent,
.theme-orange.dark .mat-mdc-tab-group.mat-background-accent,
.theme-orange.dark .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #1e293b;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-orange .dark .mat-mdc-tab-group.mat-background-warn, .theme-orange .dark .mat-mdc-tab-nav-bar.mat-background-warn,
.theme-orange.dark .mat-mdc-tab-group.mat-background-warn,
.theme-orange.dark .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #dc2626;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-orange .dark .mat-mdc-checkbox,
.theme-orange.dark .mat-mdc-checkbox {
  --mdc-form-field-label-text-color: white;
}
.theme-orange .dark .mat-mdc-checkbox.mat-primary,
.theme-orange.dark .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #431407;
  --mdc-checkbox-selected-focus-icon-color: #f97316;
  --mdc-checkbox-selected-hover-icon-color: #f97316;
  --mdc-checkbox-selected-icon-color: #f97316;
  --mdc-checkbox-selected-pressed-icon-color: #f97316;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f97316;
  --mdc-checkbox-selected-hover-state-layer-color: #f97316;
  --mdc-checkbox-selected-pressed-state-layer-color: #f97316;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-orange .dark .mat-mdc-checkbox.mat-warn,
.theme-orange.dark .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #dc2626;
  --mdc-checkbox-selected-hover-icon-color: #dc2626;
  --mdc-checkbox-selected-icon-color: #dc2626;
  --mdc-checkbox-selected-pressed-icon-color: #dc2626;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #dc2626;
  --mdc-checkbox-selected-hover-state-layer-color: #dc2626;
  --mdc-checkbox-selected-pressed-state-layer-color: #dc2626;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-orange .dark .mat-mdc-button.mat-primary,
.theme-orange.dark .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #f97316;
  --mat-text-button-state-layer-color: #f97316;
  --mat-text-button-ripple-color: rgba(249, 115, 22, 0.1);
}
.theme-orange .dark .mat-mdc-button.mat-accent,
.theme-orange.dark .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #1e293b;
  --mat-text-button-state-layer-color: #1e293b;
  --mat-text-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-orange .dark .mat-mdc-button.mat-warn,
.theme-orange.dark .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #dc2626;
  --mat-text-button-state-layer-color: #dc2626;
  --mat-text-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-orange .dark .mat-mdc-unelevated-button.mat-primary,
.theme-orange.dark .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #f97316;
  --mdc-filled-button-label-text-color: #431407;
  --mat-filled-button-state-layer-color: #431407;
  --mat-filled-button-ripple-color: rgba(67, 20, 7, 0.1);
}
.theme-orange .dark .mat-mdc-unelevated-button.mat-accent,
.theme-orange.dark .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #1e293b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-orange .dark .mat-mdc-unelevated-button.mat-warn,
.theme-orange.dark .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #dc2626;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-orange .dark .mat-mdc-raised-button.mat-primary,
.theme-orange.dark .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #f97316;
  --mdc-protected-button-label-text-color: #431407;
  --mat-protected-button-state-layer-color: #431407;
  --mat-protected-button-ripple-color: rgba(67, 20, 7, 0.1);
}
.theme-orange .dark .mat-mdc-raised-button.mat-accent,
.theme-orange.dark .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #1e293b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-orange .dark .mat-mdc-raised-button.mat-warn,
.theme-orange.dark .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #dc2626;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-orange .dark .mat-mdc-outlined-button.mat-primary,
.theme-orange.dark .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #f97316;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: #f97316;
  --mat-outlined-button-ripple-color: rgba(249, 115, 22, 0.1);
}
.theme-orange .dark .mat-mdc-outlined-button.mat-accent,
.theme-orange.dark .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #1e293b;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: #1e293b;
  --mat-outlined-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-orange .dark .mat-mdc-outlined-button.mat-warn,
.theme-orange.dark .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #dc2626;
  --mdc-outlined-button-outline-color: rgba(241, 245, 249, 0.12);
  --mat-outlined-button-state-layer-color: #dc2626;
  --mat-outlined-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-orange .dark .mat-mdc-icon-button.mat-primary,
.theme-orange.dark .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #f97316;
  --mat-icon-button-state-layer-color: #f97316;
  --mat-icon-button-ripple-color: rgba(249, 115, 22, 0.1);
}
.theme-orange .dark .mat-mdc-icon-button.mat-accent,
.theme-orange.dark .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #1e293b;
  --mat-icon-button-state-layer-color: #1e293b;
  --mat-icon-button-ripple-color: rgba(30, 41, 59, 0.1);
}
.theme-orange .dark .mat-mdc-icon-button.mat-warn,
.theme-orange.dark .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #dc2626;
  --mat-icon-button-state-layer-color: #dc2626;
  --mat-icon-button-ripple-color: rgba(220, 38, 38, 0.1);
}
.theme-orange .dark .mat-mdc-fab.mat-primary,
.theme-orange.dark .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #f97316;
  --mat-fab-foreground-color: #431407;
  --mat-fab-state-layer-color: #431407;
  --mat-fab-ripple-color: rgba(67, 20, 7, 0.1);
}
.theme-orange .dark .mat-mdc-fab.mat-accent,
.theme-orange.dark .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #1e293b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-orange .dark .mat-mdc-fab.mat-warn,
.theme-orange.dark .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #dc2626;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-orange .dark .mat-mdc-mini-fab.mat-primary,
.theme-orange.dark .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #f97316;
  --mat-fab-small-foreground-color: #431407;
  --mat-fab-small-state-layer-color: #431407;
  --mat-fab-small-ripple-color: rgba(67, 20, 7, 0.1);
}
.theme-orange .dark .mat-mdc-mini-fab.mat-accent,
.theme-orange.dark .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #1e293b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-orange .dark .mat-mdc-mini-fab.mat-warn,
.theme-orange.dark .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #dc2626;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-orange .dark .mat-accent,
.theme-orange.dark .mat-accent {
  --mdc-circular-progress-active-indicator-color: #1e293b;
}
.theme-orange .dark .mat-warn,
.theme-orange.dark .mat-warn {
  --mdc-circular-progress-active-indicator-color: #dc2626;
}
.theme-orange .dark .mat-badge-accent,
.theme-orange.dark .mat-badge-accent {
  --mat-badge-background-color: #1e293b;
  --mat-badge-text-color: white;
}
.theme-orange .dark .mat-badge-warn,
.theme-orange.dark .mat-badge-warn {
  --mat-badge-background-color: #dc2626;
  --mat-badge-text-color: white;
}
.theme-orange .dark .mat-datepicker-content.mat-accent,
.theme-orange.dark .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #1e293b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(30, 41, 59, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(30, 41, 59, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(30, 41, 59, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-orange .dark .mat-datepicker-content.mat-warn,
.theme-orange.dark .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #dc2626;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(220, 38, 38, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(220, 38, 38, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(220, 38, 38, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-orange .dark .mat-datepicker-toggle-active.mat-accent,
.theme-orange.dark .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #1e293b;
}
.theme-orange .dark .mat-datepicker-toggle-active.mat-warn,
.theme-orange.dark .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #dc2626;
}
.theme-orange .dark .mat-icon.mat-primary,
.theme-orange.dark .mat-icon.mat-primary {
  --mat-icon-color: #f97316;
}
.theme-orange .dark .mat-icon.mat-accent,
.theme-orange.dark .mat-icon.mat-accent {
  --mat-icon-color: #1e293b;
}
.theme-orange .dark .mat-icon.mat-warn,
.theme-orange.dark .mat-icon.mat-warn {
  --mat-icon-color: #dc2626;
}
.theme-orange .dark .mat-step-header.mat-accent,
.theme-orange.dark .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #1e293b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #1e293b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #1e293b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-orange .dark .mat-step-header.mat-warn,
.theme-orange.dark .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #dc2626;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #dc2626;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #dc2626;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-orange .dark .mat-toolbar.mat-primary,
.theme-orange.dark .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #f97316;
  --mat-toolbar-container-text-color: #431407;
}
.theme-orange .dark .mat-toolbar.mat-accent,
.theme-orange.dark .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #1e293b;
  --mat-toolbar-container-text-color: white;
}
.theme-orange .dark .mat-toolbar.mat-warn,
.theme-orange.dark .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #dc2626;
  --mat-toolbar-container-text-color: white;
}